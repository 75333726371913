@import 'node_modules/normalize.css/normalize';

#elm-debugger-overlay {
  box-sizing: content-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// ARIC-13790 Remove compass.sass from ARIC

// Source: https://github.com/drublic/Sass-Mixins/blob/master/partials/_user-select.scss
@mixin user-select($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin input-placeholder {
  &:-ms-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

// https://github.com/drublic/Sass-Mixins/blob/master/partials/_appearance.scss
@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin ellipsis($no-wrap: true) {
  @if $no-wrap {
    white-space: nowrap;
  }
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin iframe-dashboard {
  box-sizing: border-box;

  &__container {
    height: 100%;
  }

  iframe {
    height: 95%;
    width: 100%;
    position: absolute;
    top: 40px;
    border: $panel-header-background-color 1px solid;
  }

  &--full-screen {
    background: #FFF;
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 1;
  }

  .truncate {
    @include ellipsis;
  }

  .align-right {
    text-align: right;
  }

  a {
    color: $global-link-color;
    cursor: pointer;
  }

  .panel__title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .panel-title {
    margin: 0;
  }

  .panel-input {
    margin: 0;
    font-size: 11px;
  }
}

// END ARIC-13790 Remove compass.sass from ARIC

@import '../node_modules/leaflet/dist/leaflet';
@import '../bower_components/leaflet.zoomhome/dist/leaflet.zoomhome';
@import '../node_modules/leaflet.markercluster/dist/MarkerCluster';

@import 'Functions';
@import 'Variables';
@import 'Helpers';
@import 'Util';
@import 'Arrows';
@import 'Tooltip';

@import 'Typography';
@import 'Font';
@import 'SpacingAndLayout';
@import 'Headers';

@import 'Action';
@import 'FormInput';

@import 'ActiveFilters';
@import 'AdvancedSearch';
@import 'Banner';
@import 'Breadcrumb';
@import 'BroadcastMessage';
@import 'Button';
@import 'Chart';
@import 'ChequeControl';
@import 'Codeblock';
@import 'Communications';
@import 'DateInputPolyfill';
@import 'Documentation';
@import 'Detokenizer';
@import 'EnvironmentTitle';
@import 'EditorToolbar';
@import 'Form';
@import 'FormField';
@import 'GMap';
@import 'CustomSelectInput';
@import 'Header';
@import 'HeaderSearch';
@import 'Icon';
@import 'JsonViewer';
@import 'JuicyAceEditor';
@import 'Link';
@import 'MainBody';
@import 'Modal';
@import 'MultiSelectInput';
@import 'NotificationList';
@import 'Pagination';
@import 'Panel';
@import 'PercentageBar';
@import 'RangeInput';
@import 'ReportOverview';
@import 'ServerDrivenField';
@import 'Sidebar';
@import 'SidebarRight';
@import 'Sparkline';
@import 'StepTracker';
@import 'Table';
@import 'TableFiltering';
@import 'TablePerPage';
@import 'Tag';
@import 'TakeControl';
@import 'TimeWindowSelector';

@import 'Component/AmdlEditor';
@import 'Component/AmdlMetrics';
@import 'Component/AmdlState';
@import 'Component/AmdlTester';
@import 'Component/AutoGrowTextarea';
@import 'Component/BulkSelect';
@import 'Component/Cards';
@import 'Component/Checkbox';
@import 'Component/ColumnSettings';
@import 'Component/DataGrid';
@import 'Component/DescriptionList';
@import 'Component/DiffViewer';
@import 'Component/DropdownActions';
@import 'Component/EventFilter';
@import 'Component/FileUploaderDropzoneOverlay';
@import 'Component/Filter/Filter';
@import 'Component/FormField/MultiSelect';
@import 'Component/IncidentEventJson';
@import 'Component/JsonView';
@import 'Component/ListPageFilters';
@import 'Component/NumberFormat';
@import 'Component/Omnisearch';
@import 'Component/TenancyOverview';
@import 'Component/UserDisplayName';
@import 'Component/UserProfile';
@import 'Component/NetworkAnalysis';
@import 'Component/Action/Search';
@import 'Component/Reports/Globe3D';
@import 'Component/Reports';
@import 'Component/Reports/Overview';
@import 'Component/Tableau';

@import 'DSV2/FileUploader/FileUploaderDropzone';
@import 'DSV2/FileUploader/FileUploaderItem';

@import 'Page/Helper/Page';

@import 'Page/Analytics/Analytics';
@import 'Page/Analytics/Aggregator/Details';
@import 'Page/Analytics/Header';
@import 'Page/Analytics/Model';
@import 'Page/Analytics/Model/Classifier';
@import 'Page/Analytics/Rule';
@import 'Page/Analytics/Status';
@import 'Page/Analytics/VisualAmdl';
@import 'Page/Bootstrap';
@import 'Page/Case/Age';
@import 'Page/Case/Audit';
@import 'Page/Case/CaseWork';
@import 'Page/Case/Details';
@import 'Page/Case/ListSummaryCell';
@import 'Page/Case/Overview';
@import 'Page/Case/Transactions';
@import 'Page/Case/List';
@import 'Page/Dashboard/Default';
@import 'Page/Dashboard/Advanced';
@import 'Page/DataConnectors';
@import 'Page/DataList/Details';
@import 'Page/DataList/Items';
@import 'Page/DataList/List';
@import 'Page/Diagnostic/Show';
@import 'Page/EventList';
@import 'Page/EventSearch';
@import 'Page/Events/List';
@import 'Page/Incident/AdvancedSearch';
@import 'Page/Incident/AmdlState';
@import 'Page/Incident/BiometricsSidebar';
@import 'Page/Incident/Details';
@import 'Page/Incident/EventAttributeClassification';
@import 'Page/Incident/List';
@import 'Page/Landing';
@import 'Page/Report/Main';
@import 'Page/Report/Management';
@import 'Page/Login';
@import 'Page/Sandbox/List';
@import 'Page/Sandbox/Status';
@import 'Page/ServiceConfiguration';
@import 'Page/Session';
@import 'Page/Settings/CaseWorkflow';
@import 'Page/Settings/ConfigurationEditor';
@import 'Page/Settings/Configuration';
@import 'Page/Settings/ImportExport';
@import 'Page/Settings/IncidentFilter/Details';
@import 'Page/Settings/Teams';
@import 'Page/Settings/User/Details';
@import 'Page/Settings/UserRole/List';
@import 'Page/Settings/UserRoleDetails';
@import 'Page/System/SystemStatus';
@import 'Page/System/AsyncTasks';
@import 'Page/System/ConfigManagement';
@import 'Page/System/Diagnostic/List';
@import 'Page/System/EventSequence/Show';
@import 'Page/System/ModelMetrics';
@import 'Page/System/QueueViewer';
@import 'Page/System/RestApi';
@import 'Page/System/StormStatus';
@import 'Page/System/SchemaEditor';
@import 'Page/System/ProcessingControl';
@import 'Page/System/UsageMetrics';
@import 'Page/Tenant/List';
@import 'Page/UIConfigGroup/Edit';
@import 'Page/VersionHistory';

@import 'Component/AnalyticalDiff';

$background-color: #fff;
$page-header-analytics-background-color: #823969;
$page-header-sandbox-background-color: #f12c59;
$font-family: Roboto, $default-font-stack;

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

// scoping custom scrollbars to `body` prevents juicy-ace-editor incorrently
// calculating the scrollbar height
body,
body * {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    position: absolute;

    &:horizontal {
      height: 8px;
    }

    &:vertical {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    background-clip: padding-box !important;
    border-radius: 3px;
    color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9de;
    background-clip: padding-box !important;
    background-color: rgba(113, 114, 116, 0.5);

    &:horizontal {
      box-shadow: inset -2px 0, inset -3px 0, inset 2px 0, inset 3px 0;
      min-width: 36px;
    }

    &:vertical {
      box-shadow: inset 0 -2px, inset 0 -3px, inset 0 2px, inset 0 3px;
      min-height: 36px;
    }

    &:hover {
      background-color: rgba(113, 114, 116, 0.8);
    }
  }

  &::-webkit-scrollbar-track {
    background: #f3f3f3;
    box-shadow: inset 0 -4px 0 0, inset 0 4px 0 0;
  }
}

html {
  box-sizing: border-box;
}

html,
body,
.main {
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $background-color;
  font-family: $font-family;
  font-size: 13px;
}

.application-wrapper,
.application {
  height: 100%;
}

.application {
  outline: 0;
}

.cabinet {
  display: flex;
  height: 100%;
}

.front-frame {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}


.page-header-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .page-header {
    flex: 0 0 54px;
  }

  .page-header-analytics {
    background-color: $page-header-analytics-background-color;
  }

  .page-header-sandbox {
    background-color: $page-header-sandbox-background-color;
  }
}

.main-container {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  overflow: hidden;
  position: relative;

  &,&:focus,&:active {
    outline: none;
  }
}

.report-wrapper {
  display: flex;
  margin-top: 12px;
  border: 1px solid $report-border-color;
  box-shadow: 3px 3px lighten($report-border-color, 12%);
}

.skip-links-container {
  position: absolute;
}

.skip-links {
  transition: top 0.5s ease-out;
  top: -58px;

  &:focus, &:active {
    color: inherit !important;
    background-color: #fff !important;
    position: fixed !important;
    padding: 0 10px !important;
    border: solid 1px #c94d8c !important;
    z-index: 100;
    top: 9px;
    left: 5px;
    transition: top 0.5s ease-in;
    line-height: 32px;
  }
}

.leaflet-control-zoomhome-home {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAC7UlEQVRIie1VP0gbYRx933cXDUa9gLGHkCWCipuICIIIRglxEaH+AZcMQhzEyU03EXTywMFDODAgiiBdzNJNhC4idhPFxSGIUhAPYjQ35F4HE5vEGOnQdumD33L3eH9+33ecIIk/CflH1f+GgQrgE4D+aqSHhwdMTk7i9vYW29vb6OnpgRACkUgE19fXUBQFhmFgZGQEJCGEAIBvAH6oALpJfsk/LCYAAB4fH5FIJHB+fo6npydsbW2ho6MDjY2NSKVSuLu7g6qqsG27JBTJESHEV7iuGyVJ13VZjqurK05MTNDj8dDv9zMYDFJVVQ4NDfH09JTNzc0EQEVRaFkWi3XyugDJaLlwLpfjyckJh4eHCYC6rtOyLCaTSQ4MDFAIwa6uLtbW1r4xKJgUDNSiShBCIJvNYm9vD8vLy7i5uUE4HEY8HkcymUQqlcL8/Dza29txcHAAx3Eqnlnxiksa3N/fc3V1lQ0NDVQUhdPT00wkEhwcHKSUkgAYCoVomiYNw2AgEChpULQe5nVfDFzX5eXlJcfHx+n1etnS0sLFxUWura1R13UKIQjgdfx+P+fm5rizs8Pe3l56PB729/fz6OiIjuMU8r4YZDKZ6PHxMfv6+l4TbmxscHZ2lvX19SXC5TM6Osr9/X3GYjEKIRgMBmkYBtPp9C+DeDwe7e7uZk1NDcPhMDc3N19TVRMHQCklOzs7aZoml5aWqOs6NU3j+vo6n5+fXwzOzs6iKysrXFhY4O7uLltbWz8ULp+6ujoahsHDw0POzMzw4uKi9AzS6TQzmQxN0/xt8cKMjY0xl8vRtu2S70AFAJ/PV/G6FRCJRNDW1gYAcBwHlmW94ZCElBKappVcVfXNva2AWCyGqakpAIBt2xUNys0KumpVZh5SSkgpIYSAoihVhYUQJYFl8ctq+KjlexxZXMfn88Hr9b6brhqampoq8lQhxHcAnwEgEAhA0zRks9nfaiClRCgUeuXlV/UdAMT/f/I/N/gJU0ohgUO24jwAAAAASUVORK5CYII=");
}

.leaflet-container {
  font-family: $font-family;
}

.x-axis-group line, .x-axis-group path, .y-axis-group line, .y-axis-group path {
  fill: none;
  shape-rendering: crispEdges;
}

.hide {
  display: none;
}

.d3-tip-no-arrow {
  background: #fff;
  padding: 2px;
}

.ui-note {
  @include ui-note;
}
