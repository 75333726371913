.incident-list-advanced-search {
  margin-top: 10px;
  padding-top: 10px;
  letter-spacing: normal;

  &__header {
    font-size: 14px;
  }

  &__component {
    padding: 10px 0;
  }

  .icon-tree-structure {
    margin-right: 10px;
  }

  .advanced-search__inner {
    max-width: 250px;
    width: 250px;
  }

  .advanced-search__input {
    background: #fff;
  }

  .advanced-search .icon {
    width: 14px;
    height: 17px;
    fill: #b3b3b3;
  }

  &--open {
    .advanced-search__results {
      @include font-primary;
      padding: 0 8px;
    }

    dl {
      margin: 0;
    }

    dt, dd {
      font-size: 15px;
      margin: 5px 0;
      padding: 5px;
    }

    dt {
      display: flex;
      justify-content: space-between;
      color: $global-primary-color;
      font-weight: $weight-03;
    }

    dt:not(:first-child) {
      border-top: 2px solid color(greyLight);
      padding-top: 12px;
    }

    dd,
    .advanced-search__result-button {
      @include ellipsis;
      color: $primary-text-color;
    }

    dd {
      margin-left: 0;
      padding-top: 2px;
      padding-bottom: 2px;
      text-decoration: underline;
      cursor: pointer;
      font-weight: $weight-03;
    }

    .advanced-search__result-button {
      display: block;
      width: 100%;
    }
  }
}
