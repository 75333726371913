.diff-viewer {
  $black: #222;
  $dark-grey: #3a3939;
  $light-grey: #dadada;
  $green: #80e27e;
  $red: #ffa4a2;
  $carbon-tile-background: #f4f4f4;

  color: var(--dv-default-color, #{$black});
  white-space: pre-wrap;

  &--light {
    .diff-viewer__unified,
    .diff-viewer__side-by-side-part {
      background-color: #fff;
    }
  }

  &__unified,
  &__side-by-side-part {
    background-color: $carbon-tile-background;
  }

  &__side-by-side {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  &__side-by-side-part {
    padding: 6px 10px;
  }

  &__unified {
    padding: 10px;
  }

  del::before,
  del::after,
  ins::before,
  ins::after {
    @include visually-hidden;
  }

  del {
    text-decoration: line-through;
    background-color: var(--dv-del-background-color, #{$red});
    color: var(--dv-del-color, $dark-grey);
  }

  del::before { content: var(--dv-deletion-start-label, ' [deletion start] '); }
  del::after { content: var(--dv-deletion-end-label, ' [deletion end] '); }

  ins {
    text-decoration: underline;
    background-color: var(--dv-ins-background-color, #{$green});
    color: var(--dv-ins-color, #{$black});
  }

  ins::before { content: var(--dv-insertion-start-label, ' [insertion start] '); }
  ins::after { content: var(--dv-insertion-end-label, ' [insertion end] '); }
}
