.page-version-history {
  &__row-expanded-details {
    display: flex;
    flex-flow: row wrap;

    &-live-dates {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      width: auto;
      margin: 2px 0;

      .icon {
        display: block;
        margin-right: 4px;
        fill: currentColor;
      }
    }
  }

  &__row-history-list {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    list-style: none;
    word-wrap: break-word;

    &-item {
      display: flex;
      justify-content: space-between;
      min-width: 0;
      padding: 6px 0;

      & + & {
        border-top: 1px solid color(greyLight);
      }
    }

    &-left,
    &-right {
      display: block;
      flex: 1 1 50%;
    }

    &-right {
      padding-left: 16px;
    }

    &-user {
      font-weight: $weight-03;
      word-break: break-all;
    }

    &-comment {
      word-break: break-all;
    }
  }

  .table__cell--custom {
    padding-top: 0;
    white-space: normal;
  }

  .table__cell {
    .display-name {
      .icon {
        fill: currentColor;
        margin-right: 2px;
        position: relative;
        top: 1px;
      }
    }
  }
}
