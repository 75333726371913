.page-diagnostic-list {
  &__context {
    display: flex;

    .icon {
      display: block;
    }
  }

  &__context-name {
    margin-left: 4px;
  }
}
