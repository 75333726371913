.case-list-summary-cell {
  display: flex;

  &__item {
    display: block;
    @include ellipsis;
  }

  &__extras {
    flex: 0 0 auto;
  }
}
