.page-data-list-details {
  &__add-to-list {
    font-size: 13px;
    border: 2px solid #E3E3E3;
    padding: 10px;

    &-title {
      @include font-primary(700);
      display: block;
      font-size: 18px;
      margin: 8px 0 0;
      color: $secondary-text-color;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    &-subtitle {
      display: block;
      color: $secondary-text-color;
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      font-weight: 600;
      margin: 8px 0 0;
      line-height: 16px;
    }
  }
}
