// list layout
.cards {
  $card-target-width: 250px;
  $card-spacing: $layout-03;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($card-target-width, 1fr));
  grid-auto-rows: minmax(0, auto);
  grid-gap: $card-spacing;
  padding: $card-spacing;

  .card {
    width: auto;
    height: 100%;
  }
}

// individual layout
.card {
  $background-color-default: #fff;
  $background-color-primary-container-context: $ui-01;
  $border-color: $ui-03;
  $gutter: $spacing-05;
  $extra-info-spacing: 4px;
  $description-max-lines: 3;
  $media-size: 40px;

  display: flex;
  flex-flow: column nowrap; // needs to be nowrap for overflow/ellipsis to work!
  justify-content: space-between;
  flex: 0 0 auto;
  width: 250px;
  min-height: 130px;
  background-color: $background-color-default;
  border: 1px solid $border-color;

  &--container-context-primary {
    background-color: $background-color-primary-container-context;
  }

  &__main {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    padding: $spacing-04 $gutter $spacing-03;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin: 0 0 $spacing-04 0;
  }

  &__overline-title {
    @include body-short-text-00;
    @include ellipsis;
    display: block;
    width: 100%;
    min-width: 0; // flex overflow fix
    word-break: break-all;
  }

  &__title {
    $line-height: 1.25rem; // this should match heading-02

    @include heading-02;
    @include fixed-height-text-content(2, $line-height);
    word-break: break-word;
  }

  &__secondary-actions {
    position: relative;
    flex: 0 0 auto;
  }

  &__secondary-actions-trigger {}

  &__secondary-actions-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    z-index: 1;

    &.is-hidden {
      display: none;
    }
  }

  &__body {}

  &__description {
    $line-height: 1.125rem; // this should match body-short-text-01

    @include body-short-text-01;
    @include fixed-height-text-content($description-max-lines, $line-height);
    margin-bottom: $spacing-04;
  }

  &__extra-info-items {
    display: flex;
    flex-flow: row nowrap;
  }

  &__extra-info-item {
    @include body-short-text-00;
    @include flex-ellipsis;
    flex: 0 1 auto;
    margin: 0 $extra-info-spacing $extra-info-spacing 0;
    color: $secondary-text-color;
    word-break: break-all;

    &:not(:last-child) {
      // FIXME: this will not work as expected when things flow onto new lines
      border-right: 1px solid $border-color;
      padding-right: $extra-info-spacing + 1px;
    }
  }

  &__footer {
    padding-bottom: $spacing-03;
  }

  &__primary-actions {
    padding: $spacing-03 $gutter 0;
    border-top: 1px solid $border-color;
  }

  &__default-image {
    background-color: #fff;
  }

  &__media {
    display: flex;
    align-items: flex-start;
    flex: 1;
    min-width: 0; // flex overflow fix

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $media-size;
      height: $media-size;
      margin-right: $spacing-05;

      img,
      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-body {
      flex: 1;
      min-width: 0; // flex overflow fix
    }
  }
}
