$fieldset-border-color: #bdbbbb;

.form {
  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    min-width: 0; // fix flexing (https://github.com/philipwalton/flexbugs/issues/44#issuecomment-137855115)

    &--bordered {
      & + & {
        padding-top: 10px;
        border-top: 1px solid $fieldset-border-color;
      }
    }

    p {
      color: color(grey);
    }
  }

  &__section {
    position: relative;

    & + & {
      border-top: 1px solid #dedede;
      padding-top: 26px;
    }

    .button-wrapper {
      display: flex;
      position: absolute;
      top: -14px;
      right: -14px;
    }

    .form__section-button + .form__section-button {
      margin-left: 4px;
    }

    .form__section-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      background-image: linear-gradient(-180deg, #fdfdfd 0%, #f7f7f7 100%);
      border: 1px solid #c7c7c7;
      border-radius: 100px;

      &--remove {
        position: absolute;
        top: -14px;
        right: -14px;
      }

      .icon-wrapper {
        display: flex;
      }

      .icon {
        height: 15px;
        width: 15px;
        cursor: pointer;
        fill: #727272;
      }
    }
  }

  &__section-header {
    display: flex;
  }

  &__section-actions {
    margin-left: auto;
  }

  &__section-title {
    @include font-primary(600);
    margin-top: 0;
    margin-bottom: 28px;
    color: $global-primary-color;
    font-size: 19px;
    width: 100%;

    legend & {
      // prevent legends overlapping the fieldset
      // https://codepen.io/absolutholz/pen/jWgBMo
      // Adding float: to a <legend> makes it behave less like a legend and more like a normal element
      // https://stackoverflow.com/questions/5818960/why-wont-my-legend-element-display-inline
      // Which begs the question, why use legend in the first place?
      float: left;

      & + * {
        clear: both;
      }
    }

    .checkbox__label {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__section-subtitle {
    color: #bdbdbd;
  }

  &__section-subtitle2 {
    margin: 1.5em 0 1em;
    color: color(grey);
    font-size: 15px;
  }

  &__section--block {
    @include dropdown;
    padding: 20px;

    & + .form__section--block {
      margin-top: 20px;
    }

    .form-field__form-input-wrapper {
      flex: 0 0 190px;
    }
  }

  &__section-add-action {
    width: 100%;
    margin-top: 20px;
  }

  &__section-footer {
    margin-top: 20px;
  }

  &__footer {
    display: flex;
    text-align: right;

    .action + .action {
      margin-left: 8px;
    }
  }

  &--tooltip {
    .form-field {
      display: block;
      margin-bottom: 5px;
    }

    .form-field__label-wrap {
      @include font-secondary(600);
      font-size: 13px;
      padding-right: 0;
      max-width: none;
    }
  }
}
