.list-page-filters {
  // Active filters
  &__active-filters {
    margin-bottom: 10px;
  }

  &__active-filter-remove-button {
    @include reset-button;

    display: flex;
    color: inherit;
    font: inherit;
    cursor: pointer;
  }

  // Sidebar
  &__sidebar {
    width: 600px;

    &:focus {
      outline: none;
    }

    .focus-trap {
      &:focus {
        outline: none;
      }
    }
  }

  // Main view
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  &__title {
    @include heading-03;
    margin: 0;
    margin-right: 6px;
  }

  &__header-actions {
    margin-left: auto;

    > * + * {
      margin-left: 6px;
    }
  }

  &__filter {
    padding: 16px 0;

    &:not(:first-child) {
      border-top: 1px solid $ui-04;
    }
  }

  &__filter-header {
    display: flex;
    align-items: center;
    margin: 0 0 4px;
  }

  &__filter-header-actions {
    display: flex;
    margin-left: auto;
    padding-left: 6px;
  }

  &__filter-title {
    @include body-short-text-01;

    display: block;
    margin: 0;
  }

  // range filter
  &__range-filter {
    &--fill-width {
      .list-page-filters {
        &__range-filter-fields {
          flex-flow: row nowrap;
        }

        &__range-filter-field {
          flex: 1 1 40%;
          min-width: 0;

          .form-field {
            width: 100%;
            margin: 0;
          }

          .form-field__label-wrap {
            flex: 1 1 auto;
          }

          .form-field__form-input-wrapper {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__range-filter-fields {
    display: flex;
    flex-flow: row wrap;
  }

  &__range-filter-field {
    flex: 0 1 auto;
    display: flex;
    flex-flow: row wrap;

    .form-field__label-wrap {
      flex-grow: 0;
    }
  }

  &__range-filter-field + &__range-filter-field {
    margin-left: 10px;
  }

  // checkbox list filter
  &__checkbox-list-filter {
    .list-page-filters {
      &__filter-header {
        border-bottom: 1px solid $ui-03;
      }
    }
  }

  &__checkbox-list-filter-counts {
    display: inline-block;
    margin-left: 4px;
    font-size: 12px;
  }

  &__checkbox-list-filter-search {
    margin-bottom: -1px;
  }

  &__checkbox-list-filter-options {}

  &__checkbox-list-filter-options-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 178px;
    font-size: 16px;
  }

  &__checkbox-list-filter-options-error {
    padding: 10px 0;
    color: $color-danger;
    word-break: break-word;
  }

  &__checkbox-list-filter-options-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__checkbox-list-filter-option {
    border-bottom: 1px solid $ui-03;
  }

  &__checkbox-list-filter-option-button {
    // required for ellipsis
    max-width: 100%;

    padding: 10px 0;
  }
}
