.incident-event-json {
  &__error {
    color: $color-danger;
  }

  &__section {
    margin: 20px 0;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
  }

  &__header-error {
    font-weight: $weight-03;
    color: $color-danger;
  }

  &__title {
    @include section-title;
    margin-bottom: 0;
  }

  &__copy-button {
    @include reset-button;
    position: relative;
    top: 2px;
    margin-left: 4px;
    padding: 0 6px;
    color: $secondary-text-color;
    transition: color 0.15s ease;

    &:hover {
      color: #000;
    }

    .icon {
      display: block;
      fill: currentColor;
    }
  }
}
