.broadcast-message {
  $padding-horizontal: 14px;

  background: #fff;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1);

  &.is-hidden {
    display: none !important;
  }

  * {
    &::-webkit-scrollbar {
      &:horizontal {
        height: 6px;
      }

      &:vertical {
        width: 6px;
      }
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 0;
      color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8;

      &:hover {
        background-color: rgba(#c1c2c3, 0.8);
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 5px $padding-horizontal;
    font-size: 14px;
    background-color: color-customisation(bannerMessageDefaultBackgroundColor, color(purpleDark));
    color: color-customisation(bannerMessageDefaultColor, #fff);

    .action {
      line-height: 24px;

      &,
      .icon {
        height: 26px;
      }

      &--icon-only {
        width: 26px;
      }

      &:focus {
        outline: 1px solid $focus-indicator-color-light;
        outline-offset: 2px;
      }
    }
  }

  &__title {
    margin: 0;
    font-size: 14px;
    font-weight: $weight-03;
  }

  &__header-count {
    .icon {
      margin-right: 6px;
      fill: currentColor;
    }
  }

  &__header-actions {
    .action {
      margin-left: 6px;
    }
  }

  &__select-all {
    margin: 0 8px;

    .icon {
      fill: $input-checkbox-background-color-active;
      width: 18px;
      height: 18px;
      margin-top: 5px;
    }

    .icon-square {
      fill: $input-checkbox-background-color;
    }
  }

  &__body {
    padding: $padding-horizontal;
    overflow-x: auto;
  }

  &__message-content {
    min-height: 56px;
    max-height: 120px;
  }

  &__form-field-group {
    .form-field {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__message-info {
    line-height: 1.2;
    cursor: pointer;

    &-title {
      @include reset-button;
      @include ellipsis;
      display: block;
      margin-bottom: 2px;
      font-weight: $weight-04;
      font-size: 13px;
      color: $primary-text-color;

      &:focus {
        outline: 1px solid $focus-indicator-color-light;
        outline-offset: 2px;
      }
    }

    &-meta {
      display: flex;
      flex-flow: row nowrap;
      font-size: 13px;
    }

    &-meta-separator {
      margin: 0 2px;
    }

    &-meta-timestamp {
      display: inline-block;
      flex: 0 0 auto;
    }

    &-meta-user {
      @include ellipsis;
      display: inline-block;
      flex: 1 1 auto;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    &--expanded {
      .broadcast-message__checkbox {
        float: left;
        padding-right: 10px;

        .form-field__label-wrap {
          display: none;
        }

        .form-field__label {
          padding-right: 0;
        }
      }

      .broadcast-message__list-item {
        padding: 12px $padding-horizontal;
        border-bottom: 1px solid color(greyLight);

        &:hover {
          background-color: #fbf1f6;
        }

        &.is-selected {
          background-color: $global-primary-selected-background-color;

          .broadcast-message__message-info-title {
            color: $global-primary-color;
          }
        }
      }

      .broadcast-message__message-info-title {
        margin-bottom: 6px;

        &:focus {
          outline: 1px solid $focus-indicator-color;
          outline-offset: 2px;
        }
      }
    }

    &--summary {
      .broadcast-message__list {
        &-item {
          margin-bottom: 2px;
          padding: 12px;
          background: rgba(#fff, 0.1);
          border-radius: 6px;

          &:hover {
            background: rgba(#fff, 0.15);
          }
        }
      }

      .broadcast-message__message-info-title {
        margin-bottom: 6px;
        text-decoration: underline;
        color: inherit;
      }

      .broadcast-message__message-info-meta {
        opacity: 0.9;
      }
    }
  }

  // summary
  &--summary {
    .broadcast-message__header {
      padding-left: 10px;
      padding-right: 7px;
    }
  }

  &__summary-view {
    width: 256px;
    max-height: 160px;
    padding: 0 7px 7px;
    background-color: color-customisation(bannerMessageDefaultBackgroundColor, color(purpleDark));
    color: color-customisation(bannerMessageDefaultColor, #fff);
  }

  &__summary-show-toggle {
    font-weight: $weight-04;
  }

  // expanded view
  &--expanded {
    .broadcast-message__header {
      padding-right: 7px;
    }

    .broadcast-message__message-info-meta {
      color: $secondary-text-color;
    }
  }

  &__expanded-view {
    display: flex;
    flex-flow: row nowrap;
    width: 660px;
    height: 330px;
    padding: 0;
  }

  &__expanded-empty {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    padding: $padding-horizontal;
    color: $primary-text-color;
    font-size: 15px;
  }

  &__expanded-message-list-wrap {
    width: 40%;
    height: 100%;
    flex: 1 1 auto;
    overflow-x: auto;
    padding: 8px;
  }

  &__expanded-message-current {
    width: 70%;
    height: 100%;
    flex: 1 1 auto;
    margin-left: 8px;
    padding: $padding-horizontal $padding-horizontal $padding-horizontal 10px;
    overflow-x: auto;
    border-left: 1px solid #c1c1c1;

    .broadcast-message__message-info-title {
      white-space: normal;
      font-size: 14px;
    }
  }

  &__expanded-acknowledge {
    margin-bottom: 10px;

    .icon-check {
      fill: color(green);
    }
  }

  &__expanded-message-current-content {
    margin-top: 12px;
    color: $primary-text-color;
  }

  // new message view
  &__new-view {
    width: 400px;
    max-height: 450px;

    .form-field {
      display: block;
      margin-bottom: 18px;

      &__label {
        display: block;
        flex: 0 0 100%;
      }

      &__form-input-wrapper {
        display: block;
        flex: 0 0 100%;
      }
    }

    .radio__label {
      margin-bottom: 0;
    }
  }

  &__new-view-form-actions {
    display: flex;
    justify-content: space-between;
  }

  .application--main-fullscreen & {
    display: none;
  }
}

#message-recipient-subtype-users,
#message-recipient-subtype-teams,
#message-recipient-subtype-roles {
  .form-field__label {
    display: none;
  }
}
