$sidebar-active-background-color: #fff;
$sidebar-active-color: $global-primary-color;
$sidebar-background-color: #e3e3e3;
$sidebar-border-color: #cbcbcb;
$sidebar-color: color-customisation(sidebarColor, $primary-text-color);
$sidebar-hover-background-color: #eee;
$sidebar-state-active-background-color: $sidebar-active-background-color;
$sidebar-item-icon-width: 18px;
$sidebar-item-icon-height: 18px;
$sidebar-item-icon-spacing-right: 10px;
$sidebar-item-vertical-spacing: 13px;
$sidebar-item-horizontal-spacing: 15px;

.sidebar {
  flex: 0 0 $sidebar-width;
  height: 100%;
  overflow: auto;
  font-size: 15px;
  background-color: color-customisation(sidebarBackgroundColor, $sidebar-background-color);
  color: $sidebar-color;
  padding-top: 10px;

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &__incident-list-header {
    padding: 13px 0;
  }

  @media screen and (max-width: $header-sm-break) {
    flex: 0 0 $sidebar-sm-width;
    overflow: hidden;

    .application:not(.application--sidebar-expanded) & * {
      display: none;
    }

    .application--sidebar-expanded & {
      flex: 0 0 $sidebar-width;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 280px;
      overflow: auto;
      z-index: 1;
    }
  }

  &--reports {
    &__top {
      background-color: inherit;
      padding: 10px;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .manage-reports {
      display: block;
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__navigation {
    @include font-primary(700);
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    padding-bottom: $message-broadcast-height;

    a,
    &-link {
      cursor: pointer;
    }

    a,
    .navigation-directory .navigation-directory__title,
    &-link {
      background: transparent;
      border: none;
      border-top: 1px solid $sidebar-border-color;
      color: $sidebar-color;
      display: flex;
      font-weight: $weight-03;
      outline: 0;
      padding: $sidebar-item-vertical-spacing $sidebar-item-horizontal-spacing;
      position: relative;
      text-align: left;
      text-decoration: none;
      white-space: nowrap;
      width: 100%;
      line-height: 18px;

      &:focus {
        outline: 1px solid $global-link-color;
        outline-offset: -1px;
      }
    }

    a,
    .navigation-directory .navigation-directory__title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    li {
      &.with-padding {
        padding: 0 15px;
      }

      &:first-child {
        a, .sidebar__navigation-link {
          border-top-color: transparent;
        }
      }

      a:hover, .sidebar__navigation-link:hover {
        background-color: color-customisation(sidebarNavItemHoverBackgroundColor, $sidebar-hover-background-color);
      }


      &:not(.active) {
        a:hover, .sidebar__navigation-link:hover {
          color: color-customisation(sidebarNavItemHoverColor, $sidebar-color);
        }
      }

      &, & + li {
        a:hover {
          border-top-color: transparent;
        }
      }

      &.active {
        a, .sidebar__navigation-link {
          background: $sidebar-active-background-color;
          border-left: 3px solid $sidebar-active-color;
          padding-left: 12px;
        }

        & + li {
          a, .sidebar__navigation-link {
            border-top-color: transparent;
          }
        }
      }

      &.is-disabled {
        a, .sidebar__navigation-link {
          opacity: 0.5;
        }

        &,
        a {
          @include user-select(none);

          pointer-events: none;
        }
      }
    }

    .active {
      a,
      .sidebar__navigation-link {
        border-top-color: transparent;
        color: $sidebar-active-color;
      }

      .icon {
        fill: currentColor;
      }
    }

    .icon {
      width: $sidebar-item-icon-width;
      height: $sidebar-item-icon-height;
      margin-right: $sidebar-item-icon-spacing-right;
      vertical-align: top;
      fill: currentColor;
    }

    .icon-clipboard-list {
      position: relative;
      left: -2px;
    }

    .navigation-directory {
      &:hover {
        background-color: inherit;
      }

      &__sub-list {
        list-style: none;
        padding-left: 10px;

        li {
          border-top: 1px solid $sidebar-border-color;

          a {
            border: 0;
          }
        }
      }

      &__title {
        display: flex !important;
      }

      &__title-label {
        @include ellipsis;
        flex-grow: 1;
      }

      &__create {
        width: initial;
        border: 0;
        padding: 0;
        text-align: right;

        &:hover {
          color: $header-link-color-active;

          .icon {
            fill: $header-link-color-active;
          }
        }
      }
    }
  }

  &--incident-list {
    .sidebar__navigation-link {
      display: flex;
    }

    .icon {
      flex: 0 0 18px;
    }

    &__title {
      @include ellipsis;
      flex: 1;
    }

    &__count {
      text-align: right;
      padding-right: 9px;
    }

    &__filter {
      border-top: 1px solid #cbcbcb;
      display: flex;
      padding: 0 15px;

      .icon {
        height: 44px;
      }

      .form-input--select {
        flex: 1;

        .icon-sort {
          bottom: 0;
          right: 0;
          width: 13px;
        }
      }

      .form-input__input--select {
        background: inherit;
        border: 0;
        border-radius: 0;
        color: inherit;
        font-size: 15px;
        font-weight: $weight-03;
        line-height: 32px;
        padding-left: 2px;

        &:focus {
          border: 0 !important;
          box-shadow: none;
          outline: 1px solid $global-link-color;
        }
      }
    }

    .active + li {
      .sidebar--incident-list__filter {
        border-top-color: transparent;
      }
    }
  }

  .title {
    display: inline-block;
    width: 226px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }

  .application--main-fullscreen & {
    display: none;
  }

  .multirange__input--ghost {
    --range-color: #{color-customisation(sidebarPrimaryColor, color(purple))};
  }
}

.sidebar-left__backdrop {
  display: none;

  @media screen and (max-width: $header-sm-break) {
    display: block;
    @include backdrop;
    @include stack-context(sidebar-right-backdrop);
    left: 280px;
  }
}

.sidebar-count-link {
  display: flex;

  &__icon {
    flex: 0 0 auto;
  }

  &__label {
    @include flex-ellipsis;
    padding-right: 4px;
  }

  &__count {
    @include flex-ellipsis;
    margin-left: auto;
  }
}

// A select input inside a navigation sidebar (e.g. filtering)
.sidebar-select {
  $input-icon-with: 12px;

  position: relative;
  display: flex;
  align-items: center;

  &__info-icon {
    position: absolute;
    left: $sidebar-item-horizontal-spacing;

    .icon {
      display: block;
      width: $sidebar-item-icon-width;
      height: $sidebar-item-icon-height;
      vertical-align: initial;
    }
  }

  &__input-icon {
    position: absolute;
    right: $sidebar-item-horizontal-spacing;

    .icon {
      display: block;
      width: $input-icon-with;
      height: auto;
      margin: 0;
      vertical-align: initial;
    }
  }

  &__input {
    @include ellipsis;
    // remove the default select input icon
    @include appearance(none);

    width: 100%;
    border: 0;
    height: 100%;
    // 1px of margin prevents the focus outline being clipped
    margin: 1px;
    padding:
      $sidebar-item-vertical-spacing
      ($sidebar-item-horizontal-spacing + $input-icon-with)
      $sidebar-item-vertical-spacing
      ($sidebar-item-horizontal-spacing + $sidebar-item-icon-width + $sidebar-item-icon-spacing-right);
    background: transparent;
    font-weight: $weight-03;

    &:focus {
      outline: 1px solid $global-primary-color;
    }
  }
}

.sidebar-collapsable-list {
  &__toggle-button {
    display: flex;

    &:hover {
      background-color: color-customisation(sidebarNavItemHoverBackgroundColor, $sidebar-hover-background-color) !important;
    }
  }

  &__toggle-button-icon {
    margin-left: auto;
    margin-right: -4px;
    padding-left: 4px;

    .icon {
      display: block;
      margin: 0;
    }
  }

  &__items {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sidebar-count-link__icon {
    margin-left: 16px;
  }
}
