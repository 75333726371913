.page-aggregator-detail {
  &__repeatable-inputs {
    margin-top: 16px;
  }

  &__content-wrap {
    display: flex;
    flex-flow: row wrap;
  }

  &__content-item {
    width: 100%;
    flex: 1 0 auto;

    @media screen and (min-width: 1800px) {
      width: 50%;

      &:first-child {
        padding-right: 10px;
      }
    }
  }

  &__risk-level-section {
    .form-field:last-child {
      margin-bottom: 0;
    }
  }

  &__risk-level-add,
  &__inputs-add {
    margin-top: 10px;
  }

  &__is-required-icon {
    padding-left: 2px;

    .icon {
      fill: $global-primary-color;
      vertical-align: top;
      height: 20px;
      width: 7px;
    }
  }

  &__required-list-empty {
    color: $color-danger;
    font-weight: $weight-02;
    padding-left: 2px;
  }
}
