.tableau-component {
  width: 100%;
  height: 100%;

  tableau-component {
    display: block;
    width: 100%;
    height: 100%;

    .wrapper {
      height: 100%;
    }
  }
}
