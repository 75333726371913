.page-case-list {
  // cells
  &__relative-time-cell {
    &.is-past {
      color: $color-danger;
    }
  }

  &__bulk-action-label {
    display: flex;
    align-items: center;

    &--delete {
      color: $color-danger;
    }

    &--noRisk {
      .icon {
        color: $status-success;
      }
    }

    &--escalate {
      .icon {
        color: $status-warning;
      }
    }

    &--risk {
      .icon {
        color: $color-danger;
      }
    }
  }
}
