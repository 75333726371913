.incident-amdl-state {
  display: flex;
  align-items: center;

  &__show-more-button {
    display: flex;
    padding-left: 6px;
    color: color(grey);
  }

  &__list {
    padding: 0 10px;
    text-align: left;
    list-style: none;
  }

  &__list-item {
    margin-bottom: 8px;
  }

  &__list-item-content {
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    padding-right: 4px;
    font-weight: $weight-03;
    color: color(grey);
  }

  &__custom-field {
    flex: 0 1 auto;

    &--single {
      @include ellipsis;
    }

    &--all {
      flex: 1;
      color: #bdbdbd;
    }

    .flag {
      // force image width so that tooltip width is calculated correctly
      width: 40px;
    }
  }

  &__tooltip {
    max-width: 600px;
    max-height: 250px;
    overflow: auto;
    white-space: normal;
    word-break: break-word;
  }
}
