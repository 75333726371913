.event-filter {
  $search-input-height: 30px;
  $event-types-list-item-horizontal-padding: 2px;
  $event-types-button-horizontal-padding: 10px;
  $button-list-item-max-width: 230px;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  width: 100%;
  height: 258px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;

  &__inner {
    display: flex;
    flex-flow: column;
    flex: 1;
    max-width: 100%;
  }

  &__actions {
    flex: 0 0 auto;
    padding: 10px;
    border-top: 1px solid #ccc;
    text-align: right;
  }

  &__action-clear {
    font-weight: $weight-03;
    font-size: 14px;
  }

  &__sections {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    min-height: 0; // fix scroll in Firefox
  }

  &__section {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    padding: 10px 20px;
    min-width: 0;
    border-left: 1px solid #ccc;

    &--event-types { flex-grow: 2; }
    &--alert-activity { flex-grow: 1; }
    &--analyst-activity { flex-grow: 2; }
    &--alert-activity { border-left: 0; }
  }

  &__section-header {
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    min-height: $search-input-height;
    margin-bottom: 6px;
    font-size: 13px;
  }

  &__section-title {
    margin: 0;
    font-size: inherit;
    font-weight: $weight-03;
  }

  &__section-subtitle {
    margin-left: 4px;
    color: $global-primary-color;
    font-weight: $weight-02;
  }

  &__filter-button {
    width: 100%;
    color: $secondary-text-color;
    font-weight: $weight-03;
    background: $primary-background-color;
    text-align: left;
    padding-left: 10px;

    .icon {
      --primary-color: currentColor;
      fill: currentColor;
    }

    &.is-selected {
      border-color: $global-primary-color;
      color: $primary-text-color;

      .icon {
        --primary-color: $global-primary-color;
        fill: $global-primary-color;
      }

      &,
      &:hover {
        background: $global-primary-selected-background-color;
      }
    }
  }

  &__button-list {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
  }

  &__button-list-item {
    max-width: $button-list-item-max-width;
    flex: 1 0 100%;
    padding: 4px;
  }

  // event types
  &__event-types {
    display: flex;
    flex: 0 1 auto;
    min-height: 0; // fix scroll in Firefox
  }

  &__event-types-header-text {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-flow: column nowrap;
    flex: 1 0 auto;
    margin: 4px 8px 4px 0;
  }

  &__event-types-title {
    display: flex;
    flex: 0 0 auto;
    margin: 0 0 8px;
  }

  &__event-types-bulk-toggle {}

  &__event-types-search {
    max-width: 164px;

    input::placeholder {
      font-size: 13px;
    }
  }

  &__event-types-list {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    margin-left: -($event-types-list-item-horizontal-padding + $event-types-button-horizontal-padding);
  }

  &__event-types-item {
    display: block;
    min-width: 0;
    flex: 0 0 50%;
    padding: $event-types-list-item-horizontal-padding;
    white-space: nowrap;

    @media screen and (min-width: 1350px) {
      flex-basis: 33%;
    }
  }

  &__event-types-button {
    @include ellipsis;
    display: block;
    width: 100%;
    text-align: left;
    padding: 6px $event-types-button-horizontal-padding;

    &.is-selected {
      color: $global-primary-color;
      background-color: $global-primary-selected-background-color;
    }

    &.is-applicable {
      font-weight: $weight-04;
    }
  }

  // incident activity
  &__alert-activity {
    display: flex;
    flex: 0 1 auto;
    min-height: 0;
  }

  &__analyst-activity {
    $gutter: 10px;

    display: flex;
    min-height: 0; // fix scroll in Firefox

    &-inner {
      padding: 4px;
      overflow: auto;
    }

    &-export-button {
      width: calc(100% - #{$gutter});
      margin-left: $gutter * 0.5;
      margin-top: 8px;
    }

    @media screen and (min-width: 1150px) {
      .event-filter__button-list {
        margin-left: -($gutter * 0.5); // fix alignment
      }

      .event-filter__button-list-item {
        flex: 1 0 $button-list-item-max-width;
        max-width: 50%;
        padding-left: $gutter * 0.5;
        padding-right: $gutter * 0.5;
      }

      &-export-button {
        width: calc(100% - #{$gutter * 0.5});
        margin-left: 0;
      }
    }
  }
}
