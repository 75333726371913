.custom-select {
  $input-vertical-padding: 3.5px;
  $input-horizontal-padding: 8px;
  $input-icon-width: 12px;
  $input-icon-padding: 4px;
  $search-icon-size: 12px;
  $search-icon-margin: 4px;
  $option-vertical-padding: 12px;
  $option-horizontal-padding: 10px;
  $option-border-size: 1px;
  $options-visible-count: 5; // assumes a single line of text
  $default-option-height: 42px; // magic number, will change based on lines of text visible, font size, line height, border, etc.

  @include font-secondary(400);
  position: relative;
  font-size: 13px;
  color: $input-select-color;

  &__input {
    position: relative;
    flex: 1;
    padding: $input-vertical-padding $input-horizontal-padding;
    background: #fff;
    cursor: pointer;
  }

  &__input-inner {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__input-label {
    @include ellipsis;
    flex: 1;
    min-width: 0; // fixes flexing

    &.is-hidden {
      visibility: hidden;
    }
  }

  &__input-icon {
    display: flex;
    flex: 0;
    align-items: center;
    margin-left: auto;
    padding-left: $input-icon-padding;

    .icon {
      display: block;
      width: $input-icon-width;
      height: 13px;
      fill: currentColor;
    }
  }

  &__validation-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .icon {
      fill: color(red);
      height: 13px;
      width: 7px;
    }
  }

  &__search {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: $input-vertical-padding $input-horizontal-padding;
    opacity: 0;

    .icon {
      display: block;
      width: $search-icon-size;
      height: $search-icon-size;
      margin-right: $search-icon-margin;
      fill: $input-search-icon-color;
    }

    &.is-visible {
      opacity: 1;

      input {
        cursor: text;
      }
    }

    input {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: {
        top: $input-vertical-padding;
        bottom: $input-vertical-padding;
        left: #{$input-horizontal-padding + $search-icon-margin + $search-icon-size};
        right: #{$input-horizontal-padding + $input-icon-padding + $input-icon-width};
      }
      border: 0;
      outline: 0;
      background: transparent;
      cursor: default;
    }

    input.custom-select__search-input-with-options {
      padding-left: 8px;
    }
  }

  &__dropdown {
    @include dropdown;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    margin-top: 2px;
    background-color: #fff;
    z-index: 2;

    visibility: hidden;
  }

  &__no-results {
    display: flex;
    align-items: center;
    padding: 10px;
    color: color(grey);

    .icon {
      fill: currentColor;
      margin-right: 6px;
    }
  }

  &__options {
    max-height: ($options-visible-count * $default-option-height) + 2px;
    margin: 0;
    padding: 1px;
    overflow-x: auto;
    list-style: none;
  }

  &__option {
    @include ellipsis;
    padding: $option-vertical-padding $option-horizontal-padding;
    cursor: pointer;

    &:active {
      outline: none;
    }

    &.is-selected {
      background-color: $input-focus-background-color;
      color: $global-primary-color;
      font-weight: $weight-03;
    }
  }

  &:hover {
    .custom-select__input {
      border-color: $input-hover-border-color;
    }
  }

  &.is-open,
  &:focus {
    outline: 0;

    .custom-select__input {
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      border: 1px solid $input-focus-border-color;
    }

    .custom-select__option {
      border: $option-border-size solid transparent;

      &:hover,
      &.is-focused {
        background-color: $input-focus-background-color;
      }

      &.is-focused {
        border-color: $global-primary-color;
      }
    }
  }

  &.is-open {
    .custom-select__dropdown {
      transform: scale(1);
      visibility: visible;

      &.is-hidden {
        visibility: hidden;
      }

      &.is-disabled {
        background-color: #f5f5f5;
      }
    }
  }

  &[data-disabled] {
    pointer-events: none;
    cursor: default;

    .custom-select__input {
      background-color: $input-disabled-background-color;
      border-color: $input-disabled-border-color;
    }
  }
}
