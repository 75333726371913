.editor-toolbar {
  background-color: #EFEFEF;
  display: flex;
  padding: 5px 5px 0;
  margin: 2px 0 3px;

  &__group {
    padding-bottom: 5px;
    display: flex;

    & + & {
      margin-left: auto;
    }

    & > * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__dropdown-action {
    position: relative;
  }

  &__action {
    background-color: inherit;
    border: 0;
    padding: 0;
    line-height: 32px;

    & + & {
      border-left: 1px solid #D2D2D2;
    }

    .icon {
      height: 32px;
      vertical-align: top;
      width: 13px;

      &:first-child {
        margin-left: 15px;
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 7px;
        margin-right: 8px;
      }
    }
  }

  &__dropdown-menu {
    @include dropdown;
    background-color: #FFF;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    padding: 15px;
    min-width: 280px;
  }
}
