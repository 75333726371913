@import 'Activity';

$panel-header-color: #000;
$panel-header-background-color: #e3e3e3;

.page-incident-details {
  outline: 0;

  .incident-toggle-filter-button-wrap {
    position: relative;

    .incident-toggle-filter-button-arrow {
      $border-color: #d9d9d9;
      $background-color: #fff;

      @include arrow-box(top, 6px, 1px, $background-color, $border-color);
      border-color: transparent; // remove the arrow-box "box" border
      @include horizontal-center;
      bottom: -8px;
      z-index: 2; // stack above the event filter

      display: none;
    }

    &.is-expanded {
      .incident-toggle-filter-button-arrow {
        display: block;
      }
    }
  }

  .incident-toggle-filter-button {
    &.has-active-filters {
      color: $global-primary-color;

      .icon {
        fill: currentColor;
      }
    }
  }

  .event-filter {
    // stack above 'no activity found'
    z-index: 8; // stack above the incident details but below the toggle button arrow
    outline: none;
  }

  &__back-to-list {
    text-align: center;
    margin-bottom: 15px;
  }

  .sidebar-right__backdrop {
    background: rgba(0, 0, 0, 0.15);
  }

  &__page-title {
    display: flex;
    align-items: baseline;
    font-size: 25px;
    margin: 0 !important;

    .detokenize__container {
      min-width: 0;
    }
  }

  &__page-subtitle {
    @include font-secondary(400);
    font-size: 13px;
    margin-left: 8px;
  }

  &__timer {
    color: $secondary-text-color;
    line-height: 16px;
    padding-right: 16px;

    .icon {
      vertical-align: top;
      fill: currentColor;
      margin-right: 5px;
    }
  }

  &__main-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  &__read-only-header {
    background: #fff;
    margin-bottom: 15px;
    color: color(greyFaded);

    &-title {
      padding: 10px;
      font-weight: $weight-03;
      display: flex;
      justify-content: center;

      .icon {
        fill: currentColor;
        margin-right: 5px;
        vertical-align: text-top;
        width: 30px;
        height: 16px;
      }
    }

    &-body {
      padding: 0 4px 10px;
      font-size: 13px;
      justify-content: center;
      text-align: center;
    }

    &-user {
      @include ellipsis;
      display: block;
      width: 100%;
      line-height: 1.3; // prevent descenders being clipped
    }

    .display-name {
      .icon {
        fill: currentColor;
        position: relative;
        top: 1px;
        margin-right: 2px;
      }
    }
  }

  &__review-block {
    position: relative;
    display: flex;
    padding: 10px 15px 20px;
    margin-bottom: 15px;
    color: $secondary-text-color;
    fill: currentColor;
    flex-direction: column;
    background-color: $primary-background-color;

    &::before {
      @include brand-gradient-small;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      flex: 0 0 auto;
      width: 100%;
      height: 4px;
    }

    &--readonly {
      // TODO style properly
      opacity: 0.3;
      pointer-events: none;
    }

    &-header {
      display: flex;
      line-height: 30px;

      .icon-review {
        margin-right: 7px;
        vertical-align: top;
        width: 18px;
        height: 30px;
      }

      h1 {
        margin: 0;
        @include font-primary;
        font-size: 18px;
      }
    }

    &-alert-count {
      font-weight: $weight-03;
      font-size: 13px;
      color: $secondary-text-color;
      text-align: right;
      flex: 1;
    }

    .action--theme-no-risk,
    .action--theme-risk {
      border-color: #C8C8C8;

      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .icon {
        width: 16px;
      }
    }

    &-risk,
    &-no-risk,
    &-case {
      padding: 8px 10px;
      margin: 0 0 5px;
      font-weight: $weight-03;
      color: #5B5B5B;
      border-radius: 0 0 3px 3px;
      border: 2px solid;

      .checkbox__label {
        margin: 0;
      }

      .form-field {
        margin: 6px 0 0;

        &__label {
          font-size: 13px;
          padding: 0;
          margin: 6px 0 0;
        }
      }
    }

    &-risk {
      border-color: $review-risk;

      .zelm-action--theme-default {
        width: 100%;
      }
    }

    &-no-risk {
      border-color: $review-no-risk;
    }

    .zelm-form-field {
      margin: 10px 0;
    }

    &-case {
      background-color: $ui-02;
      border: 1px solid $global-primary-color;

      .form-field:first-child {
        margin-top: 0;
      }
    }

    &-hint {
      color: #5b5b5b;
      display: block;
      margin: 6px 0;
      font-size: 12px;
      font-weight: $weight-02;
    }

    &-risk-tag,
    &-no-risk-tag,
    &-case-tag {
      display: flex;
      width: 100%;
      padding: 5px 9px;
      margin-top: 5px;
      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      box-sizing: border-box;
      font-size: 13px;
      font-size: 12px;
      font-weight: $weight-02;
      color: #000;

      &-label {
        flex: 1;
        @include ellipsis;
      }

      &-remove {
        border: 0;
        height: 13px;
        width: 11px;
        padding: 0;

        .icon {
          height: 13px !important;
          margin: 0 !important;
        }
      }
    }

    &-tag-list {
      margin: 8px 0;
    }

    &-no-risk-tag {
      background: #E5F9D6;
      border-color: #72BC44;
    }

    &-risk-tag {
      background: #FFDDE2;
      border-color: #FF587C;
    }

    &-case-tag {
      background: #f6f6f6;
      border-color: color(grey);
      color: #555;
    }
  }

  &__review-block-start-action {
    @include font-primary;
    width: 100%;
    height: 34px;
    margin-top: 5px;
    font-size: 15px;
  }

  &__review-block-case-management {
    margin-top: 12px;
    padding-top: 7px;
    border-top: 1px solid $ui-03;
  }

  &__review-block-warnings {
    margin: 10px 0;
    font-size: 13px;

    .ui-note {
      padding: 10px;
    }
  }

  &__refer-block {
    &-refer-to {
      margin: 8px 0 0;
    }
  }

  &__add-to-list-block {
    &-data-lists {
      margin-top: 8px;
    }
  }

  &__add-to-list-block,
  &__pending-block,
  &__refer-block,
  &__review-block {
    &-comment {
      margin: 8px 0 6px;
    }

    &--bring-to-front {
      z-index: 10;
    }

    &-actions {
      display: flex;
      margin-top: 6px;
    }
  }

  &__actions-block {
    background-color: #FFF;

    &--transparent {
      background: transparent;
    }

    &--readonly {
      opacity: 0.3;
      pointer-events: none;
    }

    .sidebar-switch-view {
      .icon {
        margin-right: 5px;
        fill: #AB3363;
      }

      .version {
        font-size: 16px;
      }

      .action {
        font-weight: $weight-04;
        color: #AB3363;
        text-decoration: underline;
      }
    }
  }

  &__action-section-button:focus {
    outline-offset: 5px;
  }

  &__action-section {
    position: relative;
    padding: 10px 15px;

    &-button {
      text-align: left;
      width: 100%;
      line-height: 13.8px;
    }

    &--bring-to-front {
      z-index: 10;
      background: #FFF;
    }

    &--readonly {
      // TODO style this properly
      background-color: #F1F1F1;
      color: color(greyFaded);
    }

    &:hover:not(.page-incident-details__action-section--readonly) {
      background: #F9F9F9;
    }

    & + & {
      border-top: 2px solid #E3E3E3;
    }

    &-header {
      display: block;

      &--clickable {
        cursor: pointer;
      }
    }

    &-title {
      @include font-primary(700);
      display: block;
      font-size: 18px;
      margin: 8px 0 0;
      color: $secondary-text-color;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    &-subtitle {
      display: block;
      color: $secondary-text-color;
      font-size: 13px;
      font-weight: $weight-03;
      margin: 8px 0 0;
      line-height: 16px;

      a {
        color: $global-link-color;
        text-decoration: underline;
      }
    }

    &__secondary-action {
      @include font-secondary(600);
      cursor: pointer;
    }

    .form-field {
      display: block;
    }

    &-tags {
      .icon {
        height: 12px;
        width: 12px;
        display: block;
        margin: 10px auto;
      }
    }

    &-tag {
      display: flex;
      width: 100%;
      padding: 5px 9px;
      margin-top: 5px;
      background-color: #E7E7E7;
      border-radius: 3px;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: $weight-03;

      &-label {
        flex: 1;
        @include ellipsis;
      }

      &-remove {
        border: 0;
        height: 13px;
        width: 11px;
        padding: 0;

        .icon {
          fill: #8A8A8A;
          height: 13px !important;
          margin: 0 !important;
        }
      }
    }
  }

  &__action-section-pending-timeout {
    margin-top: 10px;

    .form-field,
    .checkbox__label {
      margin-bottom: 6px;
    }

    .form-input--unit {
      display: flex !important; // overwrite the media query
    }

    .form-input__unit-value,
    .form-input__unit-units {
      flex: 1;
    }
  }

  &__incident-summary {
    flex: 0 0 auto;
    padding-bottom: 0;

    .panel__header {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
      justify-content: space-between;
    }

    .panel__body {
      padding-top: 5px;
    }
  }

  &__tablist {
    display: flex;
  }

  &__incident-tabs {
    flex: 1 1 auto;
    min-height: unset !important;
    overflow: hidden;

    .panel__wrapper {
      height: 100%;
    }

    .panel__group {
      align-items: flex-start;
    }

    .panel__body {
      position: relative;
      padding: 0;
      overflow: hidden;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
  }

  &__filter-button {
    border: 1px solid $input-border-color;

    .icon {
      position: relative;
      left: 1px;
      margin: 0 0 0 4px !important;
    }

    &:hover {
      border-color: $input-hover-border-color;
    }
  }

  &__filter-tooltip {
    .static-tooltip__content {
      max-height: 400px;
      overflow: auto;
      width: 200px;
      padding: 0;
    }
  }

  &__filter-label {
    @include font-secondary(600);
    margin-right: 6px;
    font-size: 13px;
    color: color(grey);
  }

  &__filter-list {
    @include font-secondary(400);
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    color: color(grey);
  }

  &__filter-item {
    padding: 8px 12px;
    cursor: pointer;

    &,
    .checkbox__label-text {
      @include ellipsis;
    }

    &.is-active {
      background-color: $global-primary-selected-background-color;
    }

    .checkbox__label {
      margin: 0;
    }
  }

  &__tooltip {
    position: relative;

    .action {
      .icon-angle-down {
        margin-right: 0;
        margin-left: 3px;
      }
    }
  }

  &__tooltip-arrow {
    @include horizontal-center;
    bottom: calc(-#{$tooltip-margin} - 1px);
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: index($stack, message-tooltip) + 1;

    &::before {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
    }

    &--top {
      border-bottom-color: $tooltip-border-color;
      border-width: 0 6px 6px;

      &::before {
        top: 1px;
        left: -5px;
        border-bottom-color: $tooltip-background-color;
        border-width: 0 5px 5px;
      }
    }
  }

  &__tooltip-content {
    &-actions {
      display: flex;
      margin-top: 15px;
    }

    &-action-cancel {
      flex: 0 1 auto;
    }

    .panel--tooltip {
      width: 300px;

      .panel {
        &__body {
          margin: 0 13px;
          padding-top: 0;
        }

        &__section-form-fields {
          padding-top: 0;
        }
      }

      .form-field {
        &__validation-message {
          font-weight: $weight-02;
        }

        &__label {
          max-width: none;
          padding-right: 0;
          white-space: normal;
        }

        &__hint {
          font-weight: $weight-02;
        }
      }
    }

    &-actions {
      display: flex;
      margin-top: 15px;
    }

    &-action-submit {
      @include ellipsis;
      flex: 1;
      margin-right: 5px;
    }

    &-action-cancel {
      flex: 0 1 auto;
    }
  }

  &__data-list-select {
    margin: 10px 0 6px;
  }

  &__data-list-select-no-json-paths {
    @include font-secondary(600);
    display: block;
    margin: 4px;
    font-size: 13px;
    color: $color-danger;
  }

  &__data-list-option {
    &--list-name {
      font-weight: $weight-03;
      line-height: 18px;
    }

    &--scope-name {
      font-size: 13px;
      line-height: 10px;
    }
  }

  .incident-search-group {
    display: flex;

    &__find {
      display: flex;
      align-items: center;
      color: $secondary-text-color;
      padding: 0 2px;
    }

    &__find-info {
      padding: 0 10px;
      background-color: $secondary-button-background;
      border: 1px solid $secondary-button-border;
      border-left-width: 0;
      font-size: 13px;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }

    &__find-desc {
      color: $secondary-text-color;
    }

    &__find-navigation {
      background: $secondary-button-background;
      font-size: 17px;
      border: 1px solid $secondary-button-border;
      border-left-width: 0;
      display: flex;

      .action--icon-only {
        width: 20px;
        height: 20px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &__toggle-mode {
      padding: 0;
      display: flex;
      align-items: center;

      > .action {
        border-left-width: 0;
        border-radius: 0 3px 3px 0;
        padding: 0;
        background-color: $secondary-button-background;
        background-image: none;
        width: 30px;
        height: 30px;
      }

      .action .icon {
        height: 20px;
        width: auto;
        margin-right: 0;
        transform: rotate(0);
        transition: transform 0.2s ease;
      }

      .action:focus {
        outline-offset: 0;
      }

      .static-tooltip__body {
        font-size: 10px;
      }
    }

    &--toggle-mode-open {
      .action .icon {
        transform: rotate(180deg);
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .action {
      border-radius: 0;
      border-left: 1px solid #c7c7c7;
      background-color: #fff;

      .icon {
        border-right: 0;
      }
    }

    .static-tooltip__arrow {
      border-width: 0 6px 6px;
    }

    .static-tooltip__content {
      border-width: 1px;
      padding: 5px;

      .focus-trap {
        display: flex;
        flex-flow: column;
        align-items: start;
      }

      .action {
        font-size: 10px;
        line-height: 20px;
        border-radius: 0;
        width: 100%;
      }
    }

    &--highlighted {
      background-color: $activity-highlight-color;
    }
  }
}

.details-tab {
  overflow: auto;

  &__panel-empty-state,
  &__panel-error-state {
    display: block;
    margin: 0;
    padding: 10px;
    text-align: center;
  }

  &__panel-error-state {
    color: color(red);
  }

  .main-default .panel-incident-details-event-json {
    display: none;
  }

  &__summary-header,
  &__summary-content {
    display: flex;
    justify-content: center;
  }

  &__summary-content-wrapper {
    flex-direction: column;
    width: 650px;
  }

  &__summary-header-cell {
    border-right: 1px solid #DEDEDE;
    margin: 40px 0;
    padding: 0 50px;
    text-align: center;

    &:last-child {
      border-right: 0;
    }

    &-value {
      color: $primary-text-color;
      font-size: 40px;
    }

    &-label {
      color: $primary-text-color;
    }
  }

  &__summary-tags-warning {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    fill: color(yellow);
  }

  &__summary-content-row {
    color: $primary-text-color;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
  }

  &__summary-content-row:nth-child(2n) {
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
  }

  &__summary-content-cell-label {
    margin: 2px 0;
    padding: 2px 0;
    text-transform: capitalize;
    width: 33%;
  }

  &__summary-content-cell-value {
    width: 67%;
    flex-wrap: wrap;
  }

  .x-axis-labels {
    margin-left: 72px;
    margin-right: 72px;
    display: flex;
  }

  .x-axis-label {
    color: $secondary-text-color;
    text-align: center;
    width: 20%;
  }

  &__charts {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;

    > div {
      width: calc(50% - 10px);
    }

    &_distribution {
      margin-right: 5px;

      .bar-rect {
        fill: #c94784;
      }
    }

    &_breakdown {
      margin-left: 5px;

      rect {
        fill: #c94784;
      }
    }

    .panel__header {
      padding: 10px;
      text-transform: capitalize;
    }

    &_container {
      height: 180px;
    }

    .axis text {
      font-size: 13px;
    }

    .events rect {
      fill: #aaa;
    }

    .unreviewed-alerts rect {
      fill: #e87c02;
    }

    .reviews-no-risk rect {
      fill: #6ebe36;
    }

    .reviews-risk rect {
      fill: #eb495d;
    }
  }

  &__charts_distribution {
    rect {
      fill: #c94784;
    }
  }

  &__extra-panels {
    margin-top: 25px;

    &:first-child {
      margin-top: 10px;
    }

    .panel {
      padding-bottom: 0;
    }

    .panel__header {
      background: transparent;
    }

    .panel__title {
      color: #727272;
    }

    .panel__body {
      padding: 0;
    }

    .table__group-header th {
      background: #e7dce4;
      line-height: 20px;
      font-weight: $weight-03;
      border-right: 1px solid #fff;

      &:last-child {
        border-right: 0;
      }
    }
  }

  &__extra-panels-key-val {
    > div {
      padding: 8px;
    }

    .key {
      color: color(greyFaded);
      font-weight: $weight-04;
    }

    .value {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 1300px) {
    .x-axis-labels {
      margin-left: 60px;
      margin-right: 60px;
    }

    .x-axis-label {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 1100px) {
    .x-axis-labels {
      margin-left: 72px;
      margin-right: 72px;
    }

    &__charts {
      display: block;

      > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .x-axis-label {
      font-size: 13px;
    }
  }
}

.sidebar--incident-details {
  overflow: scroll;

  &.bring-to-front {
    @include stack-context(sidebar-incident-details-review);
    position: relative;

    .page-incident-details__left-sidebar {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.page-incident-details__left-sidebar {
  @include stack-context(sidebar-incident-details);
  position: relative;
  padding: 15px 12px $message-broadcast-height 20px;
  min-height: 100%;
}

.details-sidebar {
  overflow-y: scroll;
  display: flex;
  flex-flow: column;

  &__json-display-panel {
    flex: 0 0 auto;
  }

  &__googlemap--hidden {
    display: none;
  }

  .panel--sidebar-googlemap {
    flex: 0 0 auto;
  }

  &__in-session-tabs {
    display: flex;

    .panel__tab {
      padding-top: 5.5px;
      color: color-customisation(panelHeaderColor, $panel-header-color);
      background-color: color-customisation(panelHeaderBackgroundColor, $panel-header-background-color);

      &--active {
        color: $global-primary-color;
        background-color: #fff;
        border: 1px solid #b9b9b9;
        border-bottom: none;
        margin-bottom: -1px;
        z-index: 1;
      }
    }
  }

  .main-default {
    padding: 0 !important;

    .panel-json {
      display: none;
    }

    .panel-incident-details-event-json {
      text-align: center;

      .body {
        text-align: left;
      }

      label {
        @include section-title;
        display: inline-block;
        margin-top: 15px;

        .clipboard {
          margin-left: 5px;
          color: #000;
        }
      }
    }
  }

  .action--active {
    background: $action-default-active-background-color;
    color: #fff;
    border: none;
  }

  &--json-display {
    width: 600px;
  }

  &__models-table {
    table {
      table-layout: fixed;
    }

    .table__header-item {
      width: 33%;
    }

    .table__cell {
      max-width: 33%;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
    }
  }

  .flag img {
    display: block;
    height: 20px;
    border: 1px solid rgba(151,151,151,0.46);
    box-shadow: 1px 1px 0 0 rgba(0,0,0,0.11);
  }

  &__title {
    word-wrap: break-word;
  }

  &__date-time {
    padding: 0 10px;
    margin: 0 0 22px;
    font-size: 13px;
    font-weight: $weight-02;
    color: $secondary-text-color;
  }

  &__comment {
    position: relative;
    padding: 7px 11px;
    color: #6E6E6E;
    background-color: #FFF;
    border: 1px solid #D2D2D2;
    margin-bottom: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 16px;

    .quote::after, .quote::before {
      content: "\00201D";
    }

    &::before,
    &::after {
      position: absolute;
      left: -12px;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: "";
    }

    &::before {
      top: 2px;
      left: -14px;
      border-color: transparent;
      border-right-color: #D2D2D2;
      border-width: 7px;
    }

    &::after {
      top: 3px;
      border-right-color: #FFF;
      border-width: 6px;
    }
  }

  &__case-info {
    color: color(grey);
  }

  &__custom-review-fields {
    margin: 20px 0 10px;

    .title {
      font-size: 14px;
      line-height: 14px;
      font-weight: $weight-03;
    }
  }

  &__subtitle {
    @include section-title;
  }

  &__title {
    @include font-primary;
    padding: 0 10px;
    margin: 5px 0 10px;
    font-size: 21px;
    font-weight: $weight-03;
    line-height: 21px;
    color: $primary-text-color;

    &-text {
      margin-left: 8px;
    }

    &-icon {
      display: inline-block;
      vertical-align: text-top;

      .icon {
        display: block;
        --primary-color: color(grey);
        fill: color(grey);
      }
    }

    svg {
      width: 21px;
      height: 21px;
    }

    &--activity {
      .icon {
        --primary-color: $global-primary-color;
        fill: $global-primary-color;
      }
    }

    &--no-risk {
      .icon {
        --primary-color: #{$color-ok};
        fill: $color-ok;
      }
    }

    &--risk {
      .icon {
        --primary-color: #{$color-danger};
        fill: $color-danger;
      }
    }

    &--unreviewed {
      .icon {
        --primary-color: #{$color-alert};
        fill: $color-alert;
      }
    }
  }

  &__review-details {
    padding: 15px 10px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    border-bottom: 0;
    border-style: solid;
    border-width: 1px;
    background-position-y: 100%;

    &.no-risk {
      $border: #6EBE36;

      background-color: #F8FCF4;
      border-color: $border;

      background-image:
        linear-gradient(
          to right,
          $border 0%,
          $border 100%
        );
    }

    &.risk {
      $border: #FF3C46;

      background-color: #FFFAFA;
      border-color: $border;

      background-image:
        linear-gradient(
          to right,
          $border 0%,
          $border 100%
        );
    }

    &.case {
      $border: color(grey);

      background-color: #f9f9f9;
      border-color: $border;

      background-image:
        linear-gradient(
          to right,
          $border 0%,
          $border 100%
        );
    }

    dt {
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: $weight-03;
      color: $secondary-text-color;
    }

    dd {
      margin: 0;
      font-size: 13px;
      color: $primary-text-color;
      word-wrap: break-word;
    }

    .header {
      display: flex;
      padding: 0;
      margin: 0 0 10px;

      .icon-wrapper .icon {
        width: 29px;
        height: 29px;
      }

      .title {
        margin: 0;
        flex: 1;
        word-break: break-word;

        h1 {
          font-size: 14px;
          font-weight: $weight-03;
          line-height: 12px;
          margin: 0 0 5px 10px;
          color: $secondary-text-color;
        }

        h2 {
          font-size: 13px;
          font-weight: $weight-02;
          color: $primary-text-color;
          margin: 0 0 0 10px;

          .user-display-name {
            .icon {
              fill: currentColor;
              margin-right: 2px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
  }

  // UI editor fields
  &__custom-fields {
    margin: 20px 0 0;
    color: color(grey);
    border: {
      width: 1px 0;
      style: solid;
      color: #ccc;
    }

    .description-list__term {
      padding-right: 40px;
      font-weight: $weight-03;
      color: $secondary-text-color;
      word-break: break-word;
    }

    .description-list__details {
      word-break: break-word;
    }

    .event-search-link {
      color: $global-primary-color;
      font-weight: $weight-03;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  // review fields
  &__custom-review-fields {
    color: $secondary-text-color;
  }

  &__session-fingerprint, &__in-session-activity {
    margin-bottom: 5px;
    padding: 15px 10px 17px;
    border: 1px solid #b9b9b9;
    position: relative;

    .title {
      font-size: 16px;
      line-height: 16px;
      font-weight: $weight-03;
    }

    .open-in-session-visualizer {
      @include user-select(none);

      text-align: center;
      cursor: pointer;
      text-decoration: underline;
      font-weight: $weight-03;

      &:hover {
        text-decoration: none;
      }
    }

    .static-tooltip {
      position: absolute;
      top: 12px;
      right: 8px;
    }

    .settings {
      border: 1px solid $input-border-color;

      .icon {
        margin-right: 0;
      }

      &:hover {
        border-color: $input-hover-border-color;
      }
    }

    .settings-content {
      width: 170px;
      display: flex;
      flex-direction: column;

      .settings-item {
        display: flex;
        flex-direction: column;

        .settings-label {
          padding-bottom: 8px;
        }
      }

      .settings-item-norm .form-input--radios {
        display: flex;
        flex-direction: row;

        .radio {
          flex-grow: 1;
        }
      }

      .settings-item-context {
        .form-input--radios {
          display: flex;
          flex-direction: column;

          .radio {
            margin-left: 15px;
          }
        }

        .context-not-found {
          font-style: italic;
          color: rgba(151,151,151,0.46);
          font-size: 13px;
          margin-left: 15px;
        }
      }
    }

    .loading, .error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .error path {
      fill: orangered;
    }

    .table__cell--html {
      text-align: center;
      width: 28px;

      .table__cell-expand-button {
        border: none;
        background: none;
      }

      .icon {
        fill: color(grey);
        height: 18px;
        pointer-events: none;
        width: 12px;
      }
    }
  }

  &__alert-details {
    margin-bottom: 15px;
    padding: 15px 10px 17px;
    border: 1px solid #b9b9b9;

    dl {
      display: flex;

      dt {
        // the analytical workflow section needs at least 64px
        flex: 0 0 64px;
        padding-right: 12px;
        font-size: 13px;
        font-weight: $weight-03;
      }

      dd {
        overflow: hidden;
        flex: 1;
        margin: 0;
      }

      dt, dd {
        line-height: 15px;
        vertical-align: top;
        color: $secondary-text-color;
      }
    }

    .title {
      font-size: 14px;
      line-height: 14px;
      font-weight: $weight-03;
      color: $secondary-text-color;

      .icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        vertical-align: bottom;
        --primary-color: #FF6B39;
      }
    }

    span.title {
      margin: 0 0 10px;
    }
  }

  &__alert-details-tag {
    .tag {
      @include ellipsis;
      display: inline-block;
      max-width: calc(100% - 10px);
      margin: 0 3px 0 0;
      font-size: 13px;
    }
  }

  &__alert-details.unreviewed {
    $border: #FF6B39;

    background-color: #FFFCF7;
    border: 1px solid $border;

    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-position-y: 100%;

    background-image:
      linear-gradient(
        to right,
        $border 0%,
        $border 100%
      );
  }

  &__create-alert-button {
    margin-left: 16px;
  }

  &__edit-review {
    h1 {
      color: #515151;
      font-size: 17px;
      margin: 10px 0 8px;
      font-weight: $weight-03;
    }

    .edit-review-buttons {
      display: flex;

      .confirm-edit-review {
        width: 100%;
      }

      .cancel-edit-review {
        width: auto;
        padding: 0 10px;
        margin-left: 5px;
      }
    }

    select {
      margin-bottom: 6px;
    }

    ul {
      padding-left: 0;
    }

    ul > li {
      margin-bottom: 5px;
    }

    ul > li:last-child {
      margin-bottom: 0;
    }

    .reason-tag {
      display: inline-block;
      height: 29px;
      line-height: 20px;
      width: 100%;
      padding: 4px 9px;
      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      box-sizing: border-box;

      button {
        float: right;
        padding: 0 0 0 5px;
        margin-top: -5px;
      }
    }

    .reason-select {
      margin-bottom: 0;
    }

    .reason-tag-no-risk {
      color: #000;
      background: #E5F9D6;
      border-color: #72BC44;
    }

    .reason-tag-risk {
      color: #000;
      background: #FFDDE2;
      border-color: #FF587C;
    }

    .risk-buttons {
      display: flex;
      margin-bottom: 8px;

      button {
        width: 100%;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      button + button {
        margin-left: 5px;
      }
    }

    .form-field {
      padding: 0;
      border: 0 !important;

      &__label-wrap {
        margin-top: 6px;
      }
    }

    textarea {
      margin-bottom: 6px;
    }
  }

  &__create-alert {
    margin-bottom: 15px;

    h1 {
      margin: 0;
      font-size: 17px;
      font-weight: $weight-03;
      color: #525252;
    }

    .form-input--textarea {
      margin: 8px 0 6px;
    }

    .form-input--select {
      margin-bottom: 6px;
    }

    .create-alert-buttons {
      display: flex;

      .confirm-create-alert {
        flex: 1;
        margin-right: 5px;
      }

      .cancel-create-alert {
        flex: 0 1 auto;
      }
    }
  }

  &__subtitle .tag {
    font-size: 13px;
  }

  &__subtitle--no-workflow {
    font-style: italic;
    color: $secondary-text-color;
  }
}

.page-incident-details,
.page-editor-configuration {
  .entity-meta {
    display: flex;

    &__left {
      flex: 2 2 63%; // set flex basis to correct proportion
      min-width: 0; // enable shrink. min-width defaults to auto for flex elements

      .static-tooltip__body {
        position: relative;
        z-index: 5;
      }

      .link-button {
        line-height: 28px;
      }

      .entity-meta__left-item {
        display: flex;
        flex-flow: row nowrap;
        min-width: 0;
        padding: 0 4px 6px 0;
        line-height: 1.25;
        margin-right: 5px;

        @media screen and (max-width: 1439px) {
          flex: 0 0 49%;
        }
      }

      &--full {
        .entity-meta__left-item {
          flex: 0 0 32%;
        }
      }
    }

    &__left-list {
      margin: 0;
      padding: 0;
      list-style: none;

      &--summary {
        display: flex;
        flex-flow: wrap;

        .entity-meta__left-item-title,
        .entity-meta__left-item-value {
          @include ellipsis;
          max-width: 100%;
        }

        .entity-meta__left-item-value {
          display: flex;
          align-items: flex-start;
          padding-left: 2px;
        }
      }

      &--expanded {
        display: flex;
        flex-flow: row wrap;
        width: 640px;
        max-width: 100%;
        max-height: 200px;
        padding: 14px;
        overflow: auto;

        .entity-meta__left-item:not(:last-child) {
          margin-bottom: 6px;
        }

        .entity-meta__left-item-title,
        .entity-meta__left-item-value {
          word-wrap: break-word;
        }

        .entity-meta__left-item-title {
          margin-bottom: 4px;
        }

        @media screen and (max-width: 1024px) {
          width: 400px;
        }
      }
    }

    &__left-item {
      flex: 0 0 32%;
      font-weight: $weight-03;

      .flag img {
        display: block;
        width: auto;
        height: 16px;
      }

      @media screen and (max-width: 1024px) {
        flex-basis: 50%;
      }
    }

    &__left-item-title {
      display: block;
      min-width: 0;
      flex: 0 0 auto;
      color: $primary-text-color;
    }

    &__left-item-value {
      display: block;
      min-width: 0;
      flex: 1 1 auto;
      color: $primary-text-color;
    }

    &__left-item-value .incident-amdl-state {
      width: 100%;
    }

    &__right {
      display: flex;
      flex: 1 1 37%; // set flex basis to correct proportion
      min-width: 0; // enable shrink. min-width defaults to auto for flex elements
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-end;
    }

    &__col {
      min-width: 0; // enable shrink. min-width defaults to auto for flex elements
      color: $primary-text-color;
      text-align: right;
      border-right: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      min-height: 65px;
      padding: 10px 16px 0;
      margin: 0;
    }

    &__col-title {
      display: block;
      font-size: 13px;
      font-weight: $weight-03;
      @include ellipsis;
    }

    &__col-value {
      display: block;
      font-size: 20px;
      font-weight: $weight-03;
      @include ellipsis;
    }

    &__col-detail {
      display: block;
      font-size: 13px;
    }
  }

  .merchant-monitoring-summary {
    display: flex;
    flex-direction: row;
    overflow: auto;

    .details-tab__extra-panels {
      flex: 1;
      margin: 10px 5px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.in-session-visualizer {
  .modal__body-wrap {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0 20px 52px;
  }

  .modal__body {
    margin: auto;
    width: 100%;
    height: calc(100% - 52px);
  }
}

.tooltip {
  .entity-meta {
    &__list {
      padding: 0 10px;
      text-align: left;
      list-style: none;
    }

    &__list-item {
      margin-bottom: 8px;
    }

    &__label {
      display: block;
      margin-bottom: 4px;
      padding-right: 4px;
      font-weight: $weight-03;
      color: color(grey);
    }

    &__tooltip {
      max-width: 600px;
      max-height: 250px;
      overflow: auto;
      white-space: normal;
      word-break: break-word;
    }
  }
}
