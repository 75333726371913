
// shared chart styles
.model-chart {
  color: color(grey);

  .panel__body {
    align-items: center;
  }

  .tick text {
    font-size: 13px;
  }

  .chart {
    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__footer {
      text-align: right;
    }

    &__area-path {
      fill: rgba(15, 98, 254, 0.3);
    }

    &__line-path {
      stroke: #ffb433;
    }

    &__header h2 {
      margin: 0;
      font-size: 14px;
    }
  }
}


// retrain report
.model-retain-report-table {
  $table-border-color: #ccc;
  $cell-pad: 10px;

  h2 {
    color: color(grey);
  }

  .table {
    max-height: 500px;
    max-height: 50vh;

    table {
      min-width: 800px;
      table-layout: fixed;

      // fix borders in Firefox (https://stackoverflow.com/a/2061785)
      border-collapse: separate;
    }

    .table__header {
      border-bottom: 0;
    }

    &__header-item {
      // fallback for browsers that dont support `position: sticky`
      position: relative;
      position: sticky;
      top: 0;
      padding-left: $cell-pad;
      white-space: normal;
      background: #fff;
      text-align: center;

      &::after {
        // border for sticky headers
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 2px solid #ddd;
      }
    }

    thead {
      vertical-align: top;
    }

    th:first-child {
      width: 100px;
    }
  }

  &__table {
    .table__header-item:not(:first-child),
    .table__cell:not(:first-child) {
      border-left: 1px solid $table-border-color;
    }

    &--compare {
      .table__cell:nth-child(odd) {
        border-left-color: transparent;
      }
    }
  }

  &__spannable-header-cell {
    box-sizing: border-box;

    &-content {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      padding-bottom: 30px; // padding for classifier version boxes
    }

    &-primary-title {
      flex: 0 0 auto;
      text-align: center;
    }

    &-secondary-titles {
      display: flex;
      position: absolute;
      bottom: $cell-pad;
      width: 100%;
      left: 0;
      padding: $cell-pad $cell-pad 0;
    }

    &-secondary-title {
      @include ellipsis;
      flex: 1 1 50%;
      text-align: center;

      border: 1px solid $model-classifier-color-primary;

      & + & {
        border-color: $model-classifier-color-secondary;
        margin-left: $cell-pad * 2;
      }
    }
  }
}


// classifier chart
.model-classifier-chart {
  max-width: 650px;
  margin: 0 auto;
}


// AUC history
.model-auc-history {
  padding: 20px 0;
}

.model-auc-history-chart {
  max-width: 750px;
  margin: 0 auto;
}


// classifier type
.classifier-type {
  display: inline-block;
  margin: 0;
  padding: 2px 8px;
  text-align: center;

  &--large {
    min-width: 205px;
    padding: 8px 18px;
    border-width: 2px;
  }

  &--primary,
  &--secondary {
    border: 1px solid currentColor;
    font-weight: $weight-03;
  }

  &--primary {
    background: $global-primary-color;
    color: #fff;
  }

  &--secondary {
    background: transparent;
    color: #4d4d4f;
  }
}


// promote button
.model-classifier-promote-button {
  .icon {
    width: 16px;
  }
}


// classifier details page
.page-model-classifier-details {
  $border: 1px solid #ccc;
  $gutter: 14px;

  &__data-source-select {
    max-width: 100%;
    margin-bottom: 6px;
  }

  &__content {
    padding: 0 $gutter;
  }

  &__content-header {
    display: flex;
    margin: 16px 0;
  }

  &__content-header-item {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    flex: 1;
    min-width: 0;
    margin: 0;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;

    & + & {
      margin-left: $gutter;

      .page-model-classifier-details__content-title {
        border-color: $model-classifier-color-secondary;
      }
    }
  }

  &__content-header-actions {
    display: flex;
    flex: 1;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  &__content-subheader {
    display: flex;
    margin: 0 0 16px;
  }

  &__content-subheader-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    margin: 0;
    padding: 6px 10px;
    border: 1px solid $model-classifier-color-primary;
    border-radius: 3px;

    &.toCompare {
      border-color: $model-classifier-color-secondary;
      margin-left: $gutter;
    }

    > span {
      display: inline-flex;
      min-width: 90px;
      justify-content: space-between;
    }
  }

  &__content-title {
    display: flex;
    flex: 0;
    align-items: center;
    width: 100%;
    min-width: 0;
    min-height: 40px;
    margin: 0 0 16px;
    padding: 8px 10px;
    border: 2px solid $model-classifier-color-primary;
    border-radius: 3px;

    & + & {
      border-color: $model-classifier-color-secondary;
    }
  }

  &__content-title-text {
    @include ellipsis;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  &__content-subtitle {
    margin-left: 10px;
    font-weight: $weight-02;
    font-size: 13px;
  }

  &__section {
    padding: 30px 0;

    &-header {
      margin-bottom: 2em;
      color: color(grey);
      font-weight: $weight-04;
      font-size: 14px;
    }

    &-title,
    &-subtitle {
      display: inline;
      margin: 0;
      font-size: inherit;
    }

    .classifier-roc-auc__mouse-interaction {
      margin-bottom: 16px;
    }
  }

  &__section-header {
    margin-bottom: 0.2em;
  }

  &__info-field {
    @include ui-note;

    h3 {
      margin-top: 0;
    }
  }

  &__mouse-hint {
    margin-bottom: 1.2em;
    color: color(grey);
  }
}


.model-chart {
  .chart__lines-group path:nth-child(1) {
    stroke: $model-classifier-color-primary;
  }

  .chart__lines-group path:nth-child(2) {
    stroke: $model-classifier-color-secondary;
  }

  .chart__areas-group path:nth-child(1) {
    fill: rgba($model-classifier-color-primary, 0.2);
  }

  .chart__areas-group path:nth-child(2) {
    fill: rgba($model-classifier-color-secondary, 0.3);
  }
}


@media only screen and (max-height: 840px) {
  .model-classifier-chart {
    max-width: 450px;
  }
}
