.report-overview {
  height: 90px;
  display: flex;
  justify-content: center;

  &__item {
    //flex: 1;
    display: flex;
    align-items: center;
    padding: 0 80px;
  }

  &__icon {
    display: flex;
    float: left;
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1.75rem;
    line-height: 3.75rem;
    color: #fff;
    text-align: center;
    background: #d54a88;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
  }

  &__details {
    //width: ~"calc(100% - 3.75rem)";
    padding-left: 5px;
  }

  &__title {
    font-size: 20px;
    font-weight: $weight-04;
  }

  @media screen and (max-width: 1500px) {
    &__item {
      padding: 0 40px;
    }
  }

  @media screen and (max-width: 1200px) {
    &__item {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 1100px) {
    .hide-if-small {
      display: none;
    }

    &__item {
      padding: 0 10px;
    }
  }
}
