$labels-width: 7.5em;
$values-width: 0;
$outer-graph: $labels-width + $values-width;
$title-height: 1.5em;
$graph-bullet: #9e9d9d;
$colour-events: #ab3363;
$colour-generic: #ddd;
$colour-total: #512142;
$colour-0: #058ED9;
$colour-1: #E62559;
$colour-2: #1B998B;
$colour-3: #C84783;
$colour-4: #EE742C;
$colour-5: #F9B415;

$dashboard-label-colour: $primary-text-color;
$dashboard-numbers-colour: $secondary-text-color;

$donut-colour0:#f1eef6;
$donut-colour1:#d7b5d8;
$donut-colour2:#df65b0;
$donut-colour3:#dd1c77;
$donut-colour4:#980043;

//@mixin compound-graph-mixin($labels-flex, $values-width) {
@mixin compound-graph-mixin($graph-flex) {
  $outer-graph: $labels-width + $values-width;

  .graph-wrapper {
    //width: calc(100% - #{$outer-graph});
    flex: $graph-flex;
    //height: 100%;
  }

  .labels {
    //width: $labels-width;
    flex: 1;
  }

  .values {
    //width: $values-width;
    flex: 1;
  }
}

.reports {
  &-loading, &-error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-error {
    .icon {
      margin-right: 10px;
    }
  }
}

.panel--report {
  position: relative;
  z-index: 1;

  .panel__header {
    justify-content: space-between;
    align-items: baseline;
  }

  &:focus {
    outline: 1px solid $global-primary-color;
  }
}

.chart-component-time-options {
  &__dropdown {
    width: 100px;
    font-size: 14px;
    display: inline-block;
    padding: 2px 4px;
    background: #fff;
    border-radius: 3px;
    color: $secondary-text-color;
    top: 10px;
    right: 35px;

    button {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      color: $secondary-text-color;
      text-align: left;
      text-decoration: none;
      white-space: nowrap;
    }

    button:hover {
      color: $primary-text-color;
    }

    button:focus {
      border: 1px solid $global-primary-color;
    }
  }

  &__dropdown-toggle {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    cursor: pointer;
  }

  &__dropdown-menu {
    display: none;
    position: absolute;
    width: 100px;
    top: 22px;
    right: 0;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px #cfcfcf solid;
    box-shadow: 3px 3px 0 #e3e3e3;
    z-index: 999;

    li {
      list-style: none;
      padding: 2px 4px;
      font-weight: $weight-03;
    }

    &--open {
      display: block;
    }
  }
}

.chart-component {
  margin: 0 10px;
  display: flex;
  flex: 1;
  justify-content: center;

  .hide {
    display: none;
  }

  figcaption {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  .label {
    color: $secondary-text-color;
  }

  &__wrapper {
    width: calc(100% - 10px);
    height: 100%;
  }

  &__wrapper.small-width {
    .report-line__legend {
      display: none;
    }

    .report-line__chart {
      width: 100%;
    }
  }

  figure {
    display: flex;
    flex: 1;
    margin: 0;
  }

  .content,
  .simple-graph .graph-wrapper {
    height: calc(100% - #{$title-height});
  }

  .compound-graph .content {
    display: flex;
  }

  .labels,
  .values {
    margin: 0 0.25em 25px;
  }

  .values .value {
    display: flex;
    width: 100%;
    font-size: 1.3em;
    font-weight: $weight-03;
    //color: @dashboard-numbers-colour;
    justify-content: flex-end;
  }

  .value .no-risk,
  .value .closed {
    color: darken(#b2abd2, 20%);
  }

  .value .risk,
  .value .opened {
    color: darken(#fdb863, 20%);
  }

  .values .content {
    height: 100%;
  }

  .title {
    color: $secondary-text-color;
    font-size: 14px;
    font-weight: bold;
  }

  .labels,
  .values .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .label {
    padding-left: 0.5em;
  }

  .label:first-child {
    padding-left: 0;
  }

  .compound-graph {
    padding: 0;
  }

  .legend {
    display: flex;
    margin-bottom: 1em;

    &.hide {
      display: none;
    }
  }

  .legend .label {
    display: flex;
    align-items: flex-end;

    &.hide {
      display: none;
    }
  }

  .label-icon {
    font-size: 1.6em;
  }

  .label-icon--risk {
    margin-bottom: -0.1em;
    color: #e9a3c9;
  }

  .label-icon--no-risk {
    font-size: 1em;
    margin-bottom: 0.2em;
    padding-right: 0.2em;
    color: #a1d76a;
  }

  .label-icon--avg {
    padding-right: 0.2em;
    margin-bottom: -0.2em;
    color: #a5a5ab;
  }

  .label-icon--user {
    margin-bottom: -0.1em;
    color: #ab3363;
  }

  .label-icon--others {
    font-size: 1em;
    margin-bottom: 0.1em;
    padding-right: 0.2em;
    color: #a5a5ab;
  }

  .label-icon--closed {
    color: #b2abd2;
  }

  .label-icon--min {
    font-size: 1em;
    margin-bottom: 0.2em;
    padding-right: 0.2em;
    color: #C84783;
  }

  .label-icon--max {
    margin-bottom: -0.1em;
    color: #F9B415;
  }

  .label-icon--open {
    font-size: 1em;
    margin-bottom: 0.2em;
    padding-right: 0.2em;
    color: #fdb863;
  }

  .label-icon--closed {
    font-size: 1.6em;
    margin-bottom: -0.1em;
    color: #b2abd2;
  }

  .two-plus-one-graphs,
  .two-plus-two-graphs,
  .two-plus-four-graphs,
  .two-graphs {
    height: 100%;

    .wrapper {
      display: flex;
      height: calc(100% - 2em);
      flex-direction: column;
    }
  }

  .two-plus-one-graphs {
    .one-two {
      height: 66%;
      padding-bottom: 0.6em;
    }

    .one-two .compound-graph {
      height: 50%;
    }

    .three {
      height: 34%;

      .wrapper {
        height: 100%;
      }
    }

    .three .compound-graph {
      height: 100%;
    }
  }

  .two-plus-two-graphs {
    .one-two {
      height: 50%;
      padding-bottom: 0.6em;
    }

    .three-four {
      height: 50%;
      padding-top: 0.6em;
    }

    .compound-graph {
      height: 50%;
    }

    .simple-graph {
      width: 50%;
    }
  }

  .two-plus-four-graphs {
    width: 95%;

    .one-two {
      height: 34%;
      padding-bottom: 0.6em;

      .compound-graph {
        height: 50%;
      }
    }

    .three-six {
      height: 65%;
      padding-top: 0.6em;

      .compound-graph {
        height: 25%;
      }
    }

    .simple-graph {
      width: 50%;
    }
  }

  .two-graphs {
    .one-two {
      height: 100%;
      padding-bottom: 0.6em;
    }

    .one,
    .two {
      height: 50%;
    }
  }

  .compound-graph, .standalone { @include compound-graph-mixin(2); }

  .medium-width {
    .compound-graph, .standalone { @include compound-graph-mixin(5); }
  }

  .big-width {
    .compound-graph, .standalone { @include compound-graph-mixin(6); }
  }
  //.nested { @include compound-graph-mixin(4.6em, 4.6em); }

  &__risk-score-distribution {
    path, line {
      stroke: #bbb;
    }

    rect {
      fill: #CF5A91;
    }

    .y-label {
      position: absolute;
      left: 2px;
      writing-mode: tb;
      font-size: 11px;
    }

    .x-label {
      position: absolute;
      right: 10px;
      bottom: 5px;
      font-size: 11px;
    }
  }
}

.panel--report .chart-component {
  &__wrapper {
    height: inherit;
  }
}

report-alert-risk-score-distribution-chart {
  .viz-wrapper {
    flex: 1;
  }
}

report-acceptance,
report-analyst-headline,
report-analyst-activity {
  .risk::before {
    background-color: #e9a3c9;
  }

  .no-risk::before {
    background-color: #a1d76a;
  }

  .user::before {
    background-color: $colour-events;
  }

  .others::before, .avg::before {
    background-color: $graph-bullet;
  }

  .compound-graph {
    .risk {
      fill: #e9a3c9;
    }

    .no-risk {
      fill: #a1d76a;
    }

    .bullet {
      opacity: 0.9;
      fill: $graph-bullet;
    }

    .usr {
      fill: $colour-events;
    }

    .avg {
      fill: $graph-bullet;
    }

    .bullet {
      opacity: 1;
      fill: $graph-bullet;
    }
  }

  .small-height .three,
  .small-height .three-four,
  .small-height .three-six,
  .extra-small-height .two-plus-one-graphs .two,
  .extra-small-height .two-plus-two-graphs .two,
  .extra-small-height .two-plus-four-graphs .two {
    display: none;
  }

  .small-height .two-plus-one-graphs .one-two,
  .small-height .two-plus-two-graphs .one-two,
  .small-height .two-plus-four-graphs .one-two {
    height: 100%;
  }

  .extra-small-height .two-plus-one-graphs .one-two .one,
  .extra-small-height .two-plus-two-graphs .one-two .one,
  .extra-small-height .two-plus-four-graphs .one-two .one {
    height: 100%;
  }
}

report-data-processing {
  $labels-width: 7.5em;
  $values-width: 7.5em;

  .numbers, .sparklines { @include compound-graph-mixin(2); }

  .numbers {
    padding-bottom: 0.75em;
  }

  .sparklines {
    height: 100%;
  }

  .numbers,
  .sparklines {
    justify-content: center;
  }

  .numbers {
    display: flex;
    flex-direction: column;
  }

  .number-group {
    display: flex;
    align-items: center;
  }

  .number {
    font-size: 2em;
    color: $secondary-text-color;
    text-align: right;
    flex: 1;
  }

  .graph-header {
    height: 3em;
    align-items: center;
  }

  .legend {
    height: 10px;
  }

  .legend .label::before {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-bottom: -0.111em;
    border-radius: 5px;
  }

  .values .sparkline-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .sparklines {
    flex: 1;
  }

  .sparkline-content {
    height: 100%;
    align-items: center;
    display: flex;

    .nc-chart-group > .line {
      fill: none;
      stroke: #a5a5ab;
      stroke-width: 0.16em;
    }

    .dots path {
      opacity: 0;
    }

    .dots .min-value {
      opacity: 1;
      fill: #C84783;
      stroke: #C84783;
    }

    .dots .max-value {
      opacity: 1;
      fill: #F9B415;
      stroke: #F9B415;
    }
  }

  .graph-wrapper {
    height: 80%;
    max-height: 50px;
    min-height: 25px;
  }

  &.chart-component .labels,
  &.chart-component .values {
    margin: 0;
  }

  .value {
    line-height: 1;
    text-align: right;
    flex-direction: column;
    justify-content: center;
  }

  .value .min {
    color: #C84783;
  }

  .value .max {
    color: #F9B415;
  }

  .background {
    fill: #f9f9f9;
  }

  .numbers-plus-sparkline-graphs {
    display: flex;
    height: 100%;
    flex-direction: column;

    .numbers {
      flex: 2;
    }

    .sparklines {
      display: flex;
      flex-direction: column;
      flex: 3;
    }
  }

  // Not really necessary
  //.small-width {
  //  .values {
  //    display: none;
  //  }
  //}

  .small-height {
    .values .value {
      font-size: 1.2em;
    }
  }

  .extra-small-height {
    .values .value {
      font-size: 1em;
    }

    .values {
      display: none;
    }
  }
}

report-incident-management {
  figure {
    justify-content: center;
  }

  .multi-graph-row {
    height: 100%;
  }

  .row {
    padding: 1em 0 0;

    .simple-graph,
    .compound-graph,
    .donut-graph {
      width: 50%;
      height: 100%;
      padding: 0;
    }

    .donut-graph {
      padding-left: 1em;
    }

    .v-split > div {
      height: 50%;
    }
  }

  // FIXME: bit of a hack
  .minimal-axis {
    padding-top: 0;
  }

  .row-avg-handling-time,
  .row-open-closed {
    display: flex;
  }

  .row-avg-handling-time {
    height: 45%;
  }

  .row-unresolved-incidents {
    height: 25%;

    .wrapper {
      display: flex;
      height: calc(100% - 1.75em);
    }

    .simple-graph .graph-wrapper {
      height: calc(100% - 0.75em);
    }
  }

  .row-open-closed {
    width: 100%;
    height: 30%;
    padding-top: 1.2em;

    .compound-graph {
      width: 100%;
      height: 100%;
    }

    .content {
      height: calc(100% - 3.25em);
    }
  }

  .multi-graphs {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .simple-graph:last-child {
    padding-left: 0.6em;
  }

  .opened {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nc-chart-group > .line {
    fill: none;
    stroke: $colour-events;
    stroke-width: 0.2em;
  }

  .nc-bar-g-0 .bar {
    fill: $colour-events;
  }

  .time-24h::before {
    background-color: #9caebd;
  }

  .time-30d::before {
    background-color: $graph-bullet;
  }

  .opened::before {
    background-color: #fdb863;
  }

  .closed::before {
    background-color: #b2abd2;
  }

  .nc-x-axis-label {
    font-size: 0.8125em;
  }

  // Adjust accordingly when the xOffset changes.
  .labels {
    margin-bottom: 23px;
  }

  .nc-bar-g .reviewtimes-bracketedreviewtimes-last-24hrs {
    fill: #9caebd;
  }

  .nc-bar-g .reviewtimes-bracketedreviewtimes-last-30days {
    fill: $graph-bullet;
  }

  .nc-bar-g .opened {
    opacity: 1;
    fill: #fdb863;
  }

  .nc-bar-g .closed {
    opacity: 1;
    fill: #b2abd2;
  }

  .donut-content {
    display: flex;
    padding-top: 1em;
    //align-items: center;
    justify-content: center;
  }

  .donut-graph {
    .donut-slice-0 {
      fill: $donut-colour0;
    }

    .donut-slice-1 {
      fill: $donut-colour1;
    }

    .donut-slice-2 {
      fill: $donut-colour2;
    }

    .donut-slice-3 {
      fill: $donut-colour3;
    }

    .donut-slice-4 {
      fill: $donut-colour4;
    }
  }

  ul {
    padding-left: 1em;
    list-style: none;
  }

  .legend-donut {
    display: flex;
    padding-bottom: 0.6em;
    font-size: 0.8em;
    color: #666;

    > div {
      padding-left: 1em;
    }

    .do-wrapper-0 {
      margin-top: 6px;
    }

    .do-wrapper-1 {
      margin-top: 3px;
    }

    .do-wrapper-2 {
      margin-top: 5px;
    }

    .do-wrapper-3 {
      margin-top: 6px;
    }

    span {
      display: inline-block;
    }

    .do-0 {
      font-size: 1.3em;
      color: $donut-colour0;
    }

    .do-1 {
      font-size: 1.6em;
      color: $donut-colour1;
    }

    .do-2 {
      font-size: 1.4em;
      color: $donut-colour2;
    }

    .do-3 {
      font-size: 1.4em;
      color: $donut-colour3;
    }

    .do-4 {
      font-size: 2.1em;
      color: $donut-colour4;
    }
  }

  .arc-symbol {
    fill-opacity: 0;
    stroke: #fff;
    stroke-width: 3;
  }

  .small-height,
  .extra-small-height {
    font-size: 12px;

    .nc-x-marker-label {
      font-size: 13px;
    }

    .content {
      height: calc(100% - 55px);
    }

    .row-open-closed {
      .content {
        height: calc(100% - 3.25em);
      }
    }
  }

  .extra-small-height {
    .row-unresolved-incidents,
    .row-open-closed {
      display: none;
    }

    .row-avg-handling-time {
      height: 100%;

      .v-split > div:first-child {
        height: 100%;
      }

      .v-split > div:last-child {
        display: none;
      }
    }
  }

  .donut-graph.extra-small-width {
    display: none;
  }
}

report-geolocation {
  position: relative;
  z-index: 0;

  .map {
    height: 100%;
  }

  .map > div {
    width: 100%;
    height: 100%;
    background-color: #fbfbfb;
  }

  .info {
    padding: 6px 8px;
    color: #666;
    background: rgba(255,255,255,0.8);
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
  }

  .leaflet-legend {
    line-height: 18px;
    color: #666;
  }

  .leaflet-legend i {
    float: left;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    opacity: 0.7;
  }

  input:focus,
  .leaflet-control-zoomhome-in:focus,
  .leaflet-control-zoomhome-home:focus,
  .leaflet-control-zoomhome-out:focus {
    outline: 1px solid $global-primary-color !important;
  }
}


report-custom-line {
  .report-line {
    display: flex;
    height: calc(100% - 60px);
    padding: 0;
    margin: 10px;
    justify-content: space-around;

    &__chart {
      width: 60%;
    }

    &__legend {
      position: relative;
      width: 40%;
    }

    .legend {
      display: block;
    }
  }

  .nc-chart-group > .line {
    fill: none;
    stroke: $colour-generic;
  }

  .line .dots path {
    fill: $colour-generic;
    stroke: #fff;
    stroke-width: 0;
  }

  .nc-chart-group > .colour-total {
    stroke: $colour-total;
  }

  .colour-total .dots path {
    fill: $colour-total;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-0 {
    stroke: $colour-0;
  }

  .nc-chart-group > .colour-0-sub {
    stroke: lighten($colour-0, 25%);
  }

  .colour-0 .dots path,
  .colour-0-sub .dots path {
    fill: $colour-0;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-1 {
    stroke: $colour-1;
  }

  .nc-chart-group > .colour-1-sub {
    stroke: lighten($colour-1, 25%);
  }

  .colour-1 .dots path,
  .colour-1-sub .dots path {
    fill: $colour-1;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-2 {
    stroke: $colour-2;
  }

  .nc-chart-group > .colour-2-sub {
    stroke: lighten($colour-2, 25%);
  }

  .colour-2 .dots path,
  .colour-2-sub .dots path {
    fill: $colour-2;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-3 {
    stroke: $colour-3;
  }

  .nc-chart-group > .colour-3-sub {
    stroke: lighten($colour-3, 25%);
  }

  .colour-3 .dots path,
  .colour-3-sub .dots path {
    fill: $colour-3;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-4 {
    stroke: $colour-4;
  }

  .nc-chart-group > .colour-4-sub {
    stroke: lighten($colour-4, 25%);
  }

  .colour-4 .dots path,
  .colour-4-sub .dots path {
    fill: $colour-4;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-5 {
    stroke: $colour-5;
  }

  .nc-chart-group > .colour-5-sub {
    stroke: lighten($colour-5, 25%);
  }

  .colour-5 .dots path,
  .colour-5-sub .dots path {
    fill: $colour-5;
    stroke-width: 2px;
  }

  .nc-chart-group > .line {
    fill: none;
    opacity: 1;
    stroke-width: 2px;
  }

  .nc-chart-group > .colour-0-sub,
  .nc-chart-group > .colour-1-sub,
  .nc-chart-group > .colour-2-sub,
  .nc-chart-group > .colour-3-sub,
  .nc-chart-group > .colour-4-sub,
  .nc-chart-group > .colour-5-sub {
    //opacity: 0.3;
    stroke-width: 2px;
  }

  .nc-marker {
    fill: none;
    stroke: #ccc;
    stroke-width: 1px;
    shape-rendering: crispEdges;
    stroke-dasharray: 5 5;
  }

  .nc-x-marker-grp text {
    fill: #555;
  }

  // Legend

  .legend-active-time,
  ul {
    padding: 5px 0 0 12px;
    color: #333;
  }

  .legend-active-time {
    height: 20px;
  }

  ul {
    list-style: none;
  }

  li {
    position: relative;
    margin-bottom: 5px;
    margin-left: 24px;
  }

  .legend-label {
    position: relative;
    padding-left: 12px;
    font-weight: $weight-03;
  }

  .legend-label--value {
    padding-left: 5px;
    font-weight: $weight-02;
  }

  .no-pre {
    margin-left: 0;
  }

  .legend-label-subcategory {
    position: relative;
    margin: 0;
  }

  .legend-label:first-child {
    padding-left: 0;
  }

  .legend-label::before,
  .legend-label-subcategory::before {
    position: absolute;
    top: 6px;
    left: -24px;
    display: inline-block;
    width: 20px;
    height: 3px;
    content: "";
  }

  .legend-label input {
    margin: 0 2px;
  }

  .legend .colour-total::before {
    background: $colour-total;
  }

  .legend .colour-0::before {
    background: $colour-0;
  }

  .legend .colour-0-sub::before {
    background: lighten($colour-0, 25%);
  }

  .legend .colour-1::before {
    background: $colour-1;
  }

  .legend .colour-1-sub::before {
    background: lighten($colour-1, 25%);
  }

  .legend .colour-2::before {
    background: $colour-2;
  }

  .legend .colour-2-sub::before {
    background: lighten($colour-2, 25%);
  }

  .legend .colour-3::before {
    background: $colour-3;
  }

  .legend .colour-3-sub::before {
    background: lighten($colour-3, 25%);
  }

  .legend .colour-4::before {
    background: $colour-4;
  }

  .legend .colour-4-sub::before {
    background: lighten($colour-4, 25%);
  }

  .legend .colour-5::before {
    background: $colour-5;
  }

  .legend .colour-5-sub::before {
    background: lighten($colour-5, 25%);
  }

  .legend .colour-0 .legend-symbol {
    color: $colour-0;
    font-size: 0.6em;
  }

  .legend .colour-0-sub .legend-symbol {
    color: lighten($colour-0, 25%);
    font-size: 0.6em;
  }

  .legend .colour-1 .legend-symbol {
    color: $colour-1;
    font-size: 0.8em;
  }

  .legend .colour-1-sub .legend-symbol {
    color: lighten($colour-1, 25%);
    font-size: 0.8em;
  }

  .legend .colour-2 .legend-symbol {
    color: $colour-2;
  }

  .legend .colour-2-sub .legend-symbol {
    color: lighten($colour-2, 25%);
  }

  .legend .colour-3 .legend-symbol {
    color: $colour-3;
  }

  .legend .colour-3-sub .legend-symbol {
    color: lighten($colour-3, 25%);
  }

  .legend .colour-4 .legend-symbol {
    color: $colour-4;
  }

  .legend .colour-4-sub .legend-symbol {
    color: lighten($colour-4, 25%);
  }

  .legend .colour-5 .legend-symbol {
    color: $colour-5;
  }

  .legend .colour-5-sub .legend-symbol {
    color: lighten($colour-5, 25%);
  }

  .extra-small-height .report-line {
    height: calc(100% - 1px);
  }

  .nc-tip-rect-wrapper rect:hover {
    // using important to override nightcharts inline styling
    stroke: #000 !important;
    //fill: #000 !important;
    opacity: 0.4 !important;
    //fill-opacity: 0.03 !important;
  }
}

.report-fraud-prevented {
  overflow: auto;
  margin: 5px;

  .tables-wrapper {
    display: flex;
    flex-direction: column;
  }

  .table-wrapper {
    padding-bottom: 1.2rem;
    flex: 1;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  th {
    text-align: right;
  }

  .header-title {
    font-size: 1.2em;
    text-align: left;
  }

  td {
    line-height: 1.6;
    text-align: right;
  }

  .title {
    text-align: left;
  }

  th,
  .title {
    color: $dashboard-label-colour;
  }

  .value {
    font-size: 1.5em;
    color: $dashboard-numbers-colour;
  }

  .avg {
    font-weight: $weight-03;
  }
}

.report-avg-risk-scores {
  overflow-y: auto;

  .entity-type-child > td:first-child {
    color: $dashboard-label-colour;
    padding-left: 15px;
  }

  .text-center {
    text-align: center;
  }

  .table-responsive {
    width: 100%;
    overflow-y: hidden;
  }

  table {
    width: 100%;
  }

  thead {
    border-bottom: 2px solid #DDD;

    th {
      padding: 8px;
      text-align: left;
      white-space: nowrap;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #F9F9F9;
    }

    tr:hover {
      background-color: #F5F5F5;
    }

    td {
      padding: 8px;
      white-space: nowrap;
    }
  }

  .table-row-header {
    font-weight: $weight-04;
  }

  .table-row-break {
    height: 34px;
  }

  .table-footer {
    padding: 8px;
    text-align: left;
    white-space: nowrap;
  }

  polyline {
    opacity: 1;
    stroke: #C84783;
  }
}

.report-avg-risk-scores {
  th,
  td {
    text-align: center;
    border: 1px solid #fff;
  }

  th {
    background-color: #e4e4e4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px 2px;

    &.no-style {
      background-color: #fff;
    }

    &[rowspan] span {
      transform: rotate(180deg);
      writing-mode: vertical-lr;
    }
  }
}

.marker-cluster-vsmall,
.marker-cluster-vsmall div {
  background-color: rgba(250,159,181, 0.7);
}

.marker-cluster-small,
.marker-cluster-small div {
  background-color: rgba(247,104,161, 0.7);
}

.marker-cluster-medium,
.marker-cluster-medium div {
  background-color: rgba(221,52,151, 0.7);
}

.marker-cluster-large,
.marker-cluster-large div {
  background-color: rgba(174,1,126, 0.7);
}

.marker-cluster-vlarge,
.marker-cluster-vlarge div {
  background-color: rgba(122,1,119, 0.7);
}

.marker-cluster {
  display: flex;
  border-radius: 50px;
  background-clip: padding-box;
  align-items: center;
  justify-content: center;
}

.marker-cluster div {
  display: flex;
  width: 80%;
  height: 80%;
  font-size: 1.25em;
  text-align: center;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}

.marker-cluster div div {
  color: #fff;
}

.nc-marker {
  fill: none;
  stroke: #a5a5ab;
  stroke-width: 1px;
  stroke-dasharray: 10 5;
  shape-rendering: crispEdges;
}

.light {
  color: #555;
}

//.extra-small-height {
//  .row-avg-handling-time {
//    height: 55%;
//  }
//
//  .row-unresolved-incidents {
//    height: 45%;
//  }
//
//  .one-two {
//    height: 100%;
//  }
//}
//
//.small-width {
//  font-size: 12px;
//  $labels-width: 7.5em;
//  $values-width: 0;
//
//  .standalone {
//    .compound-graph {
//      width: $labels-width;
//      height: $values-width;
//    }
//  }
//
//  .nested {
//    .compound-graph {
//      width: 4.6em;
//      height: $values-width;
//    }
//  }
//
//  .values .title {
//    display: block;
//  }
//
//  .values .content {
//    display: none;
//  }
//
//  .numbers,
//  .sparklines,
//  .standalone {
//    .compound-graph {
//      width: $labels-width;
//      height: $values-width;
//    }
//  }
//
//  .values .sparkline-content {
//    display: none;
//  }
//}
//
//.extra-small-width {
//  .panel-dashboard-incident-management {
//    .simple-graph {
//      width: 100%;
//    }
//  }
//}
//
//.medium-width {
//  font-size: 14px;
//}
//
//.small-height {
//  font-size: 11px;
//}
//
//.medium-height {
//  font-size: 14px;
//}

.leaders-report {
  width: 100%;

  &__details {
    font-size: 14px;
    margin: 8px 0 14px 4px;
  }

  &__details-item {
    margin-bottom: 6px;
  }

  &__sparkline-cell {
    border-bottom: 1px solid $table-border-color;

    polyline {
      stroke: $global-primary-color;
    }
  }
}
