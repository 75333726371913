.sidebar-right {
  @include stack-context(sidebar-right);
  position: fixed;
  top: 0;
  right: 0;
  width: 370px;
  height: 100%;
  padding: 15px;
  background: #fff;
  overflow-y: auto;
  box-shadow: -4px 5px 1px 0 rgba(135, 135, 135, 0.5);

  &__backdrop {
    @include backdrop;
    @include stack-context(sidebar-right-backdrop);
  }

  &-ui-editor {
    @include stack-context(sidebar-right);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 450px;
    height: 100%;
    padding: 15px;
    background: #fcfcfc;
    overflow-y: auto;
    box-shadow: -4px 5px 1px 0 rgba(135, 135, 135, 0.5);
  }

  &--documentation-open {
    right: 380px;
  }

  .form-input__input--query {
    width: 100%;
    box-sizing: border-box;
  }

  .form-field--list {
    .form-field__label-wrap {
      display: flex;
      align-items: baseline;
      margin-bottom: 12px;
      padding-bottom: 0;
      padding-right: 0;
      border-bottom: 1px solid color(greyLight);
    }

    .form-field__list-counts {
      display: block;
      margin-left: 4px;
      color: $global-primary-color;
      font-weight: $weight-03;
      font-size: 13px;
    }

    .form-field--inline-search {
      max-width: 205px;
      margin-left: auto;
      margin-bottom: -1px;
      padding: 0 0 0 10px;
    }

    .divider {
      display: inline-block;
      flex: 1;
      border: 0.5px solid color(greySecondary);
      margin: 10px 15px 0;
      padding: 0 10px;
    }
  }

  .form-input--checkbox-list .form-input__list {
    .checkbox {
      margin-bottom: 10px;
    }

    .checkbox__label {
      margin-bottom: 0;
    }
  }
}

.application--banner-visible .sidebar-right {
  top: $banner-height;
  height: calc(100% - #{$banner-height});
}

.application--banner-expanded .sidebar-right {
  top: $banner-height-expanded;
  height: calc(100% - #{$banner-height-expanded});
}
