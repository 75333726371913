.detokenize {
  &__container {
    display: flex;
    align-items: end;
    min-width: 0;
  }

  &__value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button {
    @include reset-button;
    cursor: pointer;
    color: inherit;
    padding: 0 0.1em;
    margin-left: 0.2em;
    transition-property: color;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    &:focus {
      outline: 1px solid $action-default-active-background-color;
      outline-offset: -1px;
    }

    &:hover {
      color: $global-primary-color;
    }

    .icon {
      fill: currentColor;
    }
  }
}

.tooltip--detokenize {
  z-index: get-stack-context(sidebar-right) + 2;
}

.tooltip__content--detokenize {
  padding: 15px;
}

.detokenize-tooltip {
  max-width: 600px;
  word-break: break-word;

  &__token-row {
    padding-bottom: 0.5em;
  }

  &__token-row,
  &__plain-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__token {
    margin-right: 1em;
  }

  &__token,
  &__plain {
    text-align: left;
  }

  &__copy-button {
    @include reset-button;
    cursor: pointer;

    &:focus {
      outline: 1px solid $action-default-active-background-color;
      outline-offset: 2px;
    }

    &:hover {
      color: $global-primary-color;
    }
  }

  &__loading-error {
    white-space: nowrap;
    font-weight: bold;
    color: darkgrey;
    text-align: left;
    margin: 10px 0;
  }
}
