$tooltip-arrow-size: 5px;
$tooltip-background-color: #fff;
$tooltip-border-color: #cecece;
$tooltip-color: #7f7f7f;

// Dynamic tooltips
.tooltip {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  z-index: 5;

  &__wrapper {
    padding: 5px;
    position: fixed;
    visibility: hidden;

    &--visible {
      visibility: inherit;
    }
  }

  &__arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    color: $tooltip-border-color;

    &::before {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
      color: $tooltip-background-color;
    }

    &--top {
      bottom: 0;
      left: 50%;
      margin-left: -6px;
      border-top-color: currentColor;
      border-width: 6px 6px 0;

      &::before {
        top: -6px;
        left: -5px;
        border-top-color: currentColor;
        border-width: 5px 5px 0;
      }
    }

    &--right {
      top: 50%;
      left: 0;
      margin-top: -6px;
      border-right-color: currentColor;
      border-width: 6px 6px 6px 0;

      &::before {
        top: -5px;
        left: 1px;
        border-right-color: currentColor;
        border-width: 5px 5px 5px 0;
      }
    }

    &--bottom {
      top: 0;
      left: 50%;
      margin-left: -6px;
      border-bottom-color: currentColor;
      border-width: 0 6px 6px;

      &::before {
        top: 1px;
        left: -5px;
        border-bottom-color: currentColor;
        border-width: 0 5px 5px;
      }
    }

    &--left {
      top: 50%;
      right: 0;
      margin-top: -6px;
      border-left-color: currentColor;
      border-width: 6px 0 6px 6px;

      &::before {
        top: -5px;
        left: -6px;
        border-left-color: currentColor;
        border-width: 5px 0 5px 5px;
      }
    }
  }

  &__content {
    padding: 3px 8px;
    overflow: hidden;
    color: $primary-text-color;
    text-align: center;
    background-color: #fff;
    border: 1px solid $tooltip-border-color;
    border-radius: 4px;
    outline: none;

    &--are-you-sure,
    &--promote-classifier {
      width: 300px;

      .title {
        font-size: 1em;
      }

      .actions {
        display: flex;
        margin: 10px 0 0;
        justify-content: center;
      }

      .action-wrapper {
        display: inline-block;
        margin: 5px;
        flex: 1;
      }

      .action {
        width: 100%;
      }
    }

    &--promote-classifier {
      width: 400px;

      .description {
        text-align: left;
      }
    }

    &--select-incidents {
      display: flex;
      flex-direction: column;
      padding: 11px;

      .selected-of-total {
        border-bottom: 1px solid #e3e3e3;
        line-height: 24px;
        margin-bottom: 5px;
        padding: 0 4px;
      }

      .select-action {
        background: none;
        border: none;
        color: #464646;
        text-align: left;
        padding: 4px;

        &:hover {
          background-color: #d8d8d8;
        }

        &:focus {
          outline: 1px solid $global-link-color;
          outline-offset: -1px;
        }
      }
    }
  }

  &--backdrop {
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: all;
  }

  &--validation {
    $validation-background-color: color(red);
    $validation-border-color: transparent;

    .tooltip {
      &__arrow {
        color: $validation-border-color;

        &::before {
          color: $validation-background-color;
        }
      }

      &__content {
        background: $validation-background-color;
        color: white;
        border-color: $validation-border-color;
      }
    }
  }

  &--add-comment {
    .tooltip {
      &__content {
        width: 280px;
        padding: 15px;
      }
    }

    .form-field {
      margin-bottom: 10px;

      &__label {
        text-align: left;
      }
    }
  }

  &--event-list-entity-links,
  &--incident-amdl-state {
    // make the tooltip appear above the event list sidebar overlay
    z-index: get-stack-context(sidebar-right-backdrop) + 1;
  }

  &__event-list-entity-links-list {
    margin: 0;
    padding: 10px;
    text-align: left;
  }

  &__event-list-entity-links-item {
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}



// Static tooltips
.static-tooltip {
  $tooltip-margin: 10px;
  $arrow-offset: calc(-#{$tooltip-margin} - 1px);

  position: relative;
  display: inline-block;

  &:hover:not(.static-tooltip--trigger-click) {
    cursor: pointer;

    .static-tooltip__body {
      display: block;
    }
  }

  &--right {
    .static-tooltip__content {
      right: auto;
      left: 0;
    }
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  .table__tooltip-icon .icon {
    width: 10px;
    height: 10px;
  }

  &__arrow {
    @include horizontal-center;
    bottom: $arrow-offset;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: index($stack, message-tooltip) + 1;

    border-bottom-color: $tooltip-border-color;
    border-width: 0 6px 6px;

    &::before {
      content: '';
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;

      top: 1px;
      left: -5px;
      border-bottom-color: $tooltip-background-color;
      border-width: 0 5px 5px;
    }

    &--down {
      transform: rotate(180deg);
      bottom: auto;
      top: $arrow-offset;
    }
  }

  &:not(.static-tooltip--trigger-click) &__body {
    display: none;
    z-index: 8;
  }

  &__content {
    @include dropdown;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: $tooltip-margin;
    padding: 0;
    background-color: #fff;
    z-index: index($stack, message-tooltip) + 6;
  }

  &__default-content-wrap {
    min-width: 250px;
  }

  &--align-left {
    .static-tooltip__content {
      right: 0;
      left: auto;
    }
  }

  &--align-right {
    .static-tooltip__content {
      right: auto;
      left: 0;
    }
  }

  &--align-center {
    .static-tooltip__content {
      right: auto;
      @include horizontal-center;
    }
  }

  &--position-above {
    .static-tooltip__content {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: $tooltip-margin;
    }
  }

  &__actions {
    display: flex;
    margin-top: 6px;
  }

  &__action {
    flex: 0 1 auto;

    &--primary {
      flex: 1 1 auto;
    }

    & + & {
      margin-left: 6px;
    }
  }
}
