.c-checkbox {
  $background-color-active: color-customisation(primaryColor, $primary-input-color);

  // @include ellipsis;
  @include reset-button;
  display: flex;
  align-items: center;
  color: color(grey);

  &:hover:not([disabled]) {
    .c-checkbox__icon {
      border-color: $background-color-active;
    }
  }

  &:focus:not([disabled]) {
    .c-checkbox__icon {
      box-shadow: 0 0 5px 1px rgba(201, 77, 139, 0.47);
      border-color: $background-color-active;
    }
  }

  // @at-root {
  //   button.c-checkbox {
  //     &:focus {
  //       outline: 1px solid $global-primary-color;
  //       outline-offset: 1px;
  //     }
  //   }
  // }

  &[data-checked="Checked"],
  &[data-checked="HalfChecked"] {
    .c-checkbox__icon {
      background-color: $input-checkbox-background-color-active;
      border-color: transparent;
    }
  }

  &[disabled] {
    .c-checkbox__icon {
      opacity: 0.5;
    }
  }

  &__icon {
    display: block;
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    padding: 1px;
    background-color: $input-checkbox-background-color;
    border-radius: 4px;
    border: 1px solid color(grey);
    overflow: hidden;

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  &__label {
    @include ellipsis;
    display: block;
    margin-left: 10px;
  }
}
