.juicy-ace-editor-wrapper {
  border: 1px solid #C6C6C6;
  border-radius: 4px;
  display: flex;
  margin-top: 5px;

  &--full-size {
    flex-grow: 1;
  }

  .juicy-ace-editor {
    flex-grow: 1;

    &[readonly] {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #e0e0e0;
        pointer-events: none;
      }
    }
  }

  // Use the child combinator (>) selector to workaround
  // https://github.com/webcomponents/shadycss/issues/113
  // https://github.com/webcomponents/webcomponentsjs/blob/master/README.md#shadycss
  // https://github.com/webcomponents/webcomponentsjs/issues/422
  > .ace-diff {
    flex: 1;
    --diff-color: #ffeee4;
    --diff-outline-color: #ffc199;
  }
}
