$background-color: #e3e3e3;

.page-bootstrap {
  align-items: center;
  background: $background-color;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .icon-loading {
    width: 24px;
    height: 30px;
  }
}
