.page-data-connectors {
  $indent-levels: 4;

  @while $indent-levels > 0 {
    &__cell-indented-#{$indent-levels} {
      text-indent: 12px + ($indent-levels * 6px);
    }

    $indent-levels: $indent-levels - 1;
  }

  &__col {
    &-stage-reorder {
      width: 32px;
      padding: 0;
    }

    &-stage-type { width: 200px; }
    &-stage-timeout { width: 100px; }
    &-stage-options {}
    &-stage-action {}

    &-source-name { width: 200px; }
    &-source-batchSizeEvents { width: 100px; }
    &-source-catchupPollIntervalSeconds { width: 100px; }
    &-source-normalPollIntervalSeconds { width: 100px; }
    &-source-priority { width: 100px; }
    &-source-filePath {}
    &-source-action {}
    &-source-columnHeaders { flex-grow: 10; }
    &-source-eventIdField { flex-grow: 1; }
    &-source-separator { flex-grow: 1; }

    &-mapper-type { flex: 0 1 200px; }
    &-mapper-name { width: 200px; }
    &-mapper-fieldName {}
  }

  &__cell-stage-options {
    word-break: break-all;
  }

  &__keyValuePairField {
    flex: 1 1 auto;
  }

  &__mappingField {
    margin-top: 30px;
  }

  &__stages-placeholder-dz {
    height: 37px;
  }

  &__stage-option-key {
    font-weight: $weight-03;
  }

  &__table {
    // prevent borders on rows being clipped
    padding: 1px;
  }

  &__table-row {
    .table__cell {
      // work around for opacity < 1 on the parent <tr>
      // removing the <td> background color in Chrome
      background-color: inherit;
    }

    &.is-dragging {
      opacity: 0.3;
    }

    &.is-dropzone {
      background: rgba(color(purple), 0.5) !important;

      &.is-group-target {
        > .table__cell:not(:first-child) {
          border-bottom: 2px solid color(purple);
        }
      }
    }
  }

  &__table-row-modified-state {
    &.is-modified {
      &,
      button {
        font-weight: $weight-04;
      }

      &::after {
        content: '*';
        display: inline-block;
      }
    }
  }

  &__remove-button {
    width: 35px !important;
    height: 35px !important;
    line-height: 1 !important;

    .icon {
      line-height: 1 !important;
    }
  }

  &__edit-view {
    // Restore buttons to full height in edit view
    .action {
      &,
      .icon {
        height: 30px;
        line-height: 28px;
      }
    }
  }

  &__mapping-list {
    margin: 0;
    padding: 0;
  }

  &__mapping-list-item {
    position: relative;
    margin: 26px 0 26px 32px;
    padding: 15px 22px;
    list-style: none;
    border: 1px solid #c8c8c8;
    background-color: #fbf3f8;
  }

  &__mapping-list-item-remove {
    $offset: -18px;
    $size: 33px;
    position: absolute;
    top: $offset;
    left: $offset;
    width: $size;
    height: $size !important;
  }

  &__source-delegating-mapper-edit-view {
    max-width: 460px;
  }

  &__draggable-handle {
    padding: 8px 10px;
    cursor: move;
    color: color(grey);

    .icon {
      width: 10px;
      height: 10px;
      pointer-events: none;
      fill: currentColor;
    }

    &:hover,
    &:active {
      color: color(purple);
    }
  }



  $tooltip-margin: 10px;

  &__tooltip {
    @include stack-context(message-tooltip);
    position: relative;
  }

  &__tooltip-arrow {
    @include horizontal-center;
    bottom: calc(-#{$tooltip-margin} - 1px);
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: index($stack, message-tooltip) + 1;

    &::before {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
    }

    &--top {
      border-bottom-color: $tooltip-border-color;
      border-width: 0 6px 6px;

      &::before {
        top: 1px;
        left: -5px;
        border-bottom-color: $tooltip-background-color;
        border-width: 0 5px 5px;
      }
    }
  }

  &__tooltip-content {
    @include stack-context(message-tooltip);
    @include dropdown;
    position: absolute;
    top: 100%;
    right: 0;
    width: 270px;
    margin-top: $tooltip-margin;
    padding: 14px;
    background-color: #fff;

    .form-field {
      display: block;
      margin-bottom: 10px;
    }

    .form-field__label-wrap {
      @include font-secondary(600);
      font-size: 13px;
      padding-right: 0;
      max-width: none;
    }
  }

  &__tooltip-actions {
    display: flex;
    margin-top: 6px;
  }

  &__tooltip-action {
    flex: 0 1 auto;

    &--primary {
      flex: 1 1 auto;
    }
  }

  &__tooltip-success-message {
    text-align: center;
  }

  &__tooltip-success-message-icon {
    display: block;

    .icon {
      width: 36px;
      height: 36px;
      fill: color(green);
    }
  }

  &__tooltip-success-message-text {
    margin: 14px 0 0;
    line-height: 1;
    color: color(grey);

    .link-button {
      font-weight: $weight-03;
    }
  }

  &__tooltip-success-message-part1 {
    display: block;
    font-weight: $weight-04;
    margin-bottom: 8px;
  }

  &__tooltip-success-message-part2 {
    display: block;
  }

  &__tooltip--mappers-delete {
    .page-data-connectors__tooltip-main {
      display: none;
    }

    .page-data-connectors__tooltip-content {
      right: auto;
      left: 0;
      white-space: normal;
    }

    &:hover .page-data-connectors__tooltip-main {
      display: block;
    }
  }

  &__field-tooltip {
    white-space: normal;
    margin-left: 4px;

    .static-tooltip__content {
      min-width: 270px;
      color: color(greyFaded);
    }
  }

  &__field-tooltip-icon {
    .icon {
      width: 14px;
      height: 14px;
      fill: color(grey);
    }
  }

  &__dry-run {
    height: 100%;
    width: 730px;
    max-width: 100%;
    margin: 0 auto;

    &-status-action {
      .icon-check { fill: $color-ok; }
      .icon-warning { fill: $color-danger; }
    }

    &--new {}

    &-new-fieldset {
      display: flex;
      margin-bottom: 40px;

      .form-field {
        margin: 0;
      }
    }

    &-new-csv-file-field {
      flex: 0 0 auto;
      width: 40%;

      .form-input__input {
        @include ellipsis;
        max-width: 200px;
      }
    }

    &-new-csv-input-field {
      flex: 1 1 auto;
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid color(greyLight);
    }

    &-csv-input {
      height: 150px;
    }

    &--loading {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 75px 0;
    }

    &-loading-icon-1 {
      .icon {
        font-size: 32px;
        width: 1em;
        height: 1em;
      }
    }

    &-loading-title {
      margin: 2px 0 6px;
      font-size: 17px;
      font-weight: $weight-03;
    }

    &-loading-message {
      color: color(grey);
      max-width: 260px;
      margin: 0 auto;
    }

    &-loading-icon-2 {
      .icon {
        width: 24px;
        height: 24px;
      }
    }


    &--results {}

    &-results-error {
      color: $color-danger;
    }

    &-results-summary-wrap {
      display: flex;
    }

    &-results-summary {
      flex: 1 1 auto;
      padding-right: 20px;
    }

    &-results-actions {
      flex: 0 0 auto;
    }

    &-results-summary-item {
      display: flex;
      margin-bottom: 24px;
      font-size: 14px;
      color: color(grey);
    }

    &-results-summary-item-label {
      display: block;
      flex: 1;
      margin-right: 8px;
    }

    &-results-summary-item-value {
      display: block;
      flex: 2;
    }

    &-results-list {}

    &-results-list-title {
      font-size: 16px;
      font-weight: $weight-03;
    }
  }
}

.dr-results {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    margin-bottom: 2px;
    padding: 12px 14px;

    &:nth-child(odd):not(.dr-results__item--error) {
      background-color: $table-cell-background-color-stripe;
    }

    &--error {
      background-color: #ffeef0;
    }
  }

  &__value {
    display: block;
    color: color(grey);

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    .icon {
      width: 1em;
      height: 1em;
      fill: currentColor;
    }

    &--error {
      color: $color-danger;

      .icon-warning {
        position: relative;
        top: 1px;
        margin-right: 4px;
      }
    }

    &--link {
      .link-button {
        font-weight: $weight-03;
      }
    }
  }

  .codeblock:last-child {
    margin-bottom: 0;
  }
}
