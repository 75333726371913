.page-tenant-list {
  &__create {
    width: 690px;
    max-width: 100%;
    padding: 16px;
  }

  &__take-control-modal {
    width: 320px;
    min-height: auto;
    padding: 10px;
    text-align: center;

    .icon-gamepad {
      width: 70px;
      height: 70px;
      fill: $global-primary-color;
    }

    .action-title {
      margin: 20px auto;
      font-size: 18px;
      font-weight: $weight-04;
    }
  }
}
