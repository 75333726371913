.page-header {
  flex: 0 0 auto;
  margin-bottom: 20px;

  &__breadcrumbs {
    margin-bottom: 2px;
  }

  &__title {
    @include heading-04;
    margin: 0;
    word-break: break-word;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
  }

  &__action {
    & + & {
      margin-left: 6px;
    }
  }
}
