$advanced-search-results-color: $global-primary-color;

.advanced-search {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: auto;

  .icon {
    display: block;
    width: 14px;
    height: 17px;
    fill: #b3b3b3;
  }

  &__failure {
    color: #b3b3b3;

    .icon {
      margin: auto;
      margin-bottom: 5px;
    }
  }

  // TODO: Remove this block when app-legacy.css is removed
  &__input[type="search"] {
    box-sizing: border-box !important;

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  &__inner {
    position: relative;
    display: inline-block;
    max-width: 227px;
  }

  &__input {
    @include font-primary(600);
    width: 100%;
    height: 35px;
    line-height: 1;
    padding: 0 20px 0 30px;
    border: 1px solid #eee;
    background: #eee;
    font-size: 13px;
    color: color(grey);

    @include input-placeholder {
      @include font-secondary;
    }
  }

  &__search-icon-wrap {
    @include vertical-center;
    left: 8px;
    line-height: 1;
  }

  &__clear-button {
    @include reset-button;
    @include vertical-center;
    right: 5px;
    display: block;
  }

  &__loading {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .icon {
      margin-right: 6px;
    }
  }

  &__results:not(:empty) {
    @include dropdown;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 13px;
    background: #fff;
    z-index: 2;
    font-weight: $weight-03;
    font-size: 13px;
  }

  &__results-group {
    & + & {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eaeaea;
    }

    &.has-no-results .advanced-search__results-total {
      text-decoration: none;
      cursor: default;
    }
  }

  &__results-total {
    display: block;
    color: $advanced-search-results-color;
    text-decoration: underline;
    cursor: pointer;
  }

  &__results-total-icon-wrap {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;

    .icon {
      fill: $advanced-search-results-color;
      width: 13px;
      height: 13px;
    }
  }

  &__results-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__results-list-item {
    display: flex;
    flex-flow: row wrap;
    color: #a3a3a3;
    margin-bottom: 4px;
    cursor: pointer;
  }

  &__result-title {
    display: block;
    flex: 1 0 100%;
    margin-bottom: 2px;
    color: $advanced-search-results-color;
    text-decoration: underline;
  }

  &__result-button {
    @include reset-button;
    text-align: left;

    &:focus {
      outline: 1px solid $global-primary-color;
      outline-offset: 1px;
    }
  }

  &__result-subtitle {
    display: block;
    font-weight: $weight-02;
  }

  &__result-details {
    display: block;
    margin-left: auto;
    font-weight: $weight-02;
  }

  .no-results {
    cursor: default;
    text-decoration: none;
  }
}
