.multi-select {
  @include font-secondary(400);
  position: relative;
  z-index: 1;
  font-size: 13px;
  color: $input-select-color;

  &[disabled],
  &[data-disabled] {
    pointer-events: none;

    .multi-select__input-wrap {
      background-color: $input-disabled-background-color;
      border-color: $input-disabled-border-color;
    }

    .multi-select__tag {
      opacity: 0.7;
    }
  }

  &__input-wrap {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 35px;
    padding: 3.5px 22px 3.5px 3.5px;
    background: #fff;
    cursor: pointer;
    font-size: 13px;
    line-height: 1.2;
  }

  &__placeholder {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: none;

    &.is-visible {
      display: block;
    }
  }

  &__label {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 1 1 auto;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  &__input {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex: 1;
    width: 100%;
    margin-left: 6px;
    opacity: 0;

    &.is-visible {
      opacity: 1;
    }
  }

  &__input-value {
    display: block;
    width: 100%;
    height: 100%;
    min-width: 1em;
    min-height: 1em;
    line-height: calc(2em + 2px);
    outline: 0;
    border: 0;
  }

  &__input-icon {
    @include vertical-center;
    right: 5px;
    display: flex;
    align-items: center;
    margin-left: 4px;

    .icon {
      display: block;
      width: 12px;
      height: 13px;
      fill: currentColor;
    }
  }

  &__tag {
    @include reset-button;
    cursor: pointer !important;
    background-color: #fff;
  }

  &__validation-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .icon {
      fill: color(red);
      height: 13px;
      width: 7px;
    }
  }

  &__options {
    @include dropdown;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    margin: 2px 0 0;
    padding: 2px 0;
    background-color: #fff;
    outline: 0;
    z-index: 2;

    display: none;
  }

  &__options-list {
    width: 100%;
    max-height: 154px;
    margin: 0;
    padding: 1px;
    overflow-y: auto;
    list-style: none;
  }

  &__option {
    padding: 12px 10px;
    cursor: pointer;
    border: 1px solid transparent;

    &:active {
      outline: none;
    }

    &.is-selected {
      background-color: $input-focus-background-color;
      font-weight: $weight-03;
    }
  }

  &:hover {
    .multi-select__input-wrap {
      border-color: $input-hover-border-color;
    }
  }

  &.is-open,
  &:focus {
    outline: 0;

    .multi-select__input-wrap {
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      border: 1px solid $input-focus-border-color;
    }

    .multi-select__placeholder {
      display: none;
    }

    .multi-select__option {
      &:hover,
      &.is-focused {
        background-color: $input-focus-background-color;
      }

      &.is-focused {
        border-color: $input-focus-border-color;
      }
    }
  }

  &.is-open {
    .multi-select__options {
      display: block;
    }
  }

  &.is-invalid {
    .multi-select__input-wrap {
      border-color: $error-color;
    }
  }
}
