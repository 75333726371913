$amdl-editor-tree-width: 240px;
$amdl-editor-tree-gutter: 6px;
$amdl-editor-background-color: color(greyLight2);
$amdl-editor-border-color: $amdl-editor-background-color;
$amdl-editor-border-color-alt: rgba(color(grey), 0.2);
$amdl-editor-modal-width: 700px;
$amdl-editor-error-color: $color-danger;
$amdl-generator-types: ("global" "state" "rule" "event-type" "field-type" "tag");

@mixin amdl-editor-title {
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: $weight-03;
  color: $global-primary-color;
}

@mixin amdl-editor-editor-subsection-border {
  border-top: 1px solid $amdl-editor-border-color;
}

.amdl-editor-main-panel {
  height: 100%;

  .panel__wrapper,
  .panel__body {
    min-height: 0; // flex fix
  }

  @each $type in $amdl-generator-types {
    #panel-amdl-editor-ng-amdl-#{$type}-generator { display: none; }
  }

  &.is-fullscreen {
    position: fixed;
    top: $header-brand-gradient-height;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.amdl-editor {
  display: flex;
  flex: 1;
  min-height: 0; // flex fix

  &__dropdown {
    width: 300px;
    padding: 14px;

    &-actions {
      display: flex;
    }

    .action + .action {
      margin-left: 5px;
    }

    &-action-primary {
      flex: 1 0 auto;
    }

    &-action-secondary {
      flex: 0 0 auto;
    }
  }

  &__warning {
    margin: 0 0 1em;
    font-size: 14px;
    font-weight: $weight-04;
    color: $amdl-editor-error-color;
  }

  // close tab confirm
  &__close-tab-confirm {
    &-message {
      margin: 0 0 12px;
    }

    &-actions {
      display: flex;
    }

    &-action {
      flex: 1;

      & + & {
        margin-left: 8px;
      }
    }
  }

  // amdl generator
  &__amdl-generator {
    width: 610px;

    @each $type in $amdl-generator-types {
      &[data-amdl-generator-type="#{$type}"] #panel-amdl-editor-ng-amdl-#{$type}-generator { display: block; }
    }
  }

  // event generator
  &__event-generator {
    width: 610px;
    position: relative;

    #panel-amdl-editor-ng-event-generator { display: block; }
  }

  &__amdl-generator-dropdown-content {
    display: flex;
    flex-flow: column nowrap;
    min-width: 130px;
    padding: 0;
  }

  &__amdl-generator-dropdown-action {
    @include reset-button;
    display: flex;
    width: 100%;
    padding: 8px 24px 8px 14px;
    text-align: left;
  }

  // errors table
  &__amdl-errors {
    width: 690px;

    &-title {
      @include amdl-editor-title;
    }

    &-table {
      table {
        table-layout: fixed;
      }

      .table__cell {
        white-space: normal;
      }
    }

    &-row-summary {
      word-break: break-all;
      cursor: pointer;
    }

    &-row-detail-codeblock {
      background: #fff;
      border: 1px solid #c6c6c6;
      padding: 4px 8px;
      overflow: auto;
      white-space: pre;
    }
  }

  // import results pre-submit
  &__import-results {
    margin-bottom: 20px;
  }

  &__import-results-outcome {
    color: $secondary-text-color;
    margin-bottom: 10px;
    word-wrap: break-word;
    font-weight: bold;
  }


  &__import-results-outcome--success {
    .amdl-editor__import-results-outcome-message { color: $color-ok; }
  }

  &__import-results-outcome--error {
    .amdl-editor__import-results-outcome-message { color: $amdl-editor-error-color; }
  }

  &__import-annotated-code {
    margin: 0;
    padding: 8px;
    white-space: pre;
    overflow: auto;
    background: #f5f5f5;
    box-shadow: 1px 1px 3px 0 #bbb;
  }

  &__import-annotated-code-parser-problem {
    white-space: nowrap;
  }

  &__import-annotated-code-line {
    display: block;

    &.has-error {
      .amdl-editor__import-annotated-code-line-inner {
        @include wavy-underline($amdl-editor-error-color);
      }
    }
  }

  &__import-annotated-code-line--error-annotation {
    color: $amdl-editor-error-color;
  }

  &__import-results-list {
    max-height: 360px;
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  &__import-results-list-item {
    display: flex;
    margin-bottom: 2px;
    padding: 10px;
    background: $amdl-editor-background-color;
  }

  &__import-results-list-item-label-extra {
    margin-left: auto;
    color: $amdl-editor-error-color;
  }

  // import results post-submit
  &__import-create-results-title {
    @include amdl-editor-title;
  }

  &__import-create-results-list {
    max-height: 360px;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    overflow: auto;
  }

  &__import-create-results-list-item {
    margin-bottom: 2px;
    padding: 6px;
    background: #ececec;
    color: color(grey);

    &--ok { color: $color-ok; }
    &--danger { color: $amdl-editor-error-color; }
  }

  &__import-create-results-list-item-extra {
    display: block;
    font-weight: $weight-04;

    &:not(:empty) {
      padding-top: 8px;
    }
  }
}

.amdl-editor-tree {
  $node-padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 $amdl-editor-tree-width;
  min-width: 0;
  margin-right: $amdl-editor-tree-gutter;
  border: 1px solid $amdl-editor-border-color;

  &__actions-dropdown {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 8px;

    .static-tooltip {
      display: flex;
    }
  }

  &__actions-dropdown-content {
    min-width: 130px;
  }

  &__actions-dropdown-action {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;

    &:focus {
      outline: 1px solid $global-link-color;
    }
  }

  &__loading {
    padding: 20px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__header {
    display: flex;
    flex: 0 0 auto;
    padding: 5px;
    background: $amdl-editor-background-color;
  }

  &__search {
    flex: 1;
    margin-right: 5px;

    .form-input--search {
      flex: 1;
    }
  }

  &__header-actions {
    flex: 0 0 auto;
  }

  // bulk tests
  &__bulk-run-tests {
    display: flex;
    height: 34px;
    background: $amdl-editor-background-color;
    font-size: 13px;

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &,
    &-action {
      color: $secondary-text-color;

      &:focus {
        outline: 1px solid $global-link-color;
        outline-offset: 1px;
      }

      .icon {
        height: 18px;
        width: auto;
      }
    }

    &-content,
    &-actions {
      padding: 8px;
    }

    .icon {
      display: block;
      fill: currentColor;
    }

    &-content {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
    }

    &-progress-indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: color(greySecondary);

      &-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: rgba(#228400, 0.75);
        transition: width 0.2s 0.2s ease;
      }

      &--fail {
        .amdl-editor-tree__bulk-run-tests-progress-indicator-bar {
          background-color: color(red);
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &-action {
      @include reset-button;

      & + & {
        margin-left: 12px;
      }
    }

    &-label {
      position: relative;
      margin: 0 4px;

      &--with-progress {
        color: #fff;
      }
    }

    &-not-asked-icon {
      color: $tertiary-text-color;
    }
  }

  &__nodes-list {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    min-width: 0;
    padding: 0 $node-padding $node-padding 0;
    overflow: auto;
  }

  &__nodes {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto;
    min-width: 0;
    margin: 0;
    padding: 0 0 0 $node-padding;
    list-style: none;
  }

  &__node {
    display: flex;
    flex-flow: column nowrap;
    min-width: 0;

    &.is-disabled {
      text-decoration: line-through;
    }
  }

  &__node-icon {
    margin-right: 6px;
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 0;
    padding: $node-padding $node-padding $node-padding ($node-padding + 6px);
    color: $primary-text-color;
    border-bottom: 1px solid rgba(color(greyLight), 0.7);
  }

  &__label-button {
    @include reset-button;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: auto;
    min-width: 0;
    margin-right: auto;
    text-align: left;
    color: inherit;
    cursor: pointer;

    &:focus {
      outline: 1px solid $global-link-color;
      outline-offset: 1px;
    }

    &-text {
      @include ellipsis;
      display: block;
      width: 100%;
    }
  }

  &__sub-node-count {
    @include ellipsis;
    display: block;
    flex: 0 1 auto;
    min-width: 8px;
  }

  &__error-count {
    display: flex;
    align-items: center;
    color: $color-danger;
    font-weight: $weight-03;

    .icon {
      display: block;
      margin-right: 4px;
      fill: $color-danger;
    }

    &--branch {
      position: absolute;
      left: -2px;
    }

    &--leaf {
      cursor: pointer;
    }
  }

  &__branch-actions-toggle {
    @include reset-button;
    color: color(grey);

    &:focus {
      outline: 1px solid $global-link-color;
    }

    .icon {
      display: block;
      position: relative;
      top: 1px; // fix icon-ellipsis-v alignment
      fill: currentColor;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &__branch-actions-divider {
    display: block;
    height: 1px;
    margin: 6px 0;
    background: $amdl-editor-border-color-alt;
  }

  &__branch-actions-delete-dir {
    width: 170px;
    padding: 10px;
  }

  &__branch-actions-delete-dir-title {
    display: block;
    margin-bottom: 8px;
  }

  &__modal-update-directory {
    width: $amdl-editor-modal-width;
    max-width: 100%;
  }

  &__modal-import {
    width: $amdl-editor-modal-width;
    max-width: 100%;
    padding: 5px;
  }

  &__import-textarea {
    @include font-code;
    height: 200px;
    white-space: pre;
    overflow: auto;
  }
}

.amdl-editor-editor {
  flex: 1 1 auto;
  max-width: calc(100% - #{$amdl-editor-tree-width + $amdl-editor-tree-gutter});
  border: 1px solid $amdl-editor-border-color;

  &__tabs {
    min-height: 35px;
  }

  &__tab {
    $padding: 20px;

    position: relative;
    display: flex;
    min-width: 80px;
    width: 150px;
    max-width: 150px;
    padding-left: 12px;
    padding-right: $padding;
    background-color: rgba(#fff, 0.5);

    &.panel__tab--active {
      background-color: #fff;
    }

    &:hover {
      .amdl-editor-editor__tab-modified-indicator {
        visibility: hidden;
      }

      .amdl-editor-editor__tab-close-button {
        visibility: visible;
      }
    }
  }

  &__tab-open-button {
    @include reset-button;
    @include ellipsis;
    @include font-primary(600);
    flex: 1 1 auto;
    padding-right: 3px; // avoid clipping italic text

    &.is-modified {
      font-style: italic;
    }
  }

  .panel__tab--active &__tab-open-button {
    color: $global-primary-color;
  }

  &__tab-actions {
    position: absolute;
    right: 4px;
    margin-left: 4px;
  }

  &__tab-close-button {
    @include reset-button;
    position: relative;
    top: 1px;
    flex: 0 0 auto;
    fill: $secondary-text-color;
    font-size: 13px;

    visibility: hidden;

    .icon {
      display: block;
    }
  }

  &__tab-modified-indicator {
    $size: 6px;

    @include center;
    display: block;
    background: $global-primary-color;
    width: $size;
    height: $size;
    border-radius: 100%;

    visibility: visible;
  }

  &__tab-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    font-size: 13px;
  }

  &__extra-content-resizer {
    flex: 0 0 auto;
    margin-top: auto;

    .icon {
      display: block;
      margin: 0 auto;
    }
  }

  &__extra-content-resizer-button {
    @include reset-button;
    width: 100%;
    padding: 4px;
    cursor: ns-resize;

    .icon {
      pointer-events: none;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  }

  &__action-bar {
    display: flex;
    flex-flow: row wrap;
    white-space: nowrap;
  }

  &__action-bar-subgroup {
    display: flex;
    flex-flow: row wrap;

    &--right {
      margin-left: auto;
    }
  }

  &__action-bar-spinner {
    display: flex;
    align-items: center;
    padding: 0 10px;
    fill: currentColor;
  }

  &__action-bar-action-wrap {
    & + & {
      border-left: 1px solid $amdl-editor-border-color;
    }
  }

  &__action-bar-action {
    @include reset-button;
    display: flex;
    align-items: center;
    padding: 10px;
    color: $secondary-text-color;

    .icon {
      display: block;
      margin-right: 4px;
      fill: currentColor;

      & + .icon:last-child {
        margin-right: 0;
        margin-left: 4px;
      }
    }

    &:not([disabled]):hover {
      color: $global-link-color;
    }

    &[disabled] {
      opacity: 0.5;
    }

    &-toggle-enabled-disabled {
      min-width: 95px;

      &.is-disabled,
      &.is-disabled:hover {
        color: $tertiary-button-foreground;
        font-weight: bold;

        .icon {
          fill: $secondary-text-color;
        }
      }
    }

    &:focus {
      outline: 1px solid $action-default-active-background-color;
      outline-offset: -5px;
    }
  }

  &__extra-content {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100px;
    overflow: auto;

    &:empty {
      display: none;
    }

    &--tests {
      height: 250px;
      min-height: 250px;
    }
  }

  &__details {
    @include amdl-editor-editor-subsection-border;
    display: flex;
    flex: 0 1 auto;
    padding: 20px;

    .form-field__label-wrap {
      flex: 0 1 30%;
    }

    .form-field__form-input-wrapper {
      flex: 1 0 60%;
    }
  }

  &__details-inner {
    flex: 1;
    max-width: 720px;
    margin: 0 auto;
  }
}

.amdl-editor-tests {
  @include amdl-editor-editor-subsection-border;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  min-height: 0;
  padding: 10px;

  &__status-icon {
    display: block;
    fill: color(grey);

    .icon {
      display: block;
    }

    &--success { fill: $color-ok; }
    &--failure { fill: $color-danger; }
  }

  &__header {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-bottom: 10px;
  }

  &__main-actions {
    display: flex;
    margin-right: auto;
  }

  &__subview-toggle-actions {
    margin-right: 6px;
  }

  &__subview-toggle-action {
    &.is-active {
      &,
      &:hover {
        background: #dedede;
      }
    }
  }

  &__title {
    display: block;
    flex: 1;
    font-size: 14px;
    font-weight: $weight-04;
  }

  &__content {
    display: flex;
    flex: 1;
    min-height: 0;
    background: $amdl-editor-background-color;
  }

  &__test-list {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    width: 200px;
    margin-right: 6px;
    border-right: 1px solid $amdl-editor-border-color-alt;

    &--loading {
      align-items: center;
      justify-content: center;
    }
  }

  &__test-list-actions {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
  }

  &__test-list-action {
    color: $secondary-text-color;

    &[disabled] {
      opacity: 0.5;
    }
  }

  &__test-list-items {
    flex: 1 1 auto;
    overflow: auto;
    margin: 0 10px 10px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #c3c1c1;
    list-style: none;
  }

  &__test-list-item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid transparent;

    .amdl-editor-tests__status-icon {
      margin-right: 6px;
    }

    .action {
      @include ellipsis;
      padding: 0;
      width: 100%;
      min-height: 30px; // prevent element collapsing when nothing entered (editable mode)
      text-align: left;
    }

    &:hover {
      &,
      .action {
        color: $global-link-color;
      }
    }

    &.is-selected {
      &,
      .action {
        background: $global-primary-selected-background-color;
      }
    }

    &.is-editing {
      border-color: $global-primary-color;
    }
  }

  &__test-list-item-rename-input {
    width: 100%;
    background: none;
    border: none;
  }

  &__edit-details {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    padding: 10px;
    overflow: hidden;
  }

  &__edit-details-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
  }

  &__edit-details-check-rule {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 6px;

    .form-input--select {
      select {
        height: 2.3em;
        line-height: 1.2em;
      }

      .icon-sort {
        bottom: 6px;
      }
    }
  }

  &__edit-details-check-rule-label {
    padding-right: 6px;
  }

  &__edit-details-title {
    @include ellipsis;
    flex: 0 1 auto;
    min-width: 0;
  }

  &__edit-details-content {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
  }

  &__editor {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    border: 1px solid $amdl-editor-border-color-alt;

    & + & {
      margin-left: 16px;
    }
  }

  &__editor-header {
    display: flex;
    align-items: center;
    padding: 6px;
    border-bottom: 1px solid $amdl-editor-border-color-alt;
  }

  &__editor-actions {
    margin-left: auto;

    .action--icon-only {
      width: auto;
      height: auto;

      .icon {
        display: block;
        width: 14px;
        height: auto;
      }
    }
  }

  // results view
  &__results {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    padding: 10px;
    overflow: auto;
  }

  &__results-title {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    padding-bottom: 8px;
    background-color: $amdl-editor-background-color;
    border-bottom: 1px solid $amdl-editor-border-color-alt;

    .icon {
      margin-right: 4px;
    }
  }

  &__results-list-not-run,
  &__results-list-loading,
  &__results-list-empty,
  &__results-list-error,
  &__results-list {
    flex: 1;
    padding: 16px;
    background: #fff;
  }

  &__results-list-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 20px;
    }
  }

  &__results-list-error {
    color: color(red);
  }

  &__results-list {
    margin: 0;
    overflow: auto;
    list-style: none;
  }

  &__results-list-item {
    margin-bottom: 10px;
  }

  &__results-list-test-name {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 6px;
    }
  }

  &__results-list-test-failed-to-execute {
    display: flex;
    align-items: center;
    margin-left: 4px;

    .icon {
      fill: $color-warning;
      margin-right: 4px;
    }
  }

  &__results-list-item-expander {
    box-sizing: border-box; // <details> resets box-sizing

    &-summary {
      display: flex;
      align-items: center;
      outline: none;
      cursor: pointer;

      &::before {
        content: '';
        width: 1em;
        height: 1em;
        margin-right: 4px;
        background-image: url('/images/icons/fa-caret-right-solid.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] > .amdl-editor-tests__results-list-item-expander-summary::before {
      background-image: url('/images/icons/fa-caret-down-solid.svg');
    }
  }

  &__results-list-item-content {
    margin: 10px 0 20px 10px;
    padding-left: 10px;
    border-left: 1px solid color(greyLight);
  }

  &__results-list-trigger-check {
    display: flex;

    .icon {
      margin-right: 6px;
    }
  }

  &__results-list-trigger-check,
  &__results-list-expectations,
  &__results-list-codeblock {
    margin-bottom: 20px;
  }

  &__results-list-expectations-title,
  &__results-list-debug-log-title,
  &__results-list-codeblock-title {
    display: block;
    font-weight: $weight-04;
  }

  &__results-list-expectations-title,
  &__results-list-codeblock-title {
    margin-bottom: 10px;
  }

  &__results-list-expectations {
    display: flex;
    flex-flow: column nowrap;
  }

  &__results-list-expectation {
    display: flex;

    .icon {
      margin-right: 6px;
    }
  }

  // bulk run tests results modal
  &__bulk-test-results {
    display: flex;
    width: 1080px;
    max-width: 100%;
    max-height: 70vh;
    min-height: 0; // flex overflow fix

    &-list {
      flex: 0 1 30%;
      min-width: 0;
      max-width: 300px;
      margin: 0;
      padding: 0;
      list-style: none;
      border: 1px solid $amdl-editor-border-color-alt;
      margin-right: 6px;
      overflow: auto;
    }

    &-list-item {
      display: flex;

      .icon {
        display: block;
        margin-right: 4px;
      }

      &.is-selected {
        background: $global-primary-selected-background-color;

        &,
        button {
          color: $global-link-color;
          font-weight: $weight-03;
        }
      }
    }

    &-list-item-button {
      @include reset-button;
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 0;
      padding: 8px;

      &:focus {
        outline: 1px solid $global-link-color;
        outline-offset: -1px;
      }
    }

    &-list-item-label {
      display: block;
      @include ellipsis;
    }

    &-result {
      display: flex;
      flex: 1 1 70%;
      min-width: 0;
      background-color: $amdl-editor-background-color;
      border: 1px solid $amdl-editor-border-color-alt;
    }
  }
}

.amdl-editor-ace {
  display: flex;
  flex-grow: 1;

  .juicy-ace-editor-wrapper {
    margin: 0;
    border-radius: 0;
    border: 0;
  }

  &--main {
    min-height: 50px;

    .juicy-ace-editor-wrapper {
      box-shadow: 0 -2px 2px 0 #c3c3c3;
    }
  }
}

.amdl-editor-icon {
  display: block;
  color: color(greyFaded);

  .icon {
    display: block;
    fill: currentColor;
  }

  &--directory { color: $amdl-directory; }
  &--file-feature { color: $amdl-feature; }
  &--file-global { color: $amdl-global; }
  &--file-list { color: $amdl-list; }
  &--file-rule { color: $amdl-rule; }
  &--file-state { color: $amdl-state; }
  &--file-unknown { color: $amdl-unknown; }
  &--file-value { color: $amdl-value; }
  &--file-var { color: $amdl-var; }
}
