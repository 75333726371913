.tenancy-overview {
  $light-grey: #b8b8b8;

  $cell-padding-h: 14px;
  $cell-padding-v: 10px;

  $group-background-color: #f5f5f5;
  $group-indent-padding: 10px;
  $item-indent-padding: 10px;
  $item-border-color: #f7f5f5;

  $tree-line-color: $light-grey;
  $tree-line-size: 1px;
  $tree-node-icon-size: 7px;
  $tree-node-icon-offset: $tree-node-icon-size * 0.5;
  $tree-node-icon-tweak: 0.5px; // MAGIC NUMBER
  $tree-item-marker-size: 20px;

  @mixin repeating-gradient-lines($color, $size, $spacing) {
    background-image: repeating-linear-gradient(90deg, $color, $color $size, transparent $size, transparent $spacing);
  }

  @mixin horizontal-marker-position {
    &::before { @content; }
  }

  @mixin horizontal-marker {
    @include horizontal-marker-position {
      content: '';
      position: absolute;
      top: 0;
      width: $tree-item-marker-size;
      height: 100%;
      background-image: linear-gradient(to left, $tree-line-color 0%, $tree-line-color 100%);
      background-size: 100% 1px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @content;
    }
  }

  @mixin closest-vertical-marker-position {
    &::after { @content; }
  }

  @mixin closest-vertical-marker {
    @include closest-vertical-marker-position {
      content: '';
      position: absolute;
      right: -$tree-node-icon-tweak;
      width: $tree-line-size;
      height: 100%;
      background: $tree-line-color;
      @content;
    }
  }

  &__table {
    table {
      height: 100%;
    }
  }

  &__table-body {
    &.has-more {
      .tenancy-overview__table-row:last-child {
        border-bottom: 1px dashed $light-grey;
      }
    }

    @mixin closest-vertical-marker--half-size {
      @include closest-vertical-marker-position { height: 50%; }
    }

    // the first (root) node
    &:nth-child(2) {
      .tenancy-overview__table-row {
        &:first-child {
          .tenancy-overview__tree-line-container--group .tenancy-overview__tree-line-marker {
            @include horizontal-marker-position { display: none; }
            @include closest-vertical-marker--half-size;
          }
        }
      }
    }

    // nodes with half size vertical markers

    // start of a deeper group chain
    &.is-deeper-indentation-level {
      .tenancy-overview__table-row {
        &:first-child {
          .tenancy-overview__tree-line-container--group .tenancy-overview__tree-line-marker {
            @include closest-vertical-marker--half-size;
          }
        }
      }
    }

    // end of an item chain
    &:not(.has-child-groups):not(.next-node-is-sibling) {
      .tenancy-overview__table-row {
        .tenancy-overview__tree-line-container--group .tenancy-overview__tree-line-marker {
          // width: calc(#{$group-indent-padding * 2} * (var(--next-node-indent-level) + 0));
          // height: 50%;
        }

        .tenancy-overview__tree-line-container--item .tenancy-overview__tree-line-marker {
          width: calc(#{$group-indent-padding * 2} * (var(--next-node-indent-level) - 0));
        }

        &:last-child {
          .tenancy-overview__tree-line-container--item .tenancy-overview__tree-line-marker {
            @include closest-vertical-marker--half-size;
          }
        }
      }
    }
  }

  &__table-row {}

  &__table-cell {
    position: relative;
    padding: 14px 10px;
    padding: $cell-padding-h $cell-padding-v;

    &--indented {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1.5;
      overflow: hidden;

      // ARIC-16039 Tenancy Overview in Firefox does not display correctly
      height: 53px;
    }
  }

  &__table-cell-indented-content {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden;

    &-inner {
      display: flex;
      align-items: center;
    }
  }

  &__table-row-actions {
    display: flex;
    padding: 0 30px;
  }

  &__table-row-action {
    flex: 0 0 auto;
    text-align: center;

    &--main {
      flex-grow: 1;
    }

    & + & {
      margin-left: 8px;
    }

    &--link-style {
      @include reset-button;
      @include font-primary(600);
      font-size: 13px;
      text-transform: uppercase;

      &:hover {
        color: $global-link-color;
      }
    }
  }

  &__acg-row {
    background: $group-background-color;
    border-bottom: 1px solid $global-primary-color;
  }

  &__tenant-row {
    border-bottom: 1px solid $item-border-color;
  }

  &__group-title,
  &__group-title-divider {
    color: $global-primary-color;
  }

  &__group-title {
    @include font-primary(700);
    font-size: 16px;
  }

  &__group-title-divider {
    margin: 0 4px;
  }

  &__group-subtitle {
    font-weight: $weight-03;
  }

  // indent level
  &__tree-line-container {
    position: relative;
    left: $tree-node-icon-offset;
    display: block;
    width: calc(#{$group-indent-padding * 2} * (var(--indent-level) - 1));
    height: 100%;

    // node joining lines
    .tenancy-overview__tree-line-marker {
      display: block;
      width: 100%;
      height: 100%;
      @include repeating-gradient-lines($tree-line-color, $tree-line-size, $group-indent-padding * 2);
      @include horizontal-marker { right: 0; }
      @include closest-vertical-marker { bottom: 0; }
    }

    &--group {
      margin: 0 $tree-node-icon-tweak 0 $tree-node-icon-offset;

      .tenancy-overview__tree-line-marker {
        @include horizontal-marker-position { right: 0; }
        @include closest-vertical-marker-position { bottom: 0; }
      }
    }

    &--item {
      margin: 0 ($tree-item-marker-size + $tree-node-icon-offset + 6px) 0 $tree-node-icon-offset;

      .tenancy-overview__tree-line-marker {
        @include horizontal-marker-position { left: 100%; }
        @include closest-vertical-marker-position { top: 0; }
      }
    }
  }

  // node icon
  &__tree-node {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 6px;
    font-size: $tree-node-icon-size;

    .icon {
      display: block;
      fill: #80366a;
      box-shadow: 0 0 0 3px $group-background-color;
    }
  }
}
