.page-event-list {
  .pagination__list .pagination__item:last-child {
    display: none;
  }

  &__no-content-notice {
    margin-top: 90px;
    margin-bottom: 60px;

    &__heading {
      font-weight: bold;
    }
  }
}


.page-event-list-details-sidebar {
  .details-sidebar {
    &__header {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      margin-bottom: 10px;
    }

    &__subtitle {
      margin-bottom: 0;
    }

    &__copy-button {
      @include reset-button;
      position: relative;
      top: 2px;
      margin-left: 4px;
      padding: 0 6px;
      color: color(grey);
      transition: color 0.15s ease;

      &:hover {
        color: #000;
      }

      .icon {
        display: block;
        fill: currentColor;
      }
    }
  }
}

.sidebar-event-list {
  padding: 15px 0 $message-broadcast-height;

  .page-incident-details__back-to-list {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  .custom-filter__heading {
    // allow the colour customisation from the sidebar to propagate to these headings (ARIC-19101).
    color: inherit;
  }

  .form-field__label-wrap {
    // allow the colour customisation from the sidebar to propagate to these headings (ARIC-19101).
    color: color-customisation(sidebarColor, color(grey));
  }

  .multirange__tickmarks {
    color: color-customisation(sidebarColor, #656565);
  }
}
