.link,
.link-button {
  text-decoration: underline;
  color: $global-link-color;

  &:focus {
    outline: 1px solid $global-link-color;
    outline-offset: 1px;
  }

  &[disabled] {
    opacity: 0.4;
  }
}

// block level links without styling
.block-link {
  text-decoration: none;
  color: inherit;
}

// buttons without the button styles
// eg: `<button class="link-button">This looks like a regular text link</button>`
.link-button {
  @include reset-button;
  cursor: pointer;
  line-height: 1.2;
  font-weight: $weight-02;

  .icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    display: block;
    margin-right: 6px;
  }

  &:focus {
    outline: 1px solid $action-default-active-background-color;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}
