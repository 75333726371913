$tag-color: $global-primary-color;
$tag-color-alt: #b5b1b1;

.active-filters {
  margin-bottom: 5px;

  &__item {
    @include reset-button;
    display: inline-block;
    margin: 0 5px 5px 0;

    &:focus {
      outline: 1px solid $action-default-active-background-color;
      outline-offset: -4px;
    }
  }

  &__tag {
    @include font-primary(600);
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border: 1px solid currentColor;
    border-radius: 4px;
    color: $tag-color;
    line-height: 1;
    font-size: 14px;
    cursor: pointer;

    &--inverse {
      color: $tag-color-alt;
      text-decoration: line-through;
    }
  }

  &__tag-text {
    word-break: break-all;
  }

  &__tag-icon {
    display: block;
    margin-left: 4px;

    .icon {
      display: inline-block;
      vertical-align: bottom;
      width: 1em;
      height: 1em;
      fill: $tag-color-alt;
    }
  }
}
