.panel-network-analysis {
  $primaryNodeColor: #505050;
  $iconHighConnection: #ce1256;
  $iconMiddleConnection: #df65b0;
  $iconLowConnection: #d7b5d8;
  $iconVeryLowConnection: #f1eef6;

  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
  position: relative;

  a {
    color: $global-primary-color;
  }

  .panel-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .body {
    padding: 10px 0 0;
  }

  .graph-network-analysis {
    font-weight: $weight-03;
    position: relative;
    min-height: 100%;
    color: $secondary-text-color;

    button.active {
      color: color-button(standard);
      background: color-button(standard-pressed-background);
    }

    button.action-toggle {
      border: 0;
      padding: 0;
    }

    hr {
      color: color(greyLight2);
      font-weight: $weight-02;
      opacity: 0.3;
    }

    .visualization-draw-area {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .zoom-controls {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
    }

    .zoom-control:nth-child(1) {
      border-radius: 3px 0 0 3px;
      border-width: 1px;
    }

    .zoom-control:nth-child(2) {
      border-radius: 0 3px 3px 0;
      border-width: 1px 1px 1px 0;
    }

    .zoom-control:last-child {
      font-size: 18px;
      margin-left: 10px;
    }

    .zoom-control:hover {
      background: color-button(standard-hover-background);
    }

    .controls {
      position: absolute;
      top: 0;
      right: 0;

      &__label {
        margin-right: 20px;
        font-size: 15px;
        font-weight: $weight-04;
        cursor: default;
      }

      &__buttons {
        display: flex;
      }

      &__layout {
        display: flex;
        align-items: center;
      }

      &__export {
        margin-left: 20px;
      }

      &__labels {
        margin-left: 4px;
      }
    }

    .info {
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      bottom: 10px;
      right: 0;
      width: 260px;
      max-height: calc(100% - 80px);
      overflow: auto;

      &--open {
        min-height: 272px;
        border: color(greySecondary) 1px dashed;
        padding: 20px;
      }

      &--closed {
        overflow: hidden;
      }

      &__icon {
        fill: color(purple);
        margin-top: 2px;
        margin-right: 5px;
      }

      &__link {
        display: flex;
        font-weight: $weight-02;

        a {
          max-width: 190px;
          display: inline-block;
          @include ellipsis();
        }
      }

      &__title {
        font-size: 15px;
        margin-bottom: 5px;
      }

      .icon-info-circle {
        fill: $secondary-text-color;
        margin-right: 5px;
        font-size: 22px;
        vertical-align: middle;
      }

      dt {
        font-weight: $weight-03;
        margin: 12px 0 4px;
        text-transform: capitalize;
      }

      dd {
        display: flex;
        font-weight: $weight-02;
        margin: 4px 0;
        margin-inline-start: 0;
      }

      dd:last-child {
        margin-bottom: 0;
      }

      &__data-list {
        margin-top: -7px;
        margin-left: 6px;
        border-left: 1px solid #bbb;
        padding-left: 4px;
      }

      &__state-header {
        margin: 20px 0 10px;
        font-size: 13px;
        text-transform: uppercase;
      }

      &__state-content {
        margin-left: 5px;
      }
    }

    .legend {
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 200px;
      max-height: calc(100% - 80px);
      overflow: auto;

      &--bold {
        font-weight: 700;
      }

      p {
        margin: 0;
        font-size: 15px;
        width: 100%;
        @include ellipsis();
        white-space: normal;
      }

      &__first-list {
        margin-top: 10px;
        margin-left: 4px;

        li {
          height: 30px;
        }
      }

      ul {
        padding: 0;
        list-style-type: none;
      }

      .checkbox {
        margin-left: 11px;
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }
      }

      .checkbox__label {
        margin-bottom: 0;
      }

      .legend-item {
        display: flex;
        align-items: center;
        height: 26px;

        &__icon {
          flex: 1;
          display: flex;
          justify-content: center;
          fill: $secondary-text-color;
        }

        &__icon-data-list {
          fill: color(red);
          height: 18px;
        }

        &__label {
          flex: 3;
          padding-left: 10px;
        }

        &__label--cap {
          text-transform: capitalize;
        }
      }
    }

    .legend-node-icons {
      font-size: 24px;
    }

    .high-connection {
      color: $iconHighConnection;
    }

    .middle-connection {
      color: $iconMiddleConnection;
    }

    .low-connection {
      color: $iconLowConnection;
    }

    .very-low-connection {
      color: $iconVeryLowConnection;
    }

    footer {
      font-weight: $weight-02;
    }

    .icon-primary {
      font-size: 22px;
      color: $primaryNodeColor;
    }
  }
}

.d3-tip-no-arrow {
  color: $secondary-text-color;
}

