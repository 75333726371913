// 1. `width: 0` + `flex: 1` = fill container width
// 2. `min-width: 0` = flexbox truncation fix
.omnisearch {
  $query-height: 30px;
  $submit-button-size: $query-height;
  $focus-color: $global-link-color;
  $highlight-color: $global-primary-selected-background-color-alt;
  $border-color: color(greyLight);
  $dropdown-background-color: #fff;
  $shortcuts-break: 1014px;

  position: relative;
  display: flex;
  flex: 1; // 1
  width: 0; // 1

  .icon {
    fill: currentColor;
  }


  // search input
  &__query {
    display: flex;
    flex: 1;
    width: 100%;
    height: $query-height + 2px;
    color: $input-color;
    border: 1px solid $input-border-color;
  }

  &__query-items {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex: 1; // 1
    width: 0; // 1
    overflow: hidden;
    padding: 6px 24px 6px 8px;
    white-space: nowrap;
  }

  &__query-items-track {
    display: flex;
    width: 100%;
    padding: 1px 0;
    overflow: hidden;
  }

  &__query-items-list {
    display: flex;
    flex: 0 0 auto;
  }

  &__query-item {
    display: flex;
  }

  &__query-item-string {
    display: flex;
    margin-right: 6px;
  }

  &__query-item-field {
    display: flex;
    margin-right: 8px;
    border-bottom: 1px solid color(grey);
  }

  &__query-item-field-label {
    margin-right: 4px;
    padding: 0 2px;
    color: #000;
    font-weight: $weight-03;

    &::after {
      content: ':';
    }
  }

  &__query-item-field-input {}

  &__query-item-operator {
    margin-right: 6px;
    font-weight: $weight-04;
    color: #000;

    &:focus {
      outline: 1px dashed $focus-color;
    }
  }

  &__input {
    flex: 1 0 auto;
    min-width: 0.25em;
    height: 100%;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    white-space: nowrap;
  }

  &__query-clear-button {
    @include reset-button;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-right: 8px;
    color: color(grey);

    .icon {
      display: block;
    }

    &:focus {
      color: #000;

      .icon {
        outline: 1px dashed currentColor;
      }
    }
  }

  &__query-submit-button {
    @include reset-button;
    width: $submit-button-size;
    height: 100%;
    background-color: $primary-background-color;
    color: $secondary-text-color;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: block;
    }

    &:focus {
      outline: 2px solid #000;
    }
  }


  // dropdown panel with filters & results
  &__dropdown {
    @include dropdown;
    position: absolute;
    top: 100%;
    display: flex;
    width: 100%;
    width: calc(100% - #{$submit-button-size});
    min-height: 200px;
    max-height: 50vh;
    margin-top: 2px;
    padding: 10px 20px;
    overflow: auto;
    background-color: $dropdown-background-color;
    z-index: 10;
  }

  &__dropdown-title {
    @include ellipsis;
    flex: 0 0 auto;
    margin: 0 0 8px;
    padding-bottom: 2px; // needed when using the --sticky variant
    color: color(grey);
    font-weight: $weight-03;

    &--sticky {
      position: sticky;
      top: 0;
      background-color: $dropdown-background-color;
    }
  }

  &__filter-shortcuts {
    display: flex;
    flex: 2 1 60%;
    flex-flow: row nowrap;
    min-width: 0; // 2

    @media screen and (max-width: $shortcuts-break) {
      flex-flow: column nowrap;
    }
  }


  // contextual completions
  &__contextual-completions {
    min-width: 80px; // min-width >=0 required to fix flex truncation
    margin-right: 20px;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    overflow: auto;
  }

  &__contextual-completion-items-list {
    min-width: 0; // 2
    margin: 0;
    padding: 2px 6px 2px 2px;
    list-style: none;
    overflow: auto;
  }

  &__contextual-completion-item {
    margin-bottom: 6px;
    min-width: 0; // 2
  }

  &__contextual-completion-item-button {
    @include reset-button;
    @include ellipsis;
    width: 100%;
    min-width: 0; // 2
    padding: 1px;
    text-align: left;
    font-weight: $weight-04;
    background-color: transparent;
    transition: background-color 0.15s cubic-bezier(0.4, 0, 1, 1);

    &.is-focused,
    &:focus {
      outline: 1px dashed $focus-color;
    }

    &.is-highlighted {
      background-color: $highlight-color;
    }
  }


  // search operators
  &__search-operators {
    flex: 1 0 auto; // flex-shrink: 0 prevents operators being squashed when the field names are long
    min-width: 0; // 2

    @media screen and (max-width: $shortcuts-break) {
      order: -1;
      margin-bottom: 10px;
    }
  }

  &__search-operators-list {
    margin: 0;
    padding: 0;
    list-style: none;

    & + & {
      margin-top: 4px;
      padding-top: 4px;
      border-top: 1px solid $border-color;
    }
  }

  &__search-operator {}

  &__search-operator-button {
    @include reset-button;
    display: flex;
    width: 100%;
    text-align: left;

    &:focus {
      outline: 1px dashed $focus-color;
    }
  }

  &__search-operator-symbol {
    width: 40px;
    font-weight: $weight-04;
  }

  &__search-operator-label {
    @include ellipsis;
    display: block;
    width: 100%;
    min-width: 0; // 2
    color: color(greyFaded);
  }


  // search results
  &__results {
    display: flex;
    flex: 1 0 40%;
    flex-flow: column nowrap;
    min-width: 0; // 2

    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $border-color;
  }

  &__results-title {
    display: flex;
    flex: 0 0 auto;
  }

  &__results-title-text {
    @include ellipsis;
    min-width: 0;
  }

  &__results-loading-icon {
    display: block;
    margin-left: auto;

    visibility: hidden;
    opacity: 0;
    transition: none 0.15s ease;
    transition-property: visibility, opacity;

    &.is-active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__results-error {
    flex: 1 1 auto;
    overflow: auto;
    word-wrap: break-word;
    color: $color-danger;
  }

  &__results-section {
    & + & {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid color(greyLight);
    }
  }

  &__results-section-title {
    margin: 0 2px 10px;
    font-weight: $weight-03;
  }

  &__results-section-list {
    margin: 0;
    padding: 0;
    list-style: none;

    max-height: 30vh;
    padding-right: 10px;
    overflow: auto;

    .link {
      font-weight: $weight-03;
    }
  }

  &__results-section-item {
    display: flex;
    margin: 1px 1px 6px;
    padding: 1px;
  }

  &__results-section-block-link {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    min-width: 0; // 2
    padding: 1px;
    text-align: left;

    &:focus {
      outline: 1px dashed $focus-color;
    }
  }

  &__results-section-item-key {
    @include ellipsis;
    display: block;
    min-width: 0;
    flex: 1 1 auto;
    padding-right: 4px;
  }

  &__results-section-item-value {
    @include ellipsis;
    display: block;
    max-width: 100%;
    min-width: 0; // 2
    flex: 0 0 auto;
    font-weight: $weight-03;
  }

  &__results-entity-group {
    & + & {
      margin-top: 12px;
    }
  }

  &__results-entity-group-title {
    display: flex;
    margin: 1px 1px 6px;
    padding: 1px;
    color: color(greySecondary);
    font-size: 13px;
  }

  &__results-entity-group-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__results-section-main-data {
    display: flex;
    flex-flow: row nowrap; // needs to be nowrap for truncation to work correctly
    flex: 1 1 auto;
    min-width: 0; // 2
  }

  &__results-entity-extra-data {
    @include ellipsis;
    display: block;
    width: 100%;
    margin-top: 4px;
    color: color(greyFaded);
    font-size: 13px;
  }


  // expanded/focused
  &.is-showing-dropdown {
    .omnisearch {
      &__query {
        border-color: $input-focus-border-color;
        background: $input-focus-shadow-color;
      }

      &__query-submit-button {
        background-color: $primary-button-background;
        color: $primary-button-foreground;
      }
    }
  }
}
