@use "sass:math";

// Generic helpers

@mixin reset-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: 0;
  border: 0;
  outline: 0;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

//
// Hide only visually, but have it available for screenreaders: h5bp.com/v
//
@mixin visually-hidden($focusable: false) {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;

  // Extends the `visually-hidden` class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p
  @if $focusable == true {
    &:active,
    &:focus {
      clip: auto !important;
      height: auto !important;
      margin: 0 !important;
      overflow: visible !important;
      position: static !important;
      width: auto !important;
    }
  }
}

@mixin stack-context($item, $stack_: $stack) {
  z-index: get-stack-context($item, $stack_);
}

@function get-stack-context($item, $stack_: $stack) {
  @return index($stack_, $item);
}

/*
Generate a striped background, eg:
https://css-tricks.com/snippets/sass/striped-gradient-mixin

  body {
    @include stripes(to right, #8e44ad #2c3e50 #2980b9 #16a085 #27ae60);
  }
*/
@mixin stripes($direction, $colors) {
  $length: length($colors);

  @if $length > 1 {
    $stripes: ();

    @for $i from 1 through $length {
      $stripe: (math.div(100%, $length)) * ($i - 1);

      @if $i > 1 {
        $stripes: append($stripes, nth($colors, $i - 1) $stripe, comma);
      }

      $stripes: append($stripes, nth($colors, $i) $stripe, comma);
    }

    background-image: linear-gradient($direction, $stripes);
  }
  @else if $length == 1 {
    background-color: $colors;
  }
}

// flex and truncate with ellipsis 2 sibling nodes proportionally
// example:
// ```scss
// .parent { display: flex: }
// .ellipsis { @include flex-ellipsis; }
// ```
//
// ```html
// <div class="parent">
// <span class="ellipsis">Loooooooooooooooooong text</span>
// <span class="ellipsis">Loooooooooooooooooonoooooger text</span>
// </div>
// ```
@mixin flex-ellipsis {
  @include ellipsis;
  display: block;
  flex: 0 1 auto;
  max-width: 50%;

  @supports (max-width: fit-content) {
    flex: 1 1 50%;
    max-width: fit-content;
  }
}

// Pure CSS multi-line ellipsis!
@mixin multiline-ellipsis($lines) {
  @include max-lines($lines);
  overflow: hidden;
  padding: 0; // reset the padding as padding will cause this not to work
}

// This is a [ponyfill](https://github.com/sindresorhus/ponyfill) for the CSS
// [`max-lines`](https://css-tricks.com/max-lines) property.
//
// NOTE: even though this is using -webkit- prefixes it **does** work in Firefox and Chromium-based Edge (as well as Chrome and Safari)!
// > https://css-tricks.com/line-clampin/#article-header-id-0
// > In an extra weird twist, the [spec now includes this](https://drafts.csswg.org/css-overflow-3/#webkit-line-clamp) as part of the overflow module, old flexbox and all. And, [Firefox implemented it just like that](https://bugzilla.mozilla.org/show_bug.cgi?id=WebKit-line-clamp). And with Edge going Chromium, this weird technique has gotten a lot more useful instead of less.
@mixin max-lines($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

// Create a text container that will always be the height of $lines lines.
// Example: `div { @include fixed-height-text-content(5, 1.25rem) }`
@mixin fixed-height-text-content($lines, $line-height) {
  @if unitless($line-height) {
    @error '$line-height must not be unitless';
  }

  @include multiline-ellipsis($lines);
  height: $line-height * $lines;
  line-height: $line-height; // override to ensure the height is correctly calculated
  word-break: break-all;
}

// Specific UI helpers

@mixin font-primary($weight: null) {
  font-weight: $weight;
}

@mixin font-secondary($weight: null) {
  font-weight: $weight;
}

@mixin font-code() {
  font-family: "Roboto Mono", monospace;
}

@mixin section-title {
  @include font-primary(600);
  font-size: 19px;
  color: $global-primary-color;
  margin: 0 0 10px;
}

@mixin dropdown {
  border: 1px solid rgba(151, 151, 151, 0.46);
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.11);
}

@mixin backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

$brand-gradient-colors: (
  #512142
  #83376a
  #c84683
  #e62559
  #e52729
  #e7411e
  #ee742c
  #f9b415
);

@mixin brand-gradient {
  $colors: (
    nth($brand-gradient-colors, 1),
    nth($brand-gradient-colors, 2),
    nth($brand-gradient-colors, 3),
    nth($brand-gradient-colors, 4),
    nth($brand-gradient-colors, 5),
    nth($brand-gradient-colors, 6),
    nth($brand-gradient-colors, 7),
    nth($brand-gradient-colors, 8),
    nth($brand-gradient-colors, 7),
    nth($brand-gradient-colors, 6),
    nth($brand-gradient-colors, 5),
    nth($brand-gradient-colors, 4),
    nth($brand-gradient-colors, 5),
    nth($brand-gradient-colors, 5),
    nth($brand-gradient-colors, 7),
    nth($brand-gradient-colors, 8),
    nth($brand-gradient-colors, 7),
  );

  @include stripes(to right, $colors);

  .cc-stripe-set & {
    background: color-customisation(stripeBackgroundColor);
  }
}

@mixin brand-gradient-medium {
  @include stripes(to right, $brand-gradient-colors);

  .cc-stripe-set & {
    background: color-customisation(stripeBackgroundColor);
  }
}

@mixin brand-gradient-small {
  $colors: (
    nth($brand-gradient-colors, 7),
    nth($brand-gradient-colors, 8),
    nth($brand-gradient-colors, 7),
    nth($brand-gradient-colors, 5),
  );

  @include stripes(to right, $colors);

  .cc-stripe-set & {
    background: color-customisation(stripeBackgroundColor);
  }
}

@mixin ui-note($background-color: $note-background, $border-color: $note-border) {
  background: $background-color;
  border: solid $border-color;
  border-width: 2px 2px 2px 8px;
  margin-bottom: 1em;
  padding: 14px;
  color: $secondary-text-color;
  line-height: 1.35;

  p {
    margin: 0 0 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


@mixin wavy-underline($color, $size: 0.25em) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 90%;
    width: 100%;
    height: $size;
    background:
      linear-gradient(
        135deg,
        transparent,
        transparent 45%,
        $color,
        transparent 55%,
        transparent 100%
      ),
      linear-gradient(
        45deg,
        transparent,
        transparent 45%,
        $color,
        transparent 55%,
        transparent 100%
      );

    background-size: ($size * 2) ($size * 2);
    background-repeat: repeat-x, repeat-x;
  }
}
