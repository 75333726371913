@import 'node_modules/sass-kit/mixins/responsive';
@import 'stylesheets/DSV2/Variables';

.page-case-details-case-work {
  $breakpoint: 830px;

  display: flex;
  flex: 1;

  // HTTP status
  &:not(.is-loaded) {
    display: flex;
    flex: 1 1 auto;
  }

  &__loading {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  &__error {
    display: flex;
    flex: 1 1 auto;
    color: $color-danger;
  }

  // layout
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: $layout-03;
    flex: 1;
    min-height: 600px;
    padding: $layout-03;
    background: $ui-01;

    @include below($breakpoint, $convert: false) {
      grid-template-columns: 1fr;
    }
  }

  &__section {
    min-height: 0;
    padding: $layout-03;
    background-color: #fff;
  }

  &__section-header {
    margin: 4px 0 12px;
  }

  &__section-title {
    @include heading-03;
    margin: 0;
  }

  &__section-header-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;

    > * + * {
      margin-left: 8px;
    }
  }

  // section: comments
  &__section--comments {
    display: flex;
    flex-flow: column nowrap;
  }

  // comment search
  &__comment-search {
    flex: 1 1 auto;
  }

  // comment filter
  &__comment-filter {
    flex: 0 1 auto;
    width: 130px;
  }

  // comment list
  &__comment-list-wrap {
    display: flex;
    flex: 1;
    margin-bottom: 16px;
    overflow: auto;
  }

  &__comment-list {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    // FIXME: this is sort of hacky
    height: 0;
    min-height: 100%;

    margin: 0;
    padding: 0;
    list-style: none;

    @include below($breakpoint, $convert: false) {
      height: unset;
      min-height: 0;
      max-height: 500px;
    }
  }

  // comment item
  &__comment-item {
    color: color(grey);

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__comment-meta {
    margin-bottom: 4px;

    > * + * {
      margin-left: 6px;
    }
  }

  &__comment-timestamp {
    font-weight: $weight-03;
  }

  &__comment-user {
    @include body-long-text-01;
  }

  &__comment-text {
    @include body-long-text-01;
    white-space: break-spaces;
    word-break: break-word;
  }

  &__comment-documents {}

  &__comment-documents-toggle-expanded {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }

  &__comment-documents-toggle-expanded-icon {
    margin-left: 2px;

    .icon {
      display: block;
    }
  }

  &__comment-documents-list {
    margin: 8px 0 0;
    padding: 0;
    list-style: none;
  }

  &__comment-documents-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-bottom: 8px;
    padding-right: 20px;

    &.has-active-tooltip,
    &:hover {
      .page-case-details-case-work__comment-document-action {
        opacity: 1;
      }
    }
  }

  &__comment-document-action {
    @include reset-button;

    display: flex;
    align-items: center;

    // prevent buttons stretching when FileUploaderItem has errors
    max-height: $dsv2-file-min-height;

    margin-left: 16px;
    padding: 10.5px 16px;
    background-color: #f3f3f3;
    color: #000;
    white-space: nowrap;

    // using opacity to control visibility so the action can be focused using the keyboard
    opacity: 0;
    transition: opacity 0.15s ease;

    &:not([disabled]) {
      cursor: pointer;
    }

    .icon {
      display: block;
    }

    &:hover:not([disabled]) {
      background-color: #dedede;
    }

    &:focus {
      outline: 1px solid $global-primary-color;
      opacity: 1;
    }

    &[disabled] {
      cursor: wait;
      opacity: 0.6 !important;
    }
  }

  &__comment-document-action-icon {
    &:not(:empty) {
      margin-right: 4px;
    }

    &.is-error {
      color: $color-danger;
    }

    &.is-success {
      color: $color-ok;
    }

    .icon {
      fill: currentColor;
    }
  }

  // add comment
  $comment-add-input-background-color: #fff;
  $comment-add-input-border-radius: 3px;
  $comment-add-submit-button-width: 28px;

  &__comment-add-field {
    position: relative;
  }

  &__comment-add-input {
    box-sizing: border-box;
    width: 100%;
    max-height: 200px;
    padding: 6px $comment-add-submit-button-width 6px 8px;
    border: 1px solid #c7c7c7;
    border-radius: $comment-add-input-border-radius;
    background-color: $comment-add-input-background-color;
    font-size: 13px;

    &:focus {
      border: 1px solid $input-focus-border-color;
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      outline: none;
    }
  }

  &__comment-add-field-error {
    color: $color-danger;
    padding-bottom: 10px;
  }

  &__comment-add-submit-button {
    @include reset-button;

    position: absolute;
    bottom: 0;
    right: 0;
    width: $comment-add-submit-button-width;
    height: $comment-add-submit-button-width;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: $comment-add-input-border-radius;
    color: inherit;

    .icon {
      display: block;
      fill: currentColor;
    }

    &:focus {
      outline: none;
      border-color: $action-default-active-background-color;

      // appear above the scrollbar when the textarea hits max-height
      background-color: $comment-add-input-background-color;
    }

    &:not([disabled]) {
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.3;
    }
  }

  // document upload
  &__document-upload-dropzone {
    margin-top: 16px;
  }

  &__document-upload-files {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__document-upload-files:not(:empty) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
    max-height: 110px;
    margin: 24px 0 0;
    padding: 0 4px 0 0;
    overflow: auto;
  }

  &__document-upload-file {
    .dsv2-file {
      max-width: unset;
    }
  }

  // irrelevant file
  &__irrelevant-file {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    min-width: 0; // required for ellipsis
    padding: 10px 16px;
    background-color: $cds-field-01;
  }

  &__irrelevant-file-meta {
    display: flex;
    width: 100%;
    margin-bottom: 2px;

    > * {
      flex: 1 1 auto;
      min-width: 0;
    }
  }

  &__irrelevant-file-timestamp {
    flex: 0 0 auto;
    margin-right: 6px;
    font-weight: 500;
  }

  &__irrelevant-file-user {
    @include ellipsis;
    flex: 0 1 auto;
  }

  &__irrelevant-file-name {
    @include ellipsis;
    display: block;
    width: 100%; // required for ellipsis
    min-width: 0;
  }

  // section: narrative
  &__section--narrative {
    display: flex;
    flex-flow: column nowrap;
  }

  &__narrative-content {
    flex: 1;
    min-height: 0;
  }

  &__narrative-value-wrap {
    height: 100%;
    overflow: auto;
  }

  &__narrative-value {
    // FIXME: this is sort of hacky
    height: 0;

    white-space: pre-wrap;
  }

  &__narrative-editor {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    outline: 1px solid $ui-03;
    outline-offset: 2px;
    resize: none;

    &:focus {
      outline: 1px solid $global-primary-color;
    }

    &[readonly] {
      background: $ui-03;
    }
  }

  &__narrative-value,
  &__narrative-editor {
    @include body-long-text-01;
    display: block;
    color: color(grey);

    @include below($breakpoint, $convert: false) {
      height: 500px;
    }
  }
}
