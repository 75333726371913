.analytics-header {
  $tooltip-margin: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  transition: background-color 0.2s ease;

  @include analytics-version-color-default;
  &.is-previous-version { @include analytics-version-color-previous; }
  &.is-staging-version { @include analytics-version-color-staging; }
  &.is-live-version { @include analytics-version-color-live; }

  .action--theme-default {
    &:focus {
      outline-color: #fff;
      outline-offset: 2px;
    }
  }

  .loading-spinner {
    .icon {
      width: 1em;
      height: 1em;
      fill: currentColor;
    }
  }

  &__tooltip {
    width: 278px;
    color: color(grey);
    padding: 14px;
  }

  &__tooltip-actions {
    display: flex;
    margin-top: 6px;

    .action {
      flex: 1;
    }
  }

  &__title {
    display: block;
    @include font-primary(400);
    margin-right: 4px;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
    border-bottom: 1px solid currentColor;
    color: inherit;
    text-decoration: none;

    &:focus {
      outline: 1px solid #fff;
      outline-offset: 3px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 0 0 0 15px;

    &,
    .link-button {
      color: currentColor;
    }
  }

  &__right {
    display: flex;
    flex: 0 1 auto;
    min-width: 0;
    padding: 0 15px;
  }

  .version-status {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    .icon {
      width: 1em;
      height: 1em;
      fill: currentColor;
    }
  }

  .analytics-landlord-options,
  .version-history-select {
    display: flex;
    margin: 0 10px;
  }

  .analytics-landlord-options {
    margin-right: 24px;

    &__loading {
      .icon {
        fill: currentColor;
      }
    }

    .custom-select {
      width: 200px;
    }
  }

  .version-history-select {
    .custom-select {
      width: 200px;
    }
  }

  .analytics-landlord-select {
    &__label {
      display: flex;

      &--list-item {
        .analytics-landlord-select__label-part {
          line-height: 1.3;
        }
      }
    }

    &__label-part {
      @include flex-ellipsis;
    }

    &__label-separator {
      display: block;
      flex: 0;
      margin: 0 4px;
    }
  }

  &__version-selector-wrap {
    display: flex;
    align-items: center;
  }

  .analytical-workflow-pending-review {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 0;
    color: currentColor;

    &:focus {
      outline: 1px solid #fff;
      outline-offset: 1px;
    }

    .icon {
      fill: currentColor;
      margin-right: 8px;
      font-size: 18px;
    }

    span {
      text-decoration: underline;
      font-weight: $weight-03;
    }
  }

  .analytics-header-meta-actions-container {
    display: flex;
    align-items: center;
    min-width: 0;
    margin-left: auto;
    flex: 0 1 auto;
  }

  .analytical-workflow-meta {
    display: flex;
    flex: 0 1 auto;
    min-width: 100px;
    margin-right: 8px;
    color: currentColor;

    &__desc,
    &__date {
      @include ellipsis;
      display: inline-block;
    }

    &__desc {
      margin: 0 4px;
    }

    &__comment {
      margin-right: 10px;

      .icon {
        fill: currentColor;
      }
    }

    &__user {
      .icon {
        fill: currentColor;
        vertical-align: top;
      }
    }
  }

  .analytical-workflow-actions {
    display: flex;

    &__item {
      margin-left: 8px;
    }

    .action + .action {
      margin-left: 6px;
    }
  }

  .analytical-workflow-action-icon {
    &--ok { color: $color-ok; }
    &--danger { color: $color-danger; }
  }
}

.app-environment .sandbox {
  $brand-sandbox: #AB3363;
  $brand-sandbox-secondary: #F4DAE6;
  $nav-sandbox-height: 36px;
  $sidebar-md-width: 280px;

  display: flex;
  background-color: $brand-sandbox-secondary;
  flex: 0 0 $nav-sandbox-height;

  .icon {
    height: 36px;
    width: 12px;
    vertical-align: top;
    margin-right: 4px;
  }

  .sandbox-label {
    overflow: hidden;
    font-size: 13px;
    line-height: $nav-sandbox-height;
    color: #fff;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: $brand-sandbox;
    flex: 0 0 $sidebar-md-width;

    .icon {
      fill: currentColor;
    }
  }

  .nav nav {
    a {
      text-decoration: none;
      outline: none;
    }

    ul {
      background: inherit;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      list-style: none;
    }

    & > ul > li {
      background: inherit;
      position: relative;
      float: left;
    }

    a, button {
      display: block;
      padding: 0 10px;
      font-weight: $weight-02;
      font-size: 13px;
      line-height: $nav-sandbox-height;
      background: $brand-sandbox-secondary;
      color: #000;

      &:hover {
        color: $primary-button-hover-background;

        .icon {
          fill: $primary-button-hover-background;
        }
      }

      &.active {

        background-color: $brand-sandbox;
        color: $primary-button-foreground;

        .icon {
          fill: $primary-button-foreground;
        }
      }

      &:focus {
        outline: 1px solid $global-link-color;
        outline-offset: -1px;
      }
    }
  }
}

@media screen and (max-width: $header-sm-break) {
  .application--sidebar-expanded .analytics-header {
    & > * {
      display: none !important;
    }
  }
}
