$documentation-background-color: #fbfbfb;
$documentation-border-color: #dfdfdf;
$tooltip-margin: 10px;

.documentation {
  @include stack-context(docs-panel);
  background-color: $documentation-background-color;
  border-left: 5px solid $documentation-border-color;
  flex: 0 0 380px;
  display: flex;
  flex-direction: column;
  width: 380px;

  &__header {
    display: flex;
    padding: 15px;
    flex: 0 0 auto;
  }

  &__title {
    @include ellipsis;
    color: $secondary-text-color;
    line-height: 30px;
    font-size: 17px;
    font-weight: $weight-03;
    max-width: 150px;
  }

  &__group {
    margin-left: auto;
    display: flex;

    & > * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__tooltip {
    @include stack-context(message-tooltip);
    position: relative;
  }

  &__tooltip-arrow {
    @include horizontal-center;
    bottom: calc(-#{$tooltip-margin} - 1px);
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: index($stack, message-tooltip) + 1;

    &::before {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
    }

    &--top {
      border-bottom-color: $tooltip-border-color;
      border-width: 0 6px 6px;

      &::before {
        top: 1px;
        left: -5px;
        border-bottom-color: $tooltip-background-color;
        border-width: 0 5px 5px;
      }
    }
  }

  &__tooltip-content {
    @include stack-context(message-tooltip);
    @include dropdown;
    position: absolute;
    top: 100%;
    right: -35px;
    width: 348px;
    margin-top: $tooltip-margin;
    padding: 8px;
    background-color: #fff;
    font-size: 13px;

    .form-input--search {
      display: block;
    }
  }

  &__indexes {
    height: 35vh;
    overflow: auto;
    margin-top: 3px;
  }

  &__index-list {
    padding: 0;
    margin: 0;
  }

  &__index-item {
    list-style: none;
    margin: 0;
    line-height: 20px;
    border-bottom: 1px solid #DEDEDE;
  }

  &__index {
    display: block;
    padding: 5px;
    color: $secondary-text-color;
    text-decoration: none;
    border: 0;
    background: none;
    text-align: left;
    width: 100%;

    &--active {
      background-color: #FBEAF1;
      color: #D54A89;
    }
  }

  &__body {
    color: $secondary-text-color;
    flex-grow: 1;
    overflow: auto;
    padding: 0 15px;

    a {
      color: $global-link-color;
    }

    img {
      max-width: 245px;
    }

    dt {
      font-weight: $weight-03;
      padding-bottom: 10px;

      &::after {
        content: ":";
      }
    }

    dd {
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
