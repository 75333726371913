.auto-grow-textarea {
  display: grid;
  overflow: hidden;

  &__input,
  &__sizer {
    // Identical styling required!
    // These base styles can be overwritten as needed but should be applied to both the textarea input and the sizer.
    display: block;
    padding: 2px;
    border: 1px solid #ccc;
    font: inherit;
    white-space: pre-wrap;
    overflow-wrap: break-word;

    // Firefox has a bug when using web fonts + `line-height: 1.15` that causes
    // an unnecessary scrollbar to appear.
    // Using a line-height > 1.15 works, as does using a system font like Arial.
    line-height: 1.16;

    // Place on top of each other.
    grid-area: 1 / 1 / 2 / 2 !important;

    // Prevent grid blowout from long words.
    min-width: 0 !important;
  }

  &__input {
    // This would break the auto sizing.
    resize: none !important;
  }

  &__sizer {
    // Hidden from view, clicks and screen readers.
    visibility: hidden !important;
  }
}
