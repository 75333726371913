.page-amdl {
  &__metrics-errors {
    width: 690px;
    max-width: 100%;

    h1 {
      font-size: 18px;
      font-weight: $weight-03;
      color: #cd4488;
    }

    .table {
      &__cell {
        &--html .clickable {
          cursor: pointer;
        }

        &--expand {
          vertical-align: top;
        }
      }
    }
  }
}
