.page-case-details-transactions {
  .table__cell {
    vertical-align: top;
  }

  // reviewing
  &__review-toolbar {
    display: flex;
    align-items: center;
  }

  &__review-toolbar-item {
    & + & {
      margin-left: 6px;
    }
  }

  &__review-summary {
    color: color(grey);
  }

  &__review-summary-error {
    margin-bottom: 10px;
    color: $color-danger;
    font-size: 14px;
  }

  // table cells
  .table__cell {
    .percentage-bar {
      min-width: 130px;
    }
  }

  &__inline-form-field-cell {
    .form-field {
      margin-bottom: 0;

      &__label-wrap {
        @include visually-hidden;
      }
    }
  }

  &__review-cell-alert-unreviewed {
    width: 240px;
  }

  &__review-no-risk-reasons-field-expand-button {
    @include body-short-text-01;
    color: color(grey);

    &:not([disabled]) {
      cursor: pointer;
    }
  }

  $review-reasons-field-icon-size: 16px;
  $review-reasons-field-icon-spacing: 10px;

  &__review-no-risk-reasons-field-expand-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: $review-reasons-field-icon-spacing;

    .icon {
      width: $review-reasons-field-icon-size;
    }
  }

  &__review-no-risk-reasons-field-selected-label {
    display: block;
    margin-left: $review-reasons-field-icon-size + $review-reasons-field-icon-spacing;
  }

  &__review-no-risk-reasons-field-options {
    margin: 0;
    padding: 0;
    list-style: none;

    &:not(:empty) {
      margin-top: 6px;
    }
  }

  &__review-no-risk-reasons-field-option {
    @include body-short-text-01;
    margin-bottom: 6px;
    color: color(grey);

    .c-checkbox {
      line-height: inherit;
    }
  }

  &__review-cell-event,
  &__review-cell-event-removed {
    .icon {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 4px;
    }
  }

  &__risk-identified-cell {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    white-space: normal;
  }

  &__details-sidebar {
    width: 600px;

    &:focus {
      outline: 0;
    }
  }

  &__details-sidebar-actions {
    display: flex;
    justify-content: flex-end;
  }

  &__details-sidebar-title {
    @include font-primary;
    margin: 16px 0;
    font-size: 24px;
    font-weight: $weight-03;
    color: #3c3c3c;
  }

  &__details-sidebar-actions {
    display: flex;
  }

  &__details-sidebar-actions-navigate {
    margin-right: auto;

    > * {
      margin-right: 5px;
    }
  }

  &__event-type-icon {
    display: inline-block;
    margin-right: 0.1em;
  }
}
