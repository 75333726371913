.page-incident-filter-details {
  $graphic-size: 800px;

  &__time-range-subset-field {
    max-width: $graphic-size;
    margin: 0 auto;

    > .form-field__label-wrap .form-field__label {
      @include visually-hidden;
    }
  }

  &__time-range-subset-option {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding: 15px 30px;

    &--before-now {
      .page-incident-filter-details__time-range-subset-graphic-range-label--left {
        width: 51.8%;
      }

      .page-incident-filter-details__time-range-subset-graphic-range-label--right,
      .page-incident-filter-details__time-range-subset-field-group:nth-child(1) {
        width: 47.9%;
      }

      .page-incident-filter-details__time-range-subset-field-group:nth-child(1) {
        margin-left: 52.2%;
      }
    }

    &--before-offset {
      .page-incident-filter-details__time-range-subset-graphic-range-label--left {
        width: 51.6%;
      }

      .page-incident-filter-details__time-range-subset-graphic-range-label--right,
      .page-incident-filter-details__time-range-subset-field-group:nth-child(1) {
        width: 48.1%;
      }

      .page-incident-filter-details__time-range-subset-field-group:nth-child(1) {
        margin-left: 51.4%;
      }
    }

    &--between {
      .page-incident-filter-details__time-range-subset-graphic-range-label--left {
        width: 22%;
      }

      .page-incident-filter-details__time-range-subset-graphic-range-label--center,
      .page-incident-filter-details__time-range-subset-field-group:nth-child(1) {
        width: 37.8%;
      }

      .page-incident-filter-details__time-range-subset-field-group:nth-child(1) {
        margin-left: 23%;
      }

      .page-incident-filter-details__time-range-subset-graphic-range-label--right,
      .page-incident-filter-details__time-range-subset-field-group:nth-child(2) {
        width: 40%;
      }

      .page-incident-filter-details__time-range-subset-field-group:nth-child(2) {
        margin-left: 0;
      }
    }
  }

  &__time-range-subset-graphic {
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 100%;
    margin-bottom: 16px;
    padding: 5px 4px 0;

    svg {
      max-width: 100%;
      height: auto;
    }

    &-axis-labels {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      flex-flow: row;
      margin-bottom: 4px;
    }

    &-oldest-label {
      display: block;
    }

    &-now-label {
      display: block;
    }

    &-range-label-group {
      display: flex;
      flex-flow: row nowrap;
      flex: 0 0 auto;
      width: 100%;
      min-width: 0;
      margin-top: -6px;
    }

    &-range-label {
      display: block;
      flex: 0 0 auto;
      padding: 0 6px;
      text-align: center;
    }
  }

  &__time-range-subset-field-groups {
    display: flex;
    width: 100%;
    min-width: 0; // flex overflow fix
  }

  &__time-range-subset-field-group {
    padding: 0 6px;

    .form-field {
      margin-bottom: 10px;

      &__label-wrap {
        flex: 0 1 80px;
      }

      &__form-input-wrapper {
        flex: 0 0 70px;
      }
    }
  }

  &__time-range-subset-field-group-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: color(grey);
  }
}
