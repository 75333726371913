.steps-tracker {
  $steps-color: color-customisation(primaryColor, color(purpleDark));

  display: flex;
  padding: 0 0 20px;
  justify-content: center;

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step--active {
    .steps-tracker__icon {
      background: $steps-color;
    }

    .steps-tracker__icon-text {
      color: #fff;
    }

    .steps-tracker__name {
      color: $steps-color;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 160px;
  }

  &__icon {
    width: 35px;
    height: 35px;
    border: 2px solid $steps-color;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-text {
    font-size: 18px;
    font-weight: $weight-03;
    color: $steps-color;
  }

  &__bar--back,
  &__bar--front {
    width: 65px;
    height: 2px;
    background: $steps-color;
  }

  &__name {
    padding: 8px 0;
    color: $color-gray;
    font-weight: $weight-03;
  }

  &__step:last-child {
    padding-right: 0;
  }

  &__step:first-child .steps-tracker__bar--back {
    background: #fff;
  }

  &__step:last-child .steps-tracker__bar--front {
    background: #fff;
  }
}
