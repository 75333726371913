.page-landing {
  $card-padding: 10px;

  display: flex;
  justify-content: center;
  min-height: 100%;

  &__loading {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    font-size: 16px;

    .icon {
      fill: currentColor;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    color: $color-danger;
    font-size: 16px;

    .icon {
      fill: currentColor;
    }
  }

  &__success {
    width: 100%;
    flex: 1 1 auto;
  }

  &__solutions {}

  &__solutions-title {
    @include heading-01;
    margin: 1rem ($spacing-05 * 2) 0;
  }

  &__cards {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 4px;
    list-style: none;
  }

  &__card {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    flex: 0 0 auto;
    width: 250px;
    min-height: 130px;
    margin: 4px;
    background-color: #fff;
  }

  &__card-main {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: $card-padding;
  }

  &__card-media-image {
    max-width: 50px;
    margin-right: 8px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__card-media-body {
    flex: 1 1 auto;
  }

  &__card-title {
    margin: 0;
  }

  &__card-actions {
    width: 100%;
    margin-top: 4px;
    padding: $card-padding $card-padding;
    border-top: 1px solid color(greyLight);
  }
}
