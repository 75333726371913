$vertical-layout-break: 992px;

.timewindow__action-group {
  flex: 1;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  align-items: center;

  [role='button']:hover {
    text-decoration: underline;
  }

  span {
    word-spacing: -1px;
    padding-left: 10px;
    color: lighten($color-gray, 15%);
  }

  .active {
    color: $primary-text-color;
  }

  .inactive {
    color: $primary-input-color;
  }

  .start {
    color: $secondary-text-color;
  }

  @media screen and (max-width: $vertical-layout-break) {
    [role='button'],
    .right > .start {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    [role='button'] {
      max-width: 33px;
      padding-left: 6px;
    }

    .right > .start {
      max-width: 70px;
    }
  }
}

.timewindow__action-group-list {
  line-height: 1;

  font-weight: 700;

  [role="button"] {
    cursor: pointer;
  }

  &:first-child {
    padding-right: 14px;
  }

  &:last-child {
    padding-left: 4px;
    border-left: 1px color(grey) solid;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .time-resolution {
    color: $global-primary-color;
    display: inline-block;
    width: 80px;
  }
}
