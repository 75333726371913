.dropdown-actions {
  $active-background-color: $global-primary-selected-background-color;

  &--with-dropdown-style {
    @include dropdown;
  }

  &__action {
    @include ellipsis;
    width: 100%;
    padding: 3px;
    cursor: pointer;

    .action {
      @include body-short-text-01;
      display: flex;
      align-items: center;
      padding: 3px $spacing-05;

      .icon {
        width: auto;
        height: 1em;
      }

      &:hover {
        background-color: $active-background-color;
      }

      &:focus {
        background-color: $active-background-color;
        outline: 1px solid $global-primary-color;
      }
    }


    &.is-selected {
      background-color: $active-background-color;

      .action {
        color: $global-primary-color;
        font-weight: $weight-03;
      }
    }
  }
}
