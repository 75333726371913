.description-list {
  // layout: row
  &--layout-row {
    $dt-width: 25%;
    $gutter: 50px;

    .description-list__inner {
      display: grid;
      grid-template-columns: calc(25% + 50px) 1fr;
      margin: 0;
      white-space: normal;
    }

    &.description-list--terms-fit-content {
      .description-list__inner {
        grid-template-columns: fit-content(100%) minmax(auto, auto);
      }
    }

    .description-list__item {
      display: contents;

      &:not(:last-child) {
        .description-list__term,
        .description-list__details {
          border-bottom: 1px solid #d0d0d0;
        }
      }
    }

    .description-list__term,
    .description-list__details {
      margin: 0;
      padding: 0.6em 0;
      line-height: 1.7;

      // ellipsis long single words but wrap where possible
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description-list__term {
      padding-right: $gutter;
      font-weight: $weight-03;
    }

    .description-list__details {}
  }

  // layout: column
  &--layout-column {
    // column description list
    $column-dl-item-padding: 10px;

    .description-list__inner {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: fit-content(100%) 1fr;
      grid-gap: 0 $column-dl-item-padding;
      margin: 0;
    }

    &.description-list--width-fit-content {
      .description-list__inner {
        // dont expand to fill available space
        width: fit-content;
        max-width: 100%;
      }
    }

    .description-list__item {
      display: contents;

      &:not(:last-child) {
        .description-list__term,
        .description-list__details {
          padding-right: $column-dl-item-padding;
          border-right: 1px solid $ui-03;
        }
      }
    }

    .description-list__term {
      padding-bottom: 6px;
      font-weight: $weight-03;
    }

    .description-list__details {
      margin: 0;
    }
  }

  // layout: pipe
  &--layout-pipe {
    $pipe-dl-item-padding: 4px;

    position: relative;
    overflow: hidden;
    margin: 0;

    .description-list__inner {
      display: flex;
      flex-flow: row wrap;

      // -1px hides the "pipe" border for the first item in each row.
      // -$pipe-dl-item-padding aligns the first column to the content above it.
      margin: 0 0 0 calc(-1px - #{$pipe-dl-item-padding});
    }

    .description-list__item {
      flex: 0 0 auto;
      margin-bottom: 2px;
      padding: 0 $pipe-dl-item-padding;
      border-left: 1px solid color(greySecondary);
    }

    .description-list__term {
      display: inline-block;
      margin-right: 2px;

      &::after {
        content: ':';
      }
    }

    .description-list__details {
      display: inline-block;
      margin: 0;
    }
  }
}
