@use "sass:math";

$dashboard-grid-gutter: 8px;
$dashboard-dragdrop-min-width: 1240px;
$dashboard-sidebar-border-color: #cbcbcb;

// Page
.advanced-dashboard {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100%;
  z-index: 0;

  .globe-3d {
    flex: 1;
    margin-left: 12px;
  }

  &__grid {
    display: flex;
    flex: 1 0 auto;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.is-loaded {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
    }

    &--manage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: #fff;
    }
  }

  &__manage-templates-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: rgba(#fff, 0.9);
    z-index: 3;

    &-icon {
      width: 79px;
      height: 82px;
      margin: 0 auto 16px;
      background: url('/images/dashboard/DashboardIcon.png') no-repeat;
    }

    &-title {
      font-size: 18px;
      margin: 0 0 10px;
    }

    &-body {
      margin: 0;
      font-size: 14px;
      color: color(grey);
    }
  }

  &__content-loading,
  &__content-error {
    display: flex;
    align-items: center;
    font-size: 20px;

    .icon {
      fill: currentColor;
      margin-right: 6px;
    }
  }

  &__content-error {
    color: $color-danger;
  }
}


// Grid
.dashboard-grid {
  $grid-width: 3;
  $grid-height: 3;
  $grid-size: $grid-width * $grid-height;
  $gutter: $dashboard-grid-gutter;
  $min-cell-height: 260px;

  // zero indexed
  @mixin cellOffset($row: 0, $col: 0) {
    top: ((math.div(100, $grid-height)) * 1%) * $row;
    left: ((math.div(100, $grid-width)) * 1%) * $col;
  }

  @mixin cellWidth($span: 1) {
    width: ((math.div(100, $grid-width)) * 1%) * $span;
  }

  @mixin cellHeight($span: 1) {
    height: ((math.div(100, $grid-height)) * 1%) * $span;
  }

  position: relative;
  width: 100%;
  flex: 1 1 auto;

  &__drop-placeholder {
    @include cellWidth(1);
    @include cellHeight(1);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex: 0 0 auto;
    min-height: fit-content;
    padding: $gutter;

    &-inner {
      display: flex;
      flex: 1 0 auto;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 16px;
      border: 2px dashed #aeb2b7;
      color: #717478;
      font-size: 19px;
      text-align: center;
    }

    &-icon {
      display: block;
      width: 68px;
      height: 67px;
      background: url('/images/dashboard/dragdrop-icon.png') no-repeat;
      margin-bottom: 6px;
      flex: 0 0 auto;
    }
  }

  &__dropzones {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: 0 $gutter;
    opacity: 0;
    visibility: hidden;
    z-index: 1;

    // delay the grid coming up to prevent the droppable being dropped immediately
    transition: none 0.001s;
    transition-property: opacity, visibility;

    &.is-visible {
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }
  }

  &__dropzone {
    @include cellWidth(1);
    @include cellHeight(1);
    min-height: $min-cell-height;
    flex: 1 1 auto;
    border: $gutter solid transparent;

    &.is-available {
      // border-color: #fff;
      background: #eac7de;
      z-index: 3;
    }

    &.is-active {
      // border-color: #fff;
      background: #dda3c8;
    }

    &.is-resize-target {}
  }

  &__items {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__item {
    // position absolute/relative + z-index fixes the ghost image including surrounding elements when the item content has overflow
    position: absolute;
    z-index: 2;

    min-height: $min-cell-height;
    padding: $gutter;
    background: #fff;
    pointer-events: initial;

    @include cellWidth(1);
    @include cellHeight(1);

    @for $i from 1 to ($grid-width + 1) {
      &--width#{$i} { @include cellWidth($i); }
    }

    @for $i from 1 to ($grid-height + 1) {
      &--height#{$i} { @include cellHeight($i); }
    }

    // zero indexed
    @for $position from 0 through ($grid-size - 1) {
      $row: floor(math.div($position, $grid-width));
      $col: $position - ($row * $grid-width);

      &--position#{$position} { @include cellOffset($row, $col); }
    }

    &.is-dragging {}
  }

  &__item-inner {
    position: relative;
    border: 1px solid color(greyLight);
    height: 100%;
    padding-bottom: 16px;

    &:focus {
      outline: 1px solid $global-primary-color;
    }
  }

  &__draggable {
    height: 100%;
  }

  &__item-content-wrap {
    position: static;
    overflow: auto;
    height: 100%;
    margin-right: 12px;
  }

  &__report-content {
    display: flex;
    height: calc(100% - 1px);
    //padding: 10px 10px 0;
    padding: 0;
    min-height: 160px;
  }

  &__actions {
    display: flex;
  }

  &__remove-item {
    @include reset-button;
    display: block;
    margin-left: auto;
    padding: 0 4px;
    opacity: 0.5;
    transition: opacity 0.25s;

    &:hover {
      opacity: 1;
    }

    &:focus {
      border: 1px solid $global-primary-color;
    }
  }

  &__resize-handle {
    $xy-handle-size: 20px;

    @include reset-button;
    display: block;
    opacity: 0.5;
    visibility: hidden;
    transition: opacity 0.25s;

    &:hover {
      opacity: 1;
    }

    &.is-active {
      visibility: visible;
    }

    &--x {
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url('/images/dashboard/SideDrag.png');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: auto auto;
      cursor: col-resize;
    }

    &--y {
      width: 100%;
      height: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url('/images/dashboard/BottomDrag.png');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: auto auto;
      cursor: row-resize;
    }

    &--xy {
      position: absolute;
      bottom: 0;
      right: 0;
      width: $xy-handle-size;
      height: $xy-handle-size;
      background-image: url('/images/dashboard/CornerDrag.png');
      background-repeat: no-repeat;
      background-position: 2px 2px;
      background-size: 75% auto;
      cursor: nwse-resize;
    }
  }

  &__panel {
    padding-bottom: 0;

    &,
    .panel__body,
    .panel__wrapper {
      height: 100%;
    }

    .panel__body {
      min-height: 0; // fix Firefox overflow
      padding-bottom: 0;
    }
  }

  &__panel-header {
    @include ellipsis;
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 35px; // dont collapse when label is missing because it's loading
    justify-content: space-between;
  }

  &__panel-title {}

  // single column layout
  @media screen and (max-width: $dashboard-dragdrop-min-width) {
    &__drop-placeholder {
      display: none;
    }

    &__dropzones {
      display: none;
    }

    &__items {
      position: static;
    }

    &__item {
      position: static;
      top: initial;
      left: initial;
      width: auto;
      height: auto;
    }

    &__resize-handle {
      display: none;
    }
  }
}


// Summary panel
.dashboard-summary {
  $gutter: $dashboard-grid-gutter;

  width: auto;
  height: 80px;
  flex: 0 0 auto;
  margin: 0 $gutter 8px;
  padding: 8px;
  border: 1px solid color(greyLight);
}


// Sidebar
.advanced-dashboard-sidebar {
  $gutter: 15px;

  padding-bottom: $message-broadcast-height;

  &__manage-templates {
    &.is-active {
      border-bottom: 2px solid $dashboard-sidebar-border-color;
    }
  }

  &__manage-templates-button {
    margin: $gutter;

    .action {
      width: 100%;
    }
  }

  &__manage-templates-scope-select,
  &__manage-templates-copy-to-select {
    margin: $gutter;

    .form-field {
      margin: 0;
    }

    .form-field__label {
      font-weight: $weight-03;

      // allow the colour customisation from the sidebar to propagate to these headings (ARIC-19101).
      color: color-customisation(sidebarColor, color(grey));
    }
  }

  &__manage-templates-copy-to-button {
    display: flex;
    justify-content: flex-end;
    margin: 4px $gutter $gutter;
  }

  &__summary-panel-select {
    margin: $gutter;

    .form-field {
      margin: 0;
    }

    .form-field__label {
      font-weight: $weight-03;
    }
  }

  &__panels-search {
    margin: 18px $gutter 4px;
  }

  &__panel-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__panel-list-item {
    &:first-child {
      .advanced-dashboard-sidebar__panel-list-item-button {
        border-top: 0;
      }
    }
  }



  &__panel-list-item-button {
    @include reset-button;
    @include ellipsis;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px $gutter;
    border-top: 1px solid $sidebar-border-color;
    text-align: left;
    font-weight: $weight-03;

    // The colour of <button> elements gets explicitly set by Chromes default stylesheet.
    // Changing it to inherit means the colour customisation from the sidebar propagates to these buttons (ARIC-19101).
    color: inherit;

    &-label {
      @include ellipsis;
      display: block;
      flex: 0 1 auto;
      min-width: 0;
    }

    .icon {
      fill: currentColor;
      font-size: 18px;
      width: auto;
      height: 1em;
    }

    &-type-icon {
      display: block;
      flex: 0 0 auto;
      padding-right: 6px;
    }

    &-status-icon {
      display: block;
      flex: 0 0 auto;
      margin-left: auto;
      padding-left: 6px;
    }

    &:hover {
      color: color-customisation(sidebarNavItemHoverColor, $sidebar-color);
      background-color: color-customisation(sidebarNavItemHoverBackgroundColor, $sidebar-hover-background-color);
    }

    &:focus {
      outline: 1px solid $global-link-color;
      outline-offset: -1px;
    }
  }
}
