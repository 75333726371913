@import 'node_modules/sass-kit/mixins/visually-hidden';

$panel-background-color: $primary-background-color;
$panel-header-color: $primary-text-color;
$panel-title-margin: 8px;
$panel-padding-bottom: 15px;

$panel-disabled-tab-color: #A4A4A4;

$panel-section-details-border-color: #dedede;
$panel-section-details-label-color: #7f7f7f;

.panel {
  display: flex;
  flex-direction: column;
  padding-bottom: $panel-padding-bottom;

  &__anchor {
    position: absolute;
    top: -$panel-padding-bottom;
  }

  &__controls {
    margin: 0 8px;
  }

  &__header {
    color: color-customisation(panelHeaderColor, $panel-header-color);
    display: flex;
    font-size: 16px;
    font-weight: $weight-03;
    white-space: nowrap;
  }

  &__group {
    margin-left: auto;
    padding-bottom: 5px;
    display: flex;

    & > * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__group-left {
    margin-left: 0;
  }

  &__title {
    line-height: 30px;
    margin: 0 $panel-title-margin 0 0;
    overflow: hidden;
    padding-bottom: 5px;
    text-overflow: ellipsis;
    color: color-customisation(panelHeaderColor, $panel-header-color);
    display: flex;
    font-size: 16px;
    font-weight: $weight-03;
    white-space: nowrap;

    .breadcrumb {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    color: $primary-text-color;
    display: inline-block;
    font-size: 14px;
    line-height: 1;

    .link-button {
      padding: 0 10px;
      line-height: 28px;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    min-width: 0;

    .panel__subtitle {
      padding-bottom: 5px;
    }
  }

  &__tabs {
    display: flex;
    flex: 0 0 auto;
    overflow-x: auto;

    &-overflow {
      position: relative;

      &.is-expanded {
        .panel__tabs-overflow-button {
          .icon {
            transform: rotate(180deg);
          }
        }

        .panel__tabs-overflow-tabs {
          display: flex;
        }
      }
    }

    &-overflow-button {
      .icon {
        transform: rotate(0);
        transition: transform 0.2s ease;
      }
    }

    &-overflow-tabs {
      @include dropdown;
      position: absolute;
      top: 100%;
      right: 0;
      display: none;
      flex-flow: column nowrap;
      margin: 2px -5px 0 0;
      padding: 4px 0;
      color: #000;
      background-color: #fff;
      z-index: 1;

      .panel__tab {
        margin: 0;
        padding: 8px 16px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
      }
    }

    &--align-right {
      margin: 0 0 0 auto;
    }

    &--limited-visible-mode {
      overflow: initial;
    }
  }

  &__tab {
    @include ellipsis;

    border: 0;
    line-height: 30px;
    background-color: inherit;
    font-weight: $weight-03;
    margin-left: 5px;
    padding: 0 10px 5.5px;
    text-decoration: none;
    color: inherit;

    &:focus {
      outline: 1px solid $global-link-color;
      outline-offset: -1px;
    }

    &--active {
      color: $global-primary-color;
      background-color: $primary-background-color;
      cursor: default;
    }

    &:first-child {
      margin-left: 0;
    }

    &--disabled {
      color: $panel-disabled-tab-color;
      cursor: default;
    }
  }

  &__tab-screenreader {
    @include visually-hidden();
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 0;
  }

  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;

    // fix weird behaviour when <fieldset> contains flex items
    // https://github.com/philipwalton/flexbugs/issues/44#issuecomment-137855115
    min-width: 0;
  }

  &__section-form-fields, .form__section-form-fields {}


  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }


  &__section-details {
    dl {
      display: flex;
      margin: 0;

      &:last-child {
        dt,
        dd {
          border: 0;
        }
      }
    }

    dt {
      font-weight: $weight-04;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
    }

    dt,
    dd {
      border-bottom: 1px solid $panel-section-details-border-color;
      display: table-cell;
      line-height: 22px;
      padding: 8px;
      vertical-align: top;
    }

    dd {
      flex: 1;
      margin: 0;
      padding-left: 0;
      overflow: auto;
    }

    .label {
      border: 1px solid $panel-section-details-label-color;
      border-radius: 3px;
      color: $panel-section-details-label-color;
      display: inline-block;
      font-weight: $weight-03;
      font-size: 13px;
      line-height: normal;
      padding: 0 4px;
      text-decoration: none;
    }
  }

  &--empty-body {
    padding-bottom: 0;

    .panel__body {
      padding: 0 0 5px;
    }
  }

  &--editor {
    flex: 1;

    .panel__body {
      padding: 0;
    }
  }

  &--submit-button {
    float: right;
  }

  // panels inside the main content area
  &--main {
    &:last-child {
      padding-bottom: 0;
    }

    &:focus {
      outline: 1px solid $global-primary-color;
    }

    .form__section-form-fields,
    .form__section-submit-button {
      margin: 0 auto;
      max-width: 690px;
      width: 100%;
    }

    .form__section-form-fields {
      padding-top: 50px;

      &--add-button {
        padding-top: 0;
      }
    }

    .panel {
      &__header {
        padding: 5px 5px 0;
        background-color: color-customisation(panelHeaderBackgroundColor, $panel-header-background-color);
        flex: 0 0 auto;
      }

      &__subheader {
        display: flex;
        padding: 5px 5px 0;
        border-bottom: 1px solid color-customisation(panelHeaderBackgroundColor, $panel-header-background-color);
      }

      &__title {
        margin-left: $panel-title-margin;
      }

      &__wrapper {
        background-color: $panel-background-color;
      }

      &__section-form-fields {
        margin: 0 auto;
        max-width: 690px;
        padding-top: 50px;
        width: 100%;

        &--add-button {
          padding-top: 0;
        }
      }

      &__section-editor-view {
        border-bottom: 1px solid #DEDEDE;
        padding-bottom: 13px;
      }

      &__editor-manage-container {
        text-align: right;
      }

      &__editor-manage {
        position: relative;
        margin-left: auto;
      }
    }
  }

  // panels in sidebar content areas
  &--sidebar {
    .form-field {
      margin-bottom: 1px;
      padding: 15px 0;
    }

    .checkbox--reverse {
      border-top: 1px solid color(greySecondary);
      margin-top: 15px;

      .checkbox__label-text {
        font-size: 14px;
      }
    }

    .form-input--datetime-range {
      .form-input__part {
        display: block;
      }
    }
  }

  // panels in tooltip content areas
  &--tooltip {
    .panel {
      &__header {
        background-color: inherit;
      }
    }

    .form-field {
      margin-bottom: 5px;
    }
  }

  // panels with sticky headers
  &--sticky-headers {
    .panel__header {
      position: sticky;
      top: -15px;
      @include stack-context(sticky-panel-header);
    }
  }

  .panel-body--custom {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .dataservice-expandable-title &__title {
    display: inline-flex;
  }

  &__data-services-modal {
    width: 95vw;
    height: calc(90vh - 150px);
    position: relative;

    .panel__wrapper {
      position: initial;
      overflow: auto;
    }

    > .panel__wrapper > .panel__header {
      position: fixed;
      width: 95vw;
      z-index: 1;
    }

    > .panel__wrapper > .panel-body {
      margin-top: 50px;
    }

    .panel__tabs .action {
      border: 0;
      background-image: none;
      font-size: 16px;
    }

    .panel__tab {
      padding-right: 12px;
    }

    .panel__tab--has-data::after {
      content: "\25CF";
      position: absolute;
      top: -9px;
      right: 1px;
    }
  }
}
