.page-event-search {
  $highlight-color: #f3f0ae;

  &__details-markdown {
    white-space: nowrap;

    p {
      display: inline-block;
      margin: 0;
    }

    em {
      background-color: $highlight-color;
      font-style: normal;
    }
  }

  &__filters-sidebar {
    .panel__body {
      position: relative;
    }

    .static-tooltip__content {
      min-width: 180px;
      word-break: break-word;
    }

    .form-field-keywords-wrapper {
      position: relative;

      .icon-search {
        position: absolute;
        top: 53px;
        left: 8px;
        fill: #b3b3b3;
        z-index: 1;
      }

      .action--update-additional-keywords {
        position: absolute;
        top: 46px;
        right: 2px;

        svg.icon-arrow-right {
          margin-right: 0;
        }
      }

      .form-input--text-with-submit input {
        padding-left: 29px;
        padding-right: 30px;
      }
    }
  }
}

.checkbox--reverse {
  .checkbox__label {
    flex-direction: row-reverse;
    padding-top: 15px;
  }

  .checkbox__input--inline ~ .checkbox__label-text {
    display: flex;
    flex: 1;
    margin-left: 0;
  }
}

.checkbox--reverse + .form-field {
  padding-top: 0;
}

@media screen and (max-width: 992px) {
  .form-input--datetime-range {
    .form-input__part {
      width: 100%;

      & + .form-input__part {
        margin-top: 10px;
        margin-left: 0;
      }

      .form-field__label-wrap {
        flex: 1 1 230px;
      }

      .form-input__datetime-wrapper {
        flex: 1;
      }
    }
  }
}
