$header-search-results-color: $global-primary-color;

.header-search {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: auto;
  flex: 1;

  .icon {
    display: block;
    width: 14px;
    height: 17px;
    fill: #b3b3b3;
  }

  &__failure {
    color: #B3B3B3;

    .icon {
      margin: auto;
      margin-bottom: 5px;
    }
  }

  // TODO: Remove this block when app-legacy.css is removed
  &__input[type='search'] {
    box-sizing: border-box !important;
  }

  &__inner {
    position: relative;
    display: inline-block;
    flex: 1;
  }

  &__input {
    @include font-primary(600);
    width: 100%;
    height: 35px;
    line-height: 1;
    padding: 0 20px 0 30px;
    border: 1px solid #eee;
    background: #eee;
    font-size: 13px;
    color: color(grey);

    @include input-placeholder {
      @include font-secondary;
    }
  }

  &__search-icon-wrap {
    @include vertical-center;
    left: 8px;
    line-height: 1;
  }

  &__clear-button {
    @include reset-button;
    @include vertical-center;
    right: 5px;
    display: block;
  }

  &__loading {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .icon {
      margin-right: 6px;
    }
  }

  &__results:not(:empty) {
    @include dropdown;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    padding: 13px;
    background: #fff;
    z-index: 2;
    font-weight: $weight-03;
    font-size: 13px;
  }

  &__results-group {
    & + & {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eaeaea;
    }

    &.has-no-results .header-search__results-total {
      text-decoration: none;
      cursor: default;
    }
  }

  &__results-total {
    display: block;
    color: $header-search-results-color;
    text-decoration: underline;
    cursor: pointer;
  }

  &__results-total-icon-wrap {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;

    .icon {
      fill: $header-search-results-color;
      width: 13px;
      height: 13px;
    }
  }

  &__results-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__results-list-item {
    color: #a3a3a3;
    margin-bottom: 6px;
    cursor: pointer;
  }

  &__result-row {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 2px;
    justify-content: space-between;
    min-width: 0;
    flex: 1;
  }

  &__result-row-data {
    @include flex-ellipsis;
    display: block;
    flex: 0 1 auto;

    & + & {
      margin-left: auto;
    }

    &--primary {
      color: $header-search-results-color;
      text-decoration: underline;
    }
  }

  &__result-details {
    display: block;
    margin-left: auto;
    font-weight: $weight-02;
  }

  .no-results {
    cursor: default;
    text-decoration: none;
  }
}
