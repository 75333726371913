.action-search {
  position: relative;

  &__input {
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    line-height: 21px;
    width: 100%;
    border: 1px solid $input-border-color;
    background-color: $input-background-color;
    vertical-align: top;
    @include font-secondary;
    font-weight: $weight-03;
    height: 30px;
    padding: 0 24px 0 29px;
    font-size: 13px;

    &:focus {
      border: 1px solid $input-focus-border-color;
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      outline: none;
    }
  }

  .icon {
    display: flex;
    fill: #707070;
  }

  .icon-search {
    top: 1px;
    left: 10px;
    width: 12px;
    height: 28px;
    pointer-events: none;
    position: absolute;
    fill: $input-search-icon-color;
  }

  .clear-button {
    position: absolute;
    top: 0;
    right: 6px;
    height: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
  }
}
