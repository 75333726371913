.page-diagnostic-show {
  &__log {
    .table__row {
      &.is-expanded {
        .table__cell {
          white-space: normal;
        }
      }
    }

    .table__cell {
      vertical-align: top;
    }
  }
}
