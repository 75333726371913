.ff-multiselect {
  $option-height: 31px;
  $option-padding: 6px;
  $stripe-color: #f5f2f2;

  &__input {
    outline: 0;
  }

  &__input-value {
    @include ellipsis;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
  }

  &__input-value-count {
    margin-right: 2px;
    font-weight: $weight-04;
  }

  &__input-value-icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;

    .icon {
      fill: currentColor;
    }

    &--main {
      right: 4px;
      color: color(grey);
    }

    &--error {
      right: 20px;
      color: $error-color;
      font-size: 13px;
    }
  }

  &__input-dropdown {
    @include dropdown;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-bottom: 60px;
    padding: 6px;
    background: #fff;
    border-top: 0;
    z-index: 10; // this number is rather arbitrary
  }

  &__input-search {
    margin-bottom: 6px;
  }

  &__input-options {
    &,
    .ff-multiselect__input-options-button {
      font-size: 13px;
    }
  }

  &__input-options-bulk-select {
    display: flex;
    padding: 0 0 2px;
    border-bottom: 1px solid rgba(color(greySecondary), 0.7);
  }

  &__input-options-list {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 5 * $option-height;
    overflow: auto;

    &:focus {
      outline: none;
    }

    &--empty {
      padding: 8px;
      font-weight: $weight-04;
    }
  }

  &__input-options-list-item {
    display: flex;

    &:nth-child(even) {
      background-color: $stripe-color;
    }
  }

  &__input-options-button {
    @include reset-button;
    @include ellipsis;
    display: flex;
    flex: 1;
    padding: $option-padding;
    text-align: left;
    color: color(grey);
    cursor: default;

    &:focus {
      color: $global-primary-color;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    min-width: 0; // flex ellipsis fix

    .checkbox__label {
      flex: 1 1 auto;
    }
  }

  &__checkbox-label {
    @include ellipsis;
    display: block;
  }

  // modifiers

  // errors
  &.form-field--invalid {
    .ff-multiselect {
      &__input-value {
        padding-right: 30px;
        border-color: $error-color !important;
      }

      &__input-dropdown {
        border-color: $error-color;
      }
    }
  }

  // active
  &.is-active {
    .ff-multiselect {}

    &:not(.form-field--invalid) {
      .ff-multiselect {
        &__input-value,
        &__input-dropdown {
          border-color: $input-focus-border-color;
        }
      }
    }
  }

  // focused
  &.is-focused {
    .ff-multiselect {
      &__input-value {
        box-shadow: 0 0 5px 0 $input-focus-shadow-color;
        background-color: $input-focus-background-color;
        outline: none;
      }
    }
  }

  // expanded
  &.is-expanded {
    .ff-multiselect {
      &__input-value {
        border-radius: $input-border-radius $input-border-radius 0 0;
      }

      &__input-dropdown {
        display: block;
      }
    }
  }
}
