$tag-themes: (
  neutral: (
    main: $secondary-text-color,
    alt: $primary-background-color,
  ),
  primary: (
    main: $primary-button-background,
    alt: $primary-button-foreground,
  ),
  alert: (
    main: $secondary-text-color,
    alt: $primary-background-color,
  ),
  danger: (
    main: $color-danger,
    alt: $primary-background-color,
  ),
  ok: (
    main: $color-ok,
    alt: $primary-background-color,
  ),
  workflow: (
    main: $color-workflow,
    alt: $primary-background-color,
  ),
);

.tag {
  $default-theme: neutral;
  $tag-default-theme: map-get($tag-themes, $default-theme);
  $tag-default-color: map-get($tag-default-theme, main);
  $tag-default-color-alt: map-get($tag-default-theme, alt);
  $tag-padding: 0.18em 0.36em;

  @include ellipsis;
  @include font-secondary(600);
  display: inline-flex;
  align-items: center;

  // required for ellipsis
  max-width: 100%;

  margin: 2.5px;
  border: 1px solid currentColor;
  border-radius: 3px;
  background-color: $primary-background-color;
  color: $tag-default-color;
  white-space: nowrap;
  cursor: default;

  &__content {
    @include ellipsis;
    // required for ellipsis
    max-width: 100%;

    display: inline-flex;
    padding: $tag-padding;
  }

  &__text {
    @include ellipsis;
    display: block;
  }

  &__icon {
    display: flex;

    .icon {
      --primary-color: currentColor;
      display: block;
      width: 1em;
      height: auto;
      fill: currentColor;
    }
  }

  &__icon + &__text,
  &__text + &__icon {
    margin-left: 4px;
  }

  // modifiers
  &--strike {
    text-decoration: line-through;
  }

  &--action {
    cursor: pointer;
  }

  &--filled {
    background: $tag-default-color;
    border-color: $tag-default-color;
    color: $tag-default-color-alt;
  }

  &--no-spacing {
    margin: 0;
  }

  // sizes
  &--size-small {
    margin: 3px;
    font-size: 13px;

    .tag__content {
      padding: 2px 4px;
    }
  }

  &--size-large {
    margin: 4px;
    font-size: 13px;

    .tag__content {
      padding: 4px 8px;
    }
  }

  // themes
  @each $name, $style in $tag-themes {
    $main: map-get($style, main);
    $alt: map-get($style, alt);
    $filled-background: map-get($style, filledBackground);

    &--theme-#{$name} {
      color: $main;

      &.tag--split-contrast,
      &.tag--split-uniform {
        border: 1px solid $main;

        .tag__content + .tag__content {
          border-left: 1px solid $main;
        }
      }

      &.tag--split-contrast {
        .tag__content:nth-child(odd) {
          background: $main;
          color: $alt;
        }

        .tag__content:nth-child(even) {
          background: none;
          color: $main;
        }
      }

      &.tag--filled {
        background: if($filled-background, $filled-background, $main);
        border-color: if($filled-background, $filled-background, $main);
        color: $alt;

        &.tag--split-uniform {
          .tag__content + .tag__content {
            border-left-color: $alt;
          }
        }
      }
    }
  }

  // risk reason is a special case that does not conform to the other tag themes
  &--theme-risk-reason {
    background: #FFDDE2;
    border-color: #FF587C;
    color: #000;
    font-weight: normal;
  }
}


// LIST VIEW
.tag-list {
  display: flex;
  flex-flow: row wrap;
}
