.breadcrumb {
  margin-bottom: 10px;
  font-weight: $weight-03;
  display: flex;

  &__resource-name {
    &:not(:empty) {
      &::before {
        content: "“";
      }

      &::after {
        content: "”";
      }
    }

    &:empty {
      display: inline-block;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      min-width: 80px;
      height: 10px;
      margin: auto 0;
    }
  }

  &__separator {
    padding: 0 4px;
    font-weight: $weight-02;
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
  }

  a,
  button {
    color: $global-link-color;
  }

  button {
    font-weight: inherit;
  }
}
