.chart {
  path.domain,
  .tick > line {
    stroke: color(greyFaded);
  }

  .tick > text {
    fill: $primary-text-color;
  }
}
