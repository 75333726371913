$graph-first-line-color: #BBB;

.sparkline-wrapper {
  figure {
    margin: 0 !important;
  }

  figcaption div,
  figcaption div * {
    @include visually-hidden;
  }
}

.sparkline-block {
  display: flex;
  align-items: center;
}

.table .sparkline {
  width: 140px;
  padding: 2px 2px 2px 40px;
  stroke: darken($graph-first-line-color, 10%);
  stroke-width: 1px;
  transform: translate(0, 2px);
}

.sparkline-action-wrapper {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    position: relative;
    margin-top: 10px;
  }

  ul li input[type=radio] {
    position: absolute;
    visibility: hidden;
  }

  ul li label {
    padding: 10px 10px 10px 30px;
    cursor: pointer;
  }

  ul li .check {
    position: absolute;
    border: 0;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    top: 9px;
    left: 9px;
  }

  ul li .check::before {
    position: absolute;
    content: '';
    border-radius: 100%;
    margin: auto;
  }

  input[type=radio]:checked ~ .check {
    border: 1px solid $input-checkbox-background-color-active;
  }

  input[type=radio] ~ .check::before {
    background: #d7d7d7;
    height: 15px;
    width: 15px;
    top: 1px;
    left: 1px;
  }

  input[type=radio]:checked ~ .check::before {
    background: $input-checkbox-background-color-active;
    height: 10px;
    width: 10px;
    top: 2px;
    left: 2px;
  }
}
