.page-editor-configuration {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .panel__editor-manage-container {
    padding-right: 5px;
    margin-bottom: 15px;

    .panel__editor-manage {
      margin: 10px 2px 0;
    }
  }

  .data-service-selector {
    margin: 0 10px;
  }

  .panel--main__manage-data-services {
    .panel__section-form-fields {
      display: none;
    }
  }
}
