.codeblock {
  $line-number-padding: 0.6em;
  $line-padding: 6px;

  --line-char-length: 3;
  --line-char-length-value: calc(var(--line-char-length) * 1em);

  margin: 1em 0;
  padding: 0 12px 0 0;
  background: $primary-background-color;
  border: 1px solid color(greyLight);
  font-size: 1em;
  counter-reset: line;
  color: $secondary-text-color;
  box-sizing: border-box;

  > code {
    @include font-code;
    display: block;
  }

  &__line {
    position: relative;
    display: block;
    line-height: 1.5rem;
    padding-left: calc(var(--line-char-length-value) + #{$line-number-padding} + #{$line-padding});

    &:first-child {
      &,
      &::before {
        padding-top: 8px;
      }
    }

    &:last-child {
      &,
      &::before {
        padding-bottom: 8px;
      }
    }

    &::before {
      @include ellipsis;
      counter-increment: line;
      content: counter(line);

      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: calc(var(--line-char-length-value) + #{$line-number-padding});
      padding: 0 $line-number-padding;

      border-right: 1px solid #ddd;
      color: $secondary-text-color;
      background: #f7f7f7;
    }
  }
}
