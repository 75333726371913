.page-event-list {
  $scrollbar-offset: 10px;

  &__title {
    display: flex;
    align-items: baseline;
    min-width: 0;
  }

  &__subtitle {
    @include ellipsis;
    display: flex;
    margin-left: 8px;
    padding-right: 4px;
    font-size: 14px;
    font-weight: $weight-02;

    > * {
      margin: 0 2px;
    }

    &-query {
      @include ellipsis;
    }

    strong {
      font-weight: $weight-04;
    }
  }

  &__active-filters {
    min-width: 208px;
    max-width: 528px;
    color: color(grey);
    padding: 14px;

    &-toggle-button {
      .icon-angle-down {
        margin-right: 0 !important;
        margin-left: 10px !important;
      }

      &.is-active {
        color: $global-primary-color;
      }
    }

    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
      max-height: 500px;
      max-height: 50vh;
      overflow: auto;
    }

    &-item {
      width: 100%;

      &:hover {
        color: $global-primary-color;
      }

      & + & {
        margin-top: 8px;
      }
    }

    &-item-button {
      position: relative;
      width: 100%;
      padding: 0 16px;
      text-align: left;
      border: 1px solid transparent;

      &:focus {
        border-color: $global-primary-color;
      }
    }

    &-item-remove-button {
      position: absolute;
      right: 2px;
      top: 2px;
    }

    &-item-label {
      @include ellipsis;
      display: block;
      word-wrap: break-word;
      font-weight: $weight-04;
    }

    &-item-label {
      display: block;
      word-wrap: break-word;
    }

    &-item-value {
      word-break: break-all;
    }

    &-item-value-date-range {
      white-space: nowrap;
    }
  }

  &__column-settings {
    width: 250px;
    background-color: #fff;
    font-size: 13px;
    white-space: nowrap;
    padding: 14px;

    &-button {
      .icon-angle-down {
        margin-right: 0 !important;
        margin-left: 10px !important;
      }
    }

    &-header {
      display: flex;
      text-align: center;
      font-weight: $weight-03;
      padding-right: $scrollbar-offset;

      &-visibility {
        flex: 1;
      }

      &-sorting {
        flex: 0 0 64px;
      }
    }

    &-subheader {
      display: flex;
      text-align: center;
      margin: 10px 0;
      padding-right: $scrollbar-offset;
      font-weight: $weight-03;

      &-visibility {
        flex: 1;
      }

      &-primary-sorting {
        flex: 0 0 32px;
      }

      &-secondary-sorting {
        flex: 0 0 32px;
      }
    }

    &-items {
      max-height: 280px; // ~ 10 items
      overflow: scroll;
    }

    &-column {
      display: flex;

      .checkbox__label-text {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-sortable .checkbox__label-text {
        max-width: 94px;
      }

      &-identification {
        flex: 1;
      }

      &-primary-sorting,
      &-secondary-sorting {
        flex: 0 0 32px;
        text-align: center;

        .icon {
          width: 13px;
          height: 13px;
          margin: 0 !important;
        }

        .icon-sort {
          fill: #dcdcdc;
        }
      }
    }

    // custom columns
    &-manage-column-button {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      padding: 4px;
      font-size: 13px;
      border-radius: 100%;
      background: color(grey);
      color: #fff;
      cursor: pointer;

      .icon {
        width: 1em;
        height: 1em;
        margin: 0 !important;
      }

      &.is-active {
        background-color: $global-primary-color;
      }
    }

    &-add-column {
      min-width: 240px;
      padding: 14px;

      &-tooltip {
        display: block;
      }

      &-button {
        width: 100%;
      }

      &-actions {
        display: flex;

        .action {
          flex: 1 0 auto;
        }

        .action + .action {
          margin-left: 6px;
        }
      }
    }
  }

  &__cell-entity-list {
    display: flex;
  }

  &__cell-entity-show-list-button {
    cursor: pointer;
    display: flex;
    padding-top: 3px;

    &:hover {
      color: $global-primary-color;
    }

    .icon {
      margin-left: 4px;
    }
  }

  // Remove the original cell formatting and create a custom cell wrapper that looks like the normal table row/cell
  // so that rows can appear over the backdrop when using the event details (right) sidebar.
  // Using z-index on a tr/td directly doesnt work.
  &__table-cell {
    padding: $table-cell-padding;
    background: $table-cell-background-color;

    .action--icon-only {
      height: 19px;
      line-height: 17px;

      .icon {
        height: 17px;
        line-height: 17px;
      }
    }
  }

  .table.is-status-success {
    .table__row:not(.table__row--no-matches) {
      .table__cell {
        padding: 0;
      }

      .table__cell + .table__cell {
        .page-event-list__table-cell {
          padding-left: 2px;
        }
      }

      &:nth-child(odd) {
        .page-event-list__table-cell {
          background-color: $table-cell-background-color-stripe;
        }
      }

      &.is-selected {
        .page-event-list__table-cell {
          position: relative;
          z-index: 7;
        }
      }
    }
  }
}

.page-event-list-details-sidebar {
  .form-field {
    padding-top: 0;
  }

  .incident-event-json__section {
    &:first-child {
      margin-top: 0;
    }
  }
}

