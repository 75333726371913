$action-default-active-background-color: color-customisation(primaryColor, $primary-button-background);
$action-default-active-color: $primary-button-foreground;
$action-default-click-background-color: $secondary-button-pressed-background;
$action-primary-color: $primary-button-foreground;

$action-danger-click-background-color: $action-default-click-background-color;
$action-danger-color: $tertiary-button-foreground;

@mixin action-border-padding($border-width) {
  border-width: $border-width;
  padding: (1px - $border-width) (11px - $border-width);
}

@mixin action-default {
  background-image: none;
  background-color: $secondary-button-background;
  border-color: $secondary-button-border;
  color: $secondary-button-foreground;

  &:hover {
    background-color: $secondary-button-hover-background;
  }

  .icon {
    fill: currentColor;
  }

  // elm wont add `disabled` attributes to anchor tags so use `data-disabled`
  @at-root {
    fieldset[disabled] button#{&},
    &[disabled],
    &[data-disabled] {
      &,
      &:active,
      &:hover {
        background: $secondary-button-disabled-background;
        color: $secondary-button-disabled-foreground;
        cursor: default;
      }
    }
  }
}

@mixin action-theme-primary {
  &:focus {
    outline-color: $action-default-active-background-color;
  }
  background-color: $primary-button-background;
  color: color-customisation(buttonPrimaryDefaultColor, $primary-button-foreground);

  &:hover {
    background-color: $primary-button-hover-background;
  }

  &:active {
    background: color-customisation(buttonPrimaryActiveBackgroundColor, $primary-button-active-background);
  }
}

@mixin action-theme-primary-disabled {
  background: color-customisation(buttonPrimaryDisabledBackgroundColor, $primary-button-disabled-background);
  color: color-customisation(buttonPrimaryDisabledColor, $primary-button-foreground);
  cursor: default;
}

@mixin action-review($review) {
  color: $review;

  &:focus {
    outline-color: $review;
  }

  &.active {
    border: none;
    background: $review;
    color: $primary-button-foreground;
    outline-color: $review;
  }

  .badge {
    background-color: $primary-background-color;
    color: $review;
  }
}

.action {
  @include action-border-padding(1px);
  @include user-select(none);

  display: inline-block;
  border-radius: 3px;
  border-style: solid;
  font-size: 13px;
  font-weight: $weight-04;
  line-height: 28px;
  position: relative;
  text-decoration: none;
  text-transform: none;
  background: none;
  border-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;

  .icon {
    height: 28px;
    vertical-align: top;
    width: 13px;
    fill: currentColor;
    --primary-color: currentColor;
  }

  &:not(.action--icon-only) .icon {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 3px;
    }
  }


  &:focus {
    outline: 1px solid;
    outline-offset: 2px;
    outline-color: $action-default-active-background-color;
  }

  @at-root {
    fieldset[disabled] button#{&},
    &[disabled] {
      // Fix MS Edge bug where disabled buttons with child nodes are not actually disabled (ARIC-8514 / https://stackoverflow.com/a/34417139)
      pointer-events: none;
    }
  }

  // `[disabled]` doesnt work on links
  &[data-disabled] {
    pointer-events: none;
  }

  &--full-width {
    width: 100%;
  }

  &--large {
    line-height: 33px;
  }

  &--theme-default {
    @include action-default;

    &:active,
    &[aria-pressed="true"] {
      background: $secondary-button-pressed-background;
      color: $secondary-button-foreground;
    }

    &.active {
      @include action-border-padding(0);

      background: $action-default-active-background-color;
      color: $action-default-active-color;
    }
  }

  &--theme-primary {
    @include action-border-padding(0);
    @include action-theme-primary;

    // elm wont add `disabled` attributes to anchor tags so use `data-disabled`
    @at-root {
      fieldset[disabled] button#{&},
      &[disabled],
      &[data-disabled] {
        &,
        &:active,
        &:hover {
          @include action-theme-primary-disabled;
        }
      }
    }
  }

  &--theme-danger {
    background: $tertiary-button-background;
    color: $tertiary-button-foreground;
    border-color: $tertiary-button-border;

    &:hover {
      background: $tertiary-button-hover-background;
    }

    &:active {
      background: $tertiary-button-pressed-background;
    }

    // elm wont add `disabled` attributes to anchor tags so use `data-disabled`
    @at-root {
      fieldset[disabled] button#{&},
      &[disabled],
      &[data-disabled] {
        &,
        &:active,
        &:hover {
          color: $tertiary-button-disabled-foreground;
          background: $tertiary-button-disabled-background;
          cursor: default;
        }
      }
    }
  }

  &--red {
    color: #f25876;
  }

  &--purple {
    color: #d24b8e;
  }

  &--theme-translucent {
    background: rgba(#fff, 0.18);

    fieldset[disabled] button#{&},
    &[disabled],
    &[data-disabled] {
      opacity: 0.6;
    }
  }

  &--theme-white {
    background: $primary-background-color;
    color: $secondary-text-color;

    &:hover {
      background-color: $secondary-button-hover-background;
    }

    fieldset[disabled] button#{&},
    &[disabled],
    &[data-disabled] {
      opacity: 0.5;
    }
  }

  &--theme-no-risk {
    @include action-default;
    @include action-review($review-no-risk);
  }

  &--theme-risk {
    @include action-default;
    @include action-review($review-risk);
  }

  .badge {
    position: absolute;
    top: 8px;
    right: 10px;
    padding: 2px 6px;
    font-size: 13px;
    line-height: 15px;
    border-radius: 15px;
  }

  &--highlight {
    color: $action-default-active-background-color;
  }
}

/* Examples
  Left aligned actions
  ```html
  <div class="action-group">
    <button class="action">Click me</button>
    <button class="action">Click me</button>
    <button class="action">Click me</button>
  </div>
  ```

  Right aligned actions
  ```html
  <div class="action-group action-group--right">
    <button class="action">Click me</button>
    <button class="action">Click me</button>
    <button class="action">Click me</button>
  </div>
  ```

  Left & right aligned actions
  ```html
  <div class="action-group">
    <div class="action-group__subgroup">
      <button class="action">Click me</button>
      <button class="action">Click me</button>
    </div>

    <div class="action-group__subgroup action-group__subgroup--right">
      <button class="action">Click me</button>
    </div>
  </div>
  ```
*/
.action-group {
  display: flex;
  align-items: flex-start;

  &__item {
    position: relative;
  }

  &__label {
    margin-right: 3px;
    color: #727272;
    font-size: 14px;
    line-height: 28px;
  }

  .action:not(:first-child),
  &__item:not(:first-child) {
    margin-left: 5px;
  }

  .action-group__item--standalone:not(:first-child) {
    margin-left: 10px;
  }

  &--right {
    display: flex;
    justify-content: flex-end;
  }

  .action-group__subgroup {
    &--right {
      margin-left: auto;
    }
  }
}

.action-group--fixed {
  position: fixed;
  right: 15px;
  z-index: 2;
  padding: 5px 15px;
  line-height: 20px;
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.11);
}

// a group of buttons joined together with no space between them
.action-joined-group {
  .action {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-right: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.action--icon-only {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  padding: 0 !important; // override the action-border-padding(0) when .active
}

.action--cheque-control {
  margin: 3px;
}

.action-toggle {
  border: none;
  background-color: inherit;
  padding: 0;
  font: inherit;
  vertical-align: middle;
  display: flex;
  align-items: center;

  &:focus {
    box-shadow: inset 0 0 0.2em #c94d8c;
  }

  &__label-left {
    padding: 0.5em;
    font-weight: $weight-04;
    color: $primary-text-color;
  }

  &__label-right {
    padding: 0.5em;
    font-weight: $weight-04;
    color: $secondary-text-color;
  }

  &__loading-icon {
    display: none;

    .icon {
      width: 100%;
      height: 100%;
      fill: white;
    }
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 1em;
    font-size: 20px; //all sizes of the toggle switch are determined by this setting
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 0.5em;

    &.is-loading {
      background-color: #ccc;

      .action-toggle__loading-icon {
        display: flex;
      }
    }
  }

  &__slider-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    height: 1em;
    width: 1em;
    left: 0;
    border: 2px solid #f57323;
    box-sizing: border-box;
    bottom: 0;
    background-color: white;
    transition: 0.4s;
    border-radius: 100%;

    &.is-loading {
      background-color: #737373;
    }
  }

  &[aria-pressed="true"] {
    .action-toggle__label-left {
      font-weight: $weight-04;
      color: $secondary-text-color;
    }

    .action-toggle__label-right {
      font-weight: $weight-04;
      color: $primary-text-color;
    }

    .action-toggle__slider {
      background-color: #f57323;
    }

    .action-toggle__slider-button {
      transform: translateX(1em);
    }
  }
}
