.amdl-state {
  &__non-fatal-error {
    margin: 10px 0;
    color: $color-danger;
    font-weight: $weight-04;
  }

  &__result {
    display: block;
    margin: 0;
    padding: 11px;
    text-align: center;

    &--error {
      color: color(red);
    }
  }

  &__delete-confirm {
    @include font-secondary;
    min-width: 250px;
    font-size: 13px;
    white-space: normal;

    .form-field {
      margin-bottom: 14px;
    }
  }

  &__scope-select {
    line-height: 16px;

    + .icon-sort {
      top: 9px;
    }
  }

  &__expand-all-button {
    min-width: 117px;
  }
}
