$action-size: 33px;
$activity-bordered-row-size: 2px;
$grid-scrollbar-thumb-color: #A8A8A8;

$activity-row-style-default: (
  color: null,
  border: #d6d6d6,
);

$activity-row-style-alert: (
  color: #fffcf7,
  border: #FF6B39,
);

$activity-row-style-danger: (
  color: #fffafa,
  border: #FF3C46,
);

$activity-row-style-ok: (
  color: #f8fcf4,
  border: #6EBE36,
);

$activity-row-style-case: (
  color: null,
  border: #d6d6d6,
);

@mixin activity-bordered-row($style) {
  $border: map-get($style, border);

  background-repeat: no-repeat;
  background-size: 100% $activity-bordered-row-size;
  background-position-y: 100%;
  background-color: map-get($style, color);

  background-image:
    linear-gradient(
      to right,
      $border 0%,
      $border 100%
    );
}

.page-incident-details__activity-loading {
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 24px;
  }
}

.page-incident-details__activity-error {
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  color: $color-danger;

  &-message {
    display: flex;
    align-items: center;
  }

  .icon {
    display: block;
    margin-right: 6px;
    fill: currentColor;
  }
}

.data-services-modal__grid,
.page-incident-details__grid {
  flex: 1;
  overflow: hidden;
  display: grid;
  grid-template:
    "table ."
    "table vscroll"
    "hscroll hscroll";
  // https://css-tricks.com/preventing-a-grid-blowout re. `minmax(0, 1fr)`
  grid-template-columns: minmax(0, 1fr) 30px;
  grid-template-rows: 39px auto 0;

  .page-incident-details__table {
    grid-area: table;
    // prevent the horizontal scrollbar being hidden when there is a vertical scrollbar (ARIC-20266)
    overflow: hidden;
  }

  &-bring-to-front {
    @include stack-context(incident-details-activity-grid);
  }

  .dot {
    &--action,
    &--event {
      fill: $secondary-text-color;
    }

    &--no-risk {
      fill: $review-no-risk;
    }

    &--risk {
      fill: $review-risk;
    }

    &--unreviewed-alert {
      fill: $unreviewed;
    }

    &--case {
      fill: #c6c6c6;
    }
  }

  .grid-header {
    height: 39px;
    overflow: hidden;
    font-weight: $weight-04;
    letter-spacing: 0.04px;
    color: $secondary-text-color;
    text-align: left;
    flex: 0 0 39px;

    .grid-row {
      display: inline-block;
      position: relative;
      font-size: 13px;
    }

    .grid-cell {
      @include user-select(none);
      @include ellipsis();

      padding: 14px 10px 5px;
      text-align: left;
      cursor: default;
      line-height: 14px;
    }
  }

  .grid-body-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
    // z-index: 2;
  }

  .grid-body {
    position: relative;
    overflow: hidden;
    color: $primary-text-color;
    flex-grow: 1;
    background: $primary-background-color;
    display: block;

    .backdrop {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }

    .backdrop-click-through {
      pointer-events: none;
    }
  }

  .grid-empty {
    position: static;
  }

  .grid-canvas {
    position: relative;
    display: flex;
    flex-direction: column;

    &, .backdrop {
      min-height: 100%;
    }
  }

  .no-activity {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;

    &__action {
      margin: 10px auto 0;
    }
  }

  .grid-row-wrapper-clickable {
    background-color: $table-cell-background-color;
    width: 100%;
    position: absolute;

    &--stripe {
      background-color: $table-cell-background-color-stripe;
    }

    &--bring-to-front {
      z-index: 1;
    }
  }

  .grid-row {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    min-width: 100%;
    height: 47px;
    font-size: 13px;
    white-space: nowrap;

    .action {
      margin-right: 4px;
    }

    .flag {
      display: flex;
      justify-content: flex-start;
    }

    .flag img {
      display: block;
      height: 20px;
      border: 1px solid rgba(151,151,151,0.46);
      box-shadow: 1px 1px 0 0 rgba(0,0,0,0.11);
    }

    .event-search-link {
      color: $global-primary-color;
      font-weight: $weight-03;
      text-decoration: underline;
    }

    .icon {
      fill: currentColor;
      --primary-color: currentColor;
    }

    .c-checkbox__icon .icon {
      fill: #fff;
    }

    &--xl {
      height: 63px;
    }

    &--event {
      @include activity-bordered-row($activity-row-style-default);
    }

    &--action {
      @include activity-bordered-row($activity-row-style-default);

      .grid-cell__event-type-icon {
        color: $global-primary-color;
      }
    }

    &--alert-unreviewed {
      @include activity-bordered-row($activity-row-style-alert);

      .grid-cell__event-type-icon .icon,
      .grid-row__tag-list-separator {
        color: $secondary-text-color;
      }
    }

    &--alert-reviewed-risk {
      @include activity-bordered-row($activity-row-style-danger);

      .grid-cell__event-type-icon .icon,
      .grid-row__tag-list-separator {
        color: $color-danger;
      }
    }

    &--alert-reviewed-no-risk {
      @include activity-bordered-row($activity-row-style-ok);

      .grid-cell__event-type-icon .icon,
      .grid-row__tag-list-separator {
        color: $color-ok;
      }
    }

    &--alert-reviewed-case {
      @include activity-bordered-row($activity-row-style-case);
    }

    &__main-content {
      position: relative;
    }

    &--highlighted {
      background: $activity-highlight-color;
      border-bottom: 1px solid #000;
    }

    &--current-highlighted {
      background: $activity-current-highlight-color;
      border-bottom: 1px solid #000;
    }

    &__content {
      position: relative;
    }

    &__primary {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
    }

    &__secondary {
      display: block;

      .activity-comment {
        @include ellipsis();

        margin-left: 8px;
        max-width: 150px;
        vertical-align: middle;
      }
    }

    &__review-actions {
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100% - #{$activity-bordered-row-size});
      display: flex;
      align-items: center;
      padding-right: 13px;
      padding-left: 33px;
      background-image: linear-gradient(to right, transparent, #FFFCF7 30px, #FFFCF7);

      .action + .action {
        margin-left: 4px;
      }
    }

    &__review-multiselect {
      padding-top: 8px;
    }

    &__review-details {
      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      position: relative;
      padding: 5px;
      margin: 0 10px;

      .form-input__validation-icon {
        bottom: 6px;
        color: #e94b60;
      }

      &--no-risk {
        background-color: #F5FCF1;
        border-color: #6DBD54;

        .grid-row__review-details-arrow {
          border-bottom-color: #6DBD54;

          &::before {
            border-bottom-color: #F5FCF1;
          }
        }
      }

      &--risk {
        background-color: #FFEDED;
        border-color: #FF3C46;

        .grid-row__review-details-arrow {
          border-bottom-color: #FF3C46;

          &::before {
            border-bottom-color: #FFEDED;
          }
        }
      }

      &-arrow {
        position: absolute;
        top: -6px;
        right: 11px;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 6px;
        padding: 0;
        margin: 0;

        &::before {
          position: relative;
          display: block;
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;
          content: '';
          top: 1px;
          left: -5px;
          border-width: 0 5px 5px;
        }
      }

      &-wrapper {
        display: flex;
        overflow: hidden;
      }

      .form-input--select {
        flex: 1 0 auto;
        max-width: 188px;

        select {
          line-height: 11px;
        }

        .icon-sort {
          bottom: 6px;
        }
      }

      .form-input--select + .form-input--select {
        margin-left: 5px;
      }

      &-no-risk-tag,
      &-risk-tag {
        flex: 0 1 auto;
        max-width: 188px;
        min-width: 0;

        display: flex;
        padding: 3px 9px;
        margin-left: 5px;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        box-sizing: border-box;
        font-size: 13px;
        font-weight: $weight-02;

        &-label {
          flex: 1;
          @include ellipsis;
        }

        &-remove {
          border: 0;
          height: 13px;
          width: 11px;
          padding: 0;

          .icon {
            height: 13px !important;
            margin: 0 !important;
          }
        }
      }

      &-no-risk-tag,
      &-risk-tag {
        color: $primary-text-color;
        background: $primary-background-color;
      }

      &-no-risk-tag {
        border-color: $review-no-risk;
      }

      &-risk-tag {
        border-color: $review-risk;
      }
    }

    &__tag-list {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &__tag-list-separator {
      margin-left: 3px;
    }

    &__tag-item {
      font-size: 13px;
      display: flex;
      align-items: center;

      &:first-child .tag {
        margin-left: 0;
      }

      .tag {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .grid-cell {
    @include ellipsis();
    $grid-title-font-size: 14px;

    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    flex: 0 0 auto;
    padding: 0 10px;

    .user-display-name {
      .icon {
        margin-right: 2px;
        position: relative;
        top: -2px;
      }
    }

    &__title {
      @include ellipsis();
      vertical-align: middle;
      font-size: $grid-title-font-size;
      font-weight: $weight-03;
    }

    &__subtitle {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      font-size: 13px;
      color: $secondary-text-color;
    }

    &__extra {
      display: block;
      margin-top: 4px;

      &-case-details {
        @include ellipsis;
        display: block;
      }
    }

    &--details {
      // the vertical padding stops the focus outline being clipped
      padding-top: 3px;
      padding-bottom: 3px;
    }

    &--event-type,
    &--event-type-action {
      .icon {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
        margin-right: 5px;
        font-size: $grid-title-font-size;
      }

      .icon-event {
        font-size: 13px;
      }
    }

    &--event-type-action {
      max-width: calc(100% - 150px);

      .grid-cell__extra,
      .activity-comment {
        max-width: 100%;
      }
    }

    &__tag-list {
      // This enables horizontal scrollbars on cells with very long tags.
      // Originally fixed by ARIC-25203
      // Later overwriten by ARIC-27055
      // In lack of a better solution, I moved this setting down here so it doesn't get overwritten again
      overflow: auto;
    }
  }

  .grid-scrollbar {
    background: #FFF;

    .track {
      position: absolute;
      width: 100%;
      overflow: hidden;
    }

    .dots {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    .thumb {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $grid-scrollbar-thumb-color;
      cursor: pointer;
    }
  }

  .grid-vertical-scrollbar {
    grid-area: vscroll;

    .track {
      width: 23px;
      margin-left: 7px;
      height: 100%;
    }

    .thumb {
      height: 100%;
      background: transparent;
      border-color: #A8A8A8;
      border-style: solid;
      border-width: 9px 1px;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .grid-vertical-scrollbar-backdrop {
    background: rgba(0, 0, 0, 0.15);
  }

  .grid-horizontal-scrollbar {
    grid-area: hscroll;

    .track {
      height: 8px;

      .thumb {
        width: 100%;
      }
    }
  }


  .activity-comment {
    $border-color: #d9d9d9;
    $background-color: $primary-background-color;

    @include arrow-box(left, 5px, 1px, $background-color, $border-color);
    display: inline-block;
    position: relative;
    padding: 1px 5px;
    border-radius: 3px;
    color: $secondary-text-color;
    font-weight: $weight-03;
    line-height: 1.2;

    &__quote {
      @include ellipsis;
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;

      &::before {
        content: '\00201C';
      }

      &::after {
        content: '\00201D';
      }
    }
  }
}

.data-services-modal {
  &__section {
    margin-bottom: 0;
  }

  &__grid {
    display: block;
    margin-bottom: 20px;

    .grid-row__content {
      row-gap: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  &__data-service {
    // the height of the navigation section, pushes up the content so it can all be visible.
    padding-bottom: 50px;
  }

  &__navigation {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $primary-background-color;

    .action {
      margin-right: 4px;
    }
  }

  &__navigation-controls {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
  }

  &__navigation-controls-center-justified {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }

  &__navigation-controls-no-margin {
    margin: 0;
  }

  &__navigation-controls-border-top {
    border-top: solid 2px gray;
    padding-top: 1rem;
  }

  .grid-row {
    margin-bottom: 10px;
  }
}
