

// BUTTON

.fs-button {
  @include reset-button;
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0 3px;

  &:focus {
    outline: 1px solid $action-default-active-background-color;
    outline-offset: -1px;
  }

  &--icon-only {
  }

  &--primary {
    background-color: $primary-button-background;
    color: $primary-button-foreground;
  }
}

// DATA GRID

$data-grid-border-color: #ddd;

.fs-data-grid {
  display: grid;

  &__header,
  &__body,
  &__row {
    display: contents;
  }

  &__row {
    &--not-asked,
    &--loading,
    &--failure,
    &--no-matches-found {
      text-align: center;
    }

    &--failure {
      color: $table-error-color;
      line-height: 1.4em;

      .icon {
        fill: currentColor;
        width: 1.4em;
        margin-right: 3px;
        vertical-align: bottom;
      }
    }
  }

  &__header {
    &--dragging {
      .fs-data-grid__cell-header:not(.fs-data-grid__cell-header--drag-started) * {
        pointer-events: none;
      }
    }
  }

  &__body-row {
    .fs-data-grid__row--expanded .fs-data-grid__cell {
      background-color: $table-cell-background-color-highlighted;
    }

    &:nth-of-type(2n) {
      .fs-data-grid__cell {
        background-color: $table-cell-background-color-stripe;
      }

      &.fs-data-grid__row--expanded .fs-data-grid__cell {
        background-color: $table-cell-background-color-highlighted-alt;
      }
    }
  }

  &__cell {
    color: $table-cell-text-color;

    &-header {
      position: sticky;
      top: 0;
      border-bottom: 1px solid $data-grid-border-color;
      background: #f3f3f3;
      color: #595959;
      display: flex;
      z-index: 1;

      &-sort {
        display: flex;
        flex: 1;
        padding: 8px;
        overflow: hidden;
      }

      &-title {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-weight: $weight-02;
      }

      &-sort-icon {
        fill: currentColor;
      }

      &:hover,
      &--resizing {
        &:not(.fs-data-grid__cell-header--drag-started) .fs-data-grid__resize-handle {
          opacity: 0.5;
        }
      }

      &--draggable {
        .fs-data-grid__cell-header-sort {
          padding-left: 24px;
        }
      }

      &--drag-started {
        filter: brightness(1.2);
      }

      &--drag-over {
        filter: brightness(0.8);
      }
    }

    &-body {
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--draggable {
        padding-left: 24px;
      }

      &-link {
        display: inline-flex;

        &-icon {
          margin-right: 0.15rem;
        }

        &-text {
        }
      }

      .fs-button--checkbox {
        font-size: 16px;
      }
    }
  }

  &__drag-indicator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: move;
  }

  &__resize-handle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    width: 3px;
    cursor: col-resize;
  }
}
