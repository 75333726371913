@import 'stylesheets/DSV2/Variables';

.dsv2-dropzone {
  &__button {
    @include reset-button;
    width: 100%;
    padding: 16px;
    border: 1px dashed grey;
    outline: 1px solid transparent;
    color: $global-primary-color;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease;

    &:hover,
    &:focus,
    &.is-active {
      border: 1px solid $global-primary-color;
      outline-color: $global-primary-color;
      text-decoration: underline;
    }
  }
}
