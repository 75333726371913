$analytics-version-color-default-background: color-customisation(analyticsDefaultBackgroundColor, #808080);
$analytics-version-color-default-color: color-customisation(analyticsDefaultColor, #fff);

$analytics-version-color-previous-background: $analytics-version-color-default-background;
$analytics-version-color-previous-color: $analytics-version-color-default-color;

$analytics-version-color-staging-background: color-customisation(analyticsStagingBackgroundColor, #893067);
$analytics-version-color-staging-color: color-customisation(analyticsStagingColor, #fff);

$analytics-version-color-live-background: color-customisation(analyticsLiveBackgroundColor, #cc3a7f);
$analytics-version-color-live-color: color-customisation(analyticsLiveColor, #fff);

@mixin analytics-version-color-default {
  background-color: $analytics-version-color-default-background;
  color: $analytics-version-color-default-color;
}

@mixin analytics-version-color-previous {
  background-color: $analytics-version-color-previous-background;
  color: $analytics-version-color-previous-color;
}


@mixin analytics-version-color-staging {
  background-color: $analytics-version-color-staging-background;
  color: $analytics-version-color-staging-color;
}

@mixin analytics-version-color-live {
  background-color: $analytics-version-color-live-background;
  color: $analytics-version-color-live-color;
}

.analytical-workflow-modal {
  &__content {
    width: 320px;
    min-height: auto;
    padding: 10px;
    text-align: center;
  }

  &__action-icon .icon {
    width: 70px;
    height: 70px;
    color: $analytics-version-color-default-background;
    fill: currentColor;
  }

  &__title {
    @include font-primary(bold);
    margin: 20px auto;
    font-size: 18px;
  }

  $modal-icon-selector: '.analytical-workflow-modal__content .analytical-workflow-modal__action-icon .icon';

  &.is-previous-version {
    #{$modal-icon-selector} { color: $analytics-version-color-previous-background; }
  }

  &.is-staging-version {
    #{$modal-icon-selector} { color: $analytics-version-color-staging-background; }
  }

  &.is-live-version {
    #{$modal-icon-selector} { color: $analytics-version-color-live-background; }
  }
}
