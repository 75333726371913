.config-management {
  &__modal-cancel {
    flex: 1;
    margin-right: 5px;
  }

  &__modal-accept {
    flex: 1;
    margin-left: 5px;
  }

  &-list {
    td {
      position: relative;
    }
  }

  &__status {
    margin-left: 20px;
  }

  &__status::before {
    width: 12px;
    height: 12px;
    content: '';
    display: inline-block;
    border-radius: 15px;
    position: absolute;
    top: 11px;
    left: 5px;
  }

  &__status--staging::before {
    background-color: $status-warning;
  }

  &__status--inactive::before {
    background-color: $interactive-02;
  }

  &__status--live::before {
    background-color: $status-success;
  }

  &__expanded-row {
    display: grid;
    grid-template-columns: 100px auto;

    dt, dd {
      padding-bottom: 6px;
    }

    dt {
      font-weight: $weight-04;
    }

    dd {
      margin-left: 0;
    }
  }

  &__expanded-row-label {
    font-weight: $weight-04;
    padding-top: 6px;
    border-top: 1px solid $report-border-color;
  }

  &__review-changes {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 6px;
    padding: 10px;
    border: 1px solid $report-border-color;
    border-radius: 4px;
  }

  &__review-changes-header {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 6px;
    color: $secondary-text-color;
    font-weight: $weight-03;
  }

  &__review-changes-version-left,
  &__review-changes-version-right {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 1;

    .tag {
      margin-left: 6px;
    }
  }

  &__review-changes-version-left {}

  &__review-changes-version-right {
    padding-left: $ace-diff-gutter-width;
  }

  .static-tooltip__content {
    width: 278px;
    padding: 14px;
    z-index: 10;
  }
}
