.amdl-tester {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  height: 250px;

  &__status-icon {
    display: block;
    fill: color(grey);
    margin-right: 6px;

    .icon {
      display: block;
    }

    &--success { fill: $color-ok; }
    &--failure { fill: $color-danger; }
  }

  &__header {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-bottom: 10px;
  }

  &__main-actions {
    display: flex;
    margin-right: auto;
  }

  &__subview-toggle-actions {
    margin-right: 6px;
  }

  &__subview-toggle-action {
    &.is-active {
      &,
      &:hover {
        background: #dedede;
      }
    }
  }

  &__title {
    display: block;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex: 1;
    min-height: 0;
    background: $amdl-editor-background-color;
  }

  &__test-list {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    width: 200px;
    margin-right: 6px;
    border-right: 1px solid $amdl-editor-border-color-alt;
  }

  &__test-list-actions {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
  }

  &__test-list-action {
    color: color(grey);

    &[disabled] {
      opacity: 0.5;
    }
  }

  &__test-list-items {
    flex: 1 1 auto;
    overflow: auto;
    margin: 0 10px 10px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #c3c1c1;
    list-style: none;
  }

  &__test-list-item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid transparent;

    .amdl-editor-tests__status-icon {
      margin-right: 6px;
    }

    .action {
      @include ellipsis;
      padding: 0;
      width: 100%;
      min-height: 30px; // prevent element collapsing when nothing entered (editable mode)
      text-align: left;
    }

    &:hover {
      &,
      .action {
        color: $global-link-color;
      }
    }

    &.is-selected {
      &,
      .action {
        background: $global-primary-selected-background-color;
      }
    }

    &.is-editing {
      border-color: $global-primary-color;
    }
  }

  &__test-list-item-rename-input {
    width: 100%;
    background: none;
    border: none;
  }

  &__edit-details {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    padding: 10px;
    overflow: hidden;
  }

  &__edit-details-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
  }

  &__edit-details-header-field {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 6px;

    .form-input--text {
      input {
        height: 2.3em;
        line-height: 1.2em;
      }
    }

    .form-input--select {
      select {
        height: 2.3em;
        line-height: 1.2em;
      }

      .icon-sort {
        bottom: 6px;
      }
    }
  }

  &__edit-details-header-field-label {
    padding-right: 6px;
  }

  &__edit-details-title {
    @include ellipsis;
    flex: 0 1 auto;
    min-width: 0;
  }

  &__edit-details-content {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
  }

  &__editor {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    border: 1px solid $amdl-editor-border-color-alt;

    & + & {
      margin-left: 16px;
    }
  }

  &__editor-header {
    display: flex;
    align-items: center;
    padding: 6px;
    border-bottom: 1px solid $amdl-editor-border-color-alt;
  }

  &__editor-actions {
    margin-left: auto;

    .action--icon-only {
      width: auto;
      height: auto;

      .icon {
        display: block;
        width: 14px;
        height: auto;
      }
    }
  }

  // results view
  &__results {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    padding: 10px;
    overflow: auto;
  }

  &__results-title {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    padding-bottom: 8px;
    background-color: $amdl-editor-background-color;
    border-bottom: 1px solid $amdl-editor-border-color-alt;

    .icon {
      margin-right: 4px;
    }
  }

  &__results-list-not-run,
  &__results-list-loading,
  &__results-list-empty,
  &__results-list-error,
  &__results-list {
    flex: 1;
    padding: 16px;
    background: #fff;
  }

  &__results-list-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 20px;
    }
  }

  &__results-list-error {
    color: color(red);
  }

  &__results-list {
    margin: 0;
    overflow: auto;
    list-style: none;
  }

  &__results-list-item {
    margin-bottom: 10px;
  }

  &__results-list-test-name {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 6px;
    }
  }

  &__results-list-test-failed-to-execute {
    display: flex;
    align-items: center;
    margin-left: 4px;

    .icon {
      fill: $color-warning;
      margin-right: 4px;
    }
  }

  &__results-list-item-expander {
    box-sizing: border-box; // <details> resets box-sizing

    &-summary {
      display: flex;
      align-items: center;
      outline: none;
      cursor: pointer;

      &::before {
        content: '';
        width: 1em;
        height: 1em;
        margin-right: 4px;
        background-image: url('/images/icons/fa-caret-right-solid.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] > .amdl-tester__results-list-item-expander-summary::before {
      background-image: url('/images/icons/fa-caret-down-solid.svg');
    }
  }

  &__results-list-item-content {
    margin: 10px 0 20px 10px;
    padding-left: 10px;
    border-left: 1px solid color(greyLight);
  }

  &__results-list-trigger-check {
    display: flex;

    .icon {
      margin-right: 6px;
    }
  }

  &__results-list-trigger-check,
  &__results-list-expectations,
  &__results-list-codeblock {
    margin-bottom: 20px;
  }

  &__results-list-expectations-title,
  &__results-list-debug-log-title,
  &__results-list-codeblock-title {
    display: block;
    font-weight: bold;
  }

  &__results-list-expectations-title,
  &__results-list-codeblock-title {
    margin-bottom: 10px;
  }

  &__results-list-expectations {
    display: flex;
    flex-flow: column nowrap;
  }

  &__results-list-expectation {
    display: flex;

    .icon {
      margin-right: 6px;
    }
  }

  // bulk run tests results modal
  &__bulk-test-results {
    display: flex;
    width: 1080px;
    max-width: 100%;

    &-list {
      flex: 0 1 30%;
      min-width: 0;
      max-width: 300px;
      margin: 0;
      padding: 0;
      list-style: none;
      border: 1px solid $amdl-editor-border-color-alt;
      margin-right: 6px;
    }

    &-list-item {
      display: flex;

      .icon {
        display: block;
        margin-right: 4px;
      }

      &.is-selected {
        background: $global-primary-selected-background-color;

        &,
        button {
          color: $global-link-color;
          font-weight: 600;
        }
      }
    }

    &-list-item-button {
      @include reset-button;
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 0;
      padding: 8px;
    }

    &-list-item-label {
      display: block;
      @include ellipsis;
    }

    &-result {
      display: flex;
      flex: 1 1 70%;
      min-width: 0;
      background-color: $amdl-editor-background-color;
      border: 1px solid $amdl-editor-border-color-alt;
    }
  }
}
