.page-event-sequence-show {
  &__content {
    margin-top: 10px;
  }

  &__event-item {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 20px;
    padding: 20px;
    background: color(greyHeader);
  }

  &__event-item-header {
    display: flex;
    flex: 0 0 100%;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 0 16px;
  }

  &__event-item-title {
    margin: 0;
  }

  &__event-item-header-response-status {
    display: flex;
    align-items: baseline;
    font-weight: $weight-04;
  }

  &__event-item-copy-request,
  &__event-item-copy-response {
    margin-left: 10px;
    color: color(grey);

    .icon {
      fill: currentColor;
      display: block;
    }
  }

  &__event-item-copy-request {
    font-size: 14px;
  }

  &__event-item-copy-response {
    font-size: 13px;
  }

  &__event-item-content {
    display: grid;
    grid-template-columns: 50% 1fr;
    min-width: 100%;
  }

  &__event-item-message {
    display: flex;
    padding: 10px;

    &--loading {
      margin: 0 auto;
      align-self: center;
      font-size: 18px;
    }

    &--error {
      word-break: break-all;
      color: $color-danger;
      align-self: center;
      margin: 0 auto;
    }
  }

  &__event-item-request,
  &__event-item-response {
    display: flex;
    flex: 0 1 50%;
    height: 400px;
    overflow: auto;
    background: #fff;
    border: 1px solid color(greySecondary);
  }

  &__event-item-request {
    margin-right: 20px;
  }

  &__json-view {
    display: block;
    height: 100%;
    min-width: 300px;
    flex: 0 0 100%;
  }
}
