.schema-editor {
  &__status {
    margin-left: 20px;
  }

  &-list {
    td {
      position: relative;
    }
  }

  &__status::before {
    width: 12px;
    height: 12px;
    content: '';
    display: inline-block;
    border-radius: 15px;
    position: absolute;
    top: 11px;
    left: 5px;
  }

  &__status--staging::before {
    background-color: $status-warning;
  }

  &__status--inactive::before {
    background-color: $interactive-02;
  }

  &__status--live::before {
    background-color: $status-success;
  }

  &__changes-preview {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 6px;
    padding: 10px;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
  }

  &__changes-preview-header {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 6px;
    color: color(grey);
    font-weight: $weight-03;
  }

  &__changes-preview-version-left,
  &__changes-preview-version-right {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 1;

    .tag {
      margin-left: 6px;
    }
  }

  &__changes-preview-version-left {}

  &__changes-preview-version-right {
    padding-left: $ace-diff-gutter-width;
  }

  .static-tooltip__content {
    width: 220px;
  }

  td .action {
    min-width: 75px;
  }

  &-list {
    .static-tooltip__content {
      width: 250px;
    }
  }

  .form-input--select select {
    text-transform: capitalize;
  }

  &__info-field {
    @include ui-note;
    margin: 0 auto 20px;
    max-width: 800px;
  }

  &__tooltip-view {
    position: fixed;
  }

  &__tooltip-content {
    padding: 8px;
  }

  &__action-group {
    display: flex;
    justify-content: space-between;
  }

  .static-tooltip:not(.static-tooltip--trigger-click) .static-tooltip__body {
    display: block;
  }

  .table__cell {
    white-space: normal;
  }
}
