$background-color: #181830;
$disabled-input-background-color: #eee;
$footer-color: #fff;
$form-background-color: #fff;

.page-login {
  background: $background-color  url('/images/login-background.jpg');
  background-size: cover;
  min-height: 100%;
  overflow: hidden;

  .logo {
    background: url('/images/login-logo.png') no-repeat;
    height: 101px;
    left: 20px;
    position: absolute;
    top: 20px;
    width: 282px;
  }

  section {
    margin: 160px auto 80px;
    max-width: 380px;
  }

  .environment {
    padding: 8px;
    background-color: color-customisation(loginFormBackgroundColor, #eaeaea);
    color: color-customisation(loginFormColor, currentColor);

    h1 {
      @include ellipsis;
      font-size: 15px;
      font-weight: $weight-03;
      margin: 0;
      padding: 5px 10px 12px;
    }
  }

  &__panel {
    background-color: $form-background-color;
    padding: 24px;
    position: relative;

    &--button-only {
      padding: 34px;
    }
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .form-field {
    display: block;
  }

  .action {
    width: 100%;
    text-align: center;
  }

  &__copyright {
    color: $secondary-text-color;
    margin-top: 20px;
    text-align: right;
  }

  &__multi-auth {
    $button-padding: 5px;

    position: relative;

    &-menu {
      display: flex;
      background-color: #fff;
      justify-content: flex-end;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
    }

    &-menu-button {
      @include reset-button;

      .icon {
        display: block;
      }
    }

    &-menu-content {
      @include dropdown;
      position: absolute;
      top: 100%;
      right: 0;
      width: 200px;
      margin: 0;
      padding: 0;
      background: #ffff;
      list-style: none;
    }

    &-menu-content-item {
      &:first-child {
        .page-login__multi-auth-menu-content-item-button {
          padding-top: $button-padding * 2;
        }
      }

      &:last-child {
        .page-login__multi-auth-menu-content-item-button {
          padding-bottom: $button-padding * 2;
        }
      }
    }

    &-menu-content-item-button {
      @include reset-button;
      width: 100%;
      padding: $button-padding;
      text-align: left;

      &:hover,
      &.is-selected {
        color: $global-primary-color;
      }

      &:focus {
        outline: 1px solid $global-primary-color;
      }

      .icon {
        height: 0.8em;
        margin-right: 4px;
        fill: currentColor;
      }
    }
  }
}
