// <button>'s without the styling
.unstyled-button {
  @include reset-button;
  color: inherit;

  .icon {
    display: block;
    fill: currentColor;
  }

  &:focus {
    outline: 1px solid $primary-button-active-background;
    outline-offset: -1px;
  }
}

.unstyled-button-inline {
  @include reset-button;
  color: inherit;

  .icon {
    display: inline;
    fill: currentColor;
  }

  &:focus {
    outline: 1px solid $primary-button-active-background;
    outline-offset: -1px;
  }
}
