.banner {
  $banner-body-padding-left: 25px;

  background-color: color-customisation(bannerMessageDefaultBackgroundColor, color(purpleDark));
  color: color-customisation(bannerMessageDefaultColor, #fff);
  padding: 12px;
  cursor: pointer;

  &__action-prev-next {
    padding: 0;
    width: 26px;
    height: 26px;

    .icon {
      width: 18px;
      height: 24px;
    }
  }

  &__header {
    display: flex;
    line-height: 26px;
  }

  &__title {
    @include ellipsis;
  }

  &__title-subject {
    font-weight: $weight-04;
  }

  &__group {
    margin-left: auto;
    display: flex;
    white-space: nowrap;

    & > * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__about {
    margin-right: 20px;
    opacity: 0.9;
    font-size: 13px;
  }

  .action {
    height: 26px;
    line-height: 24px;

    &:focus {
      outline: 1px solid $focus-indicator-color-light;
      outline-offset: 2px;
    }

    .icon {
      height: 24px;
    }
  }

  .icon-exclamation-triangle {
    height: 26px;
    vertical-align: top;
    width: 15px;
    fill: currentColor;
    --primary-color: currentColor;
    margin-right: 10px;
  }

  .icon-check {
    fill: color(green);
  }

  &__expand {
    margin: 0 50px 0 8px;

    .action {
      padding: 0;
      border: 0;
      text-decoration: underline;
    }
  }

  &__expanded-footer-actions {
    display: flex;
    justify-content: flex-end;
    padding-left: $banner-body-padding-left;
  }

  &__minimise {
    margin-right: auto;
    padding: 0;
    border: 0;
    text-decoration: underline;
  }

  &__body {
    padding: 10px 0 10px $banner-body-padding-left;
  }

  &__message-body {
    overflow: auto;
    max-height: 100px;
  }

  &--minimised {
    &:hover {
      background-color: color-customisation(bannerMessageHoverBackgroundColor, color(purpleMid));
      color: color-customisation(bannerMessageHoverColor, #fff);
    }

    .banner__title-subject {
      text-decoration: underline;
    }
  }

  .application--main-fullscreen & {
    display: none;
  }
}
