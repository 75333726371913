.processing-control {
  margin-bottom: 5px;

  .processor-content {
    padding-top: 20px;
  }

  .deactivate-topology, .activate-topology {
    margin-left: 3px;
  }

  .toplogy-status-active {
    .icon {
      fill: $color-ok;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .toplogy-status-inactive {
    .icon {
      fill: $color-alert;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .toplogy-status-undeployed, .toplogy-status-killed {
    .icon {
      fill: $color-danger;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .toplogy-status-deactivating, .toplogy-status-activating, .toplogy-status-deploying, .toplogy-status-undeploying {
    .icon {
      fill: $color-gray;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}

.topology-content, .entity-content, .global-content {
  width: 100%;
  max-width: 1240px;
  padding: 50px 10px 20px;
  margin: 0 auto;

  .undeploy-topology {
    margin-bottom: 25px;
  }

  .json-viewer {
    border: 1px solid #ddd;
  }

  &__info-section {
    margin-bottom: 25px;

    .form-field {
      margin: auto;
    }

    .action {
      margin-top: 5px;
    }

    @media screen and (max-width: $header-ms-break) {
      display: block;
    }
  }

  &__button-container {
    margin-right: 30px;
  }

  .large-info-download, .empty-info {
    &__info-field {
      $state-info-border-color: #d8d8d8;

      &-content {
        @include ui-note;
      }

      button {
        margin-left: 15px;
      }
    }
  }
}
