
.table-per-page {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  select {
    height: 28px;
    line-height: 16px;
  }

  .icon-sort {
    bottom: 7px;
  }

  label {
    color: $secondary-text-color;
    font-weight: $weight-03;
    margin-left: 5px;
  }
}

.panel__subheader {
  .table-per-page {
    margin-bottom: 5px;
  }
}
