@import 'node_modules/sass-kit/mixins/responsive';

.page-case-details-overview {
  @mixin editing-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;

    > * + * {
      margin-left: 6px;
    }
  }

  $border-width: 1px;
  $border-style: solid;
  $border-color: $ui-03;
  $section-vertical-margin: $layout-03;
  $section-padding: $layout-03;

  // HTTP status
  &:not(.is-loaded) {
    display: flex;
    flex: 1 1 auto;
  }

  &__loading {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  &__error {
    display: flex;
    flex: 1 1 auto;
    color: $color-danger;
  }

  // reusable: associated cases
  &__associated-cases {
    flex: 1;
    margin: 0;
    padding: 0;
    list-style: none;

    & + & {
      margin-top: 16px;
    }
  }

  &__associated-case {
    display: flex;
    align-items: flex-start;
    flex-flow: row nowrap;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__associated-case-info {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    flex: 1;
  }

  &__associated-case-action-button {
    margin-right: 8px;
    cursor: pointer;
  }

  &__associated-case-identifier {
    display: flex;
    align-items: center;
    margin: 0 6px 4px 0;
    word-break: break-word;
  }

  &__associated-case-status {
    min-width: 0; // fix truncation
    margin-left: auto;
  }

  &__associated-case-meta {
    width: 100%;
    margin-top: 8px;
  }

  // layout
  &__content {
    display: flex;
    flex-flow: row nowrap;
    padding: $layout-03;
    background: $ui-01;
  }

  &__column1,
  &__column2,
  &__column3 {
    display: flex;
    flex-flow: column wrap;
  }

  &__column1 {
    flex: 2 2 auto;
    width: 30%;
    margin-right: $layout-03;
  }

  &__column2 {
    flex: 2 2 auto;
    width: 35%;
    margin-right: $layout-03;
  }

  &__column3 {
    flex: 1 1 auto;
    width: 30%;
  }

  &__section {
    max-width: 100%;
    padding: $section-padding;
    background-color: #fff;

    &:not(:last-child) {
      margin-bottom: $section-vertical-margin;
    }

    &:last-child {
      flex-grow: 1;
    }
  }

  &__section-header {
    display: flex;
    align-items: baseline;
    flex-flow: row wrap;
    margin: 0 0 12px;
  }

  &__section-title {
    flex: 0 1 auto;
  }

  &__section-primary-title {
    @include heading-03;
    display: block;
    margin: 0;
  }

  &__section-secondary-title {
    display: block;
    margin-left: auto;
    padding-left: 6px;
    font-size: 13px;
    color: color(grey);
    word-break: break-word;
  }

  &__section-header-actions {
    margin-left: 8px;

    * + * {
      margin-left: 8px;
    }
  }

  @include below(850px, $convert: false) {
    &__content {
      display: block;
      border-bottom: 0;

      > *:last-child {
        .page-case-details-overview__section:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__column1,
    &__column2,
    &__column3 {
      flex: unset;
      width: unset;
      margin-right: 0;
      border: 0;
    }

    &__section {
      border-bottom: $border-width $border-style $border-color;
    }

    &__section,
    &__section:not(:last-child) {
      margin-bottom: $section-vertical-margin;
    }
  }

  // section: status
  &__status-meta1 {
    margin-bottom: 16px;
  }

  &__status-level {
    display: inline-block;
    margin-right: 4px;
    word-break: break-word;
  }

  // section: summary
  &__summary-description-header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 12px;
  }

  &__summary-description-label {
    font-weight: $weight-03;
  }

  &__summary-description-actions {
    padding-left: 8px;

    * + * {
      margin-left: 8px;
    }
  }

  &__summary-description-content {
    margin-bottom: 16px;
  }

  &__summary-description-value {
    display: block;
    outline: 1px solid transparent;
  }

  &__summary-description-editor {
    width: 100%;
    height: 160px;
    padding: 0;
    border: 0;
    outline: 1px solid $border-color;
    outline-offset: 2px;
    resize: vertical;

    &:focus {
      outline: 1px solid $global-primary-color;
    }

    &[readonly] {
      background: $ui-03;
    }
  }

  &__summary-description-editing-actions {
    @include editing-actions;
  }

  // section: risks
  &__risks-alert-count {
    margin: 12px 0;
  }

  // section: primary entity
  &__primary-entity-id {
    margin: 12px 0;
  }

  &__primary-entity-id-value {
    @include heading-02;
    word-break: break-word;
  }

  &__primary-entity-id-editing-actions {
    @include editing-actions;
  }

  // section: associated entities
  &__associated-entities {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__associated-entity {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__associated-entity-identifier {
    min-width: 0;

    .page-case-details-overview__entity-link__link,
    .page-case-details-overview__entity-link__text {
      @include ellipsis;
      display: inline-block;
      max-width: 100%;
      word-break: break-word;
    }
  }

  // section: linked cases
  &__linked-cases-search {
    margin: 10px 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid color(greyLight);
  }

  &__linked-cases-search-results:not(:empty) {
    display: flex;
    padding-top: 16px;
  }

  &__linked-cases-search-results-loading,
  &__linked-cases-search-results-error,
  &__linked-cases-search-no-results,
  &__linked-cases-search-result {
    min-height: 59px;
  }

  &__linked-cases-search-results-loading,
  &__linked-cases-search-results-error,
  &__linked-cases-search-no-results {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
  }

  &__linked-cases-search-result {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__linked-cases-search-results-error {
    color: $color-danger;

    .icon {
      display: block;
      margin-right: 4px;
      fill: currentColor;
    }
  }

  &__linked-cases-search-results-error-text {
    display: block;
  }

  &__linked-cases-editing-actions {
    @include editing-actions;
  }
}
