.column-settings {
  $scrollbar-offset: 10px;

  min-width: 100%;
  width: 250px;
  background-color: #fff;
  font-size: 13px;
  white-space: nowrap;
  padding: 14px;

  &__button {
    .icon-angle-down {
      margin-right: 0 !important;
      margin-left: 10px !important;
    }
  }

  &__header {
    display: flex;
    text-align: center;
    font-weight: $weight-03;
    padding-right: $scrollbar-offset;

    &-visibility {
      flex: 1;
    }

    &-sorting {
      flex: 0 0 64px;
    }
  }

  &__subheader {
    display: flex;
    text-align: center;
    margin: 10px 0;
    padding-right: $scrollbar-offset;
    font-weight: $weight-03;

    &-visibility {
      flex: 1;
    }

    &-primary-sorting {
      flex: 0 0 32px;
    }

    &-secondary-sorting {
      flex: 0 0 32px;
    }
  }

  &__items {
    max-height: 280px; // ~ 10 items
    overflow: scroll;
  }

  &__column {
    display: flex;
    min-height: 30px;
    margin-bottom: 2px;

    .checkbox__label-text {
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-sortable .checkbox__label-text {
      max-width: 94px;
    }

    &-identification {
      display: flex;
      align-items: center;
      flex: 1;
    }

    &-primary-sorting,
    &-secondary-sorting {
      flex: 0 0 32px;
      text-align: center;

      .icon {
        width: 13px;
        height: 13px;
        margin: 0 !important;
        vertical-align: middle;
      }

      .icon-sort {
        fill: #dcdcdc;
      }
    }
  }

  // custom columns
  &__manage-column-button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    padding: 4px;
    font-size: 12px;
    border-radius: 100%;
    background: color(grey);
    color: #fff;
    cursor: pointer;

    .icon {
      width: 1em;
      height: 1em;
      margin: 0 !important;
    }

    &.is-active {
      background-color: $global-primary-color;
    }
  }

  &__add-column {
    min-width: 240px;
    padding: 14px;

    &-tooltip {
      display: block;
    }

    &-button {
      width: 100%;
    }

    &-actions {
      display: flex;

      .action {
        flex: 1 0 auto;
      }

      .action + .action {
        margin-left: 6px;
      }
    }
  }
}
