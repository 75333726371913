.communications {
  @include stack-context(communications);
  bottom: 0;
  left: 0;
  padding: 0 0 12px 12px;
  position: fixed;
  min-width: $sidebar-width - 12px;

  &:empty {
    display: none;
  }
}
