@use "sass:math";

@mixin arrow-body($back-color, $color, $size) {
  height: $size * 2;
  margin: 2px;
  border: 0;
  position: relative;
  overflow: hidden;
  background-image:
    linear-gradient(
      $back-color $size - math.div($size, 3),
      $color $size - math.div($size, 3),
      $color $size + math.div($size, 3),
      $back-color $size + math.div($size, 3)
    );

  &::after,
  &::before {
    content: "";
    position: absolute;
  }

  @content;
}

@mixin arrow-head-after($back-color, $color, $size) {
  right: -$size;
  border: $size solid;
  border-color: $back-color $back-color $back-color $color;
}

@mixin arrow-head-before($back-color, $color, $size) {
  left: -$size;
  border: $size solid;
  border-color: $back-color $back-color $back-color $color;
  transform: rotate(-180deg);
}

.page-analytics-status {
  // prevent staging status icons appearing above expanded small viewport sidebar
  position: relative;
  z-index: 0;

  .state-icon {
    vertical-align: middle;
    margin-right: 5px;

    &.state-icon--completed {
      .icon {
        fill: #69be4a;
      }
    }

    &.state-icon--failed {
      .icon {
        fill: #f1a236;
      }
    }

    &.state-icon--loading {
      .icon {
        fill: #727272;
      }
    }

    &.state-icon--pending {
      .icon {
        fill: #727272;
      }
    }

    &.state-icon--warning {
      .icon {
        fill: #f1a236;
      }
    }

    &.state-icon--dead {
      .icon {
        fill: $color-danger;
      }
    }

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  .form-field {
    &--list-values-field {
      display: flex;

      &-tag {
        max-width: 460px;
      }

      &-content {
        margin-left: 230px;
        margin-bottom: 10px;
      }
    }
  }

  .time-allocation-overview {
    .arrow-right {
      @include arrow-body($back-color: #fff, $color: #727272, $size: 4px) {
        &::after {
          @include arrow-head-after($back-color: #fff, $color: #727272, $size: 4px);
        }
      }
    }

    .arrow-left {
      @include arrow-body($back-color: #fff, $color: #727272, $size: 4px) {
        &::before {
          @include arrow-head-before($back-color: #fff, $color: #727272, $size: 4px);
        }
      }
    }

    line-height: 1.55;

    &__right {
      display: flex;
      flex-direction: column;
    }

    &__viz {
      display: flex;
      color: #727272;
      font-size: 12px;
    }

    &__viz > div {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &__viz > div:nth-child(odd) {
      flex: 1 1 35%;
      background: #000;
      color: #fff;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    }

    &__viz > div:nth-child(even) {
      flex: 1 1 30%;
      background: #fff;
      border-top: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
    }

    &__range-label {
      display: flex;
      align-items: center;
      color: #727272;
      font-size: 12px;

      > * {
        flex: 2;
      }

      > span {
        flex: 3;
        padding: 5px;
        text-align: center;
      }
    }
  }

  .remaining-slots-tooltip {
    margin-left: 4px;

    .tooltip-icon .icon {
      fill: $hint-color;
    }
  }

  .static-tooltip__content {
    min-width: 200px;
    text-align: left;
  }
}

.status-container {
  $stages-column-layout-break: 1200px;

  min-height: 230px;

  .stages-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 40px;
  }

  .stage-submit--user {
    .icon {
      fill: currentColor;
      vertical-align: top;
      margin-right: 2px;
    }
  }

  .stage {
    display: inline-block;
    position: relative;
    flex: 1 1 0;
    height: 100%;
    text-align: center;

    .state-bar {
      position: absolute;
      width: 100%;
      height: 4px;
      right: 50%;
      top: 35px;
      background: #c1c1c1;
    }

    .stage-icon {
      position: relative;
      height: 70px;
      width: 70px;
      background: #c1c1c1;
      border-radius: 35px;
      margin: 0 auto;
      z-index: 1;

      .icon {
        height: 30px;
        width: 30px;
        margin: 20px;
        fill: #fff;
      }
    }

    .state-heading {
      position: relative;
      color: $primary-text-color;
      font-size: 13px;
      font-weight: $weight-04;
      margin-top: 15px;
    }

    .state-info {
      color: $primary-text-color;
      margin-top: 4px;

      .state-info--dashed {
        border-bottom: 1px dashed;
      }
    }

    .button-container {
      margin-top: 8px;

      button {
        margin: 2px;
      }
    }

    &.active .state-bar,
    &.active .stage-icon {
      @include analytics-version-color-default;

      @at-root {
        .page-analytics-status {
          $selector: ".status-container .stage.active .state-bar, .status-container .stage.active .stage-icon";

          &.is-previous-version {
            #{$selector} {
              @include analytics-version-color-previous;
            }
          }

          &.is-staging-version {
            #{$selector} {
              @include analytics-version-color-staging;
            }
          }

          &.is-live-version {
            #{$selector} {
              @include analytics-version-color-live;
            }
          }
        }
      }
    }

    // halo
    &.current .stage-icon::before {
      $current-stage-icon-box-shadow: 0 0 0 6px;

      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0.5;
      box-shadow: $current-stage-icon-box-shadow color-customisation(analyticsDefaultBackgroundColor, #808080);

      @at-root {
        .page-analytics-status {
          &.is-previous-version .stage.current .stage-icon::before {
            box-shadow: $current-stage-icon-box-shadow color-customisation(analyticsDefaultBackgroundColor, #808080);
          }

          &.is-staging-version .stage.current .stage-icon::before {
            box-shadow: $current-stage-icon-box-shadow color-customisation(analyticsStagingBackgroundColor, #893067);
          }

          &.is-live-version .stage.current .stage-icon::before {
            box-shadow: $current-stage-icon-box-shadow color-customisation(analyticsLiveBackgroundColor, #cc3a7f);
          }
        }
      }
    }
  }

  @media screen and (max-width: $stages-column-layout-break) {
    display: flex;
    justify-content: center;

    .stages-container {
      display: flex;
      flex-flow: column wrap;
      width: auto;
      padding: 0 20px 20px;
    }

    .stage {
      display: flex;
      align-items: center;
      flex-basis: auto;
      width: initial;
      height: initial;
      text-align: left;
      padding: 8px 0;

      .stage-icon {
        margin: 0 24px 0 0;
      }

      .state-bar {
        width: 4px;
        height: 100%;
        top: -35px;
        left: 33px;
      }

      .state-heading {
        margin-top: 0;
      }
    }
  }
}

.analytics-status-run-preview-modal {
  .modal__body-wrap {
    max-width: 970px;
  }
}

.analytics-status-compare-versions-modal,
.analytics-status-revert-modal {
  &__content {
    display: flex;
    flex-direction: column;

    .elm-changes {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &__no-changes-container {
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  &__top-section {
    color: $secondary-text-color;
  }
}

.analytics-status-revert-modal {
  .elm-changes-category-heading-container {
    margin: 0 15px;
  }

  .modal__content {
    margin: 0;
  }

  .analytics-status__note {
    margin: 15px 18px;
  }

  &__content {
    padding: 0;
  }

  &__top-section {
    display: flex;
    margin: 15px 18px;

    .top-section-badges-container {
      display: flex;
      margin-left: auto;

      .top-section-badges {
        display: block;
      }

      .version-badge {
        padding: 4px 8px;
        margin: 0 10px;
        border: 1px solid #727272;
        border-radius: 3px;

        &.staging {
          color: $analytics-version-color-staging-background;
          border: 1px solid $analytics-version-color-staging-background;
        }
      }

      .arrow {
        vertical-align: middle;

        .icon {
          fill: #727272;
        }
      }
    }
  }

  &__comment-section {
    padding: 18px;
    background: #f4f4f4;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &__submit-container {
    display: flex;
    align-items: center;

    .form-field {
      display: block;
      flex: 1;
      margin-right: 15px;
    }

    .analytics-status-revert-modal__revert-submit {
      flex: 0;
    }
  }
}

.analytics-status-compare-versions-modal {
  .elm-changes-category-heading-container {
    margin: 0 15px;
  }

  &__content {
    width: 690px;
    max-width: 100%;
    min-height: 400px;
    padding-bottom: 10px;
  }

  .modal__header {
    margin-bottom: 0;
  }

  .modal__content {
    margin: 0;
  }

  &__top-section {
    padding: 18px;
    background: #f4f4f4;
  }
}

.fs-select-container {
  display: flex;

  .custom-select {
    @include user-select(none);

    flex: 1;

    &__input-label {
      width: 100%;
      padding: 6px 2px;

      div {
        line-height: 16px;
      }

      .version-label {
        font-weight: $weight-03;
      }
    }

    &.is-open {
      .custom-select__options {
        width: 100%;
        max-height: 160px;
      }
    }

    &__input {
      height: 55px;
    }

    &__input-icon {
      margin-left: auto;
    }
  }

  .arrow-container {
    line-height: 55px;
    margin: 0 10px;
  }

  &__select-checkbox-button {
    cursor: pointer;
    vertical-align: top;
    background: none;
    border: 0;
    padding: 0 4px;
    margin-right: 0;

    .icon {
      margin-right: 0 !important;
    }

    .icon-square {
      fill: #d8d8d8;
    }

    .icon-check-square,
    .icon-minus-square {
      fill: $input-checkbox-background-color-active;
    }

    .icon-angle-down {
      margin-right: 0;
    }

    &:focus {
      outline: 1px solid $global-primary-color;
    }
  }

  &__select-all-dropdown-button {
    .icon {
      margin: 0 5px !important;
    }
  }
}

.analytics-status {
  $field-block-layout: 992px;

  &__view-sandbox-preview-modal-content,
  &__run-sandbox-preview-modal-content {
    .form-field {
      display: flex;

      &__label-wrap {
        padding-right: 20px;
        max-width: 230px;
      }
    }
  }

  &__view-sandbox-preview-modal-content {
    padding: 16px;
    width: 690px;
    max-width: 100%;
    color: #727272;
    line-height: 15px;

    .state-info--dashed {
      border-bottom: 1px dashed;
    }

    .state-icon {
      vertical-align: middle;
      margin-right: 5px;

      &.state-icon--completed {
        .icon {
          fill: #69be4a;
        }
      }

      &.state-icon--failed {
        .icon {
          fill: #f1a236;
        }
      }

      &.state-icon--loading {
        .icon {
          fill: #727272;
        }
      }

      &.state-icon--pending {
        .icon {
          fill: #727272;
        }
      }

      &.state-icon--warning {
        .icon {
          fill: #f1a236;
        }
      }
    }

    .analytics-status__view-no-results {
      text-align: center;
      padding-top: 30px;
      color: #727272;

      .icon {
        width: 2em;
        height: 2em;
        margin-right: 10px;
        fill: #727272;
      }

      .analytics-status__error {
        position: relative;
        bottom: 0.5em;
      }
    }

    .analytics-status__no-previews {
      text-align: center;
    }

    .form {
      padding-bottom: 25px;

      .form-field__action-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;

        .form-field__actions {
          display: flex;
          flex: 0 0 auto;

          .view-sandbox-preview-modal-content {
            &__delete,
            &__stop {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  &__run-sandbox-preview-modal-content {
    padding: 16px;

    .form-field {
      .static-tooltip__content {
        right: auto;
        min-width: 200px;
        padding: 14px;
      }
    }

    .form-field__form-input-wrapper {
      flex: 1;
    }

    .form-input--radios {
      display: flex;
      flex-flow: row wrap;

      .radio {
        flex: 1 1 auto;
        margin: 0 0 0 8px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .form-input__datetime-wrapper {
      flex: 1;
    }

    .form-input--datetime-range {
      width: 450px;

      @media screen and (max-width: $field-block-layout) {
        display: flex;
      }
    }
  }

  &__run-sandbox-preview-modal-content .form-field__label {
    white-space: normal;
  }

  &__burn-in-field-group,
  &__run-sandbox-preview-modal-content .form-input--datetime-range {
    .form-field__label-wrap {
      font-size: 13px;
    }

    .form-input__input--textlike {
      padding: 2px 6px;
    }

    .form-input__input {
      &--date,
      &--time {
        letter-spacing: -1px;
        padding: 2px;
      }
    }
  }

  &__date-range-type-field {
    .form-field {
      margin-bottom: 0;
    }
  }

  &__run-sandbox-preview-subfield {
    margin-left: 26px;
  }

  &__burn-in-tooltip {
    display: flex;
    align-items: center;
    width: 290px;
  }

  &__burn-in-tooltip-image {
    margin-right: 14px;
  }

  &__burn-in-field {
    position: relative;
  }

  &__burn-in-field-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .form-input {
      position: relative;
      z-index: 2;
    }

    .form-field {
      margin: 0;
    }

    .radio + .radio {
      flex-grow: 0;
    }
  }

  &__burn-in-field-group {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 25px 230px;

    @media screen and (max-width: $field-block-layout) {
      margin-left: 0;
      padding-top: 26px;
    }
  }

  &__burn-in-field-group-item {
    position: relative;

    & + & {
      padding-left: 20px;
    }

    &--percentage {
      width: 84px;

      &::after {
        @include vertical-center;
        content: "%";
        display: block;
        left: calc(100% - 16px);
      }

      .form-input__input {
        padding-right: 20px;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button {
          display: none;
        }
      }
    }
  }

  &__burn-in-field-group-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #fff;
    z-index: 1;
  }

  &__run-sandbox-preview-configuration-chooser {
    .table {
      tbody {
        display: block;
        max-height: 185px;
        overflow-x: auto;
      }

      tr {
        display: block;
      }
    }
  }

  &__note {
    @include ui-note;
    margin-top: 14px;
  }
}

.quota-status {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.run-sandbox-replay-modal {
  &__sampling-fields {
    border-top: solid 1px #dedede;
    border-bottom: solid 1px #dedede;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  &__sampling-percentage {
    // to match other form labels
    color: color(grey);
    font-size: 13px;
    line-height: 18px;
  }
}
