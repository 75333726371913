$background-color: $primary-input-color;
$box-shadow-color: rgba(0, 0, 0, 0.1);
$line-height: 19px;
$warning-color: $primary-text-color;

.notification-list {
  width: 256px;
  margin-bottom: 12px;

  &__notification {
    background-color: $background-color;
    box-shadow: 5px 5px $box-shadow-color;
    color: $primary-background-color;
    font-weight: $weight-03;
    line-height: $line-height;
    padding: 12px;
    position: relative;
    word-wrap: break-word;

    .icon {
      --primary-color: #{$primary-background-color};
      display: inline-block;
      fill: $primary-background-color;
      height: $line-height;
      margin-right: 3px;
      vertical-align: top;
      width: $line-height;
    }

    & + & {
      margin-top: 7px;
    }

    &--error {
      background: $notification-error;
    }

    &--success {
      background: $notification-success;
    }

    &--warning {
      background: $notification-warning;
      color: $primary-text-color;

      .icon {
        fill: $primary-text-color;
      }
    }
  }

  &__notification-close {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fcfcfc;
    border: 1px solid #c9c9c9;
    border-radius: 11px;
    padding: 5px;
    line-height: 10px;
    width: 20px;
    height: 20px;

    .icon {
      z-index: 4;
      position: absolute;
      fill: color(grey);
      width: 10px;
      height: 10px;
      margin: 0;
      top: 4px;
      left: 4px;
    }

    &:focus {
      background-color: $global-primary-selected-background-color-alt;
    }

    &-loading {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;

      .hold {
        position: absolute;
        width: 100%;
        height: 100%;
        clip: rect(0, 20px, 20px, 10px);
        border-radius: 100%;
      }

      .fill {
        background-color: rgba(0, 0, 0, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        clip: rect(0, 10px, 20px, 0);
      }

      .left .fill {
        z-index: 1;
        animation: left 2.5s linear both;
      }

      .right {
        z-index: 3;
        transform: rotate(180deg);

        .fill {
          z-index: 3;
          animation: right 2.5s linear both;
          animation-delay: 2.5s;
        }
      }

      @keyframes left {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(180deg); }
      }

      @keyframes right {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(180deg); }
      }
    }
  }

  &__default-renderer {
    max-height: 60vh;
    overflow: auto;
    white-space: pre-wrap;

    p {
      margin: 0;
      padding-right: 6px;
    }
  }

  &__broadcast-message {
    margin: 0;
    max-height: 100px;
    overflow: auto;
  }

  &__action {
    background: inherit;
    border: 0;
    padding: 0;
    color: inherit;
    font-weight: $weight-03;
    text-decoration: underline;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}
