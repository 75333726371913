.system-status {
  @include iframe-dashboard;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-bottom: 15px;
    color: color-customisation(panelHeaderColor, $panel-header-color);
    background-color: color-customisation(panelHeaderBackgroundColor, $panel-header-background-color);
    font-size: 16px;
    font-weight: $weight-03;
    white-space: nowrap;
    padding: 5px 5px 0;
  }

  &__title,
  &__time,
  &__actions {
    line-height: 30px;
    padding-bottom: 5px;
  }

  &__full-screen {
    display: flex;
    cursor: pointer;
  }
}
