.page-case-details-audit {
  // table cells
  &__cell-comment {
    display: block;
    white-space: normal;
  }

  &__cell-content-change {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;

    .icon {
      display: block;
      fill: currentColor;
    }
  }

  &__cell-document {
    display: block;
  }

  &__cell-entity {
    display: inline-block;
  }

  &__cell-list {
    margin: 0;
    padding: 0 0 0 15px;
  }

  // modals
  #diff-modal .modal__body {
    background-color: #f4f4f4;
  }

  &__diff-modal-content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-width: 500px;
    min-height: 100px;
  }
}
