.page-incident-list {
  &__breadcrumb-label {
    @include ellipsis;
    display: block;
  }

  &__pending-time {
    color: $secondary-text-color;
    font-size: 13px;
    padding: 0 2px;

    &--hightlight {
      background-color: $tertiary-text-color;
      color: $primary-background-color;
    }

    .snooze-time {
      font-weight: $weight-03;
      margin-left: 4px;
    }

    .icon {
      fill: $secondary-text-color;
      margin-bottom: -1px;
    }
  }

  &__table-header-wrap {
    display: flex;
    margin-bottom: 10px;

    .page-incident-list__currently-selected {
      color: color(grey);
      line-height: 28px;
      margin-left: 5px;
    }
  }

  &__table-header-actions {
    margin-left: auto;
  }

  &__currently-selected {
    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  }

  &__tooltip {
    position: relative;

    .action {
      .icon-angle-down {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  &__tooltip-arrow {
    @include horizontal-center;
    bottom: calc(-#{$tooltip-margin} - 1px);
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: index($stack, message-tooltip) + 1;

    &::before {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
    }

    &--top {
      border-bottom-color: $tooltip-border-color;
      border-width: 0 6px 6px;

      &::before {
        top: 1px;
        left: -5px;
        border-bottom-color: $tooltip-background-color;
        border-width: 0 5px 5px;
      }
    }

    &--no-risk {
      border-bottom-color: #6DBD54;

      &::before {
        border-bottom-color: #f5fcf1;
      }
    }

    &--risk {
      border-bottom-color: #ff3c46;

      &::before {
        border-bottom-color: #ffeded;
      }
    }
  }

  &__server-driven-fields {
    margin-top: 15px;
  }

  &__tooltip-content {
    font-size: 13px;
    white-space: nowrap;

    &-column-settings {
      width: 250px;
      padding: 14px;
      max-height: calc(100vh - 190px);
      overflow: auto;

      &-header {
        display: flex;
        text-align: center;
        font-weight: $weight-03;

        &-visibility {
          flex: 1;
        }

        &-sorting {
          flex: 0 0 64px;
        }
      }

      &-subheader {
        display: flex;
        text-align: center;
        margin: 10px 0;
        font-weight: $weight-03;

        &-visibility {
          flex: 1;
        }

        &-primary-sorting {
          flex: 0 0 32px;
        }

        &-secondary-sorting {
          flex: 0 0 32px;
        }
      }

      &-column {
        display: flex;
        height: 30px;

        .checkbox__label-text {
          max-width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-sortable .checkbox__label-text {
          max-width: 94px;
        }

        &-identification {
          flex: 1;
        }

        &-primary-sorting,
        &-secondary-sorting {
          flex: 0 0 32px;
          text-align: center;

          .icon {
            width: 13px;
            height: 13px;
            margin: 0 !important;
          }

          .icon-sort {
            fill: #dcdcdc;
          }
        }
      }
    }

    &-bulk-actions {
      color: #7A7A7A;
      padding: 4px 0;
      display: flex;
      flex-direction: column;

      .action--option-link {
        border-radius: 0;
        font-weight: $weight-03;
        text-align: left;
        padding: 0 14px;

        &:hover {
          background-color: #d8d8d8;
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      .action--option-link-risk {
        .icon {
          fill: #FF3C46;
        }
      }

      .action--option-link-no-risk {
        .icon {
          fill: #6DBD54;
        }
      }

      .action--option-link-delete {
        color: color(red);

        .icon {
          fill: currentColor;
        }
      }
    }

    &-actions {
      display: flex;
      margin-top: 15px;
    }

    &-action-submit {
      flex: 1;
      margin-right: 5px;
    }

    &-action-cancel {
      flex: 0 1 auto;
    }
  }

  &__incident-link {
    color: $table-cell-link-color;
    font-weight: $weight-03;
  }

  &__sidebar-right {
    width: 600px;
  }

  &__filter-sidebar-scope-and-date {
    display: flex;
    flex-direction: column;

    .incident-list-advanced-search {
      margin-top: 0;
      padding-top: 0;
      padding-right: 10px;

      &__header {
        margin-bottom: 1px;
        color: $secondary-text-color;
      }
    }

    .advanced-search__input {
      height: 37px;
      border-color: $input-border-color;

      &:hover {
        border-color: $input-hover-border-color;
      }
    }

    .form-field {
      flex: 1;
      padding: 0;
    }
  }

  &__filter-sidebar-checkbox-list {
    .checkbox {
      margin-bottom: 0 !important;
      padding-right: 20px;
    }

    .checkbox__label {
      align-items: flex-start;
      padding: 16px 0;
      border-bottom: 1px solid color(greyLight);
    }

    .checkbox__label-wrapper {
      flex-direction: row;
      min-width: 0;
    }

    .checkbox__label-icon {
      margin-top: 1px;
    }

    .checkbox__label-sub-text {
      display: flex;
      min-width: 0;
      font-size: 13px;
    }

    &__icon-loading {
      display: flex;
      justify-content: center;
      height: 204px;
      width: 100%;
      padding-top: 90px;
    }
  }

  &__filter-sidebar-checkbox-list-icon {
    display: flex;
    margin-right: 8px;
    flex: 0;

    .icon {
      width: 14px;
      fill: currentColor;
    }
  }

  &__filter-sidebar-checkbox-list-sub-label {
    @include ellipsis;
    display: block;
    flex: 1 1 auto;
  }

  &__pagination-and-per-page {
    display: flex;
    justify-content: center;

    .table-per-page {
      margin: 0 0 0 15px;
    }
  }
}


.main-body--data-grid {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .panel {
    height: 100%;

    &__wrapper {
      height: 100%;
      display: flex;
    }

    &__body {
      overflow: hidden;
    }

    &__table {
      overflow: auto;
      flex: 1;
    }
  }
}

.table__cell-viewing {
  border: 1px solid $global-primary-color;
  border-radius: 2px;
  font-weight: $weight-03;
  font-size: 13px;
  color: $global-primary-color;
  padding: 2px 4px;

  button {
    border: none;
    background-color: transparent;
    padding-right: 2px;
  }

  .icon {
    fill: currentColor;
  }

  .action,
  .action .icon,
  .user-name {
    height: 15px;
    line-height: 15px;
  }

  .action {
    padding-right: 0;
    padding-left: 4px;
  }

  .action .icon {
    margin: 0;
  }

  .user-name {
    margin-right: 2px;

    .icon {
      vertical-align: top;
      margin-right: 1px;
    }
  }
}

.table__cell--incident-details {
  padding-left: 0;

  dl {
    display: grid;
    grid-template-columns: 100px auto;
  }

  dt, dd {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid $table-border-color;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  dt {
    font-weight: $weight-04;
  }

  dd {
    margin-left: 0;
  }
}

.expanded-row-details {
  display: grid;
  grid-template-columns: 100px auto;

  dt, dd {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid $table-border-color;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  dt {
    font-weight: $weight-04;
  }

  dd {
    margin-left: 0;
  }
}


.table__cell-incident-tag {
  padding: 0 4px;
  margin-left: 4px;
  border: 1px solid $secondary-text-color;
  border-radius: 3px;
  color: $secondary-text-color;
  font-weight: $weight-03;
  background: $primary-background-color;
}

.percentage-bar__bar {
  min-width: 100px;
}
