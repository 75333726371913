.page-sandbox-status {
  height: 100%;

  > div:last-child {
    margin-top: 20px;
    min-height: 100%;
    min-height: calc(100% + 20px);
  }

  .panels-split-wrapper {
    position: relative;
    height: 310px;
  }

  .panels-split {
    display: flex;
    height: 100%;
  }

  .panels-split > div {
    width: 50%;
    padding-top: 10px;
  }

  .panels-split > div:first-child {
    margin-right: 10px;
  }

  .panel__wrapper {
    height: 100%;
  }

  .panel__body {
    height: calc(100% - 40px);
  }

  .chart-wrapper {
    height: calc(100% - 30px);
  }

  .chart-with-pointer rect {
    cursor: pointer;
  }

  .alert-difference-chart .chart-wrapper {
    height: calc(100% - 6px);
  }

  .panel--chart {
    height: 100%;
  }

  .chart-group > .live {
    stroke: #eebc49;
  }

  .chart-group > .sandbox {
    stroke: #c57299;
  }

  .live-only,
  .live-performance {
    fill: #eebc49;
  }

  .sandbox-only,
  .sandbox-performance {
    fill: #c57299;
  }

  .live-and-sandbox {
    fill: #2eaea0;
  }

  .current-status .panel__body {
    margin-right: 10px;
  }

  .current-status dl {
    display: flex;
    margin: 0;
    float: left;
    color: #727272;
  }

  .current-status dt,
  .current-status dd {
    display: table-cell;
    padding: 8px;
    line-height: 22px;
    vertical-align: top;
    border-bottom: 1px solid #DEDEDE;
  }

  .current-status dt {
    font-weight: $weight-04;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .current-status dd {
    flex: 1;
    padding-left: 0;
    margin: 0;
  }

  .modal__content {
    .row-wrapper {
      display: flex;
    }

    .row-wrapper > div {
      flex: 1;
      justify-content: space-between;
    }

    .tick text {
      font-size: 13px;
    }
  }

  .table__cell {
    padding: 2px;
  }

  .aric-performance-modal {
    .sandbox {
      fill: #c57299;
    }

    .live {
      fill: #eebc49;
    }
  }

  .chart-wrapper {
    position: relative;
  }

  .alert-difference-chart {
    .ordinal-axis text {
      font-size: 13px;
    }
  }

  .legend {
    margin-top: 10px;

    ul {
      margin-top: 0;
      list-style-type: none;
    }

    li {
      display: inline;
      float: left;
      margin-left: 20px;
      position: relative;
      padding-left: 20px;
      line-height: 1.6;
    }

    li:first-child {
      margin-left: 0;
    }

    .sandbox {
      fill: #c57299;
    }

    .live {
      fill: #eebc49;
    }

    span::before {
      position: absolute;
      left: 0;
      display: inline-block;
      content: "";
    }

    span.sandbox::before {
      background: #c57299;
    }

    span.live::before {
      background: #eebc49;
    }
  }

  .aric-performance-chart .legend {
    span::before {
      width: 15px;
      height: 15px;
      top: 3px;
    }
  }

  .score-distribution-chart .legend {
    span::before {
      width: 15px;
      height: 3px;
      top: 9px;
    }
  }

  .x-axis-labels {
    // width: calc(100% - 90px);
    // left: 55px;
    position: absolute;
    bottom: 0;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      white-space: nowrap;
    }

    li {
      display: inline-block;
      padding: 0 2px;
      overflow: hidden;
      font-size: 13px;
      color: #555;
      text-align: center;
      text-overflow: ellipsis;
      flex: 1;
    }
  }

  .promote-modal,
  .delete-modal {
    .question {
      margin-right: auto;
      margin-bottom: 5px;
      margin-left: auto;
      font-weight: $weight-04;
      text-align: center;
    }

    .confirm-wrapper {
      display: flex;
      margin: 10px 0 0;
      justify-content: center;
    }

    .confirm {
      margin: 5px;
      flex: 1;
    }
  }

  @media (max-width: 1190px) {
    .panels-split-wrapper {
      height: 640px;
    }

    .panels-split {
      display: block;
    }

    .panels-split > div {
      width: 100%;
    }

    .panels-split > div {
      margin-right: 10px;
    }

    .panel--chart {
      height: 50%;
    }

    .panel:last-child {
      min-height: 50%;
    }
  }

  .axis line,
  .axis path {
    stroke: #555;
  }

  .axis text {
    fill: #555;
  }
}

@media screen and (max-width: 768px) {
  .app-environment > .sandbox .sandbox-label {
    flex-basis: 60px;
    width: 60px;

    span {
      display: none;
    }
  }

  .application--sidebar-expanded .app-environment > .sandbox .sandbox-label {
    flex-basis: 280px;
    width: 280px;

    span {
      display: inline;
    }
  }
}
