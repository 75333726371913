.cheque-control {
  display: flex;
  width: 70vw;

  &--carousel {
    flex-direction: row;
  }

  &--single-page {
    flex-direction: column;

    & + .cheque-control__signature-sample {
      margin-top: 10px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    height: 35vh;

    & + .cheque-control__row {
      margin-top: 10px;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: color(grey);
    padding: 20px;
    width: 100%;

    .icon {
      width: 1em;
      height: 1em;
      fill: currentColor;
      font-size: 18px;
    }
  }

  &__no-entries {
    display: flex;
    width: 70vw;
    height: 35vh;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    height: auto;
    margin: 5px 0;
  }

  &__image-description-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 5px;
  }

  &__image-wrapper {
    display: flex;
    height: 35vh;
  }

  &__description {
    word-break: break-word;
  }

  &__container {
    border: 1px solid #C8C8C8;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 0 !important;
    max-height: 100%;

    &--zoomed-in {
      background-size: auto !important;
    }
  }

  &__container--with-description {
    max-height: calc(100% - 19px);
  }

  &__image {
    max-height: 100%;
    max-width: 100%;

    &--zoomed-in {
      display: none;
    }
  }

  &__signature-sample {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    &-wrapper {
      border: 1px solid #C8C8C8;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    &-text {
      word-break: break-word;
    }

    &-image {
      margin-left: 5px;
    }
  }
}
