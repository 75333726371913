.page-sandbox-list {
  &__main-container {
    text-align: center;
  }

  &__icon {
    font-size: 42px;
    margin-top: 120px;
    color: $primary-text-color;
  }

  &__title {
    font-size: 20px;
    color: $primary-text-color;
  }

  &__subtitle {
    color: $secondary-text-color;
    font-size: 13px;
    font-weight: $weight-02;
    line-height: 17px;
    margin: 0;
  }

  &__actions {
    margin-top: 15px;

    * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__progress-bar {
    height: 11px;
    width: 265px;
    margin: 15px auto 0;
    border-radius: 8px;
    background-color: rgb(43,194,83);
    background-image: linear-gradient(to top, color(purple) 37%, #f581bc 69%);
    box-shadow: inset 0 2px 9px rgba(255,255,255,0.3), inset 0 -2px 6px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
      z-index: 1;
      background-size: 50px 50px;
      animation: progress-bar-move 2s linear infinite;
      overflow: hidden;
    }
  }
}

@keyframes progress-bar-move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}
