.pagination {
  text-align: center;
  font-weight: $weight-03;

  &__list {
    display: inline-block;
    margin: 20px 0;
    padding: 0;
  }

  &__item {
    min-width: 30px;

    & + & {
      margin-left: 4px;
    }
  }

  &--minimal > nav {
    button:first-child {
      margin-right: 4px;
    }

    button:last-child {
      margin-left: 4px;
    }
  }

  &--minimal .pagination__list {
    margin: 8px 0;
  }
}
