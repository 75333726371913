.elm-changes {
  $diff-removed: #efbcbf;
  $diff-added: #64d481;

  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    margin: 20px;
    font-size: 28px;
  }

  &__error {
    margin: 0 16px;
    color: $color-danger;
    font-size: 14px;
  }

  .change-status {
    padding: 10px 0;

    .status-heading {
      font-weight: $weight-04;
    }

    .status-state {
      margin-left: 15px;
    }
  }

  &__no-changes-container {
    padding: 30px 0;
    text-align: center;
  }

  &__no-changes-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 121px;
    background: url('/images/analytical-workflow/analytics-config-diff-no-changes.svg') no-repeat;
    background-position: center;
  }

  &__no-changes-themed-image {
    @include analytics-version-color-default;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 100%;

    .icon {
      width: 60%;
      fill: currentColor;
    }

    @at-root {
      .page-analytics-status {
        $selector: '.elm-changes__no-changes-themed-image';

        &.is-previous-version {
          #{$selector} { @include analytics-version-color-previous; }
        }

        &.is-staging-version {
          #{$selector} { @include analytics-version-color-staging; }
        }

        &.is-live-version {
          #{$selector} { @include analytics-version-color-live; }
        }
      }
    }
  }

  &__no-changes-text {
    margin-top: 10px;
    color: $secondary-text-color;
    font-weight: $weight-03;
  }

  .elm-changes-category {
    margin: 15px 0;

    .table {
      table {
        table-layout: fixed;
      }

      &__cell {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__header-item--select {
        width: 61px;
      }
    }

    .elm-changes-category-heading-container {
      &__icon {
        display: inline-flex;
        padding: 12px;
        margin-right: 10px;
        background: #f2f2f2;
        border-radius: 25px;

        .icon {
          width: 1.4em;
          height: 1.4em;
        }
      }

      &__heading {
        font-size: 18px;
        font-weight: $weight-04;
      }
    }

    .code {
      margin: 0;
      white-space: pre;
      text-align: left !important;

      span {
        display: block;
        min-height: 15px;
        line-height: 15px;
        padding: 0;
      }
    }
  }

  .diff-table {
    width: 100%;
    border-spacing: 10px;
    table-layout: fixed;

    .state-badge {
      padding: 0 4px;
      margin-left: 3px;
      color: #853267;
      font-weight: $weight-02;
      border: 1px solid #853267;
      border-radius: 3px;

      &.live-state {
        color: #fff;
        background-color: #d2317e;
        border: none;
      }
    }

    td {
      width: 100%;
      padding-bottom: 5px;
    }

    &.split {
      .diff-content--before {
        margin: 0 5px 0 0;
      }

      .diff-content--after {
        margin: 0 0 0 5px;
      }

      td {
        width: 50%;
        height: 0; // allows cell content to fill 100% of height
      }
    }

    .no-diff {
      font-weight: $weight-03;
      color: #999;
      text-align: center;

      td {
        padding: 20px;
      }

      .tick span {
        padding: 5px;
        margin-bottom: 5px;
        font-weight: 100;
        border: 2px solid #999;
        border-radius: 12px;
      }
    }

    .diff-content {
      background-color: rgba(#fff, 0.85);

      &--before {
        .property {
          &.changed,
          .changed {
            background: $diff-removed;
          }
        }
      }

      &--after {
        .property {
          &.changed,
          .changed {
            background: $diff-added;
          }
        }
      }
    }

    .title td {
      padding: 8px 0;

      div {
        padding: 10px;
        text-transform: uppercase;
        background: #f2f2f2;
        border-radius: 1px;
      }
    }

    .header {
      font-weight: $weight-04;
    }

    .diff-content {
      padding: 5px 0;
      overflow: auto;
      border-radius: 8px;

      &--empty {
        height: 100%;
        min-height: 67px;
        background-color: #efefef;
      }
    }

    .description {
      .diff-content--empty {
        height: 36px;
      }
    }

    .property {
      display: flex;
      padding: 4px 10px;
      margin: 5px 0;
      overflow: auto;

      &.no-label {
        padding-left: 0;
        padding-right: 0;

        .code-line {
          padding: 0 10px;
        }
      }

      &.label-block {
        display: block;
        padding-left: 0;
        padding-right: 0;

        .term {
          padding: 0 10px;
        }

        .code-line {
          padding: 0 10px;
        }
      }

      .term, .description {
        flex: 1;
      }

      .term {
        font-weight: $weight-04;
        padding-right: 4px;
      }

      .description {
        text-align: right;
      }
    }
  }
}

.config-diff-header-container {
  display: flex;
  margin: 10px 0 25px;
  color: $secondary-text-color;

  .label {
    margin: 0 5px 0 15px;
  }

  .version-badge {
    padding: 4px 8px;
    margin: 0 10px;
    border: 1px solid #727272;
    border-radius: 3px;
  }

  .arrow {
    vertical-align: middle;

    .icon {
      fill: #727272;
    }
  }

  .compare-button {
    font-weight: $weight-03;
    color: $global-link-color;
    text-decoration: underline;
  }

  .change-counters {
    flex: 1;
    text-align: right;
    margin-right: 10px;

    .change-item {
      font-size: 13px;
      margin: 0 15px 0 5px;

      .change-number {
        margin-left: 5px;
        padding: 2px 8px;
        border-radius: 20px;
        background-color: #f1f1f1;
      }
    }
  }
}
