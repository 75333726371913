.take-control-banner {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  background-color: color-customisation(takeControlBarBackgroundColor, #103c62);
  color: color-customisation(takeControlBarColor, #fff);
  border-bottom: 1px solid #fff;
  text-align: right;

  &__title {
    @include ellipsis;
    color: inherit;
    margin: 0;
    font-size: 13px;
    font-weight: $weight-02;
    line-height: 1.35;
  }

  &__actions {
    margin-left: auto;
    padding-left: 8px;
  }
}

.release-control-modal {
  width: 320px;
  min-height: auto;
  padding: 10px;
  text-align: center;

  .icon-gamepad {
    width: 70px;
    height: 70px;
    fill: $global-primary-color;
  }

  .action-title {
    margin: 20px auto;
    font-size: 18px;
    font-weight: $weight-04;
  }
}
