// ROBOTO (https://github.com/FontFaceKit/roboto)

@mixin roboto($fontType) {
  src:
    url("/fonts/Roboto/#{$fontType}/Roboto-#{$fontType}.woff2?v=2.137") format("woff2"),
    url("/fonts/Roboto/#{$fontType}/Roboto-#{$fontType}.woff?v=2.137") format("woff");
}

@font-face {
  font-family: Roboto;
  @include roboto("Bold");
  font-weight: $weight-04;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include roboto("Bold");
  font-weight: $weight-04;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include roboto("BoldItalic");
  font-weight: $weight-04;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include roboto("BoldItalic");
  font-weight: $weight-04;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include roboto("Italic");
  font-weight: $weight-02;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include roboto("Italic");
  font-weight: $weight-02;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include roboto("Light");
  font-weight: $weight-01;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include roboto("LightItalic");
  font-weight: $weight-01;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include roboto("Medium");
  font-weight: $weight-03;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include roboto("MediumItalic");
  font-weight: $weight-03;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  @include roboto("Regular");
  font-weight: $weight-02;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include roboto("Regular");
  font-weight: $weight-02;
  font-style: normal;
}

// ROBOTO MONO (https://google-webfonts-helper.herokuapp.com/fonts/roboto-mono)


@mixin robotoMono() {
  src:
    url("/fonts/Roboto-Mono/Roboto-Mono.woff2?v=1.0") format("woff2"),
    url("/fonts/Roboto-Mono/Roboto-Mono.woff?v=1.0") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  @include robotoMono();
  font-weight: $weight-02;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  @include robotoMono();
  font-weight: $weight-02;
  font-style: normal;
}
