.googlemap {
  width: 330px;
  height: 330px;
  margin-top: auto;
  flex: 0 0 auto;

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #e5e3df;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    color: #FF3C46;
    font-weight: $weight-03;
  }
}
