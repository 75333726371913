$colors: (
  green: #198038,
  grey: #525252,
  greySecondary: #a8a8a8,
  greyLight: #dfdfdf,
  greyLight2: #e8e8e8,
  greyFaded: #767676,
  greyHeader: #e3e3e3,
  orange: #f57423,
  darkOrange: #bd5a1c,
  yellow: #ffbe37,
  purple: #893067,
  purpleLight: rgba(#c94d8c, 0.06),
  purpleMid: #8e4076,
  purpleDark: #78396b,
  pinkLight: #f7eaf0,
  red: #da1e28,
  redFaded: #e94b60,
  blue: #44f,
  white: #fff
);



$global-primary-color: color-customisation(primaryColor, color(purple));
$global-primary-selected-background-color: color-customisation(primarySelectedBackgroundColor, #fdf8fb);
$global-primary-selected-background-color-alt: color-customisation(primarySelectedBackgroundColorAlt, #fae8f2);
$global-link-color: $global-primary-color;
$color-alert: color(orange);
$color-warning: color(yellow);
$color-danger: color(red);
$color-ok: color(green);
$color-workflow: color(blue);



$color-gray: #616161;
$report-border-color: #cacaca;

// primary and secondary typography
$primary-background-color: color(white);
$primary-text-color: #161616;

$secondary-text-color: #525252;

// Inputs
$input-border-color: #c7c7c7;
$primary-input-color: color(purple);

$focus-indicator-color: color(purpleLight);
$focus-indicator-color-light: color(white);

// primary button (purple one)
$primary-button-background: $primary-input-color;
$primary-button-foreground: color(white);
$primary-button-hover-background: #c94784;
$primary-button-active-background: #d95090;
$primary-button-disabled-background: #b68aa5;

// secondary button ("default" button)
$secondary-button-border: $input-border-color;
$secondary-button-background: #fdfdfd;
$secondary-button-foreground: $secondary-text-color;
$secondary-button-hover-background: #f4f4f4;
$secondary-button-pressed-background: #ebebeb;
$secondary-button-disabled-background: #f4f4f4;
$secondary-button-disabled-foreground: $secondary-button-border;

// tertiary button (danger button)
$tertiary-button-border: $secondary-button-border;
$tertiary-button-background: $secondary-button-background;
$tertiary-button-foreground: color(red);
$tertiary-button-hover-background: $secondary-button-hover-background;
$tertiary-button-pressed-background: $secondary-button-pressed-background;
$tertiary-button-disabled-background: $secondary-button-disabled-background;
$tertiary-button-disabled-foreground: $secondary-button-disabled-foreground;
$tertiary-text-color: color(red);

$note-border: #e6db95;
$note-background: #ffffe6;

// Review buttons and dialogs
$review-risk: color(red);
$review-no-risk: color(green);
$unreviewed: color(orange);

// percentage bar
$percentage-bar-background: #d8d8d8;
$percentage-bar-ok: #54c02f;
$percentage-bar-warning: $color-warning;
$percentage-bar-alert: $color-alert;
$percentage-bar-danger: #ef4f4f;

// Model  classifiers
$model-classifier-color-primary: color(purple);
$model-classifier-color-secondary: #0f62fe;

// AMDL Directory Icons
$amdl-directory: $secondary-text-color;
$amdl-feature: #1b998b;
$amdl-global: #c20843;
$amdl-list: color(darkOrange);
$amdl-rule: #036da6;
$amdl-state: #916b1d;
$amdl-unknown: $primary-text-color;
$amdl-value: #9f427d;
$amdl-var: #512142;

// Incident details highlighted activity
$activity-highlight-color: #fefd2b8f;
$activity-current-highlight-color: #febb2cb8;

// Notifications
$notification-success: color(green);
$notification-warning: color(yellow);
$notification-error: color(red);

// Panel
$panel-header-background-color: color(greyHeader);

$colorsButton: (
  standard: $primary-text-color,
  standard-border: $secondary-button-border,
  standard-hover-background: linear-gradient(-180deg, #F5F5F5 0%,#EBEBEB 100%),
  standard-pressed-background: #DEDEDE,
);

// shared dimensions
$sidebar-width: 280px;
$sidebar-sm-width: 60px;
$banner-height: 50px;
$banner-height-expanded: 136px;
$message-broadcast-height: 220px;
$take-control-bar-height: 49px;
$environment-title-bar-height: 40px;
$ace-diff-gutter-width: 60px;

// global stacking context (lowest to highest)
$stack:
  main-container,
  sticky-panel-header,
  message-tooltip,
  docs-panel,
  sidebar-incident-details,
  sidebar-right-backdrop,
  incident-details-activity-grid,
  sidebar-incident-details-review,
  sidebar-right,
  communications,
  modal,
  date-input-polyfill;

// DESIGN SYSTEM

// Colour
$ui-background: #fff;
$ui-01: #f4f4f4;        // Primary container background, Secondary page background.
$ui-02: #fff;           // Primary page background, Secondary container background.
$ui-03: #e3e3e3;        // border and tertiary background.
$ui-04: #8d8d8d;        // Medium contrast border.
$ui-05: #171717;        // High contrast border, Emphasis elements.
$ui-overlay: #171717;   // Background colour for overlay.

$text-inverse: #000;    // Text on interactive colours.

$status-error: #b00020;
$status-warning: #fdd13a;
$status-success: #25be52;
$status-information: #054ada;

$interactive-01: #ff0052;
$interactive-02: #6f6f6f;

$link-01: #ff0052;
