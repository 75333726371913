$error-color: color(redFaded);
$input-color: $secondary-text-color;
$input-border-radius: 3px;
$input-placeholder-color: #767676;
$input-background-color: $primary-background-color;
$input-disabled-background-color: #efefef;
$input-disabled-border-color: $input-border-color;
$input-focus-background-color: $global-primary-selected-background-color;
$input-focus-border-color: $global-primary-color;
$input-focus-shadow-color: $global-primary-selected-background-color;
$input-hover-border-color: #aaa;
$input-search-icon-color: #ccc;
$input-select-color: color(grey);
$input-checkbox-background-color: #dcdcdc;
$input-checkbox-background-color-active: color-customisation(primaryColor, $primary-input-color);
$input-textlike-height: 35px;

.form-input {
  position: relative;

  &__input--textlike,
  &__input--select {
    background-color: $input-background-color;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    box-sizing: border-box;
    display: block;
    font-size: 13px;
    line-height: 21px;
    padding: 6px 8px;
    width: 100%;
    color: $input-color;

    @include input-placeholder {
      color: $input-placeholder-color;
    }

    .form-input:not(.form-input--invalid) &:hover {
      border-color: $input-hover-border-color;
    }

    .form-input:not(.form-input--invalid) &:focus {
      border: 1px solid $input-focus-border-color;
    }

    &:focus {
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      outline: none;
    }

    &[readonly],
    &[disabled],
    fieldset[disabled] & {
      background-color: $input-disabled-background-color;
      border-color: $input-disabled-border-color;
    }
  }

  &__input--highlight {
    background-color: color(purpleLight);
  }

  &__input--textarea {
    min-height: $input-textlike-height;
    resize: vertical;
  }

  &--invalid {
    input,
    select,
    textarea {
      border-color: $error-color;
      padding-right: 18px;
    }
  }

  select {
    @include appearance(none);

    cursor: pointer;
    padding-right: 20px;
  }

  &__validation-icon {
    bottom: 11px;
    height: 13px;
    line-height: 13px;
    pointer-events: none;
    position: absolute;
    right: 9px;

    .icon {
      fill: $error-color;
      height: 13px;
      width: 7px;
    }
  }

  &--checkbox {
    display: flex;

    .checkbox {
      flex: 1;
      min-width: 0;
    }

    .checkbox__label-text {
      @include ellipsis;
      display: block;
      min-width: 0;
      flex: 1 1 0%;
      font-size: 14px;
    }
  }

  &--checkboxes,
  &--radios {
    .form-input__list {
      display: flex;
      flex-flow: row wrap;
    }

    .checkbox,
    .radio {
      display: inline-block;
      margin-right: 15px;
    }

    .checkbox + .checkbox,
    .radio + .radio {
      margin-left: 15px;
    }
  }

  &--radios-block-layout {
    .radio,
    .radio + .radio {
      display: block;
      margin: 0 0 6px;
    }
  }

  &--checkbox-list {
    .form-input__list {
      display: flex;
      flex-flow: row wrap;
    }

    .checkbox {
      width: 50%;
      margin-bottom: 15px;
      padding-right: 6px;
    }

    .checkbox__label-text {
      @include ellipsis;
    }

    .form-input__action {
      position: absolute;
      top: -24px;
      right: 0;
      width: 50px;

      .checkbox {
        width: 100%;
        margin: 0;
      }
    }

    .checkbox__label-wrapper {
      display: flex;
      flex-direction: column;

      span {
        width: 140px;
      }
    }
  }

  &__datetime-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  &--date {
    .form-input__input {
      &::-webkit-clear-button,
      &::-webkit-inner-spin-button {
        display: none;
      }
    }
  }

  &--datetime {
    display: flex !important;

    .form-input__input {
      &::-webkit-clear-button,
      &::-webkit-inner-spin-button {
        display: none;
      }
    }

    .form-input__input--date {
      flex: 6;
    }

    .form-input__input--time {
      flex: 4;
      margin-left: 10px;
    }
  }

  &--datetime-range {
    display: flex;
    flex-flow: row wrap;

    .form-input__part {
      width: 40%;
      flex: 1 1 auto;
      display: flex;
      flex-flow: row wrap;
    }

    .form-input__part + .form-input__part {
      margin-left: 10px;
    }

    .form-input__input {
      &--date {
        flex: 1 1 auto;
        width: 60%;
        margin-right: 5px;
      }

      &--time {
        flex: 1 1 auto;
        width: 33%;
      }

      &::-webkit-clear-button,
      &::-webkit-inner-spin-button {
        display: none;
      }
    }

    &.form-input--invalid input {
      padding-right: 8px;
    }
  }

  &--select {
    position: relative;

    select {
      color: $secondary-text-color;
    }

    .icon-sort {
      bottom: 11px;
      fill: $input-select-color;
      height: 13px;
      pointer-events: none;
      position: absolute;
      right: 6px;
      width: 12px;
    }

    &.form-input--invalid {
      select {
        padding-right: 38px;
      }

      .icon-sort {
        right: 24px;
      }
    }
  }

  &--search {
    display: inline-block;
    position: relative;
    vertical-align: top;

    input {
      @include font-secondary;
      border-radius: 0;
      font-weight: $weight-03;
      height: 30px;
      padding: 0 24px 0 29px;
      font-size: 13px;
    }

    .icon {
      display: flex;
      height: 28px;
      width: 12px;
      fill: #707070;
    }

    .icon-search {
      top: 1px;
      left: 10px;
      pointer-events: none;
      position: absolute;
      fill: $input-search-icon-color;
    }

    .clear-button {
      background: transparent;
      border: 0;
      top: 1px;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 6px;
    }
  }

  &--textarea {
    .form-input__validation-icon {
      bottom: auto;
      top: 11px;
    }
  }

  &--unit {
    display: flex;

    .form-input__unit-value {
      position: relative;
      flex: 2;
    }

    .form-input__unit-units {
      flex: 1;
      margin-left: 5px;
    }

    .icon-sort {
      bottom: 11px;
      fill: $input-select-color;
      height: 13px;
      pointer-events: none;
      position: absolute;
      right: 6px;
      width: 12px;
    }
  }

  &__placeholder-option {
    display: none;
  }

  @media screen and (max-width: 992px) {
    display: block;

    &--checkbox {
      display: flex;
    }
  }

  &--key-value-pairs {
    .form-input__key-value-pairs-row {
      display: flex;
      margin-bottom: 8px;

      &__input-container {
        position: relative;
        width: 50%;
        margin-right: 10px;
      }
    }

    .form-input__key-value-pairs-remove-row-button {
      width: 35px;
      height: 35px;
      flex: 0 0 auto;

      .icon {
        position: static;
        width: 13px;
        height: 33px;
      }
    }

    .form-input__key-value-pairs-add-row-button {
      margin-top: 6px;
    }

    .form-input__input {
      margin-right: 10px;

      &.is-invalid {
        border-color: $error-color;
        padding-right: 18px;

        &:hover {
          border-color: $error-color;
        }
      }
    }
  }
}

// these styles apply to `Component.FormField.File` and `FormInput.FileMultiPart`
.form-input--file {
  &.form-input--file-invalid {
    .form-input__file-label {
      border-color: $error-color !important;
    }

    .form-input__file-name {
      padding-right: 18px;
    }
  }

  .form-input__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;

    &:focus ~ .form-input__file-label {
      border-color: $input-focus-border-color;
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      outline: none;
    }
  }

  .form-input__file-label {
    display: flex;
    width: 100%;
    height: 36px;
    line-height: 21px;
    overflow: hidden;
    color: #727272;
    border: 1px solid #c7c7c7;
    border-radius: 3px;

    &:hover {
      border-color: #aaa;
      cursor: pointer;
    }
  }

  .form-input__file-name {
    @include ellipsis;
    position: relative;
    flex: 1;
    padding: 6px 8px;
  }

  .form-input__file-button {
    @include action-default;
    display: flex;
    padding: 6px 20px;
    align-items: center;
    font-weight: $weight-03;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-style: solid;
    border-width: 0 0 0 1px;

    .icon {
      fill: currentColor;
      margin-right: 8px;
    }
  }
}

.form-input--file-button-only {
  .form-input__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;

    &:focus ~ .form-input__file-button-only-label {
      border-color: $input-focus-border-color;
      box-shadow: 0 0 5px 0 $input-focus-shadow-color;
      background-color: $input-focus-background-color;
      outline: none;
    }
  }

  .form-input__file-button-only-label {
    @include action-default;
    display: flex;
    padding: 0 10px;
    align-items: center;
    font-weight: 600;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #727272;
    border: 1px solid #c7c7c7;
    border-radius: 3px;

    &:hover {
      border-color: #aaa;
      cursor: pointer;
    }

    .icon {
      fill: currentColor;
      margin-right: 8px;
    }
  }
}

.checkbox,
.radio {
  box-sizing: border-box;

  &__label {
    display: flex;
    align-items: center;
    min-width: 0; // fix flex ellipsis

    &:hover,
    &:focus {
      .checkbox__input:not([disabled]) + .checkbox__label-icon,
      .radio__input:not([disabled]) + .radio__label-icon {
        border-color: $input-checkbox-background-color-active;
      }
    }
  }

  &__label-text {
    @include user-select(none);

    margin-left: 8px;
    color: $secondary-text-color;
    font-size: 13px;
    line-height: 18px;
  }

  &__label-icon {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    padding: 1px;
    background-color: $input-checkbox-background-color;
    border-radius: 4px;
    border: 1px solid $secondary-text-color;
    overflow: hidden;

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  &__input {
    @include visually-hidden;
    // Fixes ARIC-22855
    position: static !important;
    opacity: 0 !important;
    margin: -1px 0 1px -1px !important;
  }

  &__input:focus {
    + .checkbox__label-icon,
    + .radio__label-icon {
      box-shadow: 0 0 5px 1px rgba(201, 77, 139, 0.47);
      border-color: $input-checkbox-background-color-active;
    }
  }

  @mixin active-checkbox-icon {
    background-color: $input-checkbox-background-color-active;
    border-color: transparent;
  }

  &__input[checked],
  &__input[halfChecked], // halfChecked only applies to checkbox
  &--use-checked-state .checkbox__input:checked,
  &--use-checked-state .radio__input:checked {
    + .checkbox__label-icon,
    + .radio__label-icon {
      @include active-checkbox-icon;
    }
  }

  .checkbox__label-icon {
    &[data-checked-state="checked"],
    &[data-checked-state="half-checked"] {
      @include active-checkbox-icon;
    }
  }

  &__input[disabled],
  fieldset[disabled] &__input {
    + .checkbox__label-icon,
    + .radio__label-icon {
      opacity: 0.5;
    }
  }
}

.checkbox {
  &__secondary-label {
    min-width: 0; // fix flex ellipsis
    margin-left: 8px;
    color: color(grey);
  }
}

.radio {
  &__label-icon {
    border-radius: 100%;
  }
}
