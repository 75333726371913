// This is a list of variables for spacing. Spacing values should only be used when making components.

$spacing-01: 2px;
$spacing-02: 4px;
$spacing-03: 8px;
$spacing-04: 12px;
$spacing-05: 16px;
$spacing-06: 24px;
$spacing-07: 32px;
$spacing-08: 40px;
$spacing-09: 48px;

// This is a list of variables for Layouts. Layout values should only be used to layout components and other elements that are not components on a page.

$layout-01: 4px;
$layout-02: 8px;
$layout-03: 16px;
$layout-04: 24px;
$layout-05: 32px;
$layout-06: 48px;
$layout-07: 64px;
$layout-08: 80px;
