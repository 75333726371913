.overview-report {
  &__loading,
  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .icon {
      fill: currentColor;
    }
  }

  &__loading {
    .icon {
      font-size: 18px;
    }
  }

  &__error {
    color: $color-danger;
    font-size: 16px;

    .icon {
      margin-right: 6px;
    }
  }

  &__blocks {
    display: flex;
    flex-flow: row wrap;
    margin-top: 8px;
  }

  &__block {
    display: flex;
    align-items: center;
    padding: 0 10px;
    flex: 2;

    &--title {
      flex: 1.5;
    }
  }

  &__block-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    margin-right: 8px;
    background: $global-primary-color;
    border-radius: 100%;
  }

  &__block-value {
    display: block;
    font-size: 24px;
    font-weight: $weight-04;
  }

  &__block-label {
    display: block;
    color: color(grey);
    font-weight: $weight-04;
  }

  &__date-range-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-weight: $weight-04;
    font-size: 16px;
    text-align: center;
  }
}
