.user-display-name {
  line-height: 1.1;

  &--truncate-fluid {
    // required for ellipsis
    min-width: 100%;

    .user-display-name__text {
      @include ellipsis;
    }
  }

  &__text {
    display: inline;
  }

  &__icon {
    display: inline-block;
    vertical-align: baseline;
    margin: 0 4px 0 1px;

    .icon {
      display: block;
      fill: currentColor;
      width: auto;
      height: 0.9em;
    }
  }
}
