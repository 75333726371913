$table-border-color: #ddd;
$table-error-color: $tertiary-text-color;
$table-header-color: $primary-text-color;
$table-header-border-size: 2px;
$table-cell-text-color: $primary-text-color;
$table-cell-link-color: $global-link-color;
$table-cell-padding: 8px 8px 9px;
$table-cell-background-color: $primary-background-color;
$table-cell-background-color-stripe: $ui-01;
$table-cell-background-color-highlighted: $global-primary-selected-background-color;
$table-cell-background-color-highlighted-alt: $global-primary-selected-background-color-alt;

.table {
  overflow: auto;

  &--sticky-headers {
    .table__header {
      // reset the default border so it can be applied to the `<th>`.
      border-bottom: 0;
    }

    // `position: sticky` doesn't work on `<thead>` or `<tr>`, only `<th>`.
    // https://css-tricks.com/position-sticky-and-table-headers
    .table__header-item {
      position: sticky;
      top: 0;
      z-index: 1;
      background: $table-cell-background-color;
      // `border` wont show up when the table headers are floating. Box shadow is used to emulate it instead.
      box-shadow: inset 0 -(#{$table-header-border-size}) 0 0 $table-border-color;
    }
  }

  table {
    width: 100%;

    .dataservice-compound,
    .dataservice-expandable-title {
      width: initial;
    }
  }

  &__tooltip-icon {
    .icon {
      margin: 0 0 0 5px;
      fill: color(grey);
    }
  }

  &__text-tooltip-wrapper {
    border-bottom: 1px dashed #d0cdcd;
  }

  &__header {
    border-bottom: $table-header-border-size solid $table-border-color;

    .icon {
      height: 14px;
      width: 14px;
      vertical-align: top;
      fill: currentColor;
    }
  }

  &__header-expand-button {
    @include reset-button;
    cursor: pointer;

    &:focus {
      outline: 1px solid $global-primary-color;
      outline-offset: 2px;
    }
  }

  &__header-sort-button {
    @include reset-button;
    cursor: pointer;

    font: inherit;
    display: flex;
    align-items: center;

    .icon {
      margin-left: 4px;
    }

    &:focus {
      outline: 1px solid $global-primary-color;
      outline-offset: 2px;
    }
  }

  &__header-item {
    @include user-select(none);

    color: $table-header-color;
    padding: 8px;
    text-align: left;
    white-space: nowrap;

    &--dataservice-float,
    &--dataservice-integer,
    &--dataservice-percentage {
      text-align: right;
    }

    &--tooltip {
      white-space: inherit;

      i {
        margin-left: 5px;
      }
    }

    & + & {
      padding-left: 0;
    }

    &--expand {
      text-align: center;
      width: 28px;

      .table__header-row-expand-button {
        display: inline-block;
        vertical-align: top;
      }

      .icon {
        fill: $primary-text-color;
        height: 18px;
        pointer-events: none;
        width: 12px;
      }
    }

    &--select {
      box-sizing: content-box;
      width: 47px;

      .action {
        .icon-square {
          fill: #d8d8d8;
        }

        .icon-check-square,
        .icon-minus-square {
          fill: $input-checkbox-background-color-active;
        }

        .icon-angle-down {
          margin-right: 0;
        }
      }

      &-checkbox-button {
        cursor: pointer;
        vertical-align: top;
        background: none;
        border: 0;
        padding: 0 4px;
        margin-right: 0;

        .icon {
          margin-right: 0 !important;
        }

        &:focus {
          outline: 1px solid $global-primary-color;
        }
      }

      &-all-dropdown-button {
        cursor: pointer;
        vertical-align: top;
        background: none;
        border: 0;
        padding: 0 4px;

        &:focus {
          outline: 1px solid $global-primary-color;
        }

        .icon {
          height: auto !important;
        }
      }

      &-container {
        cursor: default;
        display: flex;
      }
    }

    &--select-without-pagination {
      box-sizing: content-box;
      width: 47px;

      .checkbox {
        margin-left: 6px;
      }

      .checkbox__label-icon .icon {
        width: 100%;
        height: 100%;
        fill: #fff;
      }

      .checkbox__label {
        margin: 0;
      }
    }

    &--spannable {
      text-align: center;
    }

    &--right {
      text-align: right;

      .table__header-item-alignment-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &__row,
  &__sub-row {
    &:nth-child(odd) {
      border-color: $table-cell-background-color-stripe;
    }

    &:focus {
      outline: 1px solid $global-primary-color;
      outline-offset: -1px;
    }

    &--expandable {
      cursor: pointer;
    }

    &.is-highlighted {
      .table__row--expandable .table__cell {
        border-bottom: 0;
      }
    }

    &.is-editing {
      vertical-align: top;
      border-top: 1px solid rgba(color(purple), 0.2);
      border-bottom: 1px solid rgba(color(purple), 0.2);
    }

    &.has-error {
      outline: 1px solid $table-error-color;
    }
  }

  &__row {
    &--total {
      font-weight: $weight-03;
    }

    &:nth-child(odd) {
      background-color: $table-cell-background-color-stripe;
    }
  }

  &__cell {
    border-bottom: 1px solid $table-border-color;
    line-height: 19px;
    padding: $table-cell-padding;
    color: $table-cell-text-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--with-link-button {
      button {
        padding-right: 5px;
      }
    }

    & + & {
      padding-left: 2px;
    }

    & + &.table__cell--action {
      padding-left: 3px;
    }

    &--not-asked,
    &--loading,
    &--error,
    &--no-matches {
      text-align: center;
    }

    &--error {
      color: $table-error-color;
      white-space: normal;

      .icon {
        fill: $table-error-color;
        height: 19px;
        width: 19px;
        margin-right: 3px;
        vertical-align: bottom;
      }
    }

    &--action {
      width: 1px;
      padding-top: 4px;
      padding-bottom: 4px;
      overflow: visible;
    }

    &--align-top {
      vertical-align: top;
    }

    &--text-truncate {
      @include ellipsis;
      max-width: 0;
    }

    &--text-wrap {
      white-space: normal;
      word-break: break-word;
    }

    &--html {
      overflow: visible;
    }

    &--html-title {
      @include ellipsis;
      max-width: 300px;
    }

    &--expand {
      text-align: center;
      vertical-align: middle;

      .icon {
        display: block;
      }

      .table__cell-expand-button {
        @include reset-button;
        display: block;
        cursor: pointer;

        &:focus {
          outline: 1px solid $action-default-active-background-color;
          outline-offset: -1px;
        }
      }

      .icon {
        fill: $secondary-text-color;
        height: 18px;
        pointer-events: none;
        width: 12px;
      }
    }

    &--external-link {
      .icon {
        fill: $table-cell-link-color;
        height: 13px;
        width: 17px;
      }
    }

    &--dataservice-float,
    &--dataservice-integer,
    &--dataservice-percentage {
      text-align: right;
    }

    &--dataservice-entity,
    &--dataservice-hyperlink,
    &--dataservice-query,
    &--external-link,
    &--link {
      a, .link-button {
        color: $table-cell-link-color;
        font-weight: $weight-03;
      }
    }

    &--link {
      color: $table-cell-link-color;
      font-weight: $weight-03;
    }

    &--dataservice-compound {
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;

      td {
        padding-left: 0;
      }
    }

    &--checkbox {
      .icon {
        fill: $primary-text-color;
        height: 19px;
        vertical-align: top;
        width: 13px;
      }
    }

    &--right {
      text-align: right;
    }

    &--select {
      box-sizing: content-box;
      width: 47px;

      .checkbox__label {
        margin: 0 0 0 6px;
        width: 16px;
        cursor: pointer;
      }
    }

    &--html-icon {
      fill: $primary-text-color;
      --primary-color: $primary-text-color;

      .icon {
        height: 19px;
        width: 13px;
        vertical-align: top;
      }
    }

    .json-box {
      background: #fff;
      border: 1px solid #c6c6c6;
      padding: 5px;
      overflow: auto;

      &-title {
        margin: 0;
        font-size: 1em;
      }

      &-warning {
        @include ui-note;
        margin: 4px 0;
        padding: 4px;
      }
    }
  }

  &__cell-tooltip {
    overflow: visible;
  }

  &__tag {
    color: $secondary-text-color;
    padding: 0 4px;
    font-weight: $weight-03;
    font-size: 13px;
    border: 1px solid $secondary-text-color;
    border-radius: 3px;
  }

  .action {
    &, & .icon {
      line-height: 25px;
      height: 25px;
    }
  }

  &__inline-edit-fields {
    &--cols {
      display: flex;

      .table__inline-edit-field {
        flex-flow: column wrap;
        align-items: stretch;
        margin-bottom: 0;
        white-space: nowrap;
      }

      .table__inline-edit-field + .table__inline-edit-field {
        margin-left: 8px;
      }

      .table__inline-edit-label,
      .table__inline-edit-input {
        display: block;
        flex: 1 1 auto;
      }

      .table__inline-edit-label {
        margin-bottom: 4px;
      }
    }

    &--rows {
      .form-input--invalid {
        .form-input__input--textlike {
          border-bottom: 0;
          border-radius: 3px 3px 0 0;
        }
      }
    }
  }

  &__inline-edit-field {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    white-space: normal;

    &--has-error {
      .table__inline-edit-label {
        color: color(red);
      }
    }
  }

  &__inline-edit-label {
    display: inline-block;
    flex: 0 0 30%;
    padding-right: 12px;
    color: $table-header-color;
    font-weight: $weight-04;
  }

  &__inline-edit-input-wrap {
    display: inline-block;
    flex: 0 1 60%;
  }

  &__inline-edit-errors {
    display: block;
    padding: 0 4px;
    color: color(red);
    background-color: #FFECE7;
    border: 1px solid color(red);
    border-top: 0;
    border-radius: 0 0 3px 3px;
    font-size: 13px;
  }

  &__dl {
    display: grid;
    grid-template-columns: max-content auto;
    margin: 10px;
    white-space: normal;
    word-break: break-all;

    dt,
    dd {
      padding-top: 6px;
      padding-bottom: 6px;
      border-bottom: 1px solid #d0d0d0;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    dt {
      font-weight: $weight-04;
      padding-right: 30px;
    }

    dd {
      margin-left: 0;
    }
  }

  &__reorder-buttons {
    .action {
      width: 25px;
    }

    .action:not(:first-child) {
      margin-left: 6px;
    }
  }
}

// Not a `thead`!
// Used outside the table for related content like perpage/search/actions etc.
// Example:
// ```html
// <header class="table-header-wrap">
//     <div class="table-per-page">...</div>
//
//     <div class="action-group">
//       <button>...</button>
//       <button>...</button>
//     </div>
// </header>
//
// <div class="table">
//   <table>...</table>
// </div>
// ```
.table-header-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &--right {
    justify-content: flex-end;
  }
}
