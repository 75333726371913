$error-color: $tertiary-text-color;
$hint-color: $secondary-text-color;

.form-field {
  display: block;
  position: relative;
  margin-bottom: 25px;

  &--no-label {
    .form-field__label-wrap {
      padding-bottom: 0;
    }

    .form-field__label {
      width: 100%;
      overflow: visible;
    }
  }

  &:fullscreen {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    background-color: $primary-background-color;

    .form-field__label-wrap {
      flex: 0 0 auto;
      width: 100%;
    }

    .form-field__form-input-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  &__label-wrap {
    @include user-select(none);

    color: $secondary-text-color;
    display: flex;
    flex: 1 1 230px;
    align-items: flex-start;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 8px;

    // fixes text ellipsis on the label
    min-width: 0;
  }

  &__label {
    display: inline-block;
    @include ellipsis();

    &--highlight {
      color: $global-primary-color;
      font-weight: $weight-04;
    }
  }

  &__label-wrapper--wrap > &__label {
    white-space: normal;
  }

  &__is-required-icon {
    padding-left: 2px;

    .icon {
      fill: $global-primary-color;
      vertical-align: top;
      height: 20px;
      width: 7px;
    }
  }

  &__validation-message {
    color: $error-color;
    font-weight: $weight-02;
  }

  &__form-input-wrapper {
    flex: 0 0 460px;
    // fixes text ellipsis on the label
    min-width: 0;
  }

  &__tooltip {
    position: relative;
    top: 2px;
    margin-left: 4px;
    color: $primary-text-color;
    font-size: 13px;
    line-height: 1.3;
  }

  &__tooltip-icon {
    .icon {
      width: 14px;
      height: 14px;
      fill: $hint-color;
    }
  }

  &__hint {
    @include user-select(none);

    color: $hint-color;
    display: inline-block;
    margin-top: 5px;
  }

  &__hint-html {
    @include user-select(none);

    margin-top: 5px;
    color: $hint-color;
    line-height: 1.3;

    .bold {
      font-weight: $weight-03;
    }
  }

  &__toggle-fullscreen-button {
    margin-left: auto;

    > * {
      // needed to prevent the click triggering on the child nodes as the event handler is done in JavaScript.
      pointer-events: none;
    }
  }

  &--configuration-field {
    flex-direction: column;

    .form__section--block {
      margin-bottom: 30px;
      position: relative;
    }

    &-type-sub-field {
      margin-left: 20px;
    }

    .form__section--block-close {
      height: 28px;
      width: 28px;
      position: absolute;
      top: -14px;
      right: -14px;
      background-image: linear-gradient(-180deg, #fdfdfd 0%, #f7f7f7 100%);
      border: 1px solid #c7c7c7;
      border-radius: 100px;
    }

    .icon-close {
      height: 15px;
      width: 15px;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }

  &--list-values-field {
    display: block;

    &-tag {
      display: flex;
      width: 100%;
      margin-bottom: 5px;
      background-color: #e7e7e7;
      border-radius: 5px;
      box-sizing: border-box;
      font-weight: $weight-03;
      font-size: 13px;
      padding: 10px 12px 6px 8px;
      height: 35px;
    }

    &-tag-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $primary-text-color;
      white-space: nowrap;
    }

    &-tag-remove {
      border: 0;
      width: 0;
      color: $input-select-color;

      .icon {
        padding-bottom: 14px;
      }
    }

    &-input {
      margin-bottom: 10px;
    }

    &-content {
      flex: 0 0 460px;
      margin-left: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    .form-field__list-values-add {
      margin-top: 10px;
    }
  }

  &--column-headers {
    display: block;

    &-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }

    &-tr {
      display: flex;

      & + .form-field--column-headers-tr {
        padding-top: 8px;
      }
    }

    .form-field--column-headers-tr {
      &.is-hidden {
        display: none;
      }
    }

    &-thead {
      color: #727272;
      font-weight: $weight-03;
      padding-bottom: 10px;
    }

    &-td,
    &-th {
      flex: 1;

      & + .form-field--column-headers-td,
      & + .form-field--column-headers-th {
        padding-left: 8px;
      }
    }

    &-td-hidden {
      visibility: hidden;
    }

    &-td-width,
    &-th-width {
      flex: 0 0 90px;

      .form-input--number {
        &::after {
          content: "px";
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 35px;
          line-height: 35px;
          color: $secondary-text-color;
          text-align: center;
        }
      }

      .form-input__input {
        padding-right: 30px;
      }
    }

    &-td-sorting,
    &-th-sorting {
      flex: 0 0 90px;
    }

    &-td-sorting {
      display: inline-flex;

      .action {
        height: 35px;
        width: 35px;

        .icon {
          width: 16px;
        }
      }

      .right-button button {
        border-radius: 0 3px 3px 0;
        border-left: 0;
      }

      .left-button button {
        border-radius: 3px 0 0 3px;
      }

      .selected button {
        color: #fff;
        background-image: linear-gradient(#db5694, #c94784);
        border-width: 0;
      }
    }

    &-td-reorder,
    &-th-reorder {
      flex: 0 0 90px;
    }

    &-td-reorder {
      display: inline-flex;

      .action {
        height: 35px;
        width: 35px;
      }

      .action + .action {
        margin-left: 6px;
      }
    }

    &-td-visible,
    &-th-visible {
      flex: 0 0 70px;
    }

    &-th-visible {
      text-align: center;
    }

    &-td-visible {
      align-items: center;
      display: flex;
      justify-content: center;

      .checkbox__label {
        margin: 0;
      }
    }

    &-td-delete,
    &-th-delete {
      flex: 0 0 43px;

      .action {
        height: 35px;
        width: 35px;

        .icon {
          height: 33px;
          width: 13px;
        }
      }
    }
  }

  &--ace-editor {
    .juicy-ace-editor-wrapper {
      min-height: 250px;
    }

    &:fullscreen {
      .form-input--ace-editor {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
      }
    }

    &:not(:fullscreen) {
      .juicy-ace-editor-wrapper {
        height: 250px;
      }
    }

    .form-field__label-wrap {
      display: flex;
      align-items: center;
    }

    .form-input--invalid {
      .juicy-ace-editor-wrapper {
        border-color: $error-color;
      }
    }
  }

  &--group-list {
    @at-root {
      .form-field {
        &__group-list-validation-message {
          @include font-secondary(400);
          font-size: 13px;
        }
      }
    }
  }

  &--group-list-inline {
    $input-height: 35px;
    $field-label-height: 28px;

    .form-field__group-list-item {
      display: flex;
    }

    .form-field__group-list-item-fields {
      display: flex;
      flex: 0 1 auto;
      margin-right: 8px;

      > .form-field {
        margin-bottom: 0;

        &:not(:first-child) {
          margin-left: 6px;
        }
      }
    }

    .form__section {
      display: flex;
    }

    .form__section + .form__section {
      border: 0;
      padding-top: 16px;
    }

    .form-field__label {
      white-space: nowrap;
    }

    .form__section-remove-button {
      position: relative;
      top: $field-label-height;
      right: initial;
      flex: 0 0 auto;
      border-radius: 0;
      align-self: flex-start;
      width: $input-height;
      height: $input-height;
      margin-left: auto;
    }
  }

  &--rules {
    &-match {
      margin-bottom: 10px;
    }

    &-rule {
      display: flex;
      margin-bottom: 10px;

      & > select {
        flex: 1;
        line-height: 23px;
      }

      & > .form-field {
        flex: 1;
        margin-bottom: 0;

        .form-field__label-wrap {
          display: none;
        }

        .form-input {
          input,
          select,
          .ff-multiselect__input-value {
            border-radius: 0;
          }
        }
      }

      & > .form-input__input--select {
        border-right: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        & + .form-input__input--select {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      &-remove {
        border-left: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        height: 35px;
      }
    }
  }

  &--radios-custom {
  }

  &__section-event-properties {
    .form-field {
      display: flex;
      flex: 1;

      &__label-wrap {
        padding-right: 20px;
      }

      &--list-values-field {
        display: flex;

        &-tag {
          max-width: 460px;
        }

        &-content {
          margin-left: 230px;
        }
      }
    }
  }

  &__section-event-single-property {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &-enabler {
      display: flex;
      flex: 0 0 auto;
      height: 30px;
      margin-right: 10px;
    }

    &-details {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      flex: 1;

      .form-field {
        margin-bottom: 0;
      }

      &--date,
      &--datetime {
        align-items: flex-start;
      }
    }

    &-label {
      display: block;
      color: #727272;
      font-size: 14px;
      line-height: 20px;
    }

    &-sub-properties {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin-bottom: 12px;

      &:not(:empty) {
        padding: 8px 0 8px 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & > & {
        padding-top: 0;
        padding-bottom: 0;
      }

      .form-field__section-event-single-property-sub-properties {
        .form-field__section-event-single-property {
          flex: 1 0;
        }

        &--array {
          .form-field__section-event-single-property {
            flex-basis: 100%;

            .form-field__section-event-single-property-details {
              margin-bottom: 10px;
            }
          }
        }

        &--object {
          & > .form-field__section-event-single-property {
            flex-basis: auto;
          }
        }
      }
    }

    &-add-item {
      flex: 1 0 auto;

      .action {
        width: 100%;
      }
    }

    &-remove-item {
      margin-left: auto;
    }

    &-array-item-title {
      color: color(grey);
      font-size: 14px;
    }

    &-date-field,
    &-datetime-field {
      display: flex;
      flex: 1 0 100%;
    }

    &-validation-message {
      color: $error-color;
      font-weight: $weight-02;
    }
  }
}

.form-field__section-event-single-property-sub-properties {
  border-left: 1px solid #c94784;

  .property-details {
    color: #c94784;
  }

  .form-field__section-event-single-property-sub-properties {
    border-left-color: #b1709a;

    .property-details {
      color: #b1709a;
    }

    .form-field__section-event-single-property-sub-properties {
      border-left-color: #9998b1;

      .property-details {
        color: #9998b1;
      }

      .form-field__section-event-single-property-sub-properties {
        border-left-color: #81c1c7;

        .property-details {
          color: #81c1c7;
        }
      }
    }
  }
}

.form__message {
  margin-bottom: 0;
}

.form {
  &--block {
    .form-field {
      display: block;
    }

    .form-field__label-wrap {
      flex: 1;
      max-width: none;
    }

    .form-field__tooltip {
      position: absolute;
      top: 16px;
      right: 0;
      left: auto;
    }
  }
}

.form {
  .form-field--inline-checkbox {
    display: flex;
    flex-flow: row-reverse wrap;

    .form-field__label-wrap {
      flex: 1;
      max-width: none;
      margin-left: 8px;
    }

    .form-field__form-input-wrapper {
      flex: 0 0 auto;
      display: flex;
    }
  }
}
