$color: #c7c7c7;

.table-filtering {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  &__per-page {
    display: flex;
    align-items: center;

    select {
      height: 28px;
      line-height: 16px;
    }

    .icon-sort {
      bottom: 7px;
    }

    label {
      color: color(greyFaded);
      font-weight: $weight-03;
      margin-left: 5px;
    }
  }

  &__search {
    input {
      height: 28px;
      line-height: 16px;
    }

    .icon-search {
      bottom: 7px;
    }

    .close-button {
      bottom: 3px;
    }
  }
}

.bulk-sidebar {
  position: absolute;
  display: none;
}
