.case-age {
  font-size: 13px;

  &--past {
    .case-age__value {
      color: $color-danger;
    }
  }

  &__value {
    margin-right: 6px;
    font-size: 16px;
  }

  &__max {
    margin-right: 2px;
    color: $secondary-text-color;
  }

  &__label {
    color: $secondary-text-color;
  }
}
