@import 'stylesheets/DSV2/Variables';

.dsv2-file {
  position: relative;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  max-width: 20rem;
  min-height: $dsv2-file-min-height;
  word-break: break-word;
  background-color: var(--cds-field-01, $cds-field-01);

  &.is-invalid {
    padding: 1rem 0;
    outline: 2px solid $color-danger;
    outline-offset: -2px;
  }

  &__filename {
    @include ellipsis;
    margin-left: 1rem;
  }

  &__progress {
    position: absolute;
    bottom: 0;
    width: 0;
    max-width: 100%;
    height: 2px;
    background: var(--cds-interactive-04, $cds-interactive-04);
    opacity: 0;
    visibility: hidden;

    transition:
      opacity 0.2s ease,
      visibility 0.2s ease,
      width 0.2s ease;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__status-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    padding-right: 1rem;
  }

  &__action {
    cursor: pointer;

    &:focus {
      outline-offset: 2px;
    }

    .icon {
      display: block;
    }
  }

  &__status {
    margin-right: 0.4rem;
  }

  &__status-error {
    display: block;

    .icon {
      display: block;
      fill: var(--cds-support-01, $cds-support-01);
    }
  }

  &__status-loading {
    display: block;

    .icon {
      display: block;
    }
  }

  &__status-success {
    display: block;

    .icon {
      display: block;
      fill: var(--cds-interactive-04, $cds-interactive-04);
    }
  }

  &__error-details {
    display: block;
    grid-column: 1/-1;
    max-height: none;
    margin: 0;
    padding: 1rem 1rem 0;
    border-top: 1px solid var(--cds-ui-03, $cds-ui-03);
  }

  &__error-details-subject {
    display: block;
    color: var(--cds-text-error, $cds-text-error);
  }

  &__error-details-body {
    display: block;
    margin-top: 2px;
  }
}
