.page-user-role-details {
  .table {
    color: $secondary-text-color;
  }

  .form-field {
    justify-content: center;
    margin: 25px 0;
  }

  &__permissions-section {
    .table__header-item {
      min-width: 100px;
      max-width: 100px;

      .checkbox__label-text {
        @include ellipsis;
      }

      &:first-of-type {
        min-width: 400px;
        max-width: none;
      }
    }

    table {
      .table__row--inactive {
        opacity: 0.5;
      }

      .table__cell {
        white-space: normal;

        &--indented {
          padding-left: 35px;
        }
      }

      .checkbox {
        &, &__label {
          margin: 0;
        }
      }
    }

    .table__row--inactive .table__cell {
      color: #b1b1b1;
    }

    .panel__body {
      margin-left: 13px;

      & > section {
        margin-bottom: 10px;
      }
    }
  }

  .permission-name {
    padding-bottom: 8px;
    color: $global-primary-color;
    font-weight: 600;
  }

  &__permissions-title {
    position: sticky;
    top: 25px;
    background: #fff;
    margin: 0;
    padding: 18px 0 18px 8px;
    font-weight: $weight-03;
    color: $global-primary-color;
    font-size: 18px;
    z-index: 1;
  }

  &__permission-item {
    > div {
      display: flex;
    }
  }

  &__user-role-section {
    .panel__header {
      display: flex;
      justify-content: space-between;
      z-index: index($stack, sticky-panel-header) + 1;
    }
  }
}
