.page-async-task {
  &__cell {
    //this is a better way of doing ellipsis than using the Elm String.Extra function because
    //it keeps the entirity of the text in the body, which is good for a11y.
    &-async-id {
      max-width: 259px;
      @include ellipsis;
    }

    &-async-executor-id {
      max-width: 405px;
      @include ellipsis;
    }

    &-async-status-running {
      max-width: 270px;
      @include ellipsis;
    }

    .icon {
      vertical-align: middle;
    }

    &-async-status-pending {
      .icon-circle {
        fill: $color-alert;
        margin-right: 5px;
      }
    }

    &-async-status-claimed {
      .icon-circle {
        fill: $color-gray;
        margin-right: 5px;
      }
    }

    &-async-status-in-progress {
      .icon-circle {
        fill: $color-ok;
        margin-right: 5px;
      }
    }

    &-async-status-stopping {
      .icon-circle {
        fill: $color-danger;
        margin-right: 5px;
      }
    }

    &-async-status-sleeping {
      .icon-circle {
        fill: $color-gray;
        margin-right: 5px;
      }
    }

    &-async-status-stopped {
      .icon-circle {
        fill: $color-danger;
        margin-right: 5px;
      }
    }

    &-async-status-cancelled {
      .icon-circle {
        fill: $color-danger;
        margin-right: 5px;
      }
    }

    &-async-status-dead {
      .icon-circle {
        fill: $color-alert;
        margin-right: 5px;
      }
    }

    &-async-status-failed {
      .icon-circle {
        fill: $color-danger;
        margin-right: 5px;
      }
    }

    &-async-status-completed {
      .icon-circle {
        fill: $color-ok;
        margin-right: 5px;
      }
    }

    &-async-status-unknown {
      .icon-circle {
        fill: $color-alert;
        margin-right: 5px;
      }
    }

    &-async-status-init {
      .icon-circle {
        fill: $color-warning;
        margin-right: 5px;
      }
    }

    &-async-status-idle {
      .icon-circle {
        fill: $color-gray;
        margin-right: 5px;
      }
    }

    &-async-status-blocked {
      .icon-circle {
        fill: $color-alert;
        margin-right: 5px;
      }
    }

    &-async-status-running {
      .icon-circle {
        fill: $color-ok;
        margin-right: 5px;
      }
    }

    &-async-status-paused {
      .icon-circle {
        fill: $color-danger;
        margin-right: 5px;
      }
    }

    &-async-status-scheduled {
      .icon-circle {
        fill: $color-ok;
        margin-right: 5px;
      }
    }

    &-async-status-suspended {
      .icon-circle {
        fill: $color-danger;
        margin-right: 5px;
      }
    }
  }
}
