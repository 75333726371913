@import 'node_modules/sass-kit/mixins/visually-hidden';

$percentage-background-color: color(greyLight);
$percentage-range-max-opacity: 0.3;

// :: (name color)
// List of themes
$themes: (
  alert $percentage-bar-alert,
  danger $percentage-bar-danger,
  ok $percentage-bar-ok,
  warning $percentage-bar-warning,
);

.percentage-bar {
  display: flex;

  &__bar {
    flex: 1 0 auto;
    display: block;
    position: relative;
    background: $percentage-background-color;
    border-radius: 4px;
    overflow: hidden; // clip to border radius
    z-index: 0;

    &__screenreader {
      @include visually-hidden();
    }
  }

  &__track {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    z-index: 1;
  }

  @each $name, $color in $themes {
    &__track--#{$name} {
      background-color: $color;
    }
  }

  &__label {
    width: 50px;
    text-align: right;
    color: $secondary-text-color;
    line-height: 1.6;
    font-weight: 700;
  }
}
