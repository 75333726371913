.page-user-details {
  &__user-panel {
    .panel__section-form-fields {
      margin-top: 20px;
      margin-bottom: 30px;
      padding-top: 0;
    }
  }

  &__account-lockout-banner {
    @include ui-note;
    margin-bottom: 20px;
  }

  &__account-lockout-tooltip {
    width: 200px;
    padding: 14px;

    &-message {
      @include font-secondary(700);
      display: block;
      margin-bottom: 1em;
      font-size: 14px;
      color: color(grey);
    }
  }
}
