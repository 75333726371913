.incident-eac {
  display: flex;
  flex-flow: column nowrap;
  width: 70vw;
  height: 70vh;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary-text-color;
    padding: 20px;

    .icon {
      width: 1em;
      height: 1em;
      fill: currentColor;
      font-size: 18px;
    }
  }

  &__error {
    color: $color-danger;
    padding: 20px;
    font-size: 14px;
    text-align: center;

    .icon {
      width: 1em;
      height: 1em;
      margin-right: 6px;
      font-size: 16px;
      fill: currentColor;
    }
  }

  &__error-detail {
    margin-top: 10px;
  }

  &__review-progress {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid color(greyLight);
    color: $secondary-text-color;
  }

  &__alert-review {
    display: flex;
    flex-flow: column nowrap;
    min-height: 0; // flex overflow fix
    flex: 1 1 auto;

    // Don't scroll the summary table ARIC-28218
    .alert-summary {
      flex-shrink: 0;
    }
  }

  &__review-table-actions {
    // position the bulk select above the table
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: flex-end;
    padding: 6px 0;

    > * + * {
      margin-left: 6px;
    }
  }

  &__review-table {
    display: flex;
    flex: 0 1 auto;
    min-height: 0; // flex overflow fix

    table {
      table-layout: fixed;
    }
  }

  &__tag-list-cell {
    white-space: normal;
  }

  &__add-to-data-list-field {
    // avoid clipping the checkbox input box shadow
    overflow: visible;

    .form-field {
      margin-bottom: 0;
    }

    // show in the table without labels
    .form-field__label-wrap {
      display: none;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding-top: 16px;
  }
}
