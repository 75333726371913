.globe-3d {
  $defaultColor: #000;
  $accessoryColor: #727272;
  $alertColor: #D81B60;
  $transactionColor: #595959;
  $highlightColor: #970032;
  $hoverColor: #FF0052;
  $background: #f4f4f4;

  $defaultColorDark: #fff;
  $accessoryColorDark: #d2d2d2;
  $alertColorDark: #D81B60;
  $transactionColorDark: #f2f2f2;
  $highlightColorDark: #970032;
  $backgroundDark: #262626;

  background: $background;
  position: relative;
  z-index: 1;

  .full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $background;
  }

  .hide {
    display: none;
  }

  height: 100%;
  border: 1px solid lighten($accessoryColor, 30%);

  &:focus {
    outline: 1px solid $global-primary-color;
  }

  h1, h2, h3, h4, .control, .control-rotation, .bar-headers ul, .range-slider, .range-slider::-webkit-slider-thumb {
    margin: 0;
    color: $defaultColor;
    text-transform: capitalize;
  }

  .actions {
    *:focus {
      outline: 1px solid $global-primary-color;
    }
  }

  .reset-selections {
    color: lighten($accessoryColor, 20%);
    cursor: default;

    &:focus {
      outline: none;
    }

    &.underline {
      color: $defaultColor;
      cursor: pointer;
    }

    &.underline:focus {
      outline: 1px solid $global-primary-color;
    }
  }

  .control-zoom:focus-within {
    outline: 1px solid $global-primary-color;
  }

  .info {
    color: $highlightColor;
  }

  .info-country {
    color: $defaultColor;
  }

  .info--selected {
    color: $highlightColor;
  }

  rect {
    fill: $transactionColor;
  }

  .bar-slider rect,
  .bar-alerts rect {
    fill: $alertColor;
  }

  .bar-slider .transaction rect {
    fill: $transactionColor;
  }

  .bar-slider .outline rect {
    fill-opacity: 0;
    stroke: #d6d6d6;
    opacity: 0.5;
  }

  .bar-slider .splitline {
    stroke: #000;
    stroke-width: 0.3;
    stroke-dasharray: 3 5;
  }

  .bar-slider .outline rect:hover,
  .bar-slider .outline rect.selected {
    stroke: $highlightColor;
    stroke-width: 2px;
    opacity: 1;
  }

  .axis text {
    fill: $defaultColor;
  }

  .axis line,
  .axis path {
    stroke: $defaultColor;
  }

  .bar-headers .active {
    border-bottom: 2px solid $highlightColor;
    text-decoration: none;
  }

  .bar-headers {
    *:focus {
      outline: 1px solid darken($highlightColor, 10%);
    }
  }

  .bar-countries rect.selected {
    fill: $highlightColor;
  }

  .bar-countries rect:hover {
    fill: $hoverColor;
  }

  .bar-text {
    color: $defaultColor;
  }

  .labels text {
    font-size: 13px;
  }

  .labels--transactions text,
  .axis--y-transactions-left text {
    fill: $transactionColor;
  }

  .axis--y-transactions-left path {
    stroke: $transactionColor;
  }

  .labels--alerts text,
  .axis--y-alerts text {
    fill: $alertColor;
  }

  .axis--y-alerts path {
    stroke: $alertColor;
  }

  .range-slider::-webkit-slider-thumb {
    background: $accessoryColor;
  }

  .range-slider {
    background: $accessoryColor;
  }

  .range-slider::-webkit-slider-thumb:hover {
    background: $accessoryColor;
  }

  .range-slider::-webkit-slider-thumb:active::-webkit-slider-thumb {
    background: $accessoryColor;
  }

  .dark-mode {
    background: $backgroundDark;
    color: #e0e0e0;

    h1, h2, h3, h4, .control, .control-rotation, .bar-headers ul, .range-slider, .range-slider::-webkit-slider-thumb {
      color: $defaultColorDark;
    }

    .info {
      color: lighten($highlightColorDark, 10%);
    }

    .info-country {
      color: $defaultColorDark;
    }

    .info--selected {
      color: lighten($highlightColorDark, 10%);
    }

    rect {
      fill: $transactionColorDark;
    }

    .bar-slider rect,
    .bar-alerts rect {
      fill: $alertColorDark;
    }

    .bar-slider .transaction rect {
      fill: $transactionColorDark;
    }

    .bar-slider .outline rect {
      stroke: #fff;
    }

    .bar-slider .splitline {
      stroke: $defaultColorDark;
    }

    .bar-slider .outline rect:hover,
    .bar-slider .outline rect.selected {
      stroke: $highlightColorDark;
    }

    .axis text {
      fill: $defaultColorDark;
    }

    .axis line,
    .axis path {
      stroke: $defaultColorDark;
    }

    .bar-headers .active {
      border-bottom: 2px solid $highlightColorDark;
    }

    .bar-headers {
      *:focus {
        outline: 1px solid darken($highlightColorDark, 10%);
      }
    }

    .bar-countries rect.selected {
      fill: $highlightColorDark;
    }

    .bar-countries rect:hover {
      fill: $highlightColorDark;
    }

    .labels--transactions text,
    .axis--y-transactions-left text {
      fill: $transactionColorDark;
    }

    .axis--y-transactions-left path {
      stroke: $transactionColorDark;
    }

    .labels--alerts text,
    .axis--y-alerts text {
      fill: $alertColorDark;
    }

    .axis--y-alerts path {
      stroke: $alertColorDark;
    }

    .bar-text {
      fill: $defaultColorDark;
    }
  }
}
