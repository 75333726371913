.default-dashboard {
  display: flex;
  flex-flow: column;
  flex: 1;

  &__logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
  }

  &__logo {
    position: relative;
    margin: 1em 0;
    padding-bottom: 20px;
    font-size: 40px;
    font-weight: $weight-03;
    text-align: center;
    word-break: break-word;

    &::before {
      @include brand-gradient-medium;
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      flex: 0 0 auto;
      width: 100%;
      height: 8px;
    }
  }
}
