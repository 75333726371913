.main-body {
  flex: 1;
  overflow: auto;
  padding: 15px;
  height: 100%;
  position: relative;
  // z-index: 0; removed in context to #ARIC-16061 and ARIC-16179

  &--angular {
    display: flex;

    .sidebar-placeholder, .sidebar {
      display: none;
    }
  }

  .main-container {
    height: 100%;
    padding: 0;

    .main {
      padding: 0;
    }
  }

  .panel {
    &:last-child {
      min-height: 100%;

      .panel-wrapper {
        margin-bottom: 0;
      }
    }
  }

  &--full-size {
    display: flex;
    flex-direction: column;

    .panel {
      &:last-child {
        min-height: 0;
      }
    }
  }

  &--center {
    display: flex;
    justify-content: center;
  }

  &--no-padding {
    padding: 0;
  }
}
