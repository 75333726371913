.page-data-list-items {
  $form-breakpoint: 992px;
  $table-border-color: #ccc;

  .table__row--expandable.is-highlighted {
    .table__cell {
      white-space: normal;
      overflow: visible;
      word-break: break-all;
      vertical-align: top;
    }
  }

  &__modal-content {
    width: 660px;
    max-width: 100%;
  }

  &__import-results {
    margin-bottom: 20px;
    word-break: break-word;

    &-item {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 12px;
      color: color(grey);
      font-size: 14px;

      .icon {
        display: block;
        margin-right: 4px;
        font-size: 16px;
      }

      &--ok .icon { fill: $color-ok; }
      &--warning .icon { fill: $color-warning; }
    }

    &-extra {
      display: block;
      width: 100%;
      margin: 4px 0 0 20px;
      font-size: 13px;
    }
  }

  &__batch-actions {
    display: flex;
    flex-flow: column nowrap;
    padding: 14px;

    .action + .action {
      margin-top: 10px;
    }
  }

  &__batch-delete-modal {
    .form-field__hint {
      word-break: break-all;
    }
  }

  &__delete-warning {
    @include ui-note;
  }
}


.dli-filterable-search {
  display: flex;

  &__filter-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    background: color(greySecondary);
    fill: #fff;
  }

  &__filter-input {
    display: flex;
  }

  &__select-input {}

  .form-input--select {
    width: 100px;

    .form-input__input--select {
      padding-top: 3.5px;
      padding-bottom: 3.5px;
      border-radius: 0;
    }

    .icon-sort {
      bottom: 9px;
    }
  }

  &.has-active-filter {
    .dli-filterable-search__filter-icon {
      background: $global-primary-color;
    }
  }
}
