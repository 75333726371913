@mixin modal-scrollbars {
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: #ececec;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(113, 114, 116, 0.7);
  }
}

.modal {
  $modal-border-radius: 10px;

  @include stack-context(modal);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  &__backdrop {
    @include backdrop;
    @include modal-scrollbars;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &__body-wrap {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 80%;
    max-width: 820px;
    min-height: 360px;
    padding: 10vh 20px;

    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }

  &__body {
    max-width: 100%;
    background: #fff;
    border-radius: $modal-border-radius;

    &:focus {
      outline: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    min-height: 35px;
    margin-bottom: 4px;
    padding: 0 15px;
    border-radius: $modal-border-radius $modal-border-radius 0 0;
    background-color: color-customisation(modalHeaderBackgroundColor, color(purple));
    color: color-customisation(modalHeaderColor, #fff);
  }

  &--risk {
    .modal__header {
      background-color: #FF3C46;
      color: #FFF;
    }
  }

  &--no-risk {
    .modal__header {
      background-color: #6DBD54;
      color: #FFF;
    }
  }

  &__header-actions {
    margin-left: auto;
  }

  &__close-button {
    display: flex;
    align-items: center;
    margin-left: 6px;
    padding: 0;
    opacity: 0.85;

    &,
    .icon {
      width: 18px;
    }

    .icon {
      fill: currentColor;
      height: auto;
    }

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: 1px solid #fff;
      outline-offset: 0;
      height: 18px;
    }
  }

  &__title {
    @include font-secondary(600);
    margin: 0;
    font-size: 13px;

    .icon {
      width: 14px;
      height: 14px;
      vertical-align: top;
      margin-right: 10px;
      fill: currentColor;
    }
  }

  &__content {
    margin: 18px;
  }

  &__content-message {
    margin: 0 0 12px;
  }

  &__footer-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  &__footer-action {
    flex: 1;

    & + & {
      margin-left: 10px;
    }
  }

  .focus-trap {
    display: flex;
    height: 100%;
  }

  &--fixed-size {
    .modal__body-wrap {
      width: 100vh;
      height: 100vh;
    }

    .modal__body {
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
      width: 100%;
    }

    .modal__header {
      flex: 0;
    }

    .modal__content {
      flex: 1;
    }
  }

  &--no-max-width {
    .modal__body-wrap {
      max-width: none;
    }
  }

  .form, .form__section-form-fields {
    padding-top: 0 !important;
    min-width: 610px;
  }

  &--data-service {
    .modal__backdrop {
      align-items: center;
      overflow-y: hidden;
    }

    .modal__body-wrap {
      width: unset;
      max-width: unset;
      min-height: unset;
      padding: unset;
    }
  }
}
