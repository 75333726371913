$loading-color: #c94d8b;

.icon {
  width: 1em;
  height: auto; // auto keeps the aspect ratio
}

.icon-loading {
  rect {
    fill: $loading-color;
  }
}


// Baseline-align icons to text
// Example:
// ```html
// <span class="icon-with-text">
//   <span class="icon-with-text__icon">
//     <svg class="icon icon-foo">...</svg>
//   </span>
//   <span class="icon-with-text__text">Foo</span>
// </span>
// ```
.icon-with-text {
  display: inline-block;
  vertical-align: baseline;

  &__text,
  &__icon {
    display: inline-block;
  }

  .icon {
    width: 1em;
    height: 1em;
    display: block;
  }

  // tweaks for individual icons
  .icon-folder,
  .icon-folder-open {
    position: relative;
    top: 0.1em;
  }
}

.icon-tree-structure {
  polygon {
    fill: currentColor;
  }
}
