@use "sass:math";

@mixin table-form-field {
  .form-field {
    margin-bottom: 0;
  }

  .form-field__label-wrap {
    display: none;
  }
}


// shared
.visual-amdl {
  // truncate long breadcrumb resource names
  &__breadcrumb-title {
    @include ellipsis;
    display: flex;
    flex: 0 1 auto;

    .breadcrumb {
      min-width: 0;

      &__action-item-title {
        @include ellipsis;
        display: flex;
        flex-flow: row nowrap;
        min-width: 0;
      }

      &__resource-name {
        @include ellipsis;
        margin: 0 3px;
      }
    }

    .visual-amdl__breadcumb-action {
      display: flex;
      min-width: 0;
    }
  }

  &.panel--sticky-headers {
    // this needs to be above the FormFieldAmdl
    $sticky-header-zindex: 7;

    // position this panel below the analytics header so the analytics header dropdowns dont go underneath the panel
    position: relative;
    z-index: 0;

    .panel__header {
      z-index: $sticky-header-zindex;
    }

    .visual-amdl-details__panel-subheader {
      position: sticky;
      top: 26px; // primary panel header height
      z-index: $sticky-header-zindex;
      background: #fff;
    }
  }
}


// Import page
.visual-amdl-import {
  &__select-workflow-field {
    @include table-form-field;
  }
}


// List page
.visual-amdl-list {
  &__assign-event-types-intro {
    color: color(grey);
    font-size: 14px;
    word-break: break-word;
  }
}

.visual-amdl-details {
  &__panel-group:not(:last-child) {
    margin-left: 0;
  }
}


// Properties section
.visual-amdl-properties {
  .form__section-form-fields {
    max-width: 900px;
  }

  .visual-amdl-support-variables {
    margin-top: -25px;
    padding-top: 0;
  }
}


// Rule sets details page
.visual-amdl-rule-sets-details {
  &__toggle-button[aria-pressed="true"] {
    &,
    &:hover {
      background: #dedede;
    }
  }

  .form__section-form-fields {
    max-width: 1280px;
  }

  &__amdl-tester-field {
    margin-bottom: 25px;

    .amdl-tester {
      height: 400px;
    }
  }

  &__variables-section {
    display: none;

    &.is-visible {
      display: block;
    }

    .visual-amdl-support-variables {
      padding-top: 0;
    }

    .form__section-form-fields {
      padding-top: 0;
    }
  }
}


// Support variables section
.visual-amdl-support-variables {
  $fields: 3;

  padding-top: 15px;
  padding-bottom: 50px;

  .form-field__group-list-item-fields {
    flex: 1 1 auto;

    > .form-field {
      flex: 0 0 auto;
      width: floor(math.div(100%, $fields));
    }
  }
}


// Parameters section
.visual-amdl-parameters {
  $field-input-height: 35px;
  $fields: 4;
  $overrideable-field-width: 100px;
  $overrideable-field-position: 5;

  padding-bottom: 25px;

  .form-field__group-list-item-fields {
    flex: 1 1 auto;

    > .form-field {
      flex: 0 0 auto;
      width: calc((100% - #{$overrideable-field-width}) / #{$fields});

      &:nth-child(#{$overrideable-field-position}) {
        width: $overrideable-field-width;
        flex: 1 1 auto;

        .form-field__form-input-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: $field-input-height;
        }
      }
    }
  }
}


// Scorecards field
.visual-amdl-scorecards-field {
  $inline-layout-breakpoint: 1260px;
  $button-width: 35px;
  $button-height: $button-width;
  $field-label-height: 28px;
  $matcher-remove-button-margin: 6px;
  $matcher-gutter: 10px;
  $matcher-condition-width-proportion: 40%;
  $matcher-condition-width: calc(#{$matcher-condition-width-proportion} - #{$matcher-gutter * 2});
  $matcher-effects-width: calc(#{100% - $matcher-condition-width-proportion} - #{$button-width + $matcher-remove-button-margin + ($matcher-gutter * 2)});

  &__toggle-scorecards-expanded-button {
    margin-left: 10px;
  }

  &__collapsed-scorecard-info {
    color: color(grey);
    word-break: break-word;
  }

  .form-field__label-wrap {
    flex: 1 1 auto;
  }

  &__scorecard {
    @include dropdown;
    position: relative;
    margin: 50px 0;
    padding: 10px;
  }

  &__scorecard-remove-button {
    position: absolute;
    top: -20px;
    right: -15px;
    border-radius: 100%;
  }

  &__scorecard-reorder-buttons {
    position: absolute;
    top: -20px;
    right: 15px;
  }

  &__scorecard-reorder-button {
    border-radius: 100%;
    margin-right: 4px;
  }

  &__matchers {
    padding-top: 10px;
  }

  &__matchers-titles {
    display: flex;
    margin-bottom: 10px;
  }

  &__matchers-title {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: color(grey);
    border: 1px solid color(greyLight);
    border-bottom: 0;

    &--condition {
      flex: 0 0 auto;
      width: $matcher-condition-width;
      margin-left: $button-width + $matcher-remove-button-margin;
      margin-right: $matcher-gutter * 2;
    }

    &--effects {
      flex: 1 0 auto;
      width: $matcher-effects-width;
    }
  }

  &__matchers-title-text {
    position: relative;
    top: -10px;
    padding: 0 6px;
    background-color: #fff;
  }

  &__matcher {
    display: flex;
    flex-flow: row nowrap;
  }

  &__matcher-remove-button {
    width: $button-width;
    height: $button-height;
    margin-right: $matcher-remove-button-margin;
    top: $field-label-height;
  }

  &__matcher-condition {
    flex: 1 0 auto;
    width: $matcher-condition-width;
    padding-right: $matcher-gutter * 2;
  }

  &__matcher-effects {
    flex: 1 0 auto;
    width: $matcher-effects-width;
    margin-bottom: 16px;
  }

  &__matcher-effect {
    display: block;
    flex: 1 0 auto;

    .form-field__label {
      @include ellipsis;
    }
  }

  &__matcher-effect-remove-button {
    width: $button-width;
    height: $button-width;
    margin-bottom: 25px;
  }

  @media screen and (min-width: $inline-layout-breakpoint) {
    &__matcher-remove-button {
      top: $field-label-height;
    }

    &__matcher-effect-remove-button {
      top: $field-label-height;
    }

    &__matcher-effect-data-list-properties-button {
      display: flex;
      align-items: flex-end;

      .action {
        height: 35px;
      }
    }

    &__matcher-condition {
      $fields: 3;

      display: flex;
      flex: 1 0 auto;
      width: $matcher-condition-width;
      padding-right: $matcher-gutter;

      > *:not(:first-child) {
        margin-left: $matcher-gutter;
      }

      .form-field {
        flex: 0 0 auto;
        width: calc(#{floor(math.div(100%, $fields))} - #{$matcher-gutter});
      }

      .form-field__label {
        @include ellipsis;
      }
    }

    &__matcher-effect {
      $fields: 4;

      display: flex;
      margin-bottom: 0;

      > .form-field {
        flex: 0 0 auto;
        width: calc((100% - #{$button-width} - #{$matcher-gutter * $fields}) / #{$fields});
        margin-right: $matcher-gutter;
        margin-bottom: 10px;
      }
    }

    &__matcher-effect-remove-button {
      margin-bottom: 0;
      margin-left: auto;
    }
  }
}


// override properties
.visual-amdl-override-properties {
  &__override-field {
    @include table-form-field;
  }
}


// override rule set details
.visual-amdl-override-rule-sets-details {
  &__fields {
    padding-top: 50px;
  }

  &__static-field {
    display: flex;
    margin-bottom: 25px;
    color: color(grey);
    font-size: 14px;

    &-label {
      padding-right: 10px;
      flex: 0 0 100px;
    }

    &-value {}
  }

  &__scorecard-enabled-field {
    @include table-form-field;
  }
}
