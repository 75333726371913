.bulk-select {
  $dropdown-background-color: #fff;
  $item-vertical-gutter: 20px;

  position: relative;
  display: block;
  font-size: 13px;

  &__trigger-button {
    display: block;
    width: 100%;
  }

  &__trigger-button-icon {
    .icon {
      margin: 0 0 0 10px !important;
    }
  }

  &__dropdown {
    @include dropdown;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    background-color: $dropdown-background-color;

    &.is-expanded {
      display: block;
    }
  }

  &__dropdown-actions {
    display: flex;
    padding: 10px;

    > * {
      flex: 1;
    }

    * + * {
      margin-left: 6px;
    }
  }

  &__options-list {
    max-height: 200px;
    overflow: auto;
    margin: 0;
    padding: 0;
    list-style: none;

    &--empty {
      padding: 10px;
      color: color(grey);
    }
  }

  &__option-list-item {
    display: flex;
    padding: 10px 0 2px;

    & + & {
      border-top: 1px solid color(greyLight);
    }
  }

  &__option-group {
    width: 100%;
    min-width: 0;
  }

  &__option-group-title {
    @include ellipsis;
    display: block;
    position: sticky;
    top: 0;
    min-width: 0;
    font-weight: 600;
    padding: 6px $item-vertical-gutter;
    color: color(grey);
    background-color: $dropdown-background-color;
    line-height: 1.2;
    z-index: 1; // fixes stacking issue with disabled checkbox opacity
  }

  &__option-group-list {
    margin: 12px 0 0;
    padding: 0;
    list-style: none;
  }

  &__option {
    @include reset-button;
    display: flex;
    width: 100%;
    padding: 0 $item-vertical-gutter;
    margin-bottom: 16px;
    line-height: 1.2;

    .checkbox {
      width: 100%;
    }
  }

  &__option-icon {
    margin-right: 10px;
  }

  &__option-label {
    @include ellipsis;
  }
}
