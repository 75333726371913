// page: list
.page-model {
  &__main-table {
    // "mode" icon
    .table__header-item:nth-child(2) { width: 1px; }
    .table__cell:nth-child(2) { text-align: center; }
  }

  &__mode-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -6px;

    &--active { fill: $color-ok; }
    &--inactive { fill: $color-danger; }
    &--silent { fill: $color-warning; }
  }

  .table__cell--html a {
    color: $global-link-color;
  }

  &__error-cell {
    &--success {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      color: $color-danger;
      text-decoration: underline;

      div, span {
        padding-left: 4px;
      }

      .icon {
        fill: $color-danger;
      }

      .icon-exclamation-triangle {
        height: 19px;
      }
    }

    &--failure {
      .icon {
        fill: $color-danger;
      }
    }

    &--loading {
      .icon {
        fill: #e3e3e3;
      }
    }
  }

  &__error-extendable {
    .icon-circle {
      fill: $color-danger;
      padding-top: 4px;
      padding-right: 2px;
    }
  }

  &__error-content {
    white-space: normal;
  }

  &__risk-level-section {
    .form-field:last-child {
      margin-bottom: 0;
    }
  }

  .sparkline {
    padding: 0;
    margin-right: 5px;
    width: 90px;
    height: 25px;
  }

  .sparkline polyline {
    stroke-width: 2px;
  }

  .avg-value {
    padding-left: 4px;
    color: #9e9e9e;
    font-size: 13px;
  }
}


.page-model,
.page-model-details {
  &__risk-level-section:last-of-type {
    margin-bottom: 25px;
  }
}

//-field page: details
.page-model-details {
  .add-risk-level-button {
    margin-bottom: 25px;
  }

  &__model-blob-identifier-hint-warning {
    @include ui-note;
    margin-bottom: 0;
  }


  &__info-field, &__warning-field {
    &-message {
      padding: 10px 10px 10px 0;
    }

    $state-info-border-color: #d8d8d8;

    &--not-asked,
    &--loading,
    &--error {
      padding: 6px 0;
    }

    &--error {
      word-break: break-word;
      color: $color-danger;
    }

    &-content {
      @include ui-note;
    }

    .checkbox__label {
      margin-bottom: 0;
    }

    &-checkbox-hint {
      display: block;
      margin-top: 2px;
      margin-left: 24px;
      font-weight: $weight-02;
    }

    &-checkbox-required {
      margin-left: 4px;
    }

    &-item {
      color: $primary-text-color;
    }

    &-item:last-child &-expanded-content {
      border-bottom: 0;
    }

    &-dl {
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 0;
      border-bottom: 1px solid $state-info-border-color;
    }

    &-dl-key {
      flex: 1 0 auto;
    }

    &-dl-value {
      flex: 0 0 auto;
      margin-left: auto;
      color: $global-primary-color;
      font-weight: $weight-03;
    }

    &-expand-button {
      @include reset-button;
      flex: 0 0 auto;
      margin-right: 6px;

      .icon {
        display: block;
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &-expanded-content {
      padding: 8px 6px 8px 20px;
      border-bottom: 1px solid $state-info-border-color;
    }

    &-dl-expanded-item {
      display: block;
      margin-bottom: 2px;
      font-size: 13px;
    }

    &-checkbox {
      margin-top: 10px;
      font-weight: $weight-03;
    }
  }
}
