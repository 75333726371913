.dropzone-overlay {
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: rgba(#fff, 0.7);
    color: #000;
  }

  &__overlay-icon {
    @keyframes appear {
      from {
        transform: scale(0.4);
      }

      to {
        transform: scale(1);
      }
    }

    font-size: 80px;
    animation: appear 0.25s ease;

    .icon {
      display: block;
      fill: currentColor;
    }
  }
}
