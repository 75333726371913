.page-case-details {
  &.main-body {
    display: flex;
    flex-flow: column nowrap;

    .panel:last-child {
      /*
      The normal `.main-body .panel:last-child { min-height: 100% }` doesnt work here
      because the new page header will take up some of that 100% which causes a scrollbar to always be shown.
      The same effect is achieved using flexbox.
      */
      min-height: unset;
      flex: 1 1 auto;
    }
  }

  &__primary-action-content {
    min-width: 250px;
    padding: 14px;

    .form__section-form-fields {
      padding-top: 0;
    }
  }

  &__primary-action-close-content {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $ui-03;

    .form {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        "decision reason action comment         comment          comment"
        ".        reason action commentTemplate confirmNarrative confirmNarrative"
        ".        reason action footer          footer           footer";

      @include below(870px, $convert: false) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
          "decision        reason           action"
          "comment         comment          comment"
          "commentTemplate confirmNarrative confirmNarrative"
          "footer          footer           footer";

        .form-field:nth-child(6) {
          padding-left: 0;
        }
      }
    }

    .form-field {
      margin-bottom: 0;
      // prevent grid blowout from long input errors
      min-width: 0;
    }

    .form-field:nth-child(1) {
      grid-area: decision;
    }

    .form-field:nth-child(4) {
      grid-area: comment;
    }

    .form-field:nth-child(5) {
      grid-area: commentTemplate;

      .form-field__label-wrap {
        @include visually-hidden;
      }
    }

    .form-field:nth-child(6) {
      grid-area: confirmNarrative;
      padding-left: 16px;
      align-items: center;

      .form-field__label-wrap {
        padding-bottom: 0;
      }
    }

    .page-case-details__primary-action-close-action-custom {
      grid-area: action;
      // prevent grid blowout from long tags
      min-width: 0;
    }

    .page-case-details__primary-action-close-reason-custom {
      grid-area: reason;
      // prevent grid blowout from long tags
      min-width: 0;
    }

    .page-case-details__primary-action-close-summary {
      margin-top: 8px;
    }

    .form__footer {
      grid-area: footer;
      align-items: flex-start;
      justify-content: flex-end;

      .action {
        @include flex-ellipsis;
      }

      .action-submit {
        width: auto;
      }
    }

    .form__fieldset {
      display: contents;
    }

    .form__section-form-fields {
      display: contents;
    }
  }
}
