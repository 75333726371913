// $table-border-color: #ddd;
// $table-error-color: color(redFaded);
// $table-header-color: color(greyFaded);
$json-viewer-text-color: $primary-text-color;
$json-viewer-icon-color: $primary-text-color;
// $table-cell-link-color: color(purple);
// $table-cell-background-color: #fff;
$json-viewer-background-color-stripe: #f9f9f9;
$json-viewer-background-color-highlighted: $global-primary-selected-background-color;
$json-viewer-background-color-highlighted-alt: $global-primary-selected-background-color-alt;

.json-viewer {
  overflow: auto;

  &__table {
    width: 100%;
    table-layout: fixed;

    & + & {
      margin-top: 24px;
    }
  }

  &__grouping {
    &--highlight {
      border-bottom: 2px solid $global-primary-color;

      .json-viewer__cell {
        background: $global-primary-selected-background-color;
      }

      .json-viewer__row:nth-child(odd) {
        .json-viewer__cell {
          background: $global-primary-selected-background-color-alt;
        }
      }
    }
  }

  &__row {
    &:nth-child(odd) {
      background-color: $json-viewer-background-color-stripe;
    }
  }

  &__cell {
    position: relative;
    vertical-align: top;
    line-height: 19px;
    padding: 8px 8px 9px;
    white-space: nowrap;
    color: $json-viewer-text-color;
    overflow: hidden;

    &--value {
      border-left: 1px solid #ccc;

      .json-viewer__cell-content {
        justify-content: space-between;
      }
    }

    &:hover {
      .json-viewer__row-copy {
        opacity: 1;
        visibility: visible;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__cell-content {
    display: flex;
    align-items: center;
    min-width: 0;
  }

  &__key {
    @include ellipsis;
    display: flex;
    max-width: 100%;
    font-weight: $weight-04;
  }

  &__value {
    display: inline-block;
    white-space: normal;
    word-break: break-all;
  }

  &__toggle {
    @include reset-button;
    color: $json-viewer-text-color;

    .icon {
      color: currentColor;
    }

    &.is-invisible {
      visibility: hidden;
    }

    &:focus {
      outline: 1px solid $action-default-active-background-color;
    }
  }

  &__icon {
    display: inline-flex;
    margin-right: 7px;
    padding: 3px;
    border: 1px solid currentColor;
    border-radius: 2px;

    .icon {
      font-size: 13px;
      fill: $json-viewer-icon-color;
    }
  }

  &__toggle + &__icon {
    margin-left: 5px;
  }

  &__row-copy {
    margin-left: 3px;
    position: static;
    right: 8px;

    opacity: 0;
    visibility: hidden;
    transition: none 0.15s ease;
    transition-property: opacity, visibility;
  }

  &__row-copy-button {
    color: $secondary-text-color;
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 8px;
    border: 0;

    .icon {
      display: block;
      width: 13px;
      height: auto;
    }
  }

  &__noDataWarning {
    @include ellipsis;
    max-width: 100%;
  }

  &__copy-button {
    @include reset-button;
    position: relative;
    top: 2px;
    margin-left: 4px;
    padding: 0 6px;
    color: color(grey);
    transition: color 0.15s ease;

    &:hover {
      color: #000;
    }

    .icon {
      display: block;
      fill: currentColor;
    }
  }
}
