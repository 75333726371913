.page-usage-metrics {
  &__main-container {
    text-align: center;
  }

  &__icon {
    font-size: 96px;
    margin-top: 120px;

    .icon {
      fill: $global-primary-color;
      background-color: color(pinkLight);
    }
  }

  &__title {
    font-size: 20px;
  }

  &__subtitle {
    color: color(grey);
    font-size: 13px;
    font-weight: $weight-02;
    line-height: 17px;
    margin: 0;
  }

  &__actions {
    margin-top: 15px;

    * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
