$custom-filter-primary-color: color-customisation(sidebarPrimaryColor, color(purple));

.custom-filter {
  border-top: 1px solid $sidebar-border-color;
  padding: 13px 15px;

  &:first-of-type {
    border-top: 0;
  }

  .link,
  .link-button {
    color: $custom-filter-primary-color;
  }

  &__heading {
    @include reset-button;
    display: flex;
    align-items: center;
    width: 100%;
    color: #4a4a4a;
    font-weight: $weight-04;
    cursor: pointer;
    text-align: left;

    &-label {
      @include ellipsis;
      display: block;
      flex: 1;
    }

    &-icon {
      fill: $custom-filter-primary-color;
      vertical-align: middle;
    }

    &:focus {
      outline: 1px solid $custom-filter-primary-color;
      outline-offset: 2px;
    }
  }

  &__expanded-content {
    margin-top: 8px;
  }

  &__update-button {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .multirange {
    z-index: 0;
  }

  // Filter.Value
  &-value__inputs {
    display: flex;
    margin: 10px 0 0;
  }

  &-value__custom-select {
    display: flex;
    flex: 0 0 auto;
    width: 50%;
    margin-right: 6px;

    .custom-select__dropdown {
      width: 200%;
      left: 0;
    }
  }

  &-value__text-input {
    flex: 1 1 auto;
    width: 50%;
  }

  &-value__label {
    @include ellipsis;

    &-symbol {
      font-weight: $weight-04;
    }
  }

  .list-item {
    display: flex;

    &__symbol {
      margin-right: 1em;
    }
  }

  // Filter.DateRange
  &--date-range {
    .form-field {
      margin-bottom: 0;
    }

    .form-input--datetime-range {
      .form-field__label-wrap {
        flex: 0 0 auto;
        width: 50px;
        padding: 0 10px 0 0;
        align-items: center;
      }

      .form-input__datetime-wrapper {
        flex: 1 1 auto;
      }

      .form-input__part {
        width: 100%;
      }

      .form-input__part + .form-input__part {
        margin: 0;
      }
    }
  }

  // Filter.ScoreRange
  &--score-range {
    .multirange__slider-wrap {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__score-range-entity-type-select {
    margin: 6px 0 10px;
  }

  &__active-indicator {
    &__remove-button {
      cursor: pointer;
    }

    &__content {
      font-size: 12px;
    }
  }
}

// Filter.MultipleSelect
.multiple-select-filter-input {
  $search-input-height: 30px;
  $list-item-horizontal-padding: 2px;
  $button-horizontal-padding: 10px;

  &__header-text {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-flow: column nowrap;
    flex: 1 0 auto;
    margin: 4px 8px 4px 0;
  }

  &__title {
    display: flex;
    flex: 0 0 auto;
    margin: 0 0 8px;
  }

  &__bulk-toggle {}

  &__search {
    width: 100%;

    .form-input {
      width: 100%;
    }

    input {
      width: 100%;

      &::placeholder {
        font-size: 13px;
      }
    }
  }

  &__list-wrap {
    display: flex;
    flex: 0 1 auto;
    min-height: 0; // fix scroll in Firefox
    margin-top: 10px;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    max-height: 182px;
  }

  &__item {
    display: block;
    min-width: 0;
    flex: 0 0 50%;
    padding: $list-item-horizontal-padding;
    white-space: nowrap;
  }

  &__button {
    @include ellipsis;
    display: block;
    width: 100%;
    text-align: left;
    padding: 6px $button-horizontal-padding;

    &:focus {
      outline: 1px solid $custom-filter-primary-color;
    }

    &.is-selected {
      color: $custom-filter-primary-color;
      background-color: $global-primary-selected-background-color;
    }

    &.is-applicable {
      font-weight: $weight-04;
    }
  }
}
