@use "sass:math";

// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

$overall-height: 32px;

$track-color: #d4d4d4;
$thumb-color: #fff;

$thumb-radius: 2px;
$thumb-width: 16px;
$thumb-height: 22px;
$thumb-border-width: 1px;
$thumb-border-color: #a3a3a3;

$track-width: 100%;
$track-height: 6px;
$track-border-width: 0;
$track-border-color: transparent;

$track-radius: 0;
$contrast: 5%;

$multirange-range-color: $global-primary-color;
$multirange-active-range-height: 100%;
$multirange-tickmarks-color: #d2d2d2;


@mixin shadow($shadow-size,$shadow-blur,$shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color,5%);
}

@mixin track() {
  width: $track-width;
  height: $track-height;
  cursor: pointer;
}

@mixin thumb() {
  // @include shadow($thumb-shad ow-size,$thumb-shadow- blur,$thumb-shadow-color);
  border: $thumb-border-width solid $thumb-border-color;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-radius;
  background-color: $thumb-color;
  cursor: pointer;

  background-image:
    linear-gradient(to right, color(grey) 1px, transparent 1px),
    linear-gradient(to right, color(grey) 1px, transparent 1px);
  background-repeat: no-repeat;
  background-size: 1px 40%;
  background-position: 5px 50%, 8px 50%;
  box-shadow: 1px 1px 1px 0 rgba(#000, 0.12);
}

@mixin thumb-focus {
  border-color: #c94784;
  box-shadow: 0 0 5px 0 rgba(201,77,139,0.47);
}

.range-input {
  -webkit-appearance: none;
  width: $track-width;
  min-height: $overall-height; // prevents it collapsing to the height of the bar
  // margin: ($thumb-height / 2) 0;

  &:not(.multirange__input) {
    background: transparent;
  }

  // &:focus {
  //   outline: 1px solid #c94784;
  // }

  &::-webkit-slider-runnable-track {
    @include track;
    border: $track-border-width solid transparent;
  }

  &:not(.multirange__input--ghost)::-webkit-slider-runnable-track {
    background: $track-color;
    border-radius: $track-radius;
    border-color: $track-border-color;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: (math.div(-$track-border-width * 2 + $track-height, 2)) - (math.div($thumb-height, 2));
  }

  &:focus::-webkit-slider-thumb {
    @include thumb-focus;
  }

  // &:not(.multirange__input):focus::-webkit-slider-runnable-track {
  //   background: lighten($track-color, $contrast);
  // }

  &::-moz-range-track {
    @include track;
    border: $track-border-width solid transparent;
  }

  &:not(.multirange__input--ghost)::-moz-range-track {
    background: $track-color;
    border-radius: $track-radius;
    border-color: $track-border-color;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &:focus::-moz-range-thumb {
    @include thumb-focus;
  }

  // &::-ms-track {
  //   @include track();
  //   background: transparent;
  //   border-color: transparent;
  //   border-width: $thumb-width 0;
  //   color: transparent;
  // }
  //
  // &::-ms-fill-lower {
  //   background: darken($track-color, $contrast);
  //   border: $track-border-width solid $track-border-color;
  //   border-radius: $track-radius*2;
  //   // @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
  // }
  //
  // &::-ms-fill-upper {
  //   background: $track-color;
  //   border: $track-border-width solid $track-border-color;
  //   border-radius: $track-radius*2;
  //   // @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
  // }
  //
  // &::-ms-thumb {
  //   @include thumb();
  // }
  //
  // &:focus::-ms-fill-lower {
  //   background: $track-color;
  // }
  //
  // &:focus::-ms-fill-upper {
  //   background: lighten($track-color, $contrast);
  // }
}

// Based on https://github.com/LeaVerou/multirange
.multirange {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  &__value {
    display: block;
    margin-bottom: 4px;
    font-weight: $weight-03;
    font-size: 17px;
    color: color(grey);
  }

  &__inline-value {
    flex: 0 0 auto;
    width: 50px;
  }

  &__slider-wrap {
    flex: 1;
    padding: 0 14px;
  }

  &__inputs {
    position: relative;
  }

  &__input {
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: top;

    &::-moz-range-track {
      border-color: transparent; // needed to switch FF to "styleable" control
    }

    &-container {
      position: relative;
    }

    &--original {
      position: absolute;
      background: transparent;

      &::-webkit-slider-thumb {
        position: relative;
        z-index: 2;
      }

      &::-moz-range-thumb {
        transform: scale(1); // FF doesn't apply position it seems
        z-index: 1;
      }
    }

    &--ghost {
      position: relative;
      background: var(--track-background);
      --track-background:
        linear-gradient(
          to right,
          transparent var(--low),
          var(--range-color) 0,
          var(--range-color) var(--high),
          transparent 0
        ) no-repeat 0 50% / 100% #{$multirange-active-range-height};
      --range-color: #{$multirange-range-color};

      &::-webkit-slider-runnable-track {
        background: var(--track-background);
      }

      &::-moz-range-track {
        background: var(--track-background);
      }
    }
  }

  &__tickmarks {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    padding-top: 2px;
    color: #656565;
    margin: 2px auto 0;

    &::before {
      @include horizontal-center;
      content: '';
      flex: none;
      top: 0;
      width: 100%;
      height: 1px;
      margin: 0 auto;
      border-top: 1px solid $multirange-tickmarks-color;
    }
  }

  &__tickmarks-item {
    display: flex;
    flex-flow: column wrap;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 4px;
      background: $multirange-tickmarks-color;
      margin: -1px auto 0;
    }

    &:not(&--fixed) {
      @include horizontal-center;
    }

    &:first-child {
      &::before {
        margin-left: 0;
      }
    }

    &:last-child {
      &::before {
        margin-right: 0;
      }
    }
  }


  // modifier: multicolored score threshold
  // Example:
  // ```elm
  // FormInput.multiRange UpdateThresholds
  //   { defaultValues = ( 0, 100 )
  //   , id = "risk-score-thresholds"
  //   , minimum = 0
  //   , maximum = 100
  //   , ticks = []
  //   }
  //   { values = state.thresholdValues
  //   , sliderValues = state.thresholdSliderValues
  //   , textValues = state.thresholdTextValues
  //   }
  //   |> FormInput.setClass "multirange--score-threshold"
  // ```
  &--score-threshold {
    .multirange {
      &__slider-connects {
        --track-background:
          linear-gradient(
            to right,
            #{$color-ok} var(--low),
            var(--range-color) 0,
            var(--range-color) var(--high),
            #{$color-danger} 0
          ) no-repeat 0 50% / 100% #{$multirange-active-range-height};
        --range-color: #{$color-warning};
      }
    }
  }

  // Slider, based of https://refreshless.com/nouislider/
  &__slider {
    background: #d4d4d4;
    margin: 13px 0;
    height: 6px;
    width: 100%;

    &-container {
      margin-left: 5px;
      margin-right: 11px;
    }

    &-target {
      position: relative;
    }

    &-base,
    &-connects {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    &-connects {
      overflow: hidden;
      z-index: 0;
      background: var(--track-background);
      --track-background:
        linear-gradient(
          to right,
          transparent var(--low),
          var(--range-color) 0,
          var(--range-color) var(--high),
          transparent 0
        ) no-repeat 0 50% / 100% #{$multirange-active-range-height};
      --range-color: #{$multirange-range-color};
    }

    &-origin {
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      -ms-transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      -webkit-transform-style: preserve-3d;
      transform-origin: 0 0;
      transform-style: flat;
    }

    &-handle {
      @include thumb;
      position: absolute;
      right: -8px;
      top: -8px;

      &:focus {
        @include thumb-focus;
        outline: none;
      }
    }
  }
}
