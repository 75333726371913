.page-settings-import-export {
  .form__fieldset {
    margin-top: 30px;
    padding-top: 0;
  }

  &__intro {
    @include ui-note;
    margin-bottom: 30px;
  }
}
