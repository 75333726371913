.page-user-role-list {
  &__table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .table-per-page {
    margin-bottom: 0;
  }

  &__scope-filter-wrap {
    display: flex;
    align-items: center;

    .icon-sort {
      bottom: 7px;
    }
  }

  &__scope-filter-label {
    padding-right: 14px;
  }

  &__scope-filter-input {
    height: 28px;
    line-height: 16px;
  }
}
