.page-configuration {
  .panel__tab {
    &--active:focus {
      outline: 0;
    }

    .icon {
      fill: $color-danger;
    }

    .tab-with-icon {
      position: relative;
      top: 1px;
      margin-left: 5px;
    }
  }

  &__main-panel {
    .panel__header {
      min-height: 40px;
    }
  }

  .panel--fields-new {
    .panel__body {
      padding: 0;
    }

    .form-field--configuration-field {
      .form__section--block:first-child {
        border: none;
        box-shadow: none;
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &__incidents-list-manage,
  &__case-list-manage,
  &__event-list-manage {
    padding: 25px;
  }

  &__incidents-list-columns,
  &__case-list-columns,
  &__event-list-columns {
    flex: 1 1 auto;
  }

  &__table-filtering {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .form-input--select {
      select {
        margin-left: 5px;
        height: 28px;
        line-height: 16px;
      }

      .icon-sort {
        bottom: 7px;
      }
    }
  }

  &__table-filtering-actions {
    margin-left: auto;

    & > * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__ui-editor-content {
    position: relative;
    flex: 1;
    display: flex;
    overflow: hidden;

    &__sidebar,
    &__main {
      flex: 0 0 auto;
      margin-right: 10px;
      padding: 0 10px;
    }

    &__main {
      flex: 1 1 auto;
    }

    &__sidebar {
      background-color: color-customisation(sidebarBackgroundColor, $sidebar-background-color);
      min-width: 201px;
    }

    .sidebar-right-ui-editor {
      &__backdrop {
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &-manage-fields {
        margin-top: 12px;
      }

      .panel__wrapper {
        background-color: inherit;
      }

      .panel__header {
        background-color: inherit;
      }

      .panel__group-left {
        margin-left: 0;
      }

      .panel__section-form-fields {
        padding-top: 20px;
      }
    }
  }

  &__summary {
    padding: 25px 0;

    &-title {
      h1,
      h2 {
        display: inline;
      }

      h2 {
        color: #9a9a9a;
        padding-left: 10px;
      }
    }
  }

  &__timer--wrapper {
    color: $primary-text-color;
    display: flex;
    justify-content: space-between;

    .page-configuration__timer {
      opacity: 0.6;
      text-align: right;
      margin-bottom: 10px;
      margin-right: 16px;

      span {
        position: relative;
      }

      svg {
        left: -15px;
        position: absolute;
        top: 3px;
      }
    }
  }

  &__summary-manage-details-wrapper {
    display: flex;
    justify-content: space-between;

    .update-status {
      color: $primary-text-color;
      margin-top: 10px;
    }
  }

  &__incident-details-tabs-panel {
    .panel__header {
      height: 40px;
    }
  }

  .panel__section-form-fields-wrapper {
    position: relative;
  }

  .footnote {
    background: #f8f7df;
    border: 1px solid #e6db95;
    margin: 16px 0;
    padding: 14px;
    color: color(grey);
    line-height: 1.35;

    p {
      margin: 0 0 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form__sub-section {
    display: flex;
    align-items: center;

    .form-field:not(:first-child) {
      margin-left: 20px;
    }
  }

  .form__preview {
    color: color(grey);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
  }

  .action-toggle {
    height: 35px;
    margin-bottom: 15px;
    margin-left: 25px;
  }

  .currency-format {
    font-size: 20px;
    color: $primary-text-color;

    &__hint {
      color: $secondary-text-color;
      font-size: 12px;
    }
  }

  .actions {
    display: flex;

    &__group {
      margin-left: auto;

      & > * {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .page-configuration__ui-editor-content__sidebar {
    .form__section {
      padding-top: 50px;
    }
  }


  &__multi-select-options {
    display: flex;
    flex-wrap: wrap;
  }

  &__multi-select-option {
    display: flex;
    align-items: center;
    padding: 2px;
    //margin-top: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: $weight-02;
    color: $secondary-text-color;
    margin: 1px;

    &-label {
      flex: 1;
      @include ellipsis;
    }

    &-remove {
      display: flex;
      align-items: center;
      border: 0;
      width: 11px;
      padding: 0;

      .icon {
        height: 13px !important;
        margin: 0 !important;
      }
    }
  }

  .table {
    .page-configuration__multi-select-option .action {
      height: 13px;
    }
  }

  .data-service-selector {
    td:last-child {
      max-width: 200px;
    }
  }
}
