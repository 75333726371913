.json-view {
  margin: 20px 0;

  &__header {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    margin-bottom: 10px;
  }

  &__header-error {
    margin-left: 4px;
    font-weight: $weight-03;
    color: $color-danger;
  }

  &__subtitle {
    @include section-title;
    margin-bottom: 0;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
  }

  &__error {
    color: $color-danger;
  }
}
