$weight-01: 300; // lighter
$weight-02: 400; // normal
$weight-03: 500; // semibold
$weight-04: 600; // bold

$default-font-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Context heading
@mixin heading-01 {
  font-size: 0.875rem;
  font-weight: $weight-03;
  line-height: 1.25rem;
}

.heading-01 { @include heading-01; }

// Section heading
@mixin heading-02 {
  font-size: 1rem;
  font-weight: $weight-02;
  line-height: 1.25rem;
}

.heading-02 { @include heading-02; }

// Region heading
@mixin heading-03 {
  font-size: 1.25rem;
  font-weight: $weight-02;
  line-height: 1.625rem;
}

.heading-03 { @include heading-03; }

// Page heading
@mixin heading-04 {
  font-size: 1.75rem;
  font-weight: $weight-02;
  line-height: 2.25rem;
}

.heading-04 { @include heading-04; }

@mixin body-short-text-00 {
  font-size: 0.75rem;
  font-weight: $weight-02;
  line-height: 1rem;
}

.body-short-text-00 { @include body-short-text-00; }

@mixin body-short-text-01 {
  font-size: 0.875rem;
  font-weight: $weight-02;
  line-height: 1.125rem;
}

.body-short-text-01 { @include body-short-text-01; }

@mixin body-long-text-01 {
  font-size: 0.875rem;
  font-weight: $weight-02;
  line-height: 1.25rem;
}

.body-long-text-01 { @include body-long-text-01; }

@mixin body-short-text-02 {
  font-size: 1rem;
  font-weight: $weight-02;
  line-height: 1.375rem;
}

.body-short-text-02 { @include body-short-text-02; }

@mixin body-long-text-02 {
  font-size: 1rem;
  font-weight: $weight-02;
  line-height: 1.5rem;
}

.body-long-text-02 { @include body-long-text-02; }

@mixin code-text {
  font-family: "Roboto Mono", monospace;
  font-size: 0.875rem;
  font-weight: $weight-02;
  line-height: 1.125rem;
}

.code-text { @include code-text; }

@mixin label-text {
  font-size: 0.75rem;
  font-weight: $weight-02;
  line-height: 1.125rem;
}

.label-text { @include label-text; }

@mixin helper-text {
  font-size: 0.75rem;
  font-weight: $weight-02;
  line-height: 1.125rem;
}

.helper-text { @include helper-text; }

@mixin button-text-01 {
  font-size: 0.875rem;
  font-weight: $weight-02; // We can experiment with the weight here. Maybe Semibold
  line-height: 1.125rem;
}

.button-text-01 { @include button-text-01; }

// Use of this font size should be limited to 2 lines.
@mixin expressive-text-01 {
  font-size: 2rem;
  font-weight: $weight-01;
  line-height: 2.5rem;
}

.expressive-text-01 { @include expressive-text-01; }

// Use of this font size should be limited to 2 lines.
@mixin expressive-text-02 {
  font-size: 2.625rem;
  font-weight: $weight-01;
  line-height: 3rem;
}

.expressive-text-02 { @include expressive-text-02; }
