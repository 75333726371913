.page-service-configuration {
  $tooltip-margin: 10px;

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: all;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__service-list-link {
    font-weight: $weight-03;
  }

  &__service-list-unpublished-label {
    font-weight: $weight-02;
    color: color(grey);
  }

  &__deploying-service,
  &__online-service,
  &__offline-service {
    .icon {
      height: 19px;
      width: 13px;
      vertical-align: top;
    }
  }

  &__online-service {
    .icon {
      fill: #71bc41;
    }
  }

  &__deploying-service {
    .icon {
      fill: #f0ad4e;
    }
  }

  &__offline-service {
    color: $tertiary-text-color;

    .icon {
      fill: $tertiary-text-color;
    }
  }

  &__publish-note {
    @include ui-note;
    white-space: normal;

    .icon-exclamation-triangle {
      display: inline-block;
      vertical-align: baseline;
      position: relative;
      top: 1px;
    }
  }

  &__tooltip {
    @include stack-context(message-tooltip);
    position: relative;

    &--version-selector {
      display: flex;
      align-items: center;

      .page-service-configuration__tooltip-content {
        width: 160px;
        padding: 6px 0;
      }
    }
  }

  &__tooltip-arrow {
    @include horizontal-center;
    bottom: calc(-#{$tooltip-margin} - 1px);
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: index($stack, message-tooltip) + 1;

    &::before {
      position: relative;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: '';
    }

    &--top {
      border-bottom-color: $tooltip-border-color;
      border-width: 0 6px 6px;

      &::before {
        top: 1px;
        left: -5px;
        border-bottom-color: $tooltip-background-color;
        border-width: 0 5px 5px;
      }
    }
  }

  &__tooltip-content {
    border: 0;
    min-width: 280px;
    padding: 14px;

    .form-field {
      display: block;
      margin-bottom: 5px;
    }

    .form-field__label-wrap {
      @include font-secondary(600);
      font-size: 13px;
      padding-right: 0;
      max-width: none;
    }

    .page-service-configuration {
      &__nodes-title {
        @include font-secondary(600);
        display: block;
        margin: 12px 0 6px;
        color: color(grey);
        font-size: 13px;
      }

      &__nodes {
        padding: 5px 0;
        margin-bottom: 5px;
        max-height: 240px;
        overflow: auto;

        &::-webkit-scrollbar:vertical {
          width: 9px;
        }

        &::-webkit-scrollbar-thumb:vertical {
          border-left: 1px solid #fff;
        }
      }

      &__node-details {
        display: flex;
        flex-direction: row;
        font-weight: $weight-03;
        color: #7e7e7e;

        .form-input--checkbox {
          flex: 1;

          .checkbox__label-text {
            margin-right: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 280px;
          }
        }
      }

      &__node-status {
        .icon {
          width: 15px;
          height: 15px;
          vertical-align: top;
        }

        &--success,
        &--up-to-date {
          .icon {
            fill: #5ebc4c;
          }
        }

        &--updating {
          .icon {
            fill: #d54a89;
          }
        }

        &--error {
          .icon {
            fill: color(redFaded);
          }
        }

        &--error-message {
          color: color(redFaded);
          margin-bottom: 10px;
          white-space: normal;
        }
      }

      &__auto-rollback {
        border-top: 1px solid #DEDEDE;
        padding-top: 8px;

        label {
          margin-bottom: 0;
        }

        span {
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__tooltip-actions {
    display: flex;
    margin-top: 6px;
  }

  &__tooltip-action {
    flex: 0 1 auto;

    &--primary {
      flex: 1 1 auto;
    }
  }

  &__warning-message {
    background-color: #FC3F5E;
    color: #FFF;
    display: flex;
    padding: 5px;
    line-height: 32px;
    margin-bottom: 6px;
    font-weight: $weight-04;

    .editor-toolbar__group {
      margin-left: auto;
      padding: 0;
    }

    .icon {
      fill: #FFF;
      height: 32px;
      vertical-align: top;
      width: 13px;
      margin: 0 11px;
    }
  }

  &__version-selector,
  &__version-selector-panels {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    height: 100%;
  }

  &__version-selector-toggle-menu {
    font-weight: $weight-04;

    &,
    .icon-with-text__text {
      text-decoration: underline;
    }

    .icon {
      width: 9px;
      height: 9px;
      margin: 0 0 0 3px;
    }
  }

  &__version-selector-filter {
    width: 170px;
  }

  &__version-selector-preview {
    width: 170px;
  }

  &__version-selector-list {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 295px;
    overflow-x: auto;
  }

  &__version-selector-item {}

  &__version-selector-item-version-label {
    @include ellipsis;
    display: block;
  }

  &__version-selector-item-timestamp {
    @include ellipsis;

    &,
    &.is-active {
      display: block;
      margin-top: 4px;
      font-weight: $weight-02;
      font-size: 13px;
      color: color(grey);
    }
  }

  &__changes-preview {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 6px;
    padding: 10px;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
  }

  &__changes-preview-header {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 6px;
    color: color(grey);
    font-weight: $weight-03;
  }

  &__changes-preview-version-left,
  &__changes-preview-version-right {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 1;

    .tag {
      margin-left: 6px;
    }
  }

  &__changes-preview-version-left {}

  &__changes-preview-version-right {
    padding-left: $ace-diff-gutter-width;
  }
}
