$header-background-color: $primary-background-color;
$header-link-color: $primary-text-color;
$header-link-color-active: $global-link-color;
$header-brand-gradient-height: 4px;
$header-height: 63px;
$header-sm-height: 40px;
$header-xllg-break: 1600px;
$header-xlg-break: 1392px;
$header-lg-break: 1226px;
$header-md-break: 1169px;
$header-ms-break: 1092px;
$header-sm-break: 768px;

$sidebar-background-color: color-customisation(sidebarBackgroundColor, color(greyHeader));

$nav-toggle-button-size: 26px;
$nav-link-vertical-padding: 20px;
$nav-link-horizontal-padding: 20px;
$nav-link-horizontal-padding-xlg-break: 12px;
$nav-link-horizontal-padding-lg-break: 5px;
$nav-link-horizontal-padding-md-break: 5px;
$nav-link-horizontal-padding-sm-break: 5px;
$nav-item-border-color: #cfcfcf;
$nav-dropdown-margin-top: 8px;

@mixin header-link-active-state {
  color: $header-link-color-active;

  .icon {
    fill: $header-link-color-active;
  }
}

@mixin overlay-header {
  .nav-toggle-button {
    display: flex;

    &:focus {
      outline: 1px solid $global-link-color;
      outline-offset: -4px;
    }
  }

  .top-header__search {
    margin-left: auto;
    flex-grow: 1;
  }

  .nav-toggle-button {
    margin-left: 10px;
    flex: 0 0 auto;
  }
}

@mixin overlay-nav {
  @include stack-context(global-nav-overlay);
  display: none;
  background-color: inherit;

  .global-nav__list {
    flex-flow: column;
  }

  .is-open,
  .global-nav__item {
    border-bottom: 1px solid $nav-item-border-color;
  }

  .global-nav__list:last-child .global-nav__item:last-child {
    border-bottom: none;
  }

  .global-nav__link,
  .global-nav__dropdown-menu .global-nav__link {
    width: 100%;
    padding: 16px;
    text-align: center;
  }

  .dropdown-toggle {
    display: none;
  }

  .global-nav__dropdown-menu {
    display: block;
    position: static;
    max-width: none;
    border: 0;
    box-shadow: none;
  }

  &.is-open {
    display: block;
    background-color: $header-background-color;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 6px;
    z-index: 9;
    overflow: auto;
    // fallback if no CSS var support
    height: calc(100vh - #{$header-brand-gradient-height} - #{$header-height});
    /* stylelint-disable length-zero-no-unit */
    // these are deliberately using `0px`, using `0` is not the same!
    height: calc(100vh - #{$header-brand-gradient-height} - #{$header-height} - var(--overlay-nav-offset-environment-title-bar, 0px) - var(--overlay-nav-offset-take-control-bar, 0px) - var(--overlay-nav-offset-message-banner-bar, 0px));
    /* stylelint-enable length-zero-no-unit */
  }
}

.top-header {
  display: flex;
  flex: 0 0 $header-height;
  flex-flow: row nowrap;
  background-color: $header-background-color;
  padding-top: 4px;
  position: relative;
  border-bottom: 1px solid $ui-04;

  &::before {
    @include brand-gradient;
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    flex: 0 0 auto;
    height: $header-brand-gradient-height;
    top: 0;
  }

  .nav-toggle-button {
    display: none;
    @include reset-button;
    margin-left: auto;
    padding: 14px;
    padding-left: 5px;

    .icon {
      display: block;
      width: $nav-toggle-button-size;
      height: $nav-toggle-button-size;
    }

    &__wrap {
      display: inline-flex;
      align-items: center;
    }

    &__link {
      @include font-primary(600);
      font-size: 16px;
      margin-left: 6px;
    }
  }

  // 1. This wrapper element prevents <svg>s in <button>s from adding
  // extra height to the button
  &__icon-wrap {
    display: flex;
    height: 19px;
  }

  &__logo-and-title {
    $expand-sidebar-button-width: 30px;
    $padding: $spacing-05;

    display: flex;
    align-items: center;
    flex: 0 1 auto;
    max-width: $sidebar-width;
    padding: 0 $padding;
    background-color: #fff;

    &-content {
      display: flex;
      align-items: center;
      min-width: 0; // flex overflow fix
    }

    @media screen and (max-width: $header-sm-break) {
      flex-basis: $sidebar-sm-width;

      &-content {
        display: none;
      }

      .application--sidebar-expanded & {
        flex-basis: $sidebar-width;

        &-content {
          display: inherit;
          max-width: $sidebar-width - ($padding * 2) - $expand-sidebar-button-width;
        }
      }
    }
  }

  &__logo {
    img {
      $logo-max-width: $spacing-08 * 3;
      $logo-max-height: $spacing-08;

      display: block;
      max-width: $logo-max-width;
      max-height: $logo-max-height;
      object-fit: contain;
    }
  }

  &__solution-title {
    @include ellipsis;
    @include body-short-text-01;
    display: block;
    flex: 1 1 auto;
    min-width: 0; // flex overflow fix
    margin-left: $spacing-05;
  }

  &__sidebar-toggle {
    display: none;

    @media screen and (max-width: $header-sm-break) {
      position: absolute;
      display: block;
      box-sizing: border-box;
      padding: 0;
      line-height: normal;
      top: 21px;
      left: 16px;
      z-index: 1;

      .application--sidebar-expanded & {
        left: 240px;
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    flex: 1;
  }

  @media screen and (max-width: $header-ms-break) {
    @include overlay-header;
  }

  &.has-open-nav {
    .nav-toggle-button {
      @include header-link-active-state;
    }
  }

  .application--main-fullscreen & {
    display: none;
  }
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  background-color: inherit;
}

.nav-icon-wrapper {
  display: flex;
  background-color: inherit;
}

.global-nav {
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  font-size: 17px;

  &__username {
    @include ellipsis;
    display: block;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: inherit;
  }

  &__item {
    background-color: inherit;

    &--with-dropdown {
      position: relative;
    }

    &.has-open-dropdown {
      @include header-link-active-state;
    }
  }

  &__link {
    @include reset-button;
    @include font-primary(700);
    display: block;
    width: 100%;
    padding: $nav-link-vertical-padding $nav-link-horizontal-padding;
    color: $header-link-color;
    text-decoration: none;
    white-space: nowrap;
    text-align: left;

    .icon {
      display: inline-block;
      width: 17px;
      height: 17px;
      fill: $header-link-color;
    }

    .icon-caret-down {
      width: 10px;
    }

    &.is-active,
    &:hover {
      @include header-link-active-state;
    }

    &:focus {
      outline: 1px solid $global-link-color;
      outline-offset: -1px;
    }

    &--documentation, &--user {
      padding-left: 10px;
      padding-right: 10px;
    }

    &--user-profile, &--logout {
      width: 100%;
    }
  }

  &__dropdown-menu {
    max-width: 560px;

    .global-nav__item {
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid $nav-item-border-color;
      }
    }

    .global-nav__link {
      padding: 10px;
      line-height: 1.25;
      min-width: 116px;

      &:focus {
        outline: 1px solid $global-link-color;
      }
    }

    &.is-open {
      display: block;
    }
  }

  @media screen and (max-width: $header-xlg-break) {
    font-size: 15px;

    &__link:not(.global-nav__link--user):not(.global-nav__link--documentation) {
      padding-left: $nav-link-horizontal-padding-xlg-break;
      padding-right: $nav-link-horizontal-padding-xlg-break;
    }
  }

  @media screen and (max-width: $header-lg-break) {
    font-size: 15px;

    &__link:not(.global-nav__link--user):not(.global-nav__link--documentation) {
      padding-left: $nav-link-horizontal-padding-lg-break;
      padding-right: $nav-link-horizontal-padding-lg-break;
    }
  }

  @media screen and (max-width: $header-lg-break) {
    font-size: 15px;

    &__link:not(.global-nav__link--user):not(.global-nav__link--documentation) {
      padding-left: $nav-link-horizontal-padding-lg-break;
      padding-right: $nav-link-horizontal-padding-lg-break;
    }
  }

  @media screen and (max-width: $header-sm-break) {
    font-size: 13px;

    &__link:not(.global-nav__link--user):not(.global-nav__link--documentation) {
      padding-left: $nav-link-horizontal-padding-sm-break;
    }
  }

  @media screen and (max-width: $header-ms-break) {
    @include overlay-nav;
  }
}

.cabinet.documentation-open {
  .top-header {
    @include overlay-header;
  }

  .global-nav {
    @include overlay-nav;
  }

  .amdl-editor-main-panel.is-fullscreen {
    right: 380px;
  }

  .main-systems-grid-full-screen {
    right: 380px !important;
  }
}

.application {
  &--banner-visible .top-header {
    --overlay-nav-offset-message-banner-bar: #{$banner-height};
  }

  &--banner-expanded .top-header {
    --overlay-nav-offset-message-banner-bar: #{$banner-height-expanded};
  }

  &--take-control-visible .top-header {
    --overlay-nav-offset-take-control-bar: #{$take-control-bar-height};
  }

  &--environment-title-visible .top-header {
    --overlay-nav-offset-environment-title-bar: #{$environment-title-bar-height};
  }

  &.has-expanded-omnisearch {
    .top-header {
      @include overlay-header;
    }

    .global-nav {
      @include overlay-nav;
    }
  }
}
