.page-report-analyst-headline-dashboard,
.page-report-analyst-review,
.page-report-performance,
.page-report-my-activity {
  &__table-cell + &__table-cell {
    padding-left: 8px;
  }
}

.page-custom-table-report {
  .table-header-label {
    padding: 8px;
    white-space: nowrap;
    border-bottom: 2px solid #DDD;
  }
}

.page-analyst-activity-log {
  &__advanced-search-toggle-button {
    .icon-angle-down {
      margin-right: 0 !important;
    }
  }

  &__advanced-search-content {
    width: 400px;
    padding: 14px;

    .form__section-form-fields {
      padding-top: 0;
    }
  }
}
