.amdl-metrics {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid color(greyLight);

  &__wrapper {
    display: flex;
    justify-content: center;
    margin: 5px 0;

    > div {
      display: flex;
      align-items: center;
    }
  }

  &__info {
    flex: 0 5 auto;
    min-width: 0;
    margin: 6px 6px 6px 0;
  }

  &__title {
    @include ellipsis;
    margin: 0 10px;
    font-size: 18px;
    font-weight: $weight-03;
    line-height: 20px;
  }

  &__metrics {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex: 1 1 auto;
    justify-content: center;
    min-width: 0;
  }

  &__metric {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    min-width: 0;
  }

  &__metric-details {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    min-width: 0;
    margin-left: 6px;
  }

  &__metric-title {
    @include ellipsis;
    width: 100%;
    display: block;
    font-size: 18px;
    font-weight: $weight-03;
    line-height: 20px;
    word-break: break-all;
  }

  &__metric-subtitle {
    @include ellipsis;
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: #a2a2a2;
  }

  &__metric-icon {
    flex: 0;
    padding: 8px;
    border-radius: 100%;
    background: $global-primary-color;

    .icon {
      display: block;
      position: relative;
      top: 1px;
      width: 26px;
    }
  }

  &__metric-graph {
    flex: 0 1 180px;
    margin: 0 20px;
  }

  &__errors {
    display: flex;
    flex-flow: row wrap;
  }

  &__errors-icon {
    fill: color(red);
    margin-right: 4px;
  }

  &__errors-action {
    margin-left: 4px;
  }

  .chart .chart-y-axis {
    opacity: 0;
  }

  .chart text {
    font-size: 13px;
    fill: color(grey);
  }
}
